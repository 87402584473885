import React, { useRef, useState, useEffect } from "react";
import ChatWithUsStyles from "../../../assets/styledComponents/channelConfiguration/chat/ChatWithUs";
import { Avatar, InputAdornment, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { useChannelContext } from "../../../context/channelConfigurationContext/channelContext";

import MessageView from "../../../components/reusable/MessageView/MessageViewNew";
import InputBox from "./InputBox";
import TrackMyOrder from "./TrackMyOrder";
import OrderContainer from "./OrderContainer";
import MessageLoading from "./MessageLoading";
import { getAllUserSetup } from "../../../service/service";
import { useTenantContext } from "../../../context/TenantContext";
const ChatWithUs = () => {
  const {
    uploadIcon,
    widgetFormattedList,
    setWidgetFormattedList,
    selectedWidgetShop,
    // orderStatus,
    // openTab,
    // setOpenTab,
    // initialTabState,
    // setTalkToHumanClicked,
    // trackOrderCount,
    // setTrackOrderCount,
    // disableTrackOrder,
    // setDisableTrackOrder,
    // showTrackOrder,
    // setShowTrackOrder,
    // handleTrackOrderClick,
    showInput,
    // setShowInput,
    // trackOrderItems,
  } = useChannelContext();
  const { tenantId } = useTenantContext();

  const selectedWidgetData = widgetFormattedList[selectedWidgetShop];
  if (!selectedWidgetData) return;

  const { appearance, orderStatus, contactFormValue } = selectedWidgetData;

  const {
    themeColor,
    // gradient,
    // textColor,
    greetingMessage,
    introMessage,
    // showAvatars,
    // icon,
    isAiBot,
  } = appearance;

  // const { title, message } = orderStatus;

  const [showMessageLoading, setShowMessageLoading] = useState(false);
  const [agnetUsers, setAllAgentUsers] = useState([]);


  // const initialBubble = ["trackOrder", "talkToHuman"];
  // const [showOrder, setShowOrder] = useState(false);
  // const scrollRef = useRef();

  const fetchUserSetup = async () => {
    try {
      const result = await getAllUserSetup({ tenantId });
      setAllAgentUsers(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserSetup();
  }, []);

  return (
    <ChatWithUsStyles {...appearance}>
      <div className="chat-container">
        <MessageView msg={introMessage} time={"02, Nov 23 7:12 PM "}  extraCssClass="disable-cursor" />
        <MessageView msg={"Ask me anything"} time={"02, Nov 23 7:12 PM "}   extraCssClass="disable-cursor" />
        <MessageView
          msg={`👋  Talk to human`}
          sender={true}
          textColor={appearance.textColor}
          bgColor={themeColor}
        />
        {isAiBot && 
        <>
        <MessageView
          msg={`🤩  Query Product Information`}
          sender={true}
          textColor={appearance.textColor}
          bgColor={themeColor}
        />

        <MessageView
          msg={`🤓  Query Knowledge Base`}
          sender={true}
          textColor={appearance.textColor}
          bgColor={themeColor}
        />
        </>
        }

   


      </div>
      <div id="footer">
        <div className="loading-container">
          {showMessageLoading && <MessageLoading />}
          {(!showMessageLoading && showInput) || true && (
            <InputBox
              textColor={appearance.textColor}
              themeColor={themeColor}
            />
          )}
        </div>
        <div id="image-attachment-stamp"></div>
      </div>
    </ChatWithUsStyles>
  );
};

export default ChatWithUs;
