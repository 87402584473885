import styled from "styled-components";

const ActionButtonGroupStyle = styled.div`
  .button-container {
    display: flex;
    gap: 1rem;
  }
  .loading-icon {
    display: flex;
    align-items: center;
  }
`;
export default ActionButtonGroupStyle;
