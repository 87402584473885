import React, { useEffect, useState } from "react";
import { FilterLabelStyles } from "../../assets/styledComponents/inboxMessage";

import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";

import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Stack,
} from "@mui/material";
import { getLabel } from "../../service/service";
import { useTenantContext } from "../../context/TenantContext";
import { getKey } from "../../utils/helperFunction";
import PrimaryButton from "../reusable/PrimaryButton/PrimaryButton";
import { useInboxContext } from "../../context/inboxContext/inboxContext";

const FilterLabel = ({
  handleClose,
  label,
  setLabel,
  filterLabel,
  setFilterLabel,
  loading,
  setSearchValue,
}) => {
  const { tenantId } = useTenantContext();
  const { setLabelFilterQuery, labelFilterQuery } = useInboxContext();
  const handleChange = (e, _id, labelName, idx) => {
    setLabel((prev) => {
      let updatedValue = [...prev];
      updatedValue[idx] = {
        ...updatedValue[idx],
        isChecked: e.target.checked,
      };
      return updatedValue;
    });
    if (e.target.checked) {
      setFilterLabel((prev) => [...prev, _id]);
    } else {
      setFilterLabel((prev) => {
        return prev.filter((item) => item !== _id);
      });
    }
  };
  useEffect(() => {
    console.log("ran");
    setFilterLabel([...filterLabel]);
  }, []);
  const applyFilter = () => {
    console.log(filterLabel);
    setLabelFilterQuery(filterLabel);
    handleClose();
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const skeleton = (
    <Stack sx={{ width: "99%", height: "200px" }}>
      {Array(4)
        .fill(0)
        .map((item, idx) => {
          return (
            <Skeleton key={idx} variant="text" sx={{ fontSize: "2.6rem" }} />
          ); 
        })}
    </Stack>
  );

  return (
    <FilterLabelStyles>
      <FormGroup>
        <div className="top">
          <h3>Label</h3>
          <button onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="search-container">
          <input
            type="search"
            onChange={handleSearchChange}
            placeholder="Search"
          />
          <div className="search-btn">
            <SearchIcon />
          </div>
        </div>
        {loading ? (
          skeleton
        ) : (
          <div className="label-container">
            {label.map(({ _id, labelName, isChecked }, idx) => {
              return (
                <React.Fragment
                  key={getKey("filterLabelOptions", labelName, idx)}
                >
                  <FormControlLabel
                    style={{ paddingLeft: "0.2rem" }}
                    control={<Checkbox />}
                    label={labelName}
                    onChange={(e) => handleChange(e, _id, labelName, idx)}
                    value={labelName}
                    checked={isChecked}
                  />
                </React.Fragment>
              );
            })}
          </div>
        )}
      </FormGroup>
      <div className="btn-container">
        <PrimaryButton onClick={applyFilter} variant={"contained"}>
          Apply
        </PrimaryButton>
      </div>
    </FilterLabelStyles>
  );
};

export default FilterLabel;
