import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { LabelModalStyles } from "../../assets/styledComponents/message";
import {
  attachLabelToChatSession,
  getLabel,
  getSingleChatSession,
} from "../../service/service";
import { useTenantContext } from "../../context/TenantContext";
import { Button, Checkbox, FormControlLabel, Skeleton, Stack } from "@mui/material";
import PrimaryButton from "../reusable/PrimaryButton/PrimaryButton";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import _ from "lodash";
import useDebounce from "../../hooks/useDebounce";

const LabelModal = ({ handleModalClose, setBackendMessage }) => {
  const { tenantId } = useTenantContext();
  const { sessionId, setRefreshNotification,updateSingleSession  } = useInboxContext();
  const [selectLabel, setSelectLabel] = useState([]);
  const [label, setLabel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentSessionData, setCurrentSessionData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [debouncedValue] = useDebounce(searchValue, 500);

  const handleLabelSubmit = async () => {
    try {
      const result = await attachLabelToChatSession({
        sessionId,
        labelIds: selectLabel,
      });
      updateSingleSession() 
      // setRefreshNotification((perv) => !perv);
      handleModalClose();
      console.log(result.data.label.length);
      if (result.data.label.length === 0) {
        setBackendMessage({ msg: "Label Removed", type: "success" });
      } else {
        setBackendMessage({ msg: result.message, type: "success" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLabelSelect = (item) => {
    const { labelName } = item;
    setSelectLabel((prev) => {
      console.log(prev.some((item) => item.labelName === labelName));
      if (!prev.some((item) => item.labelName === labelName)) {
        return [...prev, { ...item, labelName }];
      } else {
        return prev.filter((item) => item.labelName !== labelName);
      }
    });
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const fetchLabels = async () => {
    setLoading(true);
    try {
      const result = await getLabel(tenantId, searchValue);
      setLabel(result.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };
  const fetchCurrentSession = async () => {
    try {
      const result = await getSingleChatSession(sessionId);
      setCurrentSessionData(result.data);
      setSelectLabel([...result.data.label, ...result.data.masterLabel]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLabels();
    fetchCurrentSession();
  }, [debouncedValue]);

  const skeleton = (
    <Stack sx={{ width: "95%" }}>
      {Array(6)
        .fill(0)
        .map((item, idx) => {
          return (
            <Skeleton key={idx} variant="text" sx={{ fontSize: "3rem" }} />
          );
        })}
    </Stack>
  );

  return (
    <LabelModalStyles>
      <div className="container">
        <div className="search-container">
          <input
            className="search-box"
            onChange={handleSearch}
            type="search"
            placeholder="Search"
          />
          <div className="search-btn">
            <SearchIcon />
          </div>
          <h4 className="title">All Label</h4>
          <div className="label-container">
            {loading ? (
              skeleton
            ) : (
              <ul>
                {label.map((item) => {
                  const { _id, labelName } = item;
                  return (
                    <li key={_id} className="label-item">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectLabel.some(
                              (item) => item.labelName === labelName
                            )}
                            onChange={() => handleLabelSelect(item)}
                          />
                        }
                        label={labelName}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div className="btn-container">
            <PrimaryButton
              type="click"
              onClick={handleLabelSubmit}
              variant="contained"
            >
              Apply
            </PrimaryButton>
          </div>
        </div>
      </div>
    </LabelModalStyles>
  );
};

export default LabelModal;
