import styled from "styled-components";
const WhatsappSetupStyles = styled.div`
  width: -webkit-fill-available;
  .container {
    height: 86vh;
    overflow-y: auto;
  }
  .scroll {
    height: 86vh;
    overflow-y: auto;
  }
  .input-container {
    margin-left: 2.3rem;
    width: 85%;
    display: flex;
    background-color: #f0f0f0;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  }
  .input-container input {
    all: unset;
    margin-left: 0.75rem;
  }
  // .top {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   padding: 1rem 2rem;
  //   padding: 20px;
  //   padding-left: 40px;
  // }
  .image {
    width: 70px;
    height: 70px;
    margin-left: 15px;
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }
  .name-container {
    margin-top: 12px;
  }
  .name-container .title {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 18px;
  }
  .url {
    display: flex;
    font-weight: 400;
    font-size: 15px;
    width: 90vw;
    color: #81848a;
  }
  .Button {
    margin-right: -640px;
  }
  .saveButton {
    margin-right: 30px;
  }
  
  .personalize-customize {
    margin-left: 35px;
    margin-top: 30px;
  }
  .personalize-customize span {
    font-weight: 500;
    font-size: 20px;
  }
  .personalize-customize p {
    font-size: 16px;
    color: #81848a;
  }

  .brand {
    margin-left: 15px;
    margin-top: 10px;
  }
  .brand span {
    font-size: 18px;
    font-weight: 500;
  }
  .brand p {
    font-size: 16px;
    color: #81848a;
  }
  .business-name {
    // margin-left: -2.7rem;
    // margin-top: 10px;
  }
  .business-name span {
    font-weight: 500;
    font-size: 18px;
  }
  .business-address {
    margin-left: 15px;
    margin-top: 10px;
  }
  .business-address span {
    font-weight: 500;
    font-size: 18px;
  }
  .business-email {
    margin-left: 15px;
    margin-top: 10px;
  }
  .business-email span {
    font-weight: 500;
    font-size: 18px;
  }
  .website-url {
    margin-left: 15px;
    margin-top: 10px;
  }
  .website-url span {
    font-weight: 500;
    font-size: 18px;
  }
  .country {
    margin-left: 15px;
    margin-top: 10px;
  }
  .country span {
    font-weight: 500;
    font-size: 18px;
  }
  .delete-button {
    margin-left: 15px;
    margin-top: 15px;
  }
  .tab-container {
    margin-left: 2rem;
  }
`;
export default WhatsappSetupStyles;
