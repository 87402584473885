import styled from "styled-components";
const EmailInputBoxStyles = styled.div`

  .email-modal.fullscreen-container {
    max-width: 100%;
  }
  width: 100%;
  height: 100%;
  --border-100: #d9d9d9;
  .disable {
    pointer-events: none;
    /* background-color: #e8e8e8; */
    opacity: .5;
  }
  .input-field-container {
    border: 2px solid var(--border-100);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .editor {
    flex-grow: 1;
  }
  .email-input-box {
    width: 100%;
    border: none;
    outline: none;
    padding: 0.5rem;
    resize: none;
    font-size: 1rem;
    height: 25vh;
  }
  .utils-icon {
    border-top: 2px solid var(--border-100);
    padding: 0.5rem;
    color: #666666;
  }
  .top-icons,
  .reply,
  .private-note {
    display: flex;
    align-items: center;
  }
  .top-icons {
    gap: 1.5rem;
    border-bottom: 2px solid var(--border-100);
    padding: 0.5rem;
    padding-bottom: 0.25rem;
    flex-wrap: wrap;
  }
  .reply,
  .private-note {
    gap: 0.25rem;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
  }
  .private-note {
    color: #999999;
  }
  .reply {
    color: #666666;
  }
  .full-view-icon {
    margin-left: auto;
    color: #666666;
    cursor: pointer;
  }
  .to-mail {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
  }
  .email-modal {
    background-color: white;
    width: 80vw;
    height: 80vh;
  }
  .private-message-active,
  .reply-active {
    color: #8364e8;
  }
  .to-container{
    display: flex;
    gap: 7rem;
  }
  .subject {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export default EmailInputBoxStyles;
