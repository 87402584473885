import React, { useEffect, useState } from "react";
import { FilterLabelStyles } from "../../../assets/styledComponents/inboxMessage";

import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";

import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Stack,
} from "@mui/material";
import { getLabel } from "../../../service/service";
import { useTenantContext } from "../../../context/TenantContext";
import { getKey } from "../../../utils/helperFunction";
import PrimaryButton from "../../reusable/PrimaryButton/PrimaryButton";
import { useInboxContext } from "../../../context/inboxContext/inboxContext";

const FilterLabel = ({
  handleClose,
  filterLabel,
  setFilterLabel,
  actionGroup,
}) => {

  const { priorityFilter } = useInboxContext();

  const [masterPriorityOptions, setMasterPriorityLabels] = useState([{
    _id: 'priority',
    labelName: "Priority",
    isChecked: priorityFilter
  }]);

  const handleChange = (e, _id, labelName, idx) => {
    const newMasterPriorityOptions = [...masterPriorityOptions];
    newMasterPriorityOptions[idx].isChecked = !newMasterPriorityOptions[idx].isChecked;
    setMasterPriorityLabels(newMasterPriorityOptions);

    const newFilterLabel = [...filterLabel];
    const isFilterLabelExists = newFilterLabel.filter(
      (filterDataObj) => filterDataObj._id === _id
    );

    if (isFilterLabelExists.length) {
      setFilterLabel(
        [...filterLabel].filter((filterDataObj) => filterDataObj._id !== _id)
      );
    } else {
      setFilterLabel([
        ...filterLabel,
        {
          labelName,
          _id,
        },
      ]);
    }
  };

  return (
    <FilterLabelStyles>
      <FormGroup>
        <div className="top-box">
          <h3>Filter By Priority</h3>
        </div>

        <div  className="label-container priority-container">
          {masterPriorityOptions.map(({ _id, labelName, isChecked }, idx) => {
            return (
              <React.Fragment
                key={getKey("filterLabelOptions", labelName, idx)}
              >
                <FormControlLabel
                  style={{ paddingLeft: "0.2rem" }}
                  control={<Checkbox />}
                  label={labelName}
                  onChange={(e) => handleChange(e, _id, labelName, idx)}
                  value={labelName}
                  checked={isChecked}
                />
              </React.Fragment>
            );
          })}
        </div>
      </FormGroup>
      <div className="btn-container">{actionGroup}</div>
    </FilterLabelStyles>
  );
};

export default FilterLabel;
