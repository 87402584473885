import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import WhatsappSetupForm from "../../../components/settings/ChannelConfiguration/WhatsappSetupForm";
import { getKey } from "../../../utils/helperFunction";
import WhatsappCard from "../../../components/settings/ChannelConfiguration/WhatsappCard";

function CustomTabPanel(props) {
  const { children, value, index, padding, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: padding ? padding :0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const SocialTabs = ({
  values,
  tabValue,
  handleChange,
  allWhatsappConfig,
  setMainTabValue,
  mainTabValue,
  handleAddWhatsappClickInCard,
  handleSave,
  handleCancel,
  disableSaveBtn,
  handleDeleteWhatsappAccount,
  storeTabs,
  formattedWhatsappConfig
}) => {

  const handleTabChange = (_, newValue) => {
    setMainTabValue(newValue);
  };

  return (
    <div className="store-tab-container">
      <Tabs
        value={mainTabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {storeTabs.map((tabData, index) => {
          return (
            <Tab
              key={getKey("Socialtab", tabData.shop, index)}
              label={`${tabData.shop || "New Account"}  ${
                tabData.isDefault ? "- Default" : ""
          }`}
            />
          );
        })}
      </Tabs>
      {storeTabs.map((tabData, index) => {
        const item = formattedWhatsappConfig[tabData.shop];

        return (
          <CustomTabPanel
            value={mainTabValue}
            index={index}
            key={`social-tab-panel-${index+1}`}
            padding={0}
            sx={{ padding: 0 }}
          >
            {item.isFirst ? (
              <WhatsappCard
                index={index}
                handleAddWhatsappClickInCard={handleAddWhatsappClickInCard}
              />
            ) : (
              <WhatsappSetupForm
                values={values}
                mainTabValue={mainTabValue}
                handleChange={handleChange}
                whatsappConfig={item}
                handleSave={handleSave}
                tabValue={tabValue}
                handleCancel={handleCancel}
                disableSaveBtn={disableSaveBtn[mainTabValue]}
                handleDeleteWhatsappAccount={handleDeleteWhatsappAccount}

              />
            )}
          </CustomTabPanel>
        );
      })}
    </div>
  );
};

export default SocialTabs;
