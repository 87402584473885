import styled from "styled-components";
const ShopifyStyles = styled.div`
  // width: 81vw;
  .input-container {
    margin-left: 2.3rem;
    width: 85%;
    display: flex;
    background-color: #f0f0f0;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  }
  .input-container input {
    all: unset;
    margin-left: 0.75rem;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    padding: 20px;
    padding-left: 40px;
  }
  .Button {
    margin-right: -700px;
  }
  .saveButton {
    margin-right: 20px;
  }
  .underline {
    background-color: var(--border-clr);
    width: 94%;
    height: 1px;
    margin: 0 auto;
    padding-left: 40px;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .personalize-customize {
    // margin-left: 35px;
    margin-top: 30px;
  }
  .personalize-customize span {
    font-weight: 500;
    font-size: 20px;
  }
  .personalize-customize p {
    font-size: 16px;
    color: #81848a;
  }
  .general {
    // margin-left: 35px;
    margin-top: 30px;
    font-weight: 500;
  }
  .shopify-store {
    // margin-left: 35px;
    margin-top: 20px;
    width: 450px;
    margin-bottom: 1.5rem;
  }
  .shopify-store-heading {
    margin-bottom: 0.7rem;
    font-weight: 500;
    font-size: 18px;
  }
  .brand {
    width: 450px;
    // margin-left: 35px;
    margin-top: 10px;
    margin-bottom: 1.5rem;
  }
  .brand span {
    font-size: 18px;
    font-weight: 600;
  }
  .brand p {
    font-size: 16px;
    color: #81848a;
    margin-bottom: 0.7rem;
  }
  .order-data {
    // margin-left: 35px;
    margin-top: 10px;
  }
  .order-data span {
    font-weight: 500;
    font-size: 18px;
  }
  .order-data p {
    font-size: 16px;
    color: #81848a;
  }
  .custom-Switch {
    display: flex;
    justify-content: right;
    margin-right: 790px;
    position: relative;
    bottom: 40px;
    left: 26px;
  }
  .sync-profile {
    // margin-left: 35px;
    margin-top: 1px;
  }
  .sync-profile span {
    font-weight: 500;
    font-size: 18px;
  }
  
  delete-button
  .sync-profile p {
    font-weight: 400;
    font-size: 16px;
    color: #81848a;
  }
  .delete-button {
    // margin-left: 35px;
  }
  .order-data {
    width: 450px;
    display: flex;
    justify-content: space-between;
    // margin-left: 35px;
    margin-bottom: 1.5rem;
  }
  .order-data h3 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 5px;
  }
  .order-data p {
    width: 90%;
    font-size: 16px;
    color: #81848a;
  }

  @media (max-width: 1368px) {
    width: 100%;
  }
`;
export default ShopifyStyles;
