import styled from "styled-components";

const SnoozeStyles = styled.div`
  width: 330px;
  // margin: 10px;

  .datepicker-conatiner-box {
    padding: 20px;
  }

  // border-radius: 40%;
  .title {
    font-weight: 300;
    // margin-top: 10px;
    // margin-bottom: 1rem;
    // white-space: nowrap;
    // font-size: 13px;
    // color: #666666;

    color: #666;
    font-family: Roboto;
    font-size: 14px;
    margin-bottom: 14px;
  }
  .date-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .custom-time-picker {
    position: relative;
  }
  .custom-time-picker .MuiSvgIcon-root {
    position: absolute;
    left: 250px;
    top: 50%;
    transform: translateY(-50%);
    color: #666666;
  }
  .date-container .MuiFormControl-root {
    // width: 298px;
  }
  .MuiIconButton-root {
    margin-bottom: 29px;
    margin-right: 1px;
  }
  ul {
    gap: 1rem;
    border-bottom: var(--border);
  }
  .active {
    background-color: var(--primary-100);
    border-radius: 0.2rem;
    cursor: pointer;
  }
  .null {
    cursor: pointer;
  }
  li,
  .custom {
    padding: 0.6rem 14px;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .custom {
    padding-bottom: 0;
    // margin-top: 10px;
    cursor: pointer;
    color: #666666;
  }
  button {
    margin: 20px;
    // margin-right: 19px;
    // margin-top: 32px;
    float: right;
  }
`;

export default SnoozeStyles;
