import React from "react";

const NonAuthenticated = () => {
  return (
    <div
      className="hero-section"
      style={{
        backgroundColor: "#EFF2ED",
        height: "100vh",
        padding: "8% 10%",
      }}
    >
      <div style={{ display: "inline-block", width: "45%", float: "left" }}>
        <img src="https://helpiq.s3.us-east-2.amazonaws.com/helpIqLogo.png" />
        <span
          style={{
            display: "block",
            fontSize: "34px",
            fontWeight: "bold",
            margin: "8px 10px",
          }}
        >
          Unauthorized Access
        </span>
        <span
          style={{
            display: "block",
            marginLeft: "10px",
            fontSize: "18px",
            color: "#67757E",
          }}
        >
          Access denied. Kindly log in using valid credentials to proceed
          securely
        </span>
        <a
          style={{
            cursor: "pointer",
            background: "#8DB178",
            width: "max-content",
            padding: "8px 15px",
            borderRadius: "2px",
            color: "white",
            margin: "15px 10px",
            fontWeight: "600",
            textDecoration: "none",
            display: "block",
          }}
          href={"/login"}
        >
          Login
        </a>
      </div>
      <div style={{ float: "left" }}>
        <img
          style={{ height: "70vh" }}
          src="https://helpiq.s3.us-east-2.amazonaws.com/Hero-section-image-1.png"
        />
      </div>
    </div>
  );
};

export default NonAuthenticated;
