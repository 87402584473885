import React from "react";
import { useTable, useBlockLayout, useResizeColumns  } from "react-table";
import ReportFilterStyles from "../../assets/styledComponents/ReportFilter";
import CustomReportTableStyles from "../../assets/styledComponents/report/CustomReportTable";
import { getKey } from "../../utils/helperFunction";
import Skeleton from "@mui/material/Skeleton";
import { Button, CircularProgress, Pagination } from "@mui/material";
import FullPageSpinner from "../FullPageSpinner";
import TableSkeleton from "../../pages/aiConfig/TableSkeleton";
const CustomReportTable = ({ columns, data, tableLoading , setPage, page, totalPage,mlLogLoading  }) => {
  const table  =
    useTable({ columns, data, useBlockLayout, useResizeColumns});
  
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = table || {}
  
  const pageChange = (e, value) => {
    setPage(value)

  }

  return (
    <CustomReportTableStyles>
      <div className="pagination">
        <Pagination onChange={pageChange} page={page} count={totalPage} />
      </div>
      <div className="table-container">
        <table className="table" {...getTableProps()} style={{}}>
          <thead className="thead">
            {headerGroups.map((headerGroup, id) => (
              <tr
                className="tr"
                {...headerGroup.getHeaderGroupProps()}
                style={{ margin: "0 10px" }}
                key={getKey("report-table", "header-row", id)}
              >
                {headerGroup.headers.map((column, cellIndex) => (
                  <th
                    key={getKey("report-table", "row-hedear-cell", cellIndex)}
                    className="th"
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="tbody" {...getTableBodyProps()}>
            {mlLogLoading ? (
              Array(4).fill(0).map((_, i) => (
                <TableSkeleton key={`Sekeleton-${i + 1}`}/>
              ))
            ) :  rows.length === 0 ? 
              <tr>
                <td className="" colSpan={headerGroups[0].headers.length}>
                  <div className="empty-table">
                    <h2>No Ai Logs found</h2>
                  </div>
                </td>
              </tr>
            : (
              rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr
                    className="tr"
                    {...row.getRowProps()}
                    key={getKey("report-table", "body-row", rowIndex)}
                    style={
                      {
                        // borderBottom: "1px solid #ddd",
                      }
                    }
                  >
                    {row.cells.map((cell, cellIndex) => (
                      <td
                        key={getKey("report-table", "body-row-cell", cellIndex)}
                        className="td"
                        {...cell.getCellProps()}
                      >
                        {tableLoading ? (
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "2.6rem", width: "100%" }}
                          />
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </CustomReportTableStyles>
  );
};

export default CustomReportTable;
