import React, { useEffect, useState } from "react";
import { getAllTenantStore } from "../../service/service";
import { Tab, Tabs } from "@mui/material";
import { useTenantContext } from "../../context/TenantContext";
import FullPageSpinner from "../FullPageSpinner";

const StoreTabs = ({
  activeTab,
  setActiveTab,
  storeTabs,
}) => {
  const { tenantId } = useTenantContext();


  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {console.log("Store Tabs --", storeTabs)}
        {storeTabs.map((tabData) => (
          <Tab
            label={`${tabData.shop || "New Account"}  ${
              tabData.isDefault ? "- Default" : ""
            }`}
            key={`${tabData.shop || "New Account"}  ${
              tabData.isDefault ? "- Default" : ""
            }`}
          />
        ))}
      </Tabs>
    </>
  );
};

export default StoreTabs;
