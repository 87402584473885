import { Box, FormControl, InputLabel, MenuItem, Select , SelectProps} from "@mui/material";
import React from "react";


/**
 * 
 * @param {SelectProps} rest 
 * @returns {JSX.Element}
 */
const CustomSelect = ({
  list,
  boxShadow = 5,
  startAdornment,
  size,
  width,
  label,
  value,
  onChange,
  name,
  ...rest
}) => {
  return (
    <Box>
      <FormControl variant="outlined" fullWidth>
        <InputLabel
          id="demo-simple-select-label"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {/* Store Icon */}
        </InputLabel>
        <Select
          name={name}
          sx={{ boxShadow, width }}
          value={value}
          onChange={onChange}
          size={size}
          startAdornment={startAdornment}
          {...rest}
        >
          <MenuItem disabled value={label}>
            {label}
          </MenuItem>
          {list.map(({ value, label }) => {
            return (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomSelect;
