import styled from "styled-components";
const DropdownStyles = styled.div`
  /* border-bottom: 1px solid var(--border-clr); */
  border-bottom: 1px solid var(--border-clr);
  /* width: 90%;
  margin-left: auto;
  margin-right: auto; */
  padding-top: 1rem;
  .dropdown-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    /* padding: 0 1rem; */
    padding-inline: .25rem;
    ${({ settingsPage }) => settingsPage ? "padding-inline: .25rem;" :"padding-inline: 1rem;" }
  }

  .name {
    cursor: pointer;
  }

  .name i {
    font-size: 20px;
    color: gray;
  }

  .remove-btn {
    border: 1px solid #d9d9d9;
    border-radius: 0.3rem;
  }
  .show-dropdown {
    display: block;
    transition: 0.5 ease-in-out;
  }
  .hide-dropdown {
    display: none;
  }
  .items {
    width: 100%;
    display: flex;
    color: #666666;
    align-items: center;
    gap: 0.7rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
  }
  .name {
    margin-bottom: 1px;
  }
  .active {
    background-color: var(--primary-100);
    color: var(--primary-500);
    border-radius: 3px;
  }
  .dropdown-active {
    background-color: var(--primary-100);
    color: var(--primary-500);
    border-radius: 3px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }


  .dropdown-active .name i{
    color: var(--primary-500);
  }

  .list-container {
    padding: 0 1rem;
    cursor: pointer;

  }
  .chat-settings-icons {
    padding-top: 3px;
  }
 @media (max-width : 1300px) {
  font-size: 14px;
 }
`;
export default DropdownStyles;
