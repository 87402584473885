import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";

import useClipboard from "../../hooks/useClipboard";

const ClipboardButton = ({
  text,
  copyOutlineIcon = (
    <ContentCopyIcon fontSize="small" sx={{ color: "#8DB178" }} />
  ),
  copyFilledIcon = (
    <ContentCopyTwoToneIcon fontSize="small" sx={{ color: "#8DB178" }} />
  ),
}) => {
  const [isCopied, copyToClipboard, setIsCopied] = useClipboard("");
  const toolTipText = isCopied ? "copied to clipboard" : "copy to clipboard";

  const handleClick = () => {
     copyToClipboard(text)
     setTimeout(() => {
       setIsCopied(false)
     }, 3000);
  }
  
  return (
    <Tooltip title={toolTipText}>
      <button
        style={{ height: "fit-content" }}
        onClick={handleClick}
      >
        {isCopied ? copyFilledIcon : copyOutlineIcon}
      </button>
    </Tooltip>
  );
};

export default ClipboardButton;
