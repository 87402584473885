import React, { useContext } from "react";
import ShopifyStyles from "../../assets/styledComponents/settings/ShopifyIntegration";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import CustomSwitch from "../CustomSwitch";
import { TenantContext } from "../../context/TenantContext";
import CustomTextField from "../reusable/CustomTextField";
import { buyNewStore } from "../../service/service";

const ShopifyCIntegration = ({ data, handleDeleteIntegrarion }) => {
  const { profile, tenantId } = useContext(TenantContext);
  const handleToggleSwitch = () => {};
  const handleStorePurchase = async() => {
      const buyShopify = await buyNewStore({tenantId : tenantId , shop :data.shop})
      const redirectUrl = buyShopify.paymentUrl
      console.log({redirectUrl })
      window.location.href  = redirectUrl
  }

  return (
    <ShopifyStyles>
      <div className="personalize-customize">
        <h4>Personalize Your Shopify Widget</h4>
        <p>customize the widget as per your interest</p>
      </div>
      <div className="general">
        <span>General</span>
      </div>
      <div className="shopify-store">
        <h3 className="shopify-store-heading">Shopify Store </h3>
        <CustomTextField value={data.shop} label={"Store"} disabled={true} />
      </div>
        {/* {!data.isDefault && ( */}
          <Button variant="outlined" onClick={handleDeleteIntegrarion}>
            {"Delete Store Integration"}
          </Button>

          {/* <Button variant="outlined" onClick={handleStorePurchase}>
            {"Activate store"}
          </Button> */}
        {/* )} */}
      {/*  Need to opne in future */}
      {/* <div className="brand">
        <span>Brand</span>
        <p>which shopify brand associated with this account ?</p>
        <CustomTextField
          sx={{
            marginBottom: 3,
            marginTop: 1,
          }}
          placeholder={"Brand"}
        />
      </div>
      <div className="order-data">
        <section>
          <h3 className="heading">Order Data</h3>
          <p>see orders and tracking data next to each other</p>
        </section>
        <CustomSwitch handleToggleSwitch={handleToggleSwitch} />
      </div>
      <div className="order-data">
        <section>
          <h3 className="heading">Sync Customers Profile</h3>
          <p>
            Edit orders,Shipping address,Cancel order,issue refund and create
            new drafts order from Richpanel
          </p>
        </section>
        <CustomSwitch />
      </div>
      */}

      {/* {<div className="delete-button">
        <Button variant="outlined">Delete Integration</Button>{" "}
      </div> } */}
    </ShopifyStyles>
  );
};
export default ShopifyCIntegration;
