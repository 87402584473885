import styled from "styled-components";
const HeaderStyles = styled.div`
  /* width: calc(100% - 200px -70px -100px); */
  margin-right: 40px;
  margin-left: 40px;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 1rem 2rem;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .top h3 {
    font-weight: 500;
    font-size: 26px;
  }

  .top-desc {
    color: #81848a;
    font-size: 17px;
  }
  .underline {
    background-color: var(--underline-clr);
    // width: 95%;
    height: 0.5px;
    margin: 0 auto;
  }
  .icon-container {
    cursor: pointer;
    color: var(--primary-700);
    }
  .sub-heading {
    color: var(--gray-500);
  }
  .button-container {
    display: flex;
    gap: 1rem;
  }
  .loading-icon {
    display: flex;
    align-items: center;
  }

  .paper-header {
    padding: 20px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background: white !important;
    padding-bottom: 25px;
    margin-top: 20px;

    .top {
      padding: 0px !important;
      margin-bottom: 10px;
    }
  }

`;
export default HeaderStyles;
