import React, { useEffect, useState } from "react";
import AppearanceScreenStyles from "../../../assets/styledComponents/channelConfiguration/chat/AppearanceScreen";
import { Avatar, colors } from "@material-ui/core";
import CustomTextField from "../../../components/reusable/CustomTextField";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ChatWithUs from "./ChatWithUs";
import ContactFormScreenStyles from "../../../assets/styledComponents/channelConfiguration/chat/ContactFormScreen";
import CheckOrderStatus from "./CheckOrderStatus";
import { useChannelContext } from "../../../context/channelConfigurationContext/channelContext";
import { useTenantContext } from "../../../context/TenantContext";
import hiIcon from "../../../assets/images/hiIcon.png";
import { getKey } from "../../../utils/helperFunction";
import { getAllUserSetup } from "../../../service/service";
import ContactFormScreen from "./ContactFormScreen";
import CloseIcon from "@mui/icons-material/Close";

const AppearanceScreen = ({ type }) => {
  const { tenantId, profile } = useTenantContext();

  const {
    uploadIcon,
    widgetFormattedList,
    setWidgetFormattedList,
    selectedWidgetShop,
    // orderStatus,
    openTab,
    setOpenTab,
    initialTabState,
    talkToHumanClicked,
    setShowAppearanceScreen,
    showInput,
  } = useChannelContext();

  const selectedWidgetData = widgetFormattedList[selectedWidgetShop];
  // console.log({ selectedWidgetData });
  if (!selectedWidgetData) return;

  const { appearance, orderStatus, contactFormValue } = selectedWidgetData || {};

  const {
    themeColor,
    gradient,
    textColor,
    greetingMessage,
    introMessage,
    showAvatars,
    brandName,
    statusText,
    icon,
  } = appearance || {};
  const { contactForm } = contactFormValue || {};

  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState([]);

  const fetchUserSetup = async () => {
    try {
      setIsLoading(true);
      const result = await getAllUserSetup({ tenantId });
      setUserDetails(result.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserSetup();
    if (type === "orderStatus") {
      setOpenTab((prev) => {
        let updatedTab = [...prev];
        updatedTab[0].isOpen = false;
        updatedTab[1].isOpen = true;
        return updatedTab;
      });
    }
  }, []);

  // const handleClick = (e, tabClicked) => {
  //   const currentTab = e.target.innerText;
  //   // console.log({ currentTab });
  //   if (currentTab === undefined) {
  //     return;
  //   }
  //   if (currentTab === "Chat order") {
  //     setOpenTab(initialTabState);
  //     return;
  //   }
  //   setOpenTab((prev) => {
  //     const result = prev.map((item) => {
  //       return item.mobileTab === tabClicked
  //         ? { ...item, isOpen: true }
  //         : { ...item, isOpen: false };
  //     });
  //     // console.log({ result });
  //     return result;
  //   });
  // };

  console.log("Type====", type, openTab);

  return (
    <ContactFormScreenStyles {...appearance}>
      <div className="contact-wrapper">
        <div className="navbar">
          <div
            className="close-icon"
            onClick={() => setShowAppearanceScreen(false)}
          >
            <CloseIcon />
          </div>
          <article className="welcome-container">
            <div className="img-container">
              <img
                src={icon ? icon : hiIcon}
                sizes="lg"
                width={40}
                height={40}
              />
              <div className="online-symbol"></div>

              <div className="logo-msg">
                <h3 className="greeting-msg">{brandName}</h3>
                <p className="intro-msg"> {statusText} </p>
              </div>
            </div>

            {showAvatars && !isLoading && type === "Appearance" && (
              <div className="profile-container chatbot-avtar-box">
                {userDetails.slice(0, 2).map((user, index) => (
                  <article
                    className={`profile ${index ? "clipped-image" : ""}`}
                    key={getKey("userDetails", user?.name, index)}
                  >
                    {user.profileImageUrl ? (
                      <Avatar
                        src={user.profileImageUrl}
                        alt={user.name}
                        sx={{ width: 70, height: 70 }}
                        // style={{ fontSize: "56" }}
                      />
                    ) : (
                      <Avatar
                      // alt={user.name}
                      // sx={{ width: 56, height: 56 }}
                      // style={{ fontSize: "36px" }}
                      >
                        {user.name[0].toUpperCase()}
                      </Avatar>
                    )}
                    {/* <p>{user.name}</p> */}
                  </article>
                  // <article className="profile">
                  //   <Avatar src={user.profileImageUrl} alt={user.name} sx={{ width: 56, height: 56 }} />
                  //   <p>{user.name}</p>
                  // </article>
                ))}
              </div>
            )}
          </article>
        </div>
        <div className="content">
          <div className="message-content">
            {openTab.map(({ isOpen, mobileComponent, tab }, index) => {
              if (isOpen && tab === 'Track order' && !orderStatus.orderSearch) {
                return <React.Fragment key={getKey("openTab", "item", index)}>
                  <ChatWithUs />
                </React.Fragment>
              }
              
              return (
                <React.Fragment key={getKey("openTab", "item", index)}>
                  {isOpen && mobileComponent}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div id="branding-text">
          Chat powered by{" "}
          <a href="https://www.google.com" rel="noreferrer" target="_blank">
            HelpIQ
          </a>
        </div>
      </div>
    </ContactFormScreenStyles>
  );
};

export default AppearanceScreen;
