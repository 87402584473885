import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styled from 'styled-components'

const AIDatePicker = ({startValue, setStartValue, endValue, setEndValue }) => {
  

  const setCleared = (field) => {
    if (field === 'start') {
      setStartValue(null)
    } else {
      setEndValue(null)
    }
  }

  return (
    <AIDatePickerStyles>

    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          value={startValue}
          format="DD/MM/YYYY"
          onChange={(newValue) => setStartValue(newValue)}
          sx={{ width: 50 , minWidth: '174px',
            marginTop: '9px',
          }}
          slotProps={{
            field: { clearable: true, onClear: () => setCleared('start') },
            textField: {
            },
          }}
          label="Start Date"
        />
        <DatePicker
          value={endValue}
          format="DD/MM/YYYY"
          onChange={(newValue) => setEndValue(newValue)}
          sx={{ width: 50 }}
          slotProps={{
            field: { clearable: true, onClear: () => setCleared('end') },
            textField: {
              // size: "small",
            },
          }}
          label="End Date"
        />
      </DemoContainer>
    </LocalizationProvider>
    </AIDatePickerStyles>
  );
};
const AIDatePickerStyles = styled.div`
.MuiTextField-root {
min-width: 174px;
margin-top: 10px;
}
`


export default AIDatePicker;

