import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const CustomTab = (props) => {
  const { tabLabels, tabContents, onTabClick } = props;
  const [value, setValue] = useState(0);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabClick(newValue);
  };
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {tabLabels.map((label, index) => {
              return <Tab key={index} label={label} {...a11yProps(index)} sx={{ textTransform: 'capitalize'}} />;
            })}
          </Tabs>
        </Box>
        {tabContents.map((content, index) => {
          return (
            <CustomTabPanel key={index} value={value} index={index}>
              {content}
            </CustomTabPanel>
          );
        })}
      </Box>
    </div>
  );
};

export default CustomTab;
