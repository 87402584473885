import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  TextFieldProps
} from "@mui/material";
import React from "react";

import { getKey } from "../utils/helperFunction";
import { SxProps } from "@mui/system";

const CustomInputDropDown = ({
  icon,
  label,
  selectList,
  handleChange,
  value,
  width,
  id,
  height,
  formControlSx,
  inputLabelSx,
  ...rest
}) => {
  return (
    <>
      <Box
        boxSizing={"small"}
        sx={{
          marginTop: 2,
          borderRadius: "10px",
          width,
          height,
        }}
      >
        <FormControl fullWidth sx={formControlSx}>
          <InputLabel
            id="demo-simple-select-label"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              ...inputLabelSx,
            }}
          >
            {icon ? icon : ""}
            {label ? label : ""}
          </InputLabel>
          <Select
            value={value}
            label={`${label} ${">>>"}`}
            onChange={handleChange}
            required
            name={id}
            sx={{ height }}
            {...rest}
          >
            {selectList && selectList.map(({ id, value, name },index) => {
              return (
                <MenuItem
                key={id || getKey(name, "selectList", index)}
                value={value ? value : name}
              >
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default CustomInputDropDown;
