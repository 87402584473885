import { useState } from "react";
import { uploadToS3 } from "../../utils/uploadImageToS3";

const fileUploadDemo = async () => {
  return new Promise((resolve, _) => {
    console.log("ran");
    setTimeout(() => {
      resolve("https://txta.s3.us-east-2.amazonaws.com/1694676212569.hello.c");
    }, 2000);
  });
};

const useAttachmentUpload = () => {
  const [loading, setLoading] = useState(false);
  const uploadFile = async (attachment) => {
    try {
      setLoading(true);
      // const result = await fileUploadDemo(attachment)
      const result = await uploadToS3(attachment);
      setLoading(false);
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  return [uploadFile, loading];
};

export default useAttachmentUpload;
