import React from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Box } from "@mui/material";
import styled, { keyframes } from "styled-components";

const glowRotate = keyframes`
  0% {
    opacity: 1;
    transform: scale(1) rotate(0deg);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2) rotate(180deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(360deg);
  }
`;

const GlowingRotatingIcon = styled(AutoAwesomeIcon)`
  animation: ${glowRotate} 2s infinite ease-in-out;
  font-size: 24px;
`;

const SuggestReplyLoading = () => {
  return (
    <SuggestReplyLoadingStyles>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <GlowingRotatingIcon />
        <span style={{ marginTop: "1rem", fontWeight : 500, fontSize : 22 }}>Analyzing Conversation</span>
      </Box>
    </SuggestReplyLoadingStyles>
  );
};

export default SuggestReplyLoading;

const SuggestReplyLoadingStyles = styled.div`
  color: var(--primary-800);
`;
