import { Avatar } from "@mui/material";
import React from "react";

const CustomAvatar = ({ name = "Name", src }) => {
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  function stringAvatar(name) {
    const nameArray = name.split(" ").slice(0, 2);
    let avatarLetter = "";
    for (let letter of nameArray) {
      avatarLetter += letter.charAt(0);
    }
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: avatarLetter,
    };
  }
  return <>{src ? <Avatar src={src} /> : <Avatar {...stringAvatar(name)} />}</>;
};

export default CustomAvatar;
