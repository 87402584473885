import React, { useState, useContext, useEffect } from "react";
import WhatsappSetupStyles from "../../../assets/styledComponents/settings/WhatsappSetup";
import Swal from "sweetalert2";
import { TenantContext } from "../../../context/TenantContext";
import {
  fetchWhatsappConfig,
  updateWhatsappConfig,
  deleteWhatsappConfig,
  getAllTenantStore,
} from "../../../service/service";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import _ from "lodash";
import Modal from "../../reusable/DialogModal/Modal";
import SocialTabs from "../../../pages/channelConfiguration/chat/SocialTabs";
import CustomSnackbar from "../../snackbar/CustomSnackbar";
import HeaderBar from "../../reusable/HeaderBar/HeaderBar";
import FullPageSpinner from "../../FullPageSpinner";

const initialState = [
  {
    name: "phoneId",
    label: "Phone ID",
    value: "",
    error: "",
  },
  {
    name: "accessToken",
    label: "Access Token",
    value: "",
    error: "",
  },
  {
    name: "webHookUrl",
    label: "Webhook URL",
    value: "",
    disabled: true,
    error: "",
  },
  {
    name: "verifyToken",
    label: "Webhook verify token",
    value: "",
    disabled: true,
    error: "",
  },
];

let webHookUrl = process.env.REACT_APP_BACKEND_URL;

const initialWhatsappConfig = {
  phoneId: "",
  accessToken: "",
  webHookUrl,
  verifyToken: "",
  error: "",
  isFirst: true,
};

const WhatsappSetup = () => {
  const { profile, setProfile } = useContext(TenantContext);
  const { tenantId } = profile?.token || {};
  const [allWhatsappConfig, setAllWhatsappConfig] = useState([
    { ...initialWhatsappConfig },
  ]);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [mainTabValue, setMainTabValue] = useState(0);
  const [tabValue, setTabValue] = useState("1");
  const [values, setValues] = useState([...initialState]);
  const [storeTabs, setStoreTabs] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [backendMessage, setBackendMessage] = useState({ msg: "", type: "" });
  const [originalWhatsappConfig, setOriginalWhatsappConfig] = useState({
    ...initialWhatsappConfig,
  });
  const [formattedWhatsappConfig, setFormattedWhatsappConfig] = useState({});

  const { whatsappAccount: whatsappAccountLimit } =
    profile?.token?.pricingPlan || {};

  const fetchStore = async () => {
    try {
      const res = await getAllTenantStore(tenantId);
      setStoreTabs(res.data);
      fetchConfigDetails(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getFormattedWhatsappConfig = async (
    configData,
    newStoreTabs = storeTabs
  ) => {
    const newFormmattedValue = _.groupBy(configData, "storeName");
    await Promise.all(
      newStoreTabs.map((storeTabData) => {
        if (newFormmattedValue[storeTabData.shop]) return;
        else {
          newFormmattedValue[storeTabData.shop] = {
            appSecret: "",
            isWhatsappEnabled: false,
            phoneId: "",
            verifyToken: "",
            webHookUrl: "",
            isFirst: true,
            _id: null,
            storeName: storeTabData.shop,
            storeId: storeTabData._id,
          };
        }
      })
    );

    await Promise.all(
      Object.entries(newFormmattedValue).map(([key, value]) => {
        if (Array.isArray(value)) {
          newFormmattedValue[key] = {
            ...value[0],
            isFirst: !value[0].phoneId,
            error: "",
          };
        }

        if (!key || key === "undefined") {
          delete newFormmattedValue[key];
        }
      })
    );
    return newFormmattedValue;
  };

  const fetchConfigDetails = async (newStoreTabs = storeTabs) => {
    if (!tenantId) return;
    setLoading(true);
    const whatsappConfigFromDB = await fetchWhatsappConfig(tenantId);
    if (whatsappConfigFromDB.success) {
      const newFormmattedValue = await getFormattedWhatsappConfig(
        whatsappConfigFromDB.data,
        newStoreTabs
      );
      console.log(">>. New Formatted Data ---", newFormmattedValue);
      setOriginalWhatsappConfig(whatsappConfigFromDB.data);
      setFormattedWhatsappConfig(newFormmattedValue);
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchConfigDetails();
    fetchStore();
  }, [profile?.token]);

  let initialSaveValue = Array(whatsappAccountLimit).fill(true);
  const [disableSaveBtn, setDisableSaveBtn] = useState(initialSaveValue);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let isDirty = true;

    const selectedStoreData = storeTabs[mainTabValue];
    const { shop } = selectedStoreData;
    const newFormmattedValue = { ...formattedWhatsappConfig };
    newFormmattedValue[shop][name] = value;
    newFormmattedValue[shop].errorField = "";
    newFormmattedValue[shop].error = "";
    isDirty =
      newFormmattedValue[shop]["phoneId"] !==
        formattedWhatsappConfig[shop]["phoneId"] &&
      newFormmattedValue[shop]["accessToken"] !==
        formattedWhatsappConfig[shop]["accessToken"];

    const newDisableBtnData = structuredClone(disableSaveBtn);
    newDisableBtnData[mainTabValue] = isDirty;

    setAllWhatsappConfig(newFormmattedValue);
    // setAllWhatsappConfig([...updatedValue]);
    setDisableSaveBtn(newDisableBtnData);
  };

  const handleAddWhatsappClickInCard = (index) => {
    const selectedStoreData = storeTabs[index];
    const newFormmattedValue = { ...formattedWhatsappConfig };
    newFormmattedValue[selectedStoreData.shop].isFirst = false;

    setFormattedWhatsappConfig(newFormmattedValue);
  };

  const resetDisableBtn = () => {
    const newDisableBtnData = structuredClone(disableSaveBtn);
    newDisableBtnData[mainTabValue] = true;
    setDisableSaveBtn(newDisableBtnData);
  };

  const handleSave = async () => {
    const selectedStoreData = storeTabs[mainTabValue];
    const { shop } = selectedStoreData;

    const { phoneId, accessToken } = formattedWhatsappConfig[shop] || {};

    let newError = "";
    let errorField = "";

    if (!phoneId) {
      newError = "Phone Id is required";
      errorField = "phoneId";
    }

    if (!accessToken) {
      newError = "Access Token is required";
      errorField = "accessToken";
    }

    if (newError) {
      const newFormmattedValue = { ...formattedWhatsappConfig };
      newFormmattedValue[shop].error = newError;
      newFormmattedValue[shop].errorField = errorField;

      setFormattedWhatsappConfig(newFormmattedValue);
      return;
    }

    console.log({ formattedWhatsappConfig });
    try {
      const formattedWhatsappConfigForPayload = Object.values(
        formattedWhatsappConfig
      );

      const res = await updateWhatsappConfig(profile.token.tenantId, {
        whatsappConfig: formattedWhatsappConfigForPayload,
      });
      const newFormmattedValue = await getFormattedWhatsappConfig(res.value);
      setFormattedWhatsappConfig(newFormmattedValue);
      setOriginalWhatsappConfig([...res.value]);
      setBackendMessage({ msg: res.data, type: "success" });
      resetDisableBtn();

      console.log("After Save", { res: res.value });
    } catch (error) {
      console.log(error);
      setBackendMessage({ msg: error.data, type: "error" });
    }
  };

  const handleFourthButtonClick = () => {
    console.log("Fourth button clicked");
    setOpenSecondModal(true);
    console.log("openSecondModal state:", openSecondModal);
  };

  const handleCancel = async () => {
    console.log(">> Hanlde Cancel Called", originalWhatsappConfig);
    const newFormmattedValue = await getFormattedWhatsappConfig(
      originalWhatsappConfig
    );
    setFormattedWhatsappConfig(newFormmattedValue);
    resetDisableBtn();
  };

  const handleDeleteWhatsappAccount = async () => {
    try {
      const selectedStoreData = storeTabs[mainTabValue];
      const { shop } = selectedStoreData;

      const { phoneId, accessToken } = formattedWhatsappConfig[shop] || {};

      const result = await Swal.fire({
        title: "Are you sure ?",
        text: "Upon deleting the WhatsApp account, all associated chats will be permanently removed ",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#8db178",
        cancelButtonColor: "#D9D9D9",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        const res = await deleteWhatsappConfig(
          tenantId,
          formattedWhatsappConfig[shop]._id
        );
        const { whatsappConfiguration } = res;
        setMainTabValue(0);
        // setAllWhatsappConfig([...whatsappConfiguration]);

        const newFormmattedValue = await getFormattedWhatsappConfig(
          whatsappConfiguration
        );
        setFormattedWhatsappConfig(newFormmattedValue);
        setOriginalWhatsappConfig([...whatsappConfiguration]);
        if (res) {
          Swal.fire({
            title: "Deleted!",
            text: `Successfully deleted the WhatsApp account from HelpIQ. You will no longer receive its messages in HelpIQ.`,
            confirmButtonColor: "#8db178",
            icon: "success",
          });
        }
      }
    } catch (error) {
      console.log(error);

      Swal.fire({
        title: "Error",
        text: "An error occurred while deleting the account. Please contact admin at support@helpiq.app.",
        icon: "error",
        confirmButtonColor: "#8db178",
      });
    }
  };

  return (
    <>
      {backendMessage.msg && <CustomSnackbar payload={backendMessage} />}
      <WhatsappSetupStyles>
        <Modal
          name="Need help ?"
          open={openSecondModal}
          onClose={() => setOpenSecondModal(false)}
        >
          <div style={{ width: "500px", marginLeft: "12px" }}>
            <span>
              <a
                href="#"
                style={{ display: "block", margin: "10px 0", padding: "5px" }}
              >
                how to whatsapp setup works ?
              </a>
            </span>
          </div>
        </Modal>
        <HeaderBar
          title={"Whatsapp"}
          showDescription={true}
          description={"Setup Your WhatsApp Widget"}
          primaryButtonLabel={"Add new account"}
          showIcon={true}
          icon={<QuestionMarkIcon />}
          hideFourthButton={true}
          handleIconClick={handleFourthButtonClick}
          hideThirdButton={true}
          hideSecondaryButton={true}
          hidePrimaryButton={true}
          // hideFourthButton={true}
        />
        <div className="scroll">
          {/* <div className="personalize-customize">
            <h4>Setup Your WhatsApp Widget</h4>
            <p>setup the widget as per your interest</p>
          </div> */}
          {isLoading ? (
            <FullPageSpinner />
          ) : (
            <div className="tab-container">
              <SocialTabs
                key={1}
                values={values}
                tabValue={tabValue}
                mainTabValue={mainTabValue}
                setMainTabValue={setMainTabValue}
                handleChange={handleChange}
                formattedWhatsappConfig={formattedWhatsappConfig}
                handleAddWhatsappClickInCard={handleAddWhatsappClickInCard}
                handleSave={handleSave}
                disableSaveBtn={disableSaveBtn}
                handleCancel={handleCancel}
                handleDeleteWhatsappAccount={handleDeleteWhatsappAccount}
                storeTabs={storeTabs}
              />
            </div>
          )}
        </div>
      </WhatsappSetupStyles>
    </>
  );
};
export default WhatsappSetup;
