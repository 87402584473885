import React, { useContext, useEffect } from "react";
import { useState } from "react";
import LoginStyles from "../assets/styledComponents/Login";
import login123 from "../assets/images/login.jpg";
import logo from "../assets/images/logoTitle.svg";
import { TextField, InputAdornment, Button } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { EMAIL_REGEX } from "../utils/constants";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { DEFAULT_PASSWORD_CONSTRAINT } from "../utils/helperData";
import { Link, useNavigate } from "react-router-dom";
import { loginUser, connectShopify } from "../service/service";
import CustomSnackbar from "../components/snackbar/CustomSnackbar";
import { TenantContext } from "../context/TenantContext";
import LogoSpinner from "../components/reusable/Loader/LogoSpinner";
import queryString from "query-string";

const Login = () => {
  const { setToken } = useContext(TenantContext);
  const navigate = useNavigate();

  const [value, setValue] = useState({ email: "", password: "" });
  const [showPasswordAlert, setPasswordAlert] = useState(
    DEFAULT_PASSWORD_CONSTRAINT
  );
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [snackBarValue, setSnackBarValue] = useState({ msg: "", type: "" });
  const [isLoading, setLoading] = useState(false);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    const newErrors = { ...errors };

    if (name === "email") {
      const regex = EMAIL_REGEX;
      const isEmail = regex.test(value);
      if (!isEmail) {
        newErrors[name] = "Invalid Email";
      } else {
        newErrors[name] = "";
      }
    } else if (name === "password") {
      if (value !== "") {
        newErrors[name] = "";
      }
      newErrors[name];
      let currentPassword = value;
      let newPassConstraintCheck = [...DEFAULT_PASSWORD_CONSTRAINT];

      newPassConstraintCheck = await Promise.all(
        newPassConstraintCheck.map((passConstraintData) => {
          let result = {
            ...passConstraintData,
          };

          if (passConstraintData.regexPattern) {
            if (passConstraintData.regexPattern.test(currentPassword)) {
              result.valid = true;
            } else {
              result.valid = false;
            }
          }
          if (passConstraintData.minLength) {
            if (currentPassword.length >= passConstraintData.minLength) {
              result.valid = true;
            } else {
              result.valid = false;
            }
          }
          return result;
        })
      );

      setPasswordAlert(newPassConstraintCheck);
    }

    setValue((prev) => ({ ...prev, [name]: value }));
    setErrors(newErrors);
  };

    const handleSubmit = async (e) => {
    const { email, password } = value;

    const store = localStorage.getItem("store");

    // setValue({ email: "", password: "" });
    const newErrors = { ...errors };
    let isDirty = false;

    if (errors.email) {
      return
    }

    if (!value.email) {
      newErrors.email = "Email is Required";
      isDirty = true;
    }

    if (!value.password) {
      newErrors.password = "Password is Required";
      isDirty = true;
    }
    setTimeout(() => {
      setSnackBarValue({ msg: "", type: "" });
    }, [5000])

    if (isDirty) {
      setErrors(newErrors);
      return;
    }
    if (!isDirty) {
      try {
        setLoading(true);
        const userLogin = await loginUser({
          email,
          password,
          shop: null
        });
        

        const { search } = window.location;
        const parsed = queryString.parse(search);
        setToken(userLogin?.token);

        if (
          parsed.code &&
          parsed.host &&
          parsed.shop && 
          userLogin?.token
        ) {
        
          const updatedUserLogin = await connectShopify({
            code: parsed.code, 
            shop: parsed.shop
          })
    
          setToken(updatedUserLogin?.token);
        }   
        setSnackBarValue({ msg: userLogin.message, type: "success" });

        setTimeout(() => {
          navigate("/");
          setLoading(false);
          window.location.reload();
        }, 1000);
      } catch (error) {
        setSnackBarValue({ msg: error?.response?.data.message, type: "error" });
        setLoading(false);
      }
    } else if (!store) {
      setSnackBarValue({
        msg: "please install shopify app & login from shopify dashboard",
        type: "error",
      });
    } else {
      setSnackBarValue({ msg: "please provide all values", type: "error" });
    }
  };

const handleKeyPress = (event) => {
  if (event.key === "Enter") {
    handleSubmit();
  }
};
  return (
    <LoginStyles>
      {snackBarValue.msg && snackBarValue.type === 'success' && <CustomSnackbar payload={snackBarValue} />}
      <div className="container">
        <div className="left">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <img className="login-img" src={login123} alt="" />
        </div>
        <div className="right">
          <div className="right-container">
            <div className="login-title">Login to HelpIQ account </div>
            <div className="vertical-spacer-20" />
            {/* <p className="welcome-msg">
              Welcome to HelpIQ to explore the new things
            </p> */}
            <div className="form">
              <div className="vertical-spacer-20" />
              <div className="signup-form">
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={handleKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlinedIcon htmlColor="#8db178" />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  sx={{ marginBottom: "16px" }}
                />

                <div className="vertical-spacer-20" />

                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  error={Boolean(errors.password)}
                  helperText={errors.password}
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  onChange={(e) => handleChange(e)}
                  onKeyDown={handleKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon htmlColor="#8db178" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={() => setShowPassword(!showPassword)}
                        sx={{ cursor: "pointer" }}
                      >
                        {showPassword ? (
                          <VisibilityOutlinedIcon htmlColor="#8db178" />
                        ) : (
                          <VisibilityOffOutlinedIcon htmlColor="#8db178" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />

                {/* <div className="forgot-password">
                  <Link style={{ color: "#4C65FF" }}>Forgot password</Link>
                </div> */}
                <div className="vertical-spacer-10" />
                {snackBarValue.type === "error" && snackBarValue.msg && (
                  <div className="error">{snackBarValue.msg}</div>
                )}
                {isLoading ? (
                  <Button
                    // onClick={(e) => handleSubmit(e)}
                    color="primary"
                    variant="contained"
                    fullWidth
                    sx={{
                      color: "white",
                      height: 50,
                    }}
                  >
                    <LogoSpinner />
                  </Button>
                ) : (
                  <Button
                    onClick={(e) => handleSubmit(e)}
                    color="primary"
                    variant="contained"
                    fullWidth
                    sx={{
                      color: "white",
                      height: 50,
                    }}
                  >
                    Login
                  </Button>
                )}

                <div />
                <p className="login-user-link">
                  Don&apos;t have an account ?{" "}
                  <Link to={"/register"} style={{ color: "#504BD0" }}>
                    Sign Up
                  </Link>
                </p>
                <p className="login-user-link">
                  Forgot your password ?{" "}
                  <Link to={"/forgot-password"} style={{ color: "#504BD0" }}>
                    Forget Password
                  </Link>
                </p>
                <div className="terms-and-condition">
                  {`By selecting "login," you acknowledge your agreement with the`}{" "}
                  <Link to={"/terms-and-conditions"}>Terms and Conditions</Link>{" "}
                  as well as the{" "}
                  <Link to={"/privacy-and-policy"}>Privacy Policy</Link>
                  {"  "}outlined in HelpIQ.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginStyles>
  );
};
export default Login;
