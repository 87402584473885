import styled from "styled-components";
const TicketSummaryStyles = styled.div`
  padding: 1rem;
  border-bottom: 1px solid var(--border-clr);
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ticket-summary-icon{
    cursor:pointer;
  }
  .ticket-summary-icon .img {
    float: left;
    margin-top: 8px;
  }
  .ticket-summary-icon .img img {
    height: 26px;
  }

  .ticket-id {
    margin-left: 0rem;
    color: #666666;
    font-size: 16px;
    font-weight: 500;
    width: 150px;
  }

  .ticket-id span {
    color: black;
    font-size: 14px;
  }
  .left {
    display: flex;
    align-items: center;
  }
  .left,
  .right,
  .center {
    display: flex;
  }
  .center {
    margin-left: 6px;
  }
  /* .center h4{
    margin-left:6px
  } */
  .center span {
    /* margin-left: 6px; */
  }
  .heading {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    font-size: 14px;
  }

  .dfc {
    padding-left: 4px;
  }

  .dfc span {
    font-size: 0.6rem;
    letter-spacing: 2px;
    color: #666666;
  }

  .assign-to {
    display: flex;
    align-items: center;
    color: var(--primary-800);
    /* margin-left: 10px; */
    margin-top: 5px;
    /* margin-right: 2px; */
    padding: 0 8px;
  }

  .avatar-img {
    margin-left: 10px;
    /* margin-right: 5px; */
  }
  .right span {
    font-weight: 500;
  }

  .three-dot-btn {
    border-radius: 0.5rem;
  }

  .three-dot-btn {
    padding: 0.3rem;
    background-color: var(--secondary-500);
  }
  .options {
    display: none;
  }
  .hide {
    visibility: hidden;
    display: none;
  }
  .right {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    justify-content: flex-end;
    align-items: center;
    /* background-color: chartreuse; */
    width: 100%;
    margin-right: .75rem;
  }
  .ticket-summary-icon {
    margin-left: auto;
  }
`;
export default TicketSummaryStyles;
