import styled from "styled-components";

const MessageViewStyles = styled.div`
  margin-top: 20px;
  .txt-container-msg {
    margin-left: 5px;
    margin-right: 20px;
  }

  .sender-container {
    margin-right: 0px !important;
    margin-left: 80px !important;
  }
  .ticket-note {
    margin-left: 0 !important;
    text-align: center;
  }

  .sender-container .msg {
    color: ${({ textColor }) => textColor};
    background-color: ${({ bgColor }) => bgColor};
  }

  .sender-container .time {
    float: right;
    // width: 130px;
  }

  .txt-container-msg .msg {
    border: 1px solid silver;
    padding: 12px;
    border-radius: 8px;
    font-size: 16px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    width: fit-content;
    margin-left: auto;
  }
  .txt-container-msg .time {
    color: #858585;
    font-size: 14px;
    margin-top: 4px;
    margin-left: auto;
  }
  .image-container {
    float: right;
  }
  .time {
    display: flex;
    align-items: center;
  }
  .user-name {
    // font-weight: 500;
    float: right;
    font-size: 14px;
    margin-top: 4px;
    margin-left: auto;
  }
`;
export default MessageViewStyles;
