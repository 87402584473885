import React, { useEffect, useState } from "react";
import StoreStyles from "../../assets/styledComponents/shopifyStore/Store";
import PrimaryButton from "../../components/reusable/PrimaryButton/PrimaryButton";
import CustomSearchBox from "../../components/reusable/CustomSearchBox";
import { Button, Chip } from "@mui/material";
import CustomTab from "../../components/reusable/CustomTab";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CustomInputDropDown from "../../components/CustomInputDropDown";
import {
  getSingleShopifyOrders,
  getSingleShopping,
} from "../../service/service";
import { useTenantContext } from "../../context/TenantContext";
import FullPageSpinner from "../../components/FullPageSpinner";
import { getKey } from "../../utils/helperFunction";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import { setAsCustomer } from "../../service/service";
import CustomSnackbar from "../../components/snackbar/CustomSnackbar";
import ProductList from "./ProductList";
import useDebounce from "../../hooks/useDebounce";
import ProductListSkeleton from "../../components/skeleton/ProductListSkeleton";
import OrderInfoSkeleton from "../../components/skeleton/OrderInfoSkeleton";
import CheckIcon from "@mui/icons-material/Check";

const customerInfoData = {
  "First Name": "Laura",
  "Last Name": "D'souza",
  "Email Id": "laura23@xyz.com",
  Phone: "9782344553",
  City: "Abc",
  Country: "Australia",
  // "Last Seen": "25 February ,2022",
  "Shipping Address": "Sector 2, house no 1221, abc, australia",
  // "Customer Note": "Nil",
  "Customer Tag": "Refund",
};

const orderInfoData = {
  id: "",
  Total: "AUD 229",
  Discount: "AUD 20",
  "Order Coupon": "",
  Taxes: "AUD 8",
  "Delivery Charges": "AUD 8",
  "Order Total": "AUD 217",
};
// const deliveryDetailsData = {
//   "Order Data": "25 February ,2022",
//   "Shipping Address": "Sector 2, house no 1221, abc, australia",
// };
// const trackingDetailsData = {
//   "Tracking Url": "https:/test.tracking/234",
//   "Tracking Number": "1234567",
//   "Sent By": "Bluedart",
//   "Ship Date": "25 February ,2022",
//   "Delivered Date": "25 February ,2022",
// };
// const othersData = {
//   "Order Tag": "Nil",
//   "Order Note": "Nil",
// };

const INITIALLOADING = {
  orderInfoLoading: false,
  shoppingLoading: false,
};

const Store = () => {
  const { profile, userId, tenantId, tenantStores, 
  selectedShop, setSelectedShop,
  } = useTenantContext();


  const {
    setText,
    sessionId,
    setRefreshNotification,
    error,
    setError,
    setCurrentSessionData,
    currentSessionData,
    orderData,
    setOrderData,
    searchQuery,
    setSearchQuery,
    refreshChatSessionsListOnly,
    shoppingData, setShoppingData

  } = useInboxContext();


  const [loading, setLoading] = useState({ ...INITIALLOADING });
  const [backendMsg, setBackendMsg] = useState({ msg: "", type: "" });
  const [selectedTab, setSelectedTab] = useState("orderInfo");
  const [placeholder, setPlaceholder] = useState("Search Order");
  const [debouncedValue] = useDebounce(searchQuery, 500);

  const { orderInfoLoading, shoppingLoading } = loading;

  useEffect(() => {
    if (searchQuery?.length === 0) {
      setOrderData([]);
    }
  }, [searchQuery]);

  const fetchOrderData = async () => {
      setError("");
    if (searchQuery === "") {
      setError("");
      return;
    }
    if (!currentSessionData._id || !selectedShop) {
      setError("Select a conversation first");
      return;
    }
    try {
      if (searchQuery.length >= 4 && selectedTab === "orderInfo") {
        setLoading((prev) => ({ ...prev, orderInfoLoading: true }));
        const result = await getSingleShopifyOrders(selectedShop, searchQuery);
        setLoading((prev) => ({ ...prev, orderInfoLoading: false }));
        setOrderData(result.data.findId);
      }
      if (selectedTab === "shopping") {
        setLoading((prev) => ({ ...prev, shoppingLoading: true }));
        const result = await getSingleShopping(selectedShop, searchQuery);
        setLoading((prev) => ({ ...prev, shoppingLoading: false }));
        setShoppingData(result.data.products);
      }
    } catch (error) {
      setError(error.response.data);
      setLoading({ ...INITIALLOADING });
      console.log(error.response.data);
    }
  };

  const handleSearch = async (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    fetchOrderData();
  }, [debouncedValue]);

  const handleClick = async (e, item) => {
    e.preventDefault();
    try {
      if (!item.customerInfo.customerId) {
        setBackendMsg({ msg: "No customer info found", type: "error" });
        return;
      }
      const result = await setAsCustomer({
        ...item.customerInfo,
        "Order Id": item.orderId,
        sessionId,
        "Order Status": item.orderInfo["Order Status"],
      });
      if (result.success) {
        setBackendMsg({ msg: result.message, type: "success" });
        setCurrentSessionData((prev) => ({
          ...result.data,
        }));
        setRefreshNotification((prev) => !prev);
        refreshChatSessionsListOnly();
        // setName(result.)
      } else {
        setBackendMsg({ msg: result.message, type: "error" });
      }
    } catch (error) {
      setBackendMsg({ msg: error.response.data, type: "error" });
      console.log(error);
    }
  };

  const OrderInfo = ({ data, orderId }) => {
    return (
      <StoreStyles>
        {backendMsg.msg ? (
          <CustomSnackbar
            payload={backendMsg}
            setPayload={setBackendMsg}
            duration={2000}
          />
        ) : (
          ""
        )}
        <div className="order-info-container">
          <h4 className="heading">Order Info</h4>
          <div className="order-id">
            <div className="id">
              <p className="light-gray">Order Id: </p>
              <span className="dark-black">{orderId}</span>
            </div>
            <div className="status-chip">
              <Chip
                size="small"
                sx={{
                  fontSize: ".6rem",
                  background: "#8db178",
                  color: "white",
                }}
                label={data?.["Order Status"]}
              />
            </div>
          </div>
          <div className="list"></div>
          <h5 className="secondary-heading">Order Details</h5>
          <div className="data-list">
            {Object.keys(orderInfoData).map((key, index) => {
              return (
                <div key={index} className="row">
                  <div className="key">{key}</div>
                  <div className="value">{data[key] ? data[key] : "_"}</div>
                </div>
              );
            })}
          </div>
        </div>
      </StoreStyles>
    );
  };

  const Details = ({ data, name }) => {
    return (
      <StoreStyles>
        <div className="details-wrapper">
          <h5 className="secondary-heading">{name}</h5>
          <div className="data-list">
            {Object.keys(data).map((key, index) => {
              return (
                <div key={index} className="row">
                  <div className="key">{key}</div>
                  <div className="value">{data[key]}</div>
                </div>
              );
            })}
          </div>
        </div>
      </StoreStyles>
    );
  };

  const CustomerInfo = () => {
    return (
      <StoreStyles className="tab-pannel-wrapper">
        <div className="order-info-wrapper">
          {orderInfoLoading ? (
            <OrderInfoSkeleton />
          ) : error ? (
            <h3 className="error-message">{error}</h3>
          ) : searchQuery?.length === 0 && !orderData.length ? (
            <div className="empty-order-state">
              Search Order details by entering Order Number
            </div>
          ) : (
            orderData.map((item, index) => {
              return (
                <div
                  key={getKey("orderData", "customerName", index)}
                  className="customer-info-container"
                >
                  <div className="heading-container">
                    <h4 className="heading">Customer info</h4>

                    <div className="create-order-btn-container">
                    <a
                      href={`https://${profile?.token?.shop}/admin/draft_orders/new`}
                      target="_blank"
                      rel="noreferrer"
                      style={{}}
                    >
                      <p className="create-order-btn">Create Order</p>
                    </a>
                  </div>

                    <PrimaryButton
                      size="small"
                      sx={{
                        fontSize: ".5rem",
                        padding: ".5rem 0.2rem",
                      }}
                      variant={"contained"}
                      onClick={(e) => handleClick(e, item)}
                    >
                      {currentSessionData?.customerInfo ? (
                        <span className="marked-customer">
                          <CheckIcon sx={{ fontSize: ".9rem" }} />
                          Customer
                        </span>
                      ) : (
                        "Set as Customer"
                      )}
                    </PrimaryButton>
                  </div>
                  <div className="data-list">
                    {Object.keys(customerInfoData).map((key, index) => {
                      return (
                        <div key={index} className="row">
                          <div className="key">{key}</div>
                          <div className="value">
                            {item?.customerInfo[key]
                              ? item?.customerInfo[key]
                              : "_"}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="underline"></div>
                  <OrderInfo
                    data={{ ...item?.orderInfo, id: item?.id }}
                    orderId={item?.orderId}
                  />
                  <div className="underline"></div>
                  {/* <Details
                    name="Delivery Details"
                    data={{ ...item?.deliveryDetails }}
                  />
                  <div className="underline"></div>
                  <Details
                    name={"Tracking Details"}
                    data={{ ...item?.trackingDetails }}
                  /> */}
                  <div className="underline"></div>
                  <Details name={"Others"} data={{ ...item?.others }} />
                </div>
              );
            })
          )}
        </div>
      </StoreStyles>
    );
  };

  const shopping = (
    <StoreStyles className="tab-pannel-wrapper">
      <div className="product-info">
        {/* <h4 className="heading">Product Info</h4> */}
        <a
          href={`https://${profile?.token?.shop}/admin/draft_orders/new`}
          target="_blank"
          rel="noreferrer"
        >
          <p className="create-order-btn">Create Order</p>
        </a>
      </div>
      {shoppingLoading ? (
        Array(5)
          .fill()
          .map((_, index) => {
            return <ProductListSkeleton key={index} />;
          })
      ) : error ? (
        <h3 className="error-message">{error}</h3>
      ) : (
        <div className="product-list-container">
          {searchQuery?.length === 0 && !shoppingData.length ? (
            <div className="empty-order-state">
              Search Products by entering name of the product
            </div>
          ) : (
            shoppingData.map((item, index) => {
              const product = item || {};
              return (
                <div key={index}>
                  <ProductList {...product} shopName={selectedShop} />
                </div>
              );
            })
          )}
        </div>
      )}
    </StoreStyles>
  );
  const tabLabels = ["Order Info", "Shopping"];
  const tabContents = [<CustomerInfo key={1} />, shopping];
  const selectList = [
    { name: profile?.token?.shop, value: profile?.token?.shop },
  ];

  const handleDropDownChange = (e) => {
    const { name, value } = e.target || {};
    setSelectedShop(value);
    setSearchQuery('');
    setShoppingData([])
    setOrderData([])
  };
  const handleTabClick = (value) => {
    if (value === 0) {
      setSelectedTab("orderInfo");
      setPlaceholder("Search Order");
    } else if (value === 1) {
      setSelectedTab("shopping");
      setPlaceholder("Search Shopping");
    }

    setSearchQuery("");
    setOrderData([]);
    setShoppingData([]);
  };

  return (
    <StoreStyles>
      <div className="main-store-container">
        <div className="select-store">
          <div className="name">Store Name</div>
          <div className="dropdown">
            {console.log({tenantStores})}
            <CustomInputDropDown
              label={"Select Store"}
              name={"shop"}
              value={ selectedShop ||  profile?.token?.shop}
              selectList={tenantStores}
              required={true}
              onChange={handleDropDownChange}
              width={"100px"}
              height={"35px"}
              formControlSx={{
                backgroundColor: "#8DB178",
                outline: "none",
                border: "none",
              }}
              inputLabelSx={{
                marginTop: "-.4rem",
                marginLeft: "-.4rem",
                fontSize: ".7rem",
                color: "white",
                border: "none",
                fontWeight: "500",
              }}
            />
          </div>
        </div>
        <div className="search-container">
          <CustomSearchBox
            handleSearch={handleSearch}
            placeholder={placeholder}
            value={searchQuery}
          />
        </div>
        <div className="tab-container">
          <CustomTab
            tabLabels={tabLabels}
            tabContents={tabContents}
            onTabClick={handleTabClick}
          />
        </div>
      </div>
    </StoreStyles>
  );
};

export default Store;
