import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";

const ProductListSkeleton = () => {
  return (
    <Stack sx={{ padding: ".5rem 1rem" }} spacing={1}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <Skeleton variant="rectangular" height={70} width={70} />
        <Box sx={{ display: "flex", flexDirection: 'column', alignItems: "flex-start", justifyContent: 'start', gap: "1rem" }}>
          <Skeleton variant="rectangular" height={10} width={80} />
          <Skeleton variant="rectangular" height={10} width={40} />
          <Skeleton variant="rectangular" height={10} width={40} />
        </Box>
        <Skeleton variant="rectangular" sx={{ borderRadius: '1rem', alignSelf: 'flex-end', marginBottom: '.5rem' }} height={20} width={50} />
      </Box>
    </Stack>
  );
};

export default ProductListSkeleton;
