import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Chip, IconButton, Tooltip, Typography } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import DeleteOutline  from "@mui/icons-material/DeleteOutline";


export default function FaqAccordion({
  question,
  answer,
  status,
  handleDelete,
  handleEdit,
  faqId,
}) {
  return (
    <div>
      <Accordion sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div style={{}}>
            <div>{question}</div>
            <span
              style={{
                fontSize: "14px",
                color: "grey",
              }}
            >
              Last Trained on 29 Feb 2023
            </span>
          </div>
          <div
            style={{
              marginLeft: "auto",
              paddingLeft: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {status === "Not Trained" ? (
              <Chip
                color="warning"
                sx={{ marginLeft: "auto", marginRight: "2rem" }}
                label={status}
              />
            ) : (
              <Chip
                color="success"
                sx={{ marginLeft: "auto", marginRight: "2rem" }}
                label={status}
              />
            )}
            <IconButton onClick={() => handleDelete(faqId)}>
              <DeleteOutline />
            </IconButton>
            <IconButton onClick={() => handleEdit(faqId, question, answer)}>
              <Edit  />
            </IconButton>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <b>Answer</b>
          </div>
          <div style={{ fontSize: "16px", marginTop: "6px" }}> 👉 {answer}</div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
