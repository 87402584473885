/* eslint-disable no-undef */
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_REGION,
  signatureVersion: "v4",
});

const s3 = new AWS.S3();
// export const uploadToS3 = async (file) => {
//   if (!file) {
//     return;
//   }
//   const params = {
//     Bucket: "txta",
//     Key: `${Date.now()}.${file.name}`,
//     Body: file,
//   };
//   const { Location } = await s3.upload(params).promise();
//   return Location;
// };

export const uploadToS3 = async (file) => {
  if (!file) {
    return;
  }
  let contentType 
  if (file.type.startsWith('image/')) {
    contentType = file.type;
  } else {
    contentType = 'application/octet-stream';
  }
  const params = {
    Bucket: "txta",
    Key: `${Date.now()}.${file.name}`,
    Body: file,
    ContentType: contentType,
  };

  try {
    const { Location } = await s3.upload(params).promise();
    return Location;
  } catch (error) {
    console.error('Error uploading to S3:', error);
    throw error;
  }
};
