import React, { useContext, useState } from "react";
// import Typography from "@material-ui/core/Typography";
// import { Box } from "@material-ui/core";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import Swal from "sweetalert2";
import { TenantContext } from "../../../context/TenantContext";
import { buyAdditionalTickets, verifyTicketPurchase } from "../../../service/service";
import ActionButtonGroup from "../ActionButtonGroup/ActionButtonGroup";
import Modal from "../../reusable/DialogModal/Modal";
import styled from "styled-components";

const StyledTicketPurchaseModal = styled.div`
  width: 600px;
  // height:500px;
  position: relative;

  .container {
    padding: 20px;
    height: 200px;
    overflow-y: auto;
  }

  .btn-container {
    position: absolute;
    bottom: 1.5rem;
    right: 2rem;
  }
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

const QuotaProgress = ({ usedQuota, totalQuota }) => {
  const { profile } = useContext(TenantContext);

  // Calculate percentage of used quota
  const percentage =
    usedQuota && totalQuota ? (usedQuota / totalQuota) * 100 : 0;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  // Determine the color of the progress bar based on the percentage
  const color =
    percentage >= 80 ? "#ff6213" : percentage >= 50 ? "#ffbf13" : "#8db178";
  const integerPart = Math.trunc(percentage); // integerPart will be 3

  const handleRechargeButton = async () => {
    const pricingPlan = profile?.token?.pricingPlan;

    // if (pricingPlan) {
    //   setOpen(true);
    // }
    const result = await Swal.fire({
      title: "Recharge Ticket Quota",
      text: `Buy 1000 additional tickets for $${pricingPlan.additionalTicketPrice}`,
      confirmButtonColor: "#8db178",
      showCancelButton: true,
      confirmButtonText: "Buy",
      denyButtonText: `Cancel`,
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      handleBuyButtonClick() 
    }
  };

  const handleShowQuoataDetails = async () => {
    await Swal.fire({
      title: "Tickets Quota",
      text: `You have used ${usedQuota} tickets out of ${totalQuota} tickets. Now you have ${
        totalQuota - usedQuota
      } tickets available for this month.`,
      confirmButtonColor: "#8db178",
    });
  };

  const onModalClose = (event, reason) => {
    console.log("close reaason", reason);
    setOpen(false);
  };

  const handleBuyButtonClick = async () => {
    const pricingPlan = profile?.token?.pricingPlan;
    const tenantId = profile.token.tenantId;
    setLoading(true);
    try {
      const buyTicketsRes = await buyAdditionalTickets({
        tenantId,
        selectedPlanId: pricingPlan._id,
        shop: profile.token.shop,
      });
      console.log("buy ticket res---", buyTicketsRes);
      if (buyTicketsRes.success) {
        const chargeId = buyTicketsRes.chargeId;
        const paymentUrl = buyTicketsRes.paymentUrl;
        if (paymentUrl) {
          window.location.href = paymentUrl;
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setOpen(false);
      await Swal.fire({
        icon: "error",
        title: "Recharge Ticket Quota",
        text: `Opps! Looks like your payment got failed. Please check your payment method or contact helpiq admin`,
        confirmButtonColor: "#8db178",
      });
    }
  };

  const BuyMoreTicketModal = () => {
    const pricingPlan = profile?.token?.pricingPlan;

    if (!pricingPlan) return;
    const text = `Buy 1000 additional tickets for $${pricingPlan.additionalTicketPrice}`;
    return (
      <Modal
        name="Recharge Ticket Quota"
        open={open}
        onClose={onModalClose}
        disableEscapeClose={true}
        disableBackdropClose={true}
      >
        <StyledTicketPurchaseModal>
          <div className="ticket-purchase-modal">
            {loading ? (
              <div className="container">
                <div className="loader">
                  <CircularProgress />
                </div>
              </div>
            ) : (
              <>
                <div className="container">
                  <p>{text}</p>
                </div>
                <div className="btn-container">
                  <ActionButtonGroup
                    primaryButtonLabel={"Buy"}
                    secondaryButtonLabel={"Cancel"}
                    handlePrimaryButtonClick={handleBuyButtonClick}
                    handleSecondaryButtonClick={onModalClose}
                  />
                </div>
              </>
            )}
          </div>
        </StyledTicketPurchaseModal>
      </Modal>
    );
  };

  return (
    <div style={{ position: "relative", width: 80, height: 80 }}>
      <Box sx={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: "#d3d3d3",
          }}
          size={80}
          thickness={5}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          disableShrink
          sx={{
            color: color,
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          value={percentage}
          size={80}
          thickness={5}
        />
      </Box>

      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <Typography
          variant="caption"
          sx={{ fontSize: 18, cursor: "pointer" }}
          onClick={handleShowQuoataDetails}
        >
          {`${integerPart}%`}
        </Typography>

        <Tooltip title="Buy more tickets">
          <IconButton
            sx={{ height: 14, width: 14 }}
            onClick={handleRechargeButton}
          >
            <ElectricBoltIcon style={{ color: "#ffc107" }} />
          </IconButton>
        </Tooltip>
        <BuyMoreTicketModal />
      </div>
    </div>
  );
};

export default QuotaProgress;
