import React, { useContext, useState } from "react";
import styled from "styled-components";
import Logo from "../Logo";
import { settingsList } from "../../utils/helperData";
import Dropdown from "../inboxMenu/Dropdown";
import { useNavigate, useLocation } from "react-router-dom";
import { TenantContext } from "../../context/TenantContext";
import { getKey } from "../../utils/helperFunction";

const SettingsMenuStyles = styled.div`
  width: 250px;
  border-right: var(--border);
  height: 100vh;
  padding-inline: .75rem;
  overflow-y: auto;
  h3 {
    /* margin-left: 1rem; */
    margin-top: 2rem;
    margin-bottom:10px
  }
  .dropdown {
    /* padding: 0 1rem; */
    /* padding-inline: .7rem; */
  }
  .report-list {
    padding: 1rem;
    position: relative;
    cursor: pointer;
    /* background-color: red; */
    border-bottom: 1px solid var(--border-clr);
    padding-left: .2rem;
    /* width: 90%; */
    /* margin-left: auto;
    margin-right: auto; */

  }
  /* .report-list::after {
    content: "";
    position: absolute;
    left: 5%;
    bottom: 0;
    width: 90%;
    height: 1px;
    background-color: var(--border-clr);
  } */
  .active {
    background-color: var(--primary-100);
  }
  .logo {
    margin-left: -18px;
  }
`;

const SettingsMenu = () => {
  const [selectedSettingsList, setSelectedSettingsList] = useState();
  const { profile } = useContext(TenantContext);
  const navigate = useNavigate();

  const handleClick = (name, route) => {
    if (
      profile &&
      profile.isMenuDisabled &&
      route !== "connect-shopify" &&
      route !== "pricing"
    ) {
      return;
    }

    setSelectedSettingsList(name);
    if (route) {
      navigate(route);
    }
  };

  const location = useLocation();
  const path = location.pathname.split("/")[2];
  
  return (
    <SettingsMenuStyles>
      <div className="logo">
      <Logo />
      </div>
      <div className="container">
        <h3>Settings</h3>
        {settingsList.map((item, index) => {
          const { id, name, subList, route,firstRoute, path: sublistPath } = item;
          return (
            <React.Fragment key={id}>
              {subList ? (
                <li className="dropdown">
                  <Dropdown
                    isActive={path === route}
                    iconList={subList}
                    name={name}
                    path={sublistPath}
                    firstRoute={firstRoute}
                    noOfPath={3}
                    settingsPage={true}
                  />
                </li>
              // ) : id === 8 ? (
                // <li className="dropdown">
                //   <Dropdown
                //     iconList={subList}
                //     name={name}
                //     path={"aiConfiguration"}
                //     firstRoute={"faqTraining"}
                //     noOfPath={3}
                //   />
                // </li>
             ) : (
                <li
                  className={`report-list ${path === route ? "active" : null} `}
                  onClick={() => handleClick(name, item?.route)}
                >
                  {name}
                </li>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </SettingsMenuStyles>
  );
};

export default SettingsMenu;
