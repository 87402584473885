import logo from "../assets/images/logo.png";
import { subscribe } from "../service/service";
import { urlBase64ToUint8Array } from "../utils/helperFunction";
const usePushNotification = () => {
  const publicVapidKey =
    "BNsCxiA3MvtUPw87Tm7-O9TvrXk_ZcuoApRo4qKYZYJy1UOZV1RqjGiYU8355piYig0It8oIt7wdXPxilH5LPGw";

  const askPermission = async () => {
    const permission = await Notification.requestPermission();
    return permission;
  };

  const requestPermissionAgain = async () => {
    const permission = await askPermission();
    // You can handle the permission response here if needed.
  };

  const sendNotification = async (message) => {
    const permission = await askPermission();

    if (permission === "default") {
      requestPermissionAgain()
    }
    if (permission === "granted") {
      const notification = new Notification("New Notification", {
        icon: logo,
        body: message,
        data: { hello: "world" },
      });
    }
  };
  return [askPermission, sendNotification];
};

export default usePushNotification;
