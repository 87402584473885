import styled from "styled-components";
const UserHistoryStyles = styled.div`
  border-bottom: none;
  .user-history-container {
    height: 500px;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1.5rem;
  }
  .open-container {
    width: 90%;
    margin: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .profile {
    margin-top: 1rem;
  }
  .message-card {
    height: 100px;
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.25);
    width: 550px;
    border-radius: 1rem;
  }
  .message-preview-container {
    width: 610px;
  }
  .session-card-container {
    height: 500px;
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* gap: 5rem; */
    gap: 2rem;
    width: -webkit-fill-available
  }
`;
export default UserHistoryStyles;
