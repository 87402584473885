import styled from "styled-components";
const QuickReplyStyles = styled.div`
  /* width: 81vw; */
  .quick-reply-container {
    display: flex;
    gap: 4rem;
  }
  .input-wrapper{
    flex: 1.7;
    /* padding-right: 1rem; */
    height: 88vh;
    border-right: 1px solid var(--border-clr);
    margin-top: -10px;

  }
  .text-editor-container{
    margin-top: 1rem;
    flex :3;
  }
  .input-container {
    margin-left: 2.3rem;
    width: 85%;
    display: flex;
    background-color: #d9d9d9;
    padding: 0.5rem;
    border-radius: 6px;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }
  .input-container input {
    all: unset;
    margin-left: 0.75rem;
  }
  
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .top button {
    margin-right: 18px;
  }
  .underline {
    background-color: var(--border-clr);
    width: 100%;
    height: 1px;
    margin-top: 0px;
    margin-bottom: 10px;
  }


  .template-item.active {
    background: var(--primary-100) !important;
  }

  .line {
    background: gray;
    width: 113%;
    height: 0.1px;

    position: relative;
    bottom: 34px;
    left: -40px;
  }
  .vertical {
    height: 90px;
    width: 1px;
    background-color: var(--border-clr);
  }
  .this {
    font-size: 28px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
  }
  .search-icon {
    cursor: none;
  }
  .delete-icon{
    display: flex;
    margin-left: auto;
    align-items: center;
    margin-top: 6px;
    margin-right:10px
  }
  .template-list{
    display:flex
  }
  .quick-reply-label h2 {
    padding-left: 40px;
    padding-top: 6px;
    font-size: 17px;
    margin-top: 5px;
    padding-bottom: 10px;
    font-weight: 400;
    margin-bottom: 5px;
    color: #333333;
    cursor: pointer;
  }
  .search {
    width: 100px;
    padding-left: 40px;
  }
  .search input {
    background-color: silver;
    margin-bottom: 3px;
    outline: none;
    border: none;
  }
  .icon {
    display: flex;
    background-color: silver;
    box-shadow: none;
    width: 300px;
    height: 35px;
    border: none;
    border-radius: 5px;
    padding-left: 15px;
    padding-top: 6px;
  }
  .return1.selected {
    background-color: var(--primary-100);
    padding: 80px;
    height: 100px;
  }
  .temp1 input {
    width: 580px;
    height: 40px;
    border-radius: 5px;
    box-shadow: 7;
  }
  .vertical-line {
    height: 88vh;
    background-color: var(--border-clr);
    width: 1px;
    margin-top: -10px;
    // margin-left: 1px;
  }

  .heading3 {
    /* margin-left: -40px; */
    cursor: none;
  }
  .heading2 {
    /* margin-left: -40px; */
    cursor: none;
  }
  .text1 {
    border-radius: 50px;
  }
  .template-message {
    margin-top: 1rem;
    /* margin-left: -2.5rem; */
  }
  .save-button {
    color: white;
    margin-top: 1.5rem;
    align-self: flex-end;
  }
  .template-container {
    /* width: 650px; */
    width: 90%;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 100px) !important;
    overflow-y: auto;
  }
  .scroll-div {
    /* max-height: 73vh; */
    max-height: calc(100vh - 180px) !important;
    overflow-y: auto;
  }
  .error-label {
    margin-top: 0.5rem;
    margin-left: -2rem;
    font-size: small;
    color: #d32f2f;
  }
  @media (max-width: 1368px) {
    width: 100%;
  }
  @media (max-width: 1200px) {
    .template-container {
      padding-top: 2rem;
    }

    .template-message {
      width: 100%;
    }

    .save-button {
      margin-top: 2rem;
    }
  }
  .template-card span {
    font-size: 17px;
    margin-top: 10px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #333333;
  }
`;
export default QuickReplyStyles;
