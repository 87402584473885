import { Button, Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import facebookfill from "../../../assets/images/facebook-fill.png";
import StepLabel from "@mui/material/StepLabel";
import CustomTextField from "../../reusable/CustomTextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  customDialog: {
    borderRadius: 15,
    width: 900,
    marginLeft: 400,
  },
  underline: {
    borderTop: "1px solid",
    borderColor: "#999999",
    height: "2px",
    margin: "10px 0px",
  },
  facebook: {
    fontWeight: 400,
    marginTop: 6,
  },
  facebook1: {
    fontWeight: 400,
    marginTop: 19,
  },
  text2: {
    color: "#81848A",
    fontSize: "14px",
    paddingTop: "6px",
  },
  text3: {
    fontSize: "13px",
    paddingTop: "6px",
  },
  coloredIcon: {
    fill: "#8CB077 !important",
    marginRight: "15px",
    marginBottom: -5,
  },
  head1: {
    fontWeight: 500,
    fontSize: 18,
    marginTop: 8,
  },
  stepper: {
    fontWeight: 500,
    fontSize: 18,
    paddingBottom: 18,
  },
  button: {
    color: "white !important",
  },
});
const steps = ["Provide Access", "Number Verified", "Create Account"];

const WhatsappDialog = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const classes = useStyles();
  const jsx = [
    <div key={1}>
      <div className={classes.head1}>Facebook Business Manager</div>
      <div className={classes.text2}>
        You must log in to your facebook account and have access to the Facebook
        <br></br>
        Business Manager associated with your company.
      </div>
      <br />
      <div className={classes.facebook}>
        <CheckBoxOutlinedIcon className={classes.coloredIcon} />I have Facebook
        account and understand the above
      </div>
      <div className={classes.facebook1}>
        Dont have Facebook account ? <Link to="">Click Here</Link>
      </div>
    </div>,
    <div key={2}>
      <div className={classes.head1}>
        Phone Number For Business Whatsapp API
      </div>
      <div className={classes.text2}>
        Your WhatsAPP channel needs a Phone number with the following<br></br>
        specification:<br></br>
        <div className={classes.text2}>
          {" "}
          This number must be your companys business number.Dont use your{" "}
          <br></br>
          private number.
        </div>
        <br></br>. This number must be able to receive phone calls or text
        message
      </div>
      <br />
      <div className={classes.facebook}>
        {" "}
        <CheckBoxOutlinedIcon className={classes.coloredIcon} />I have Facebook
        account and understand the above
      </div>
    </div>,
    <div key={3}>
      <div className={classes.head1}>
        Phone Number For Business Whatsapp API
      </div>
      <div className={classes.text2}>
        To Create the business account you need the following<br></br>. This
        number must be your companys business number.Dont use your <br></br>
        private number.
        <br></br>. the name of your company<br></br>. the name of your company
        <br></br>. the name of your company<br></br>
      </div>
      <br />
      <div className={classes.head1}>Create acoount</div>
      <div className={classes.text3}>
        We will redirect the facebook Business manager.Follow the steps to
        create your<br></br> whatsapp business account
      </div>

      <div>
        <CustomTextField
          sx={{
            width: 355,
            marginBottom: 3,
            marginLeft: 0,
            marginTop: 1,
          }}
          startAdornment={
            <img
              src={facebookfill}
              style={{ width: 30, marginLeft: 1, marginTop: 1 }}
            />
          }
          placeholder={"Continue with facebook page"}
          margin={"normal"}
        />
      </div>
    </div>,
  ];
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  //   const handleReset = () => {
  //     setActiveStep(0);
  //   };

  //   const [store, setStore] = useState("");
  //   const classes = useStyles();

  //   const handleChange = (e) => {
  //     setStore(e.target.value);
  //   };

  //   const handleClose = () => {
  //     handleCloseModal();
  //     setStore("");
  //   };

  //   const onButtonClick = () => {
  //     if (!store) return;

  //     window.location = `${process.env.REACT_APP_BACKEND_APP_URL}api/auth?shop=${store}`;
  //   };

  return (
    <Dialog
      open={open}
      //onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // className={classes.customDialog}
      sx={{ borderRadius: 19 }}
    >
      <DialogTitle id="alert-dialog-title">
        {"Getting started with whatsapp business API"}
        <span
          style={{ float: "right", cursor: "pointer" }}
          //onClick={handleClose}
        >
          <CloseIcon />
        </span>
        <div className={classes.underline}></div>
      </DialogTitle>
      <DialogContent>
        <div>
          <Box sx={{ width: "100%" }}>
            <Stepper
              className={classes.stepper}
              activeStep={activeStep}
              alternativeLabel
            >
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {jsx[activeStep]}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    onClick={handleNext}
                    //  className={classes.button}
                    sx={{
                      marginLeft: 56,
                      marginTop: 1,
                      color: "white !important",
                    }}
                    variant="contained"
                  >
                    {activeStep === steps.length - 1 ? "Next" : "Next"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WhatsappDialog;
