import moment from 'moment';

export const getMonthName = (monthIndex) => {
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months[monthIndex];
}
export const generateChartLabels = (customStartDate, customEndDate) => {
    // Convert customStartDate and customEndDate to Date objects
    const startDate = moment(customStartDate, "DD/MM/YYYY");
    const endDate = moment(customEndDate, "DD/MM/YYYY");
    
    // Calculate the difference in days
    const dayDifference = endDate.diff(startDate, 'days');

    console.log(">>> Days Difference", dayDifference)
    if (dayDifference < 15) {
        // If the difference is less than 15 days, generate daily labels
        const labels = [];
        for (let i = 0; i <= dayDifference; i++) {
            const date = moment(startDate).add(i, 'days');
            const formattedDate = date.format('DD MMM');
            labels.push(formattedDate);
        }
        return labels;
    } else if (dayDifference < 30) {
        // If the difference is less than 30 days, generate weekly labels
        const labels = [];
        let currentDate = moment(startDate);
        for (let i = 1; i <= Math.ceil(dayDifference / 7); i++) {
            const weekStartDate = currentDate.clone();
            const weekEndDate = currentDate.clone().add(6, 'days'); // Assuming a week ends on the 7th day
            const formattedWeek = `Week ${weekStartDate.format('DD MMM')} - ${weekEndDate.format('DD MMM')}`;
            labels.push(formattedWeek);
            currentDate = weekEndDate.add(1, 'days');  // Move to the next week
        }
        return labels;
    } else {
        // Handle cases where the difference is 30 days or more
        const labels = [];
        const startMonthYear = moment(startDate).format('MMM YY');
        const endMonthYear = moment(endDate).format('MMM YY');
      
        // Push the start month and year
        labels.push(startMonthYear);
      
        // Generate labels for each month and year in between
        let currentMonth = moment(startDate).add(1, 'month').startOf('month');
        while (currentMonth.isBefore(endDate, 'month')) {
          labels.push(currentMonth.format('MMM YY'));
          currentMonth.add(1, 'month');
        }
      
        // Push the end month and year
        labels.push(endMonthYear);
      
        return labels;
      }
      
      
}

// Helper function to get the month name
