import React from "react";
import MetaAddPageStyles from "../../../assets/styledComponents/settings/MetaAddpage";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CustomSwitch from "../../CustomSwitch";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const MetaAddPage = ({ name, logo, url }) => {
  return (
    <MetaAddPageStyles>
      <div className="image">
        <img src={logo} />
        <div className="name-container">
          <span className="title">{name}</span>{" "}
          <span className="url">{url}</span>
        </div>
      </div>
      <div className="action-icon">
        <EditOutlinedIcon />
        <DeleteOutlineOutlinedIcon />
        <div className="custom-icon">
          <CustomSwitch />
        </div>
      </div>
    </MetaAddPageStyles>
  );
};
export default MetaAddPage;
