import React, { useCallback, useEffect, useRef, useState } from "react";
import { MessageConversationStyles } from "../../assets/styledComponents/chatBox";
import MessageInput from "./MessageInput";
import { useTenantContext } from "../../context/TenantContext";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import MessageBox from "./MessageBox";
import {
  getDate,
  getFullDate,
  getKey,
  getTime,
} from "../../utils/helperFunction";
import {
  getAllUserSetup,
  removeUnReadMessage,
  updateUserName,
} from "../../service/service";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import { Chip, CircularProgress } from "@mui/material";
import CustomPopover from "../reusable/CustomPopover";
import TicketNote from "../message/TicketNote";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import FacebookPost from "./FacebookPost";
import SessionInfo from "./SessionInfo";
import FullPageSpinner from "../FullPageSpinner";

const MessageConversation = () => {
  const {
    normalMessage,
    sessionId,
    setName,
    selectedShop,
    // name,
    getChatMessage,
    isMessageLoading,
    numOfPages,
    backendMessage,
    setBackendMessage,
    currentSessionData,
    messageSent,
    setRefreshNotification,
    ticketNoteRef,
    socket,
    fbCommentCount,
    setFbCommentCount
  } = useInboxContext();

  let name = currentSessionData?.customerInfo?.["First Name"] || "Unknown";

  const {assignUser, assignTo ,status, channelName} = currentSessionData || {}
  const { userId: currentUserId, tenantId, profile, } = useTenantContext();
  const agentName = assignUser?.name || assignTo?.name || '' 
  console.log({currentSessionData})
  const [userNames, setUserNames] = useState([]);
  const [containerHeight, setContainerHeight] = useState(68);
  const [isFirstRender, setIsFirstRender] = useState(true);


  const chatContainerRef = useRef();
  const messageEndRef = useRef();
  const bottomRef  =useRef();

  const {
    taggedUser,
    facebookDetails,
    instagramDetails,
    instagramMessageType,
    facebookMessageType,
  } = currentSessionData || {};

  const {
    likeCount,
    commentCount,
    postCaption,
    postImage,
    userName,
    pageImage,
    postId,
    userId : socialUserId,
  } = facebookDetails || instagramDetails || {};
  const showPostCard =
    (instagramMessageType === "comment" || facebookMessageType === "comment") &&
    (facebookDetails || instagramDetails);


  socket?.off("AGENT_NEW_MESSAGE_NOTIFICATION").on("AGENT_NEW_MESSAGE_NOTIFICATION", (message, snooze) => {
    if (currentSessionData._id === message?.chatSessionId) {
      setFbCommentCount((prev) => prev + 1);
      console.log("AGENT_NEW_MESSAGE_NOTIFICATION", "inside fbpost", message, snooze);
    }
  })
useEffect(() => {
  setFbCommentCount(commentCount)
},[commentCount])
  console.log({fbCommentCount, commentCount})

  const fetchUserName = async () => {
    try {
      const result = await getAllUserSetup({ tenantId });
      setUserNames(result.data);
    } catch (error) {
      console.log(error);
    }
  };


  // const unRead = async () => {
  //   const result = await removeUnReadMessage(sessionId, "agent");
  //   console.log({ result }, "unread");
  // };

  // useEffect(() => {
  //   unRead();
  // }, [sessionId]);

  useEffect(() => {
    fetchUserName();
  }, []);
  useEffect(() => {
    setIsFirstRender(false)
  } , [])


  useEffect(() => {
    scrollToBottom()
  }, [messageSent]);


  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = 0;
    }
    bottomRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const handleFocus = () => {
    console.log('Socket In Focus', currentSessionData);
    // Emit a socket event when the component is in focus
    if (currentSessionData && currentSessionData._id) {
      socket?.emit('AGENT_SEEN_MESSAGE', currentSessionData._id, tenantId, currentUserId);
    }
  };

  useEffect(() => {
    // Attach the onFocus event listener when the component mounts
    const component = document?.getElementById('conversation-container');
    component?.addEventListener('focus', handleFocus);
    // Clean up the event listener when the component unmounts
    return () => {
      component?.removeEventListener('focus', handleFocus);
    };
  }, []); 


  const getContainerHeight = () => {
        // Total Height Of the Component 
        var mainContainer = document.getElementById('main-msg-container');
        var mainContainerHeight =  mainContainer ? (mainContainer.getBoundingClientRect().height / window.innerHeight) * 100 : 0;
    

      
        // Height of Header
        var headerContainer = document.getElementById('header-section-message');
        var headerContainerHeight =  headerContainer ? (headerContainer.getBoundingClientRect().height / window.innerHeight) * 100 : 0;
    
        // Height of Input Box
        // Height of Header
        var inputContainer = document.getElementById('message-input-box');
        var inputContainerHeight =  inputContainer ? (inputContainer.getBoundingClientRect().height / window.innerHeight) * 100 : 0;
        // Calculate Container Height    

        const extraHeight = 100 - mainContainerHeight;

        var newHeight = mainContainerHeight - headerContainerHeight - inputContainerHeight - extraHeight;
    
        console.log({
            mainContainerHeight,
            headerContainerHeight,
            inputContainerHeight
        })

        if (!newHeight || newHeight <0) {
          newHeight = 68
        }

        return newHeight
  }


  useEffect(() => {
    handleFocus();
  }, [normalMessage.length])

  const handleInputChange = async (e) => {
    if (e.target.textContent === name) {
      return;
    }
    setName(e.target.textContent);
    try {
      const result = await updateUserName({
        chatSession: sessionId,
        userName: e.target.textContent,
      });
      setRefreshNotification((prev) => !prev);
      setBackendMessage({ msg: result.message, type: "success" });
    } catch (error) {
      console.log(error);
    }
  };

  const observer = useRef(null);
  let page = 1;
  const lastMessage = useCallback(
    (node) => {
      if (!node) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          page += 1;
          if (numOfPages < page) return;
          getChatMessage({ userId: currentUserId, sessionId, page, messageType: channelName });
        }
      });
      observer.current.observe(node);
    },
    [sessionId]
  );


  if (isMessageLoading) {
    return (
      <MessageConversationStyles>
        <FullPageSpinner/>
      </MessageConversationStyles>
    )
  }

  if (normalMessage.length === 0) {
    return (
      <MessageConversationStyles>
        <h1 className="empty-msg">No Message Selected to display</h1>
      </MessageConversationStyles>
    );
  }


  console.log("Calculated VH -", containerHeight)
  let currentDate;

  return (
    <MessageConversationStyles  ref={chatContainerRef}>
      {backendMessage.msg && <CustomSnackbar payload={backendMessage} />}
          {isMessageLoading && (
            <div className="center-spinner">
              <CircularProgress />
            </div>
          )}
            <div className="message-end" ref={bottomRef}></div>
            {normalMessage.map((item, index) => {
              const {
                id,
                content,
                sender,
                createdAt,
                isSeen,
                userId,
                isPrivate,
                taggedUser,
                contactUs,
              } = item;

              // const showInLeft = taggedUser.includes(currentUserId);
              const showInLeft = isPrivate && userId?._id !== currentUserId;
              console.log("Message Item Check--", item);
              currentDate =
                index < normalMessage.length - 1
                  ? getDate(normalMessage[index + 1]?.createdAt)
                  : null;
              const lastMessageRef =
                index === normalMessage.length - 2
                  ? lastMessage
                  : index === 0
                  ? messageEndRef
                  : null;
              const time = getTime(createdAt);
              if (showInLeft) {
                return (
                  <MessageBox
                    {...item}
                    ref={lastMessageRef}
                    key={getKey("normalMessage", content, index)}
                    time={time}
                    ticketNote
                    userNames={userNames}
                    sender={false}
                    isCurrentUser={showInLeft}
                    name={userId?.name}
                  >
                    {content}
                  </MessageBox>
                );
              }
              return (
                <div key={getKey("normalMessage", content, index)}>
                  {currentDate !== getDate(createdAt) && (
                    <p className="current-date">
                      <Chip label={getDate(createdAt)} />
                    </p>
                  )}
                  {showPostCard && index === normalMessage.length - 1 && (
                    <FacebookPost
                      likeCount={likeCount}
                      commentCount={fbCommentCount}
                      postCaption={postCaption}
                      postImage={postImage}
                      userName={userName}
                      pageImage={pageImage}
                      socialUserId={socialUserId}
                    />
                  )}

                  {sender === "agent" ? (
                    <MessageBox
                      ref={lastMessageRef}
                      key={getKey("normalMessage", content, index)}
                      time={time}
                      sender={true}
                      ticketNote
                      userNames={userNames}
                      showSeenStatus={true}
                      {...item}
                    >
                      {content}
                    </MessageBox>
                  ) : (
                    <MessageBox
                      ref={lastMessageRef}
                      key={getKey("normalMessage", content, index)}
                      time={time}
                      name={name}
                      sender={false}
                      userNames={userNames}
                      contactUs={item?.contactUs}
                      image={item?.image}
                      _id={item._id}
                      facebookCommentMetaData={item?.facebookCommentMetaData}
                      instagramCommentMetaData={item?.instagramCommentMetaData}
                      postId={postId}
                      userId={currentUserId}
                      attachment={item?.attachment}
                    >
                      {content}
                    </MessageBox>
                  )}
                </div>
              );
            })}
          {/* <div className="message-box-container">
            <div className="message-input-container">
              {currentSessionData.status === "complete" ? (
                <MessageInput disabled={true} />
                ) : (
                <MessageInput />
              )}
            </div>
          </div> */}

    </MessageConversationStyles>
  );
};

export default MessageConversation;
