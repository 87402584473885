import React from "react";
import MessageBoxStyles from "../../assets/styledComponents/chatBox/MessageBox";
import { useTenantContext } from "../../context/TenantContext";

const ProductMessage = ({ productDetails, message }) => {
  const { imageUrl, quantity, title, price, grams, name, productUrl, variant } =
    productDetails || {};
  const { profile } = useTenantContext();
  return (
    <MessageBoxStyles>
      <div className="container">
        <div className="cart-message">
          <img
            className="product-image"
            width={110}
            height={100}
            src={imageUrl}
            alt="message Image icon"
          />
          <div className="item-details">
            <div className="product">
              <p className="name">{title}</p>
              <p className="price"><b>Price: </b>{price}</p>
              <a
                className="btn"
                target="_blank"
                rel="noreferrer"
                href={productUrl}
              >
                {/* {`https://${profile.token.shop}/orders`} */}
                {"View Product"}
              </a>

              {/* <p className="price">{price}</p> */}
            </div>
            {/* <div className="desc">
              {variant ? (
                <p>Size : {variant.toUpperCase()}</p>
              ) : (
                <p>weight: {grams}g</p>
              )}
              <p>Qty: {quantity}</p>
            </div> */}
          </div>
        </div>

        <p className="message">{message}</p>
      </div>
    </MessageBoxStyles>
  );
};

export default ProductMessage;
