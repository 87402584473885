import React from 'react';

const ContactUsMessage = ({ data }) => {
  const {
    customerName,
    emailAddress,
    messageInput,
    typeOfQuery,
    destinationEmail,
    attachmentPath,
  } = data || {};

  const createRow = (label, value) => (
    <div key={label} style={{display: "flex", gap: "10px"}}>
      <span><b>{label}: </b></span>
      <span>{value}</span>
    </div>
  );

  return (
    <div>
          {createRow('Name', customerName)}
          {createRow('Email', emailAddress)}
          {createRow('Message', messageInput)}
          {createRow('Type of Query', typeOfQuery)}
          {attachmentPath && createRow('Attachment Path', <a href={attachmentPath}>Download File Here</a>)}
    </div>
  )
};

export default ContactUsMessage;
