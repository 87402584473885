import styled from "styled-components";
const InboxMessageStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* overflow-y: scroll; */
  padding-top: 1.5rem;
  border-right: 1px solid var(--border-clr);
  width: 290px;
  flex-shrink: 0;

  .container-title-quota {
    display: flex;
    justify-content: space-between;
  }

  .quaota-container {
    margin-right: 15px;
  }
  .filter-checkbox-div {
    display: block;
    margin-bottom: 10px;
    font-weight: 500
  }

  .filter-checkbox-div label {
    margin-right: 1rem;
  }

  .filter-checkbox-div input {
    display: none;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    padding:0px
  }
  .color-icon {
    background-color: #5a7b47;
    border-radius: 50%;
    padding: 0px 6px;
    font-size: 10px;
    margin-right: 5px;
  }
  .icon-container {
    display: block;
    float: left;
    margin-top: -2px;
  } 
  input {
    all: unset;
    border-radius: 0.5rem;
    width: 75%;
    padding: 0.5rem;
    background-color: #d9d9d9;
    border: none;
    padding-left: 3rem;
    padding-top: 0.6rem;
  }
  .search-container {
    position: relative;
  }
  .search-btn {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 12px;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  .info {
    width: 70%;
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    width: 200px;
  }
  .filter {
    font-weight: 500;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .container {
    margin-left: 1rem;
    cursor:pointer;
  }
  .top {
    margin-left: 1rem;
  }

  .inboxMsgPreview {
    cursor: pointer;
  }
  .inActive {
  }
  .active {
    background-color: var(--primary-100);
  }
  .messagePreview {
    overflow-y: auto;
    height: -webkit-fill-available;
    /* width:  -webkit-fill-available; */
    width: auto;
  }
  @media (max-width : 1300px) {
    width: 250px;
    .info{
      width: 80%;
    }
    .messagePreview  {
      width: 250px;
    }
    .search-container {
      width: 225px;
    }
    .message {
      width: 190px;
      font-size: 14px;
    }
  }
`;
export default InboxMessageStyles;
