import React, { useRef, useState } from "react";
import EmailInputBoxStyles from "../../../assets/styledComponents/inboxMessage/mail/EmailInputBox";
import { Button, Chip, TextField } from "@mui/material";
import InputToolbar from "./InputToolbar";

import EventNoteIcon from "@mui/icons-material/EventNote";
import ReplyIcon from "@mui/icons-material/Reply";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import TextEditor from "../../../components/reusable/TextEditor";
import ReactQuill from "react-quill";
import EmailEditor from "./EmailEditor";
import Modal from "../../../components/reusable/DialogModal/Modal";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { useTenantContext } from "../../../context/TenantContext";
import { useInboxContext } from "../../../context/inboxContext/inboxContext";
import { useEffect } from "react";

const EmailInputBox = (props) => {
  const {
    text,
    setText,
    reply,
    setCursorPosition,
    isMessagePrivate,
    setIsMessagePrivate,
    openFullViewModal,
    handleOpenFullView,
    height,
    toName,
    setTaggedUserDetails,
    setSubject,
    subject,
    isSmall,
  } = props || {};

  const {userId} = useTenantContext()
  const {currentSessionData} = useInboxContext()
  const {assignTo, taggedUser, storeName} = currentSessionData || {}


  const handleSubjectChange = (e) => {
    const {name, value} = e.target
    setSubject(value)

  }

  const isSessionTagged =
    userId === assignTo ? true : taggedUser?.includes(userId);
  const isNotAssignedUser =
    userId === assignTo ? false : taggedUser?.includes(userId);
    console.log({isNotAssignedUser })

  const handlePrivateBtn = (e) => {
    // if (disabled) return;
    if (!isNotAssignedUser) {
      setIsMessagePrivate((prev) => !prev);
    }
  };

  useEffect(() => {
    if (isNotAssignedUser) {
      setIsMessagePrivate(isNotAssignedUser)
    }
  }, [])

  return (
    <EmailInputBoxStyles>
      <div className={`input-field-container ${!isSessionTagged ? 'disable' : ''}`}>
        <section className="top-icons">
          {/* <div
            className={`reply ${reply.emailMessageId ? "reply-active" : ""}`}
          >
            <ReplyIcon />
            <span>Reply</span>
          </div> */}
          <button
            onClick={handlePrivateBtn}
            className={`private-note ${
              isMessagePrivate  ? "private-message-active" : ""
            }`}
          >
            <EventNoteIcon />
            <span>Private Note</span>
          </button>
          {openFullViewModal ? (
            <button
              onClick={() => handleOpenFullView(false)}
              className="full-view-icon"
            >
              <CloseFullscreenIcon />
            </button>
          ) : (
            <button
              onClick={() => handleOpenFullView(true)}
              className="full-view-icon"
            >
              <OpenInFullIcon />
            </button>
          )}
        </section>
        {!isMessagePrivate && (
          <div className="to-container">
          <section className="to-mail">
            <p>To : </p>
            <Chip label={toName}  />
          </section>
          <section className="subject">
            <p>Subject : </p>
            <TextField value={subject} onChange={handleSubjectChange} sx={{marginLeft : '.5rem'}} variant="standard" />
          </section>
          </div>
        )}
        {/* <textarea
          ref={inputRef}
          value={text}
          onChange={handleTextAreaChange}
          className="email-input-box"
          name=""
          id=""
          rows="13"
        >
          hey laura
        </textarea> */}
        <div className="editor">
          <EmailEditor
            text={text}
            setText={setText}
            height={height}
            isSmall={isSmall}
            setCursorPosition={setCursorPosition}
            isMessagePrivate={isMessagePrivate}
            setTaggedUserDetails={setTaggedUserDetails}
          />
        </div>
        {/* <TextEditor/> */}
        {/* <div contentEditable className="input-field">
        <section className="to-mail">
          <p>To : </p>
          <Chip label={name} onDelete={handleDelete} />
        </section>
        hey laura
      </div> */}
        <section className="utils-icon">
          <InputToolbar {...props} />
        </section>
      </div>
    </EmailInputBoxStyles>
  );
};

export default EmailInputBox;
