import React, { useEffect, useState, useContext } from "react";
import Swal from "sweetalert2";
import PricingPlanStyles from "../../assets/styledComponents/settings/PricingPlan";
import Grid from "@mui/material/Grid";
import FullPageSpinner from "../FullPageSpinner";

import Vector1 from "../../assets/images/Vector (1).png";
import Vector2 from "../../assets/images/Vector (2).png";
import feBeginner2 from "../../assets/images/feBeginner2.png";
import PricingCard from "./PricingCard";

import { makeStyles } from "@mui/styles";

import { getPricingPlan, subscribePlan } from "../../service/service";
import { TenantContext } from "../../context/TenantContext";
const icon = [Vector1, Vector2, feBeginner2];

const useStyles = makeStyles({
  customGrid: {
    display: "flex",
    gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
    // gap: "20px",
    justifyContent: "space-evenly",
    marginLeft: "40px",
    width: "81vw",
  },
});

const PricingPlan = ({ fullWidth }) => {
  const classes = useStyles();
  const { profile } = useContext(TenantContext);

  const [backendError, setBackendError] = useState({ msg: "", type: "error" });
  const [isLoading, setIsLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currentPlanId, setCurrentPlanId] = useState('')
  const [priceData, setPriceData] = useState([]);

  const fetchAllPermission = async () => {
    try {
      setIsLoading(true);
      const result = await getPricingPlan({});
      console.log(result.data);
      setPriceData(result.data);
    } catch (error) {
      setBackendError({ msg: error.response.data.message, type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllPermission();
  }, []);

  const handlePlanSelection = async (selectedPlanId, buttonStatus) => {
    const tenantId = profile?.token?.tenantId;
    if (!tenantId || !selectedPlanId || buttonLoading) return;



    if (!profile?.token?.shop) {
      window.location = "/settings/connect-shopify";
      setButtonLoading(false);
      return;
    }

    setCurrentPlanId(selectedPlanId)
    setButtonLoading(true);

    const existingPricingPlanId = profile?.token?.pricingPlan?._id
    try {
      let payload = {
        tenantId: tenantId,
        selectedPlanId: selectedPlanId,
        shop: profile?.token?.shop,
      }
      if (buttonStatus === 'Downgrade') {
        const result = await Swal.fire({
          title: "Are you sure want to downgrade?",
          html: `
          <div style="font-size: 14px; text-align: left">
            <ul>
              <li style="margin-bottom: 10px"><span style="margin-right: 5px">👉 </span>  Agents beyond the new plan's limit will be automatically deleted.</li>
              <li style="margin-bottom: 10px"><span style="margin-right: 5px">👉 </span> The number of tokens available to your account will be reduced.</li>
              <li style="margin-bottom: 10px"><span style="margin-right: 5px">👉 </span> Your monthly ticket quota will be reduced.</li>
              <li style="margin-bottom: 10px"><span style="margin-right: 5px">👉 </span> Your ticket history will be limited according to the new plan.</li>
            </ul>
            Please ensure you back up any important data before proceeding.
          </div>
         `,

          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#8db178",
          showCancelButton: true,
          cancelButtonColor: "#D9D9D9",
          confirmButtonText: "Yes, downgrade my plan!",
        });
      if (result.isConfirmed) {
        payload = { ...payload, pricingType : 'Downgrade'}
      }else {
        setButtonLoading(false);
        return
      }
      }
      if (buttonStatus === 'Upgrade') {
        const result = await Swal.fire({
          title: "Are you sure you want to upgrade?",
          html: `
        <div style="font-size: 14px; text-align: left">
          <ul>
            <li style="margin-bottom: 10px">
          <span style="margin-right: 5px">👉 </span>  Additional agents can be added based on the new plan's limits.</li>
            <li style="margin-bottom: 10px"><span style="margin-right: 5px">👉 </span> The number of tokens available to your account will be increased.</li>
            <li style="margin-bottom: 10px"><span style="margin-right: 5px">👉 </span> Your monthly ticket quota will be increased.</li>
            <li style="margin-bottom: 10px"><span style="margin-right: 5px">👉 </span> Your ticket history will be expanded according to the new plan.</li>
          </ul>
          Enjoy the enhanced features and benefits of your upgraded plan!
        </div>
        `,
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#8db178",
          showCancelButton: true,
          cancelButtonColor: "#D9D9D9",
          confirmButtonText: "Yes, upgrade my plan!",
        });
      if (result.isConfirmed) {
        payload = { ...payload, pricingType : 'Upgrade'}
      }else {
        setButtonLoading(false);
        return
      }
      }
      const res = await subscribePlan(payload);
      if (res.paymentUrl) {
        window.location = res.paymentUrl;
        setButtonLoading(false);
      }
      setButtonLoading(false);
    } catch (error) {
      console.log(error)
      setButtonLoading(false);
    }

  }
  if (isLoading) {
    return <FullPageSpinner />
  }

  return (
    <PricingPlanStyles>
      <div className="top">
        <div className="this">
          <h2>Pricing</h2>
        </div>
      </div>
      <div className="underline"></div>
      <div className="pricing-wrapper">
        <div className="second">
          <p className="paragraph">Pricing & Billing </p>
          <span className="desc">
            Change your workspace plan according to your need
          </span>
        </div>
        <div className='pricing-card-container'>
          {priceData.map((item, index) => {
            const { description, features, name, price, _id: planId, supportFeatures } = item;
            return (
              <PricingCard
                key={index}
                planId={planId}
                icon={icon[index]}
                description={description}
                features={features}
                supportFeatures={supportFeatures || []}
                isPlanActive={planId === profile?.token?.pricingPlan?._id}
                buttonStatus={
                  profile?.token?.pricingPlan?._id
                    ? price < profile?.token?.pricingPlan?.price
                      ? "Downgrade"
                      : price > profile?.token?.pricingPlan?.price
                        ? "Upgrade"
                        : null
                    : null
                }
                name={name}
                price={price}
                handlePlanSelection={handlePlanSelection}
                buttonLoading={buttonLoading}
                currentPlanId={currentPlanId}
              />
            );
          })}
        </div>
      </div>
    </PricingPlanStyles>
  );
};
export default PricingPlan;
