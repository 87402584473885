import styled from "styled-components";
const ReportCardDetailStyles = styled.div`
   flex : 1;
    height: 100%;
    align-items: stretch;
    border: 1px solid #C2C2C2 !important;
    box-shadow: 0px 1px 7px 0px #00000040;
    border-radius: 5px !important;
    padding: 1rem;


  .head-section {
    font-weight: 500;
    font-size: 18px;
    /* margin-left: 3px;
    margin-top: -20px; */
    display: flex;
  }
  
  .number {
    font-weight: 700;
    font-size: 30px;
    margin-left: 2px;
    margin-top: 4px;
    display:flex;
    margin-left:33px
  }
  .time3 {
    font-size: 24px;
  }
  .time1 {
    font-size: 24px;
  }
  .time2 {
    font-size: 24px;
  }
  .icon {
   height:19px
  }
  .name {
    margin-left: 10px;
  }
  .container span {
    margin-left: 5px;
    font-size: 15px;
    font-weight:500;
    color: #5a7b47;
  }
`;
export default ReportCardDetailStyles;
