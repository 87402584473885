import React, { useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import Modal from "../../reusable/DialogModal/Modal";
import styled from "styled-components";
import ActionButtonGroup from "../../reusable/ActionButtonGroup/ActionButtonGroup";
import FilterLabel from "./FilterLabel";
import FilterPriority from "./FilterPriority";
import FilterAssigned from "./FilterAssigned";
import { getKey } from "../../../utils/helperFunction"
import {
  initialState,
  useInboxContext,
} from "../../../context/inboxContext/inboxContext";

import { updateFilters } from "../../../service/service";
import { useTenantContext } from "../../../context/TenantContext";
const StyledFilterModal = styled.div`
  width: 600px;
  height: 520px;
  .container {
    display: flex;
  }

  .left {
    border-right: 1px solid #a7a6a6;
  }

  .categoryItem {
    padding: 10px 15px;
    border-bottom: 1px solid #a7a6a6;
    width: 200px;
    cursor: pointer;
  }

  .categoryItem:hover {
    padding: 10px 15px;
    border-bottom: 1px solid #a7a6a6;
    width: 200px;
    cursor: pointer;
  }

  .categoryItem:hover {
    background: silver;
  }

  .categoryItem.selected {
    background: var(--primary-100);
  }

  .right {
    width: 100%;
  }

  .btn-container {
    margin-bottom: 20px;
    float: right;
  }
`;

const FilterModal = () => {

  const {
    setLabelFilterQuery,
    setPriorityFilter,
    setAssignedFilter,
    priorityFilter,
    assignedFilter,
    labelFilterQuery,
    inboxType
  } = useInboxContext();

  const { tenantId } = useTenantContext();

  const FILTER_CATEGORIES = [
    {
      label: "Filter By Label",
      FilterComponent: FilterLabel,
      isOpen: true,
      filteredValue: [],
      filterType: "label",
      show: true
    },
    {
      label: "Filter By Priority",
      FilterComponent: FilterPriority,
      isOpen: false,
      filteredValue: [],
      filterType: "priority",
      show: true
    },
    {
      label: "Filter By Assigned",
      FilterComponent: FilterAssigned,
      isOpen: false,
      filteredValue: [],
      filterType: "assigned",
      show:  inboxType !== 'myInbox'
    },
  ];
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filterCategories, setFilterCategories] = useState([
    ...FILTER_CATEGORIES,
  ]);

  let isLabelApplied = (inboxType === 'myInbox' || inboxType === 'snoozedMessage') ? priorityFilter?.length  || labelFilterQuery?.length : priorityFilter?.length || assignedFilter !== 'all' || labelFilterQuery?.length

  const handleFilterIconClick = () => {
    setOpenFilterModal(true);
  };

  const formatInitialOptions = async () => {
    let newFilterCategories = [...filterCategories];
    const unassignedFilterIndex = newFilterCategories.findIndex(filterCData => filterCData.filterType === "assigned");

    if (unassignedFilterIndex !== -1) {
      // The element was found, and `index` contains its position
      newFilterCategories[unassignedFilterIndex] = {
        ...newFilterCategories[unassignedFilterIndex],
        isOpen: false,
        show: inboxType !== "myInbox" && inboxType !== 'snoozedMessage'
      }
    } 

    newFilterCategories = await Promise.all(newFilterCategories.map((dataObj) => {
      dataObj.isOpen = false;
      return dataObj
    }))

    newFilterCategories[0].isOpen = true;
    setFilterCategories(newFilterCategories);
    console.log("Index:", unassignedFilterIndex);
  }
  useEffect(() => {
    formatInitialOptions()
  }, [inboxType])

  const handleCloseModal = () => {
    setOpenFilterModal(false);
  };

  const handleApplyFilter = async (index) => {
    const payload  = {
      tenantId,
    }

    filterCategories.forEach((filterItem) => {
      const filterType = filterItem.filterType;

      if (filterType === "label") {
        const labelId = filterItem.filteredValue.map(
          (item) => item._id
        );
        setLabelFilterQuery(labelId);
        payload.labelFilter = labelId
        setOpenFilterModal(false);
      }
  
      if (filterType === 'priority') {
        setPriorityFilter(filterItem.filteredValue.length ?  true : null);
        payload.priorityFilter = filterItem.filteredValue.length ?  true : null;
        setOpenFilterModal(false);
      }
  
      if (filterType === 'assigned') {
        setAssignedFilter(filterItem.filteredValue.length ?  filterItem.filteredValue[0]._id : 'all');
        payload.assignedFilter = filterItem.filteredValue.length ?  filterItem.filteredValue[0]._id : 'all';
        setOpenFilterModal(false);
      }
    })

    const updateFilter = await updateFilters(payload);
   };

  const handleCategoryChange = (index) => {
    let newFilterCategories = [...filterCategories];
    newFilterCategories = newFilterCategories.map((categoryData) => {
      return { ...categoryData, isOpen: false };
    });
    newFilterCategories[index].isOpen = true;
    setFilterCategories(newFilterCategories);
  };

  const handleFilterValueChange = (newFilterValue, index) => {
    const newFilterCategories = [...filterCategories];
    newFilterCategories[index].filteredValue = newFilterValue;
    setFilterCategories(newFilterCategories);
  };

  const FilterModalUI = (
    <div className="container">
      <div className="left">
        <div className="categoryList">
          {filterCategories.map((filterData, index) => {
            const isOpenCategory = filterData.isOpen;

            const { show } = filterData;

            if (!show) return;

            return (
              <div
                key={getKey('filter-label', filterData.label, index)}
                className={`categoryItem ${isOpenCategory ? "selected" : ""}`}
                onClick={() => {
                  handleCategoryChange(index);
                }}
              >
                {filterData.label}
              </div>
            );
          })}
        </div>
      </div>

      <div className="right">
        {filterCategories.map((filterData, index) => {
          const isOpenCategory = filterData.isOpen;
          const { FilterComponent, filteredValue, show } = filterData;
          if (!isOpenCategory || !show) return null;
          return (
            <div key={getKey('filter-component', filterData.filterType, index)}>
              <FilterComponent
                filterLabel={filteredValue}
                setFilterLabel={(newFilters) => {
                  handleFilterValueChange(newFilters, index);
                }}
                actionGroup={
                  <ActionButtonGroup
                    primaryButtonLabel={"Apply"}
                    secondaryButtonLabel={"Cancel"}
                    handleSecondaryButtonClick={handleCloseModal}
                    handlePrimaryButtonClick={() => {
                      handleApplyFilter(index);
                    }}
                  ></ActionButtonGroup>
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <div>
      <div
        onClick={handleFilterIconClick}
        className={`filter-box  ${isLabelApplied ? "active-label-box" : ""}`}
        style={{ display: "flex" }}
      >
        <span>
          <FilterListIcon />
        </span>
        <span style={{ margin: "2px 5px" }}> Filter Options</span>
      </div>
      <Modal name="Filters" open={openFilterModal} onClose={handleCloseModal}>
        <StyledFilterModal>{FilterModalUI}</StyledFilterModal>
      </Modal>
    </div>
  );
};

export default FilterModal;
