import React, { useContext } from "react";
import facebookLogo from "../../../assets/images/facebook 1.png";
import InstagramCardStyles from "../../../assets/styledComponents/settings/InstagramCard";
import ShopifyCard from "../ShopifyCard";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { metaAccountLogin } from "../../../service/service";
import { useTenantContext } from "../../../context/TenantContext";
import InstagramConfiguration from "./InstagramConfiguration";

const useStyles = makeStyles({
  customButton: {
    color: "#FFFFFF !important",
    backgroundColor: '#1877F2 !important'
  },
});

const InstagramCard = ({ shop, tabValue, isPageRegistered,setDisableSaveBtn, stateValues }) => {
const { tenantId } = useTenantContext();
const classes = useStyles();
const data = {
  name: "Facebook Setup",
    logo: facebookLogo,
    text: "Connect your Facebook business account for a better connection with your Facebook customers because we allows you to integrate your Facebook account to have a centralized helpdesk that enables your agents to extend the productive experience with your customers.",
  };
  const navigate = useNavigate();
  const handleClick = async () => {
    // const res = await metaAccountLogin();
    // connect to facebook
    // navigate("/settings/instagramconfiguration");
  };
  const button = (
    <Button
      href={`${process.env.REACT_APP_BACKEND_SERVICE_URL}api/facebook/auth?tenantId=${tenantId}&shop=${shop}`}
      // target="_blank"
      onClick={() => handleClick()}
      variant="contained"
      className={classes.customButton}
    >
      Continue with Facebook{" "}
    </Button>
  );

  return (
    <>
      {isPageRegistered ? (
        <InstagramConfiguration 
          tabValue={tabValue} 
          setDisableSaveBtn={setDisableSaveBtn} 
          stateValues={stateValues}
        />
      ) : (
        <InstagramCardStyles>
          <ShopifyCard {...data} button={button} />
        </InstagramCardStyles>
      )}
    </>
  );
};
export default InstagramCard;
