import React from "react";

const NotFoundPage = () => {
  return (
    <div
      style={{
        fontFamily: "Poppins",
        fontSize: "24px",
        textAlign: "center",
        margin: "0 auto",
        width: "65%",
        paddingTop: "160px",
      }}
    >
      404 Page
    </div>
  );
};

export default NotFoundPage;
