import React, { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import CopilotStyles from "../../assets/styledComponents/copilot/Copilot";
import MessageBox from "./MessageBox";
import InputField from "./InputField";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import CopilotFaqBubble from "./CopilotFaqBubble";
import { getFaq, getProduct } from "../../service/service";
import useFetch from "../../hooks/useFetch";
import { useTenantContext } from "../../context/TenantContext";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import { getKey } from "../../utils/helperFunction";
import ProductList from "../../pages/DashboardPage/ProductList";
import ProductLists from "../reusable/ProductLists";
import CopilotProductList from "../../pages/DashboardPage/CopilotProductList";
const Copilot = () => {
  const [fetchFaq, loading, result, backendMessage] = useFetch(getFaq);
  const [fetchProduct, productLoading, productResult, productMessage] =
    useFetch(getProduct);
  const { profile, tenantId, selectedShop } = useTenantContext();
  const { currentSessionData } = useInboxContext();
  const { storeName } = currentSessionData || {};
  const [showProduct, setShowProduct] = useState(false);
  const [productData, setProductData] = useState([]);
  const [placeholder, setPlaceholder] = useState("Type your text");
  const [question, setQuestion] = useState("");
  const [mode, setMode] = useState(""); // "ORD" or "PROD" or "FAQ"
  const userName = profile.token.name;
  const initialMessage = [{ message: "", sender: "", success: true }]; // sender = 'bot' or 'agent'
  const [message, setMessage] = useState([]);
  const [visibleButton, setVisibleButton] = useState(null);
  const [disableSendBtn , setDisableSendBtn] = useState(false)
  const changeLastMessage = (message) => {
    console.log({ message });
    setMessage((prev) => {
      let updatedValue = [...prev];
      updatedValue[0] = message;
      return updatedValue;
    });
  };

  const pushToLast = (messages) => {
    setMessage((prev) => {
      let updatedValue = [];
      updatedValue.push(...messages);
      return [...updatedValue, ...prev];
    });
  };
  const handleBubbleClick = async (mode) => {
    // const question = "shoe size issue"
    // if (question == '') return
    if (mode === "PROD") {
      setPlaceholder("Query Products");
    } else if (mode === "FAQ") {
      setPlaceholder("Search Faq");
    } else if (mode == "ORD") {
      setPlaceholder("Query Orders");
    }

    setMode(mode);
    console.log({ placeholder });
    if (mode) return;
    setMessage((prev) => {
      let updatedValue = [];
      updatedValue.push(
        { message: question, sender: "agent", success: true },
        { message: "", sender: "bot", success: true }
      );
      return [...updatedValue, ...prev];
    });
    try {
      const payload = {
        Tenantid: tenantId, // tenantId
        shop: storeName, // shop
        input_query: question,
        mode,
      };
      const result = await fetchFaq(payload);
      console.log({ result });
      setMessage((prev) => {
        let updatedValue = [...prev];
        let lastMessage = updatedValue[updatedValue.length - 1];
        lastMessage = { ...lastMessage, message: result.data, success: true };
        updatedValue[updatedValue.length - 1] = lastMessage;
        return updatedValue;
      });
    } catch (error) {
      console.log("error ran");
      setMessage((prev) => {
        let updatedValue = [...prev];
        let lastMessage = updatedValue[updatedValue.length - 1];
        lastMessage = { ...lastMessage, message: "error", success: false };
        updatedValue[updatedValue.length - 1] = lastMessage;
        return updatedValue;
      });
      console.log(error);
    }
  };

  const handleProductClick = async (mode) => {
    // const question = "shoe size issue"
    // if (question == "") return;
    setPlaceholder("Query Products");
    setMode(mode);
  };

  const handleSubmit = async () => {
    setDisableSendBtn(true)
    const payload = {
      Tenantid: tenantId, // tenantId
      shop: storeName, // shop
      input_query: question, //  "shoe size issue"
      mode,
    };
    setQuestion("");
    if (mode === "FAQ" || mode === "ORD") {
      pushToLast([
        { message: "", sender: "bot", success: true },
        { message: question, sender: "agent", success: true },
      ]);
      try {
        const result = await fetchFaq(payload);
        console.log({ result });
        changeLastMessage({
          message: result.data,
          success: true,
          sender: "bot",
        });
        setDisableSendBtn(false)
      } catch (error) {
        setDisableSendBtn(false)
        changeLastMessage({
          message: error.message,
          success: false,
          sender: "bot",
        });
      }
    }
    if (mode === "PROD") {
      pushToLast([
        { message: "", sender: "bot", success: true },
        { message: question, sender: "agent", success: true },
      ]);
      try {
        const result = await fetchProduct({
          Tenantid: tenantId,
          shop: storeName,
          input_query: question,
          mode: "PROD",
        });
        setDisableSendBtn(false)
        changeLastMessage({
          message: result.data,
          success: true,
          sender: "bot",
        });
        
      pushToLast([
        { message: result.botMessage , sender: "bot", success: true },
      ]);
        // setShowProduct(true);
        // setProductData(result.data);
      } catch (error) {
        setShowProduct(false);
        setDisableSendBtn(false)
        changeLastMessage({
          message: error.message,
          success: false,
          sender: "bot",
        });
      }
    }
  };

  console.log({ productMessage });

  const handleClose = () => {
    setMessage([]);
    setMode("");
    setVisibleButton("");
  };

  return (
    <CopilotStyles>
      {backendMessage.msg && <CustomSnackbar payload={backendMessage} />}
      <div className="wrapper">
        <nav className="nav-bar">
          <div className="heading-container">
            <h2 className="heading">Copilot</h2>
            <button onClick={handleClose}>
              {visibleButton && (
                <HomeIcon color="#8db178" sx={{ color: "#8db178" }} />
              )}
            </button>
          </div>
          <span className="sub-heading">Your AI assistant</span>
        </nav>
        <div className="underline"></div>
        <div className="message-box">
          {message.length > 0 ? (
            message.map((msg, index) => {
              const { message } = msg || {};
              return Array.isArray(message) ? (
                message.map((item, index) => {
                  const product = item || {};
                  return (
                    <div className="product-container" key={index}>
                        <SmartToyIcon  sx={{ color: "#8DB178", fontSize : '43px', 
                        visibility : index === message.length - 1 ? 'visible' : 'hidden'}}/>
                      <CopilotProductList
                        {...product}
                        shopName={selectedShop}
                      />
                    </div>
                  );
                })
              ) : (
                <MessageBox
                  key={getKey("message", message, index)}
                  name={userName}
                  sender={msg.sender}
                  content={msg.message}
                  success={msg?.success}
                />
              );
            })
          ) : (
            <CopilotFaqBubble
              handleBubbleClick={handleBubbleClick}
              handleProductClick={handleProductClick}
              setVisibleButton={setVisibleButton}
              visibleButton={visibleButton}
            />
          )}
        </div>

        <div className="input-field">
        <InputField
          disabled={disableSendBtn}
          handleSubmit={handleSubmit}
          placeholder={placeholder}
          setQuestion={setQuestion}
          question={question}
        />
        </div>
        {/* {showProduct ? (
          <div className="">
            {" "}
            <ProductLists
              setShowProduct={setShowProduct}
              setMessage={setMessage}
              productData={productData}
            />
          </div>
        ) : (
          <>
            {message.length > 0 ? (
              <div className="message-box">
                {message.map((msg, index) => (
                  <MessageBox
                    key={getKey("message", message, index)}
                    name={userName}
                    sender={msg.sender}
                    content={msg.message}
                    success={msg?.success}
                  />
                ))}
              </div>
            ) : (
              <CopilotFaqBubble
                handleBubbleClick={handleBubbleClick}
                handleProductClick={handleProductClick}
                setVisibleButton = {setVisibleButton}
                visibleButton={visibleButton}
              />
            )}
            <InputField
              handleSubmit={handleSubmit}
              placeholder={placeholder}
              setQuestion={setQuestion}
              question={question}
            />
          </>
        )} */}
      </div>
    </CopilotStyles>
  );
};

export default Copilot;