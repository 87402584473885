import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";
const MessagePreviewSkeleton = () => {
  return (
    <Stack
      sx={{
        padding: ".5rem 1rem",
        width: "290px",
        "@media (max-width : 1300px)": {
          width: "250px",
        },
      }}
      spacing={1}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <Skeleton variant="circular" width={50} height={40} />
        <Skeleton variant="rectangular" height={10} width={"100%"} />
      </Box>
      <Skeleton variant="rectangular" height={60} />
    </Stack>
  );
};

export default MessagePreviewSkeleton;
