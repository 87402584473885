import React, { useContext } from "react";
import facebook1 from "../../../assets/images/facebook 1.png";
import FacebookCardStyles from "../../../assets/styledComponents/settings/FacebookCard";
import Rectangle73 from "../../../assets/images/Rectangle 73.png";
import { TenantContext } from "../../../context/TenantContext";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MetaAddPage from "../../reusable/ChannelConfiguration/MetaAddPage";
const FacebookConfiguration = () => {
  const { profile } = useContext(TenantContext);
  const data = {
    name: "Facebook Page",
    logo: Rectangle73,
    url: "example-myshopify.com",
  };
   return (
    <>
      <FacebookCardStyles>
        <div className="top">
          <div className="">
            <h2>Facebook Configuration</h2>
          </div>
          <div className="saveButton">
            <Button variant="contained" sx={{ marginRight: 2 }}>
              Add Page
            </Button>
          </div>
        </div>
        <div className="underline"></div>
        <MetaAddPage {...data} />
        <MetaAddPage {...data} />
        <MetaAddPage {...data} />
      </FacebookCardStyles>
    </>
  );
};
export default FacebookConfiguration;
