/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { SnoozeStyles } from "../assets/styledComponents/message";
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { snoozeMessage } from "../service/service";
import { useInboxContext } from "../context/inboxContext/inboxContext";
import PrimaryButton from "./reusable/PrimaryButton/PrimaryButton";
import IconButton from '@mui/material/IconButton';

const SnoozeDatePicker = ({ setOpenSchedule, onSnoozeListClose }) => {
  const { sessionId, setRefreshNotification } = useInboxContext();
  const today = new Date();

  const [date, setDate] = useState(dayjs(Date.now()));
  const [time, setTime] = useState(dayjs(today));
 
  const setSchedule = async () => {
    const formatDate = moment(date.$d.toISOString());
    const formatTime = moment(time.$d.toISOString()).format("HH:mm:ss");
    const result = formatDate.set({
      hour: moment(formatTime, "HH:mm:ss").hour(),
      minute: moment(formatTime, "HH:mm:ss").minute(),
    });
    const snoozeDate = result.format();
    try {
      const result = await snoozeMessage({ sessionId, snoozeDate });
      onSnoozeListClose();
      setOpenSchedule(false);
      setRefreshNotification((prev) => !prev);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SnoozeStyles>
      <div className="datepicker-conatiner-box">
      <p className="title">Choose a day and time to set the remainder</p>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker", "TimePicker"]}>
          <div className="date-container">
            <DatePicker
              label="Date"
              value={date}
              format="DD-MM-YYYY"
              onChange={(newValue) => setDate(newValue)}
            />
            <MobileTimePicker
              label="Time"
              value={time}
              // format=""
              onChange={(newTime) => setTime(newTime)}
              // renderInput={(props) => (
              //   <>
              //     <IconButton color="primary" {...props}>
              //       <AccessTimeIcon />
              //     </IconButton>
              //     {props.input}
              //   </>
              // )}
            />
          </div>
        </DemoContainer>
        <PrimaryButton className="btn-primary" onClick={() => setSchedule()}>
          Set
        </PrimaryButton>
      </LocalizationProvider>
      </div>

    </SnoozeStyles>
  );
};

export default SnoozeDatePicker;
