import React, { useState } from "react";
import styled from "styled-components";

const CopilotFaqBubble = ({ handleBubbleClick, handleProductClick , visibleButton, setVisibleButton}) => {
  const handleClick = (type) => {
    setVisibleButton(type); 
    if (type === 'FAQ') {
      handleBubbleClick('FAQ');
    } else if (type === 'PROD') {
      handleProductClick('PROD');
    } else if (type === 'ORD') {
      handleBubbleClick('ORD');
    }
  };
  return (
    <CopilotFaqBubbleStyles onlyOneVisible={visibleButton}>
      <div className="bubble-container">
        {(!visibleButton || visibleButton === 'FAQ') && (
          <button className="bubble bubble-1" onClick={() => handleClick('FAQ')}>
            <p>Query FAQ <span className="emoji">🤓</span></p>
            {/* <i className="fa-regular fa-circle-question"></i> */}
          </button>
        )}
        {(!visibleButton || visibleButton === 'PROD') && (
          <button className="bubble bubble-2" onClick={() => handleClick('PROD')}>
            <p>Query Product 🤩 </p>
            {/* <i className="fa-solid fa-bag-shopping"></i> */}
          </button>
        )}
        {(!visibleButton || visibleButton === 'ORD') && (
          <button className="bubble bubble-3" onClick={() => handleClick('ORD')}>
            <p>Query Order 🧐</p>
            {/* <i className="fa-solid fa-receipt"></i> */}
          </button>
        )}
      </div>
    </CopilotFaqBubbleStyles>
  );
};

export default CopilotFaqBubble;

const CopilotFaqBubbleStyles = styled.div`
  .bubble-container {
    position: absolute;
    /* bottom: 23%; */
    bottom: ${({ onlyOneVisible }) => onlyOneVisible ? '15%' : '23%'};
    transform: translateY(50%);
    right: 1rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .bubble {
    display: flex;
    align-items: center;
    width: 150px;
    justify-content: space-around;
    /* border-radius: 2rem; */
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
    padding: 0.75rem;
    gap: 10px;
    justify-content: center;
    background-color: var(--primary-200);
    color: black;
    overflow-wrap: break-word;
    white-space: pre-line;
    font-size: 13px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    transition: color 0.3s ease;
    color: white;
    margin-bottom: 0.25rem;
    .icon {
      margin-left: 0.5rem;
      opacity: 0;
      transition: opacity 0.3s ease;
      margin-top: 0.25rem;
    }
    &:hover {
      /* background-color: #dff3d3; */
      // .icon {
      //   opacity: 1;
      // }
    }
    i {
      font-size: 18px;
    }
  }
  .bubble-1 {
    &:hover {
      background-color: var(--primary-200);
      color: black;
    }

    background-color: #ffffff;
    color: black;
    border: 1px solid #9E9E9E;

    i {
      color: #ab826f;
    }
  }
  .bubble-2 {
    &:hover {
      background-color: var(--primary-200);
      color: black;
    }

    background-color: #ffffff;
    color: black;
    border: 1px solid #9E9E9E;
    i { color: #136fb8; }

  }
  .bubble-3 {
    &:hover {
      background-color: var(--primary-200);
      color: black;
    }

    background-color: #ffffff;
    color: black;
    border: 1px solid #9E9E9E;

    i {
      color: #E91E63;

    }
  }
`;
