import styled from "styled-components";
const ReportFilterStyles = styled.div`
  width: inherit; 
  .channel {
    display: flex;
    align-items: center;
  }
  
  .channel h4{
    // margin-top:22px;
    // margin-left:40px;
    font-size:18px
  }

  .channel-dropdown {
    width: 250px;
    margin-left:11px
  }
`;
export default ReportFilterStyles;
