import React, { useContext } from "react";
import { useState } from "react";
import LoginStyles from "../assets/styledComponents/Login";
import login123 from "../assets/images/login.jpg";
import logo from "../assets/images/logoTitle.svg";
import { TextField, InputAdornment, Button } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { EMAIL_REGEX } from "../utils/constants";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { DEFAULT_PASSWORD_CONSTRAINT } from "../utils/helperData";
import { Link, useNavigate } from "react-router-dom";
import { loginUser, forgetPasswordApi } from "../service/service";
import CustomSnackbar from "../components/snackbar/CustomSnackbar";
import { TenantContext } from "../context/TenantContext";
import LogoSpinner from "../components/reusable/Loader/LogoSpinner";

const ForgotPassword = () => {
  const { setToken } = useContext(TenantContext);
  const navigate = useNavigate();

  const [value, setValue] = useState({ email: "" });
  const [errors, setErrors] = useState({
    email: "",
  });
  const [snackBarValue, setSnackBarValue] = useState({ msg: "", type: "" });
  const [isLoading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };
    if (name === "email") {
      const regex = EMAIL_REGEX;
      const isEmail = regex.test(value);
      if (!isEmail) {
        newErrors[name] = "Invalid Email";
      } else {
        newErrors[name] = "";
      }
    }
    newErrors[name];
    setValue((prev) => ({ ...prev, [name]: value }));
    setErrors(newErrors);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async (e) => {
    const { email } = value;

    // setValue({ email: "", password: "" });
    const newErrors = { ...errors };
    let isDirty = false;

    if (!value.email) {
      newErrors.email = "Email is Required";
      isDirty = true;
    }

    if (isDirty) {
      setErrors(newErrors);
      return;
    }

    try {
      setLoading(true);
      const result = await forgetPasswordApi({ email });
      if (result) {
        setOtpSent(true);
        setSnackBarValue({ msg: "OTP sent to your email.", type: "success" });
      } else {
        setSnackBarValue({ msg: result.message, type: "error" });
      }
    } catch (error) {
      setSnackBarValue({ msg: error?.response?.data.message, type: "error" });
    } finally {
      setLoading(false);
    }
  };
  return (
    <LoginStyles>
      {snackBarValue.msg && <CustomSnackbar payload={snackBarValue} />}
      <div className="container">
        <div className="left">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <img className="login-img" src={login123} alt="" />
        </div>
        <div className="right">
          <div className="right-container">
            <div className="login-title">Enter Email to the Reset Password</div>
            <div className="vertical-spacer-20" />
            {/* <p className="welcome-msg">
              Welcome to HelpIQ to explore the new things
            </p> */}
            <div className="form">
              <div className="vertical-spacer-20" />

              <div className="signup-form">
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  onKeyDown={handleKeyPress}
                  onChange={(e) => handleChange(e)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlinedIcon htmlColor="#8db178" />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  sx={{ marginBottom: "16px" }}
                />
                {otpSent && (
                  <div className="otp-sent-message">
                    A password reset link has been sent to your email.
                  </div>
                )}
                {/* <div className="forgot-password">
                  <Link style={{ color: "#4C65FF" }}>Forgot password</Link>
                </div> */}
                <div className="vertical-spacer-10" />
                {isLoading ? (
                  <Button
                    // onClick={(e) => handleSubmit(e)}
                    color="primary"
                    variant="contained"
                    fullWidth
                    sx={{
                      color: "white",
                      height: 50,
                    }}
                  >
                    <LogoSpinner />
                  </Button>
                ) : (
                  <Button
                    onClick={(e) => handleSubmit(e)}
                    color="primary"
                    variant="contained"
                    fullWidth
                    sx={{
                      color: "white",
                      height: 50,
                    }}
                  >
                    {isLoading ? <LogoSpinner /> : "Reset Password"}
                  </Button>
                )}

                <div />
                <p className="login-user-link">
                  Remember you password ?{" "}
                  <Link to={"/login"} style={{ color: "#504BD0" }}>
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginStyles>
  );
};
export default ForgotPassword;
