import React from "react";
import styled from 'styled-components'

const SessionInfo = ({name , agentName, status, currentSessionData}) => {
  const currentStatus = status === 'progress' ? 'In Progress' : status
  return (
    <SessionInfoStyles>
      <div className="header-section-message" id="header-section-message">
        <h3 className="store-name truncate">{name}</h3>
        <span className="agent-name">
          {agentName && <b>Agent : </b>}
          {agentName && <span className="name">{agentName}</span>}
          <span className="ticket-status">{currentStatus}</span>
        </span>
        <span className="shop-name truncate">{currentSessionData.storeName}</span>
      </div>
    </SessionInfoStyles>
  );
};

export default SessionInfo;

const SessionInfoStyles = styled.div`
  /* position: sticky;
  top: 0px;
  padding-top: 10px ;
  z-index: 1000;
  background-color: white; */
  .header-section-message {
    display: flex;
    // margin: 10px 10px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: #ebe8e8;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.26) 0px 0px 0px 1px;
  }
  .agent-name {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .store-name {
    width: 130px;
  }
  .shop-name {
    line-height: 28px;
    color: var(--primary-800);
    /* min-width: fit-content; */

  }
  .ticket-status {
    background: var(--primary-200);
    float: right;
    margin-top: 2px;
    padding: 6px 12px;
    border-radius: 15px;
    border: 1px solid #CDDC39;
    color: black;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
    margin-left: 1rem;
  }
`
