import React, { useEffect, useState } from "react";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import CustomAvatar from "../reusable/CustomAvatar";
import { useLocation, useNavigate } from "react-router-dom";
import { useTenantContext } from "../../context/TenantContext";
import { getAllNotification, markNotificationAsSeen } from "../../service/service";
import ChannelIcon from "../inboxMessage/ChannelIcon";
import { fullDate } from "../../utils/helperFunction";
import NotificationPreviewStyles from "../../assets/styledComponents/message/NotificationPreview";


const NotificationPreview = ({ toggleDrawer }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    setCurrentChat,
    setHighlight,
    setCurrentSessionData,
    messageNotifications,
    setRefreshMessageNotification,
    socket,
  } = useInboxContext();
  const { setIconTitle, setPreviewActive, tenantId, userId } = useTenantContext();

  const markAsSeen = async (id) => {
    try {
      const result = await markNotificationAsSeen(id);
      setRefreshMessageNotification((prev) => !prev);
    } catch (error) {
      console.log(error);
    }
  };
  const openMessage = (item) => async(e) => {
    console.log({item})
    const chatSessionId =  item.chatSessionId._id
    if (location.pathname !== "/") {
      setIconTitle("inbox");
      navigate("/");
    }
    
    // close the model
    // set the
    toggleDrawer("left", false)(e);
    markAsSeen(item._id)
    setPreviewActive({
      isActive: true,
      sessionId:chatSessionId
    });
    let payload = {
      ...item.chatSessionId,
      assignUser : item?.chatSessionId?.assignTo,
      assignTo: item?.chatSessionId?.assignTo?._id,
      sessionId : chatSessionId,
      messageType : item.channelName,
    };
    setHighlight(chatSessionId);
    setCurrentChat(payload);
    setCurrentSessionData(payload);
    socket?.emit("NOTIFICATION_OPEN_BY_AGENT", tenantId, userId, item._id);
    socket?.emit('AGENT_SEEN_MESSAGE', item.chatSessionId._id, tenantId, userId);

    // socket?.emit("JOIN_CHAT", chatSessionId);
    // socket?.emit("UPDATE_MESSAGE_IS_SEEN", chatSessionId, "agent");
    // socket?.emit("MESSAGE_OPENED_BY_AGENT_SENT", chatSessionId, "agent");

  };
 
  return (
    <NotificationPreviewStyles>
      <div className="wrapper">
        {messageNotifications.map((item, index) => {
          const { ticketId, description, createdAt } = item || {};
          const formattedDate = fullDate(createdAt)
          return (
            <article
              onClick={openMessage(item)}
              key={index}
              className={`container ${item.isPrivate ? "isPrivate" : ""}`}
            >
              <CustomAvatar name={'Unknown'} />
              <div className="message-container">
                <span>  Ticket Id : {ticketId}</span>
                <p className="name truncate">{description }</p>
                <p className="time"> {formattedDate} </p>
              </div>
              <div className="social-icons">
                <ChannelIcon channelName={item?.channelName} />
              </div>
            </article>
          );
        })}
      </div>
    </NotificationPreviewStyles>
  );
};

export default NotificationPreview;
