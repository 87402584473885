import styled from "styled-components";
const FacebookStyles = styled.div`
  width: 81vw;
  .container {
    height: 86vh;
    overflow-y: scroll;
    padding-right: 40px;
    padding-left: 40px;
  }
  .input-container {
    margin-left: 2.3rem;
    width: 85%;
    display: flex;
    background-color: #f0f0f0;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  }
  .input-container input {
    all: unset;
    margin-left: 0.75rem;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    padding: 20px;
    padding-left: 40px;
  }
  .Button {
    margin-right: -550px;
  }
  .saveButton {
    margin-right: 30px;
  }
  .underline {
    background-color: var(--border-clr);
    width: 94%;
    height: 1px;
    margin: 0 auto;
    padding-left: 40px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .personalize-heading {
    margin: 2rem 0;
  }
  .personalize-heading h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .personalize-heading p {
    color: #81848a;
    font-size: 16px;
    font-weight: 400;
  }
  .general {
    margin-top: 30px;
    font-weight: 600;
    font-size: 20px;
  }
  .facebook-Page {
    margin-top: 30px;
    font-weight: 500;
    font-size: 18px;
  }
  .brand {
    margin-top: 10px;
  }
  .brand span {
    font-size: 20px;
    font-weight: 600;
  }
  .brand p {
    font-size: 16px;
    color: #81848a;
  }
  .facebook-messenger {
    margin-top: 10px;
  }
  .facebook-messenger span {
    font-weight: 500;
    font-size: 18px;
  }
  .switch-container {
    width: 480px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .post-comments span {
    font-weight: 500;
    font-size: 18px;
  }
  .delete-button {
    margin-top: 10px;
  }
  .custom-select {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
.instagram-account {
  width:455px ;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 1rem;
}
.connected {
  display: flex;
  gap: 1rem ;
  align-items: center;
}
`;
export default FacebookStyles;
