import React, { useState } from 'react'
import CustomInputDropDown from '../../components/CustomInputDropDown'
import { useTenantContext } from '../../context/TenantContext'
import PrimaryButton from '../../components/reusable/PrimaryButton/PrimaryButton'
import { Button } from '@mui/material'
import AIDatePicker from './AIDatePicker'
import dayjs from 'dayjs';
import styled from 'styled-components'

const AiSelection = ({fetchMLLogs }) => {
  const {tenantId, tenantStores, profile} = useTenantContext()
  const statusList = [ {name : 'Success', value : 'Success'}, {name : 'Error', value : 'Error'}]
  const typeList = [{name : 'Bot Training', value : 'Bot Training'}, ]
  const currentDate = new Date();
  const [selectedShop, setSelectedShop] = useState('')
  const [status, setStatus] = useState('')
  const [typeValue, setTypeValue ] = useState('Bot Training')
  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const handleDropDownChange = (e) => {
    const { name, value } = e.target || {};
    if (name === 'store') {
      setSelectedShop(value)
    }
    if (name === 'status') {
      setStatus(value)
    }
    if (name === 'type') {  
      setTypeValue(value)
    }
  }
  const handleApply = async() => {
      const startDate = startValue ?  dayjs(startValue).format("MM/DD/YYYY") :'' 
      let endDate = endValue ?  dayjs(endValue).format("MM/DD/YYYY") : ""
      if  (startDate && !endDate) { 
        setEndValue(dayjs(currentDate))
        endDate =  dayjs(currentDate).format("MM/DD/YYYY")
      }
      fetchMLLogs(selectedShop, status, typeValue , startDate, endDate)
  }
  const handleClear = () => {
    setSelectedShop('')
    setStatus('')
    // setTypeValue('')
    fetchMLLogs()
    setStartValue(null)
    setEndValue(null)
  }
  return (
    <AiSelectionStyles>

          <div className="training-history paper-card">
            <div className="training-data">
            <div className='store-select'>
            <CustomInputDropDown
              label={"Select Store"}
              name={"store"}
              value={selectedShop}
              selectList={tenantStores}
              required={true}
              onChange={handleDropDownChange}
              width={"150px"}
              size={''}
            />
            <CustomInputDropDown
              label={"Select Status"}
              name={"status"}
              value={status}
              selectList={statusList}
              required={true}
              onChange={handleDropDownChange}
              width={"150px"}
              size={''}
            />

            {/* <CustomInputDropDown
              label={"Select Type"}
              name={"type"}
              value={typeValue}
              selectList={typeList}
              required={true}
              onChange={handleDropDownChange}
              width={"150px"}
              size={'small'}
            /> */}

            <AIDatePicker
                startValue={startValue}
                setStartValue={setStartValue}
                endValue={endValue}
                setEndValue={setEndValue}
            />
            </div>
            <div className="btn-container">
                <Button variant='outlined' onClick={handleClear}>
                    Clear 
                </Button>
                <PrimaryButton onClick={handleApply}>
                    Apply
                </PrimaryButton>
            </div>
            </div>
          </div>
    </AiSelectionStyles>
  )
}

const AiSelectionStyles = styled.div`
width: 66%;

`
export default AiSelection