import React, { useEffect, useState } from "react";
import QuickReply from "../settings/QuickReply";
import CustomSearchBox from "../reusable/CustomSearch";
import { getAllQuickReply } from "../../service/service";
import { useTenantContext } from "../../context/TenantContext";
import {
  getKey,
  htmlToPlainText,
  inputChangerForQuickReply,
} from "../../utils/helperFunction";
import QuickReplyModalStyles from "../../assets/styledComponents/inboxMessage/QuickReplyModal";
import PrimaryButton from "../reusable/PrimaryButton/PrimaryButton";
import { useInboxContext } from "../../context/inboxContext/inboxContext";

const QuickReplyModal = ({ setText, setQuickReplyOpen }) => {
  const { tenantId } = useTenantContext();
  const { currentSessionData, setQuickReplySelected, orderData, quillRef } =
    useInboxContext();

  const [quickReplyList, setQuickReplyList] = useState([]);
  const [query, setQuery] = useState("");
  const [backendError, setBackendError] = useState({ msg: "", type: "error" });
  const fetchAllQuickReplyData = async () => {
    try {
      const result = await getAllQuickReply(tenantId);
      setQuickReplyList(result.data);
    } catch (error) {
      setBackendError({ msg: error.response.data.message, type: "error" });
    }
  };
  useEffect(() => {
    fetchAllQuickReplyData();
  }, []);

  const [templateData, setTemplateData] = useState({ name: "", msg: "" });

  const handleClick = (name, msg) => {
    return () => {
      setTemplateData({ name, msg });
    };
  };

  const handleSubmit = () => {
    if (!templateData.msg) return;
    const {
      ticketId,
      customerInfo: customerInfo1,
      channelName,
    } = currentSessionData || {};

    const { customerInfo, orderId, orderInfo } = orderData[0] || {};
    const quickReplyOption = {
      ...customerInfo,
      ...customerInfo1,
      "Ticket Id": ticketId,
      "Order Id": orderId,
      "Order Status": orderInfo?.["Order Status"],
      "Order Coupon": orderInfo?.["Order Coupon"],
    };
    const formattedMessage = inputChangerForQuickReply(
      templateData.msg,
      quickReplyOption
    );

    const insertHtmlAtCursor = (html) => {
      const quill = quillRef?.current?.getEditor();
      quill?.focus();
      let range = quill?.getSelection();
      if (range?.index === 0) {
        // If there's no selection, insert at the end
        const length = quill.getLength();
        quill.clipboard.dangerouslyPasteHTML(length, html);
      } else {
        // If there's a selection, insert at the cursor position
        quill.clipboard.dangerouslyPasteHTML(range?.index, html);
      }
    };

    const quill = quillRef?.current?.getEditor();

    if (quill) {
      insertHtmlAtCursor(formattedMessage);
    } else {
      setText((prev) => prev + formattedMessage); 
    }
    setQuickReplyOpen(false);
    setQuickReplySelected(true);
  };
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  return (
    <QuickReplyModalStyles>
      <div className="wrapper">
        <div className="list-container">
          <div className="search-container">
            <CustomSearchBox handleSearch={handleSearch} />
          </div>
          <div className="list">
            {quickReplyList
              .filter((result) =>
                result.templateName.toLowerCase().includes(query.toLowerCase())
              )
              .map((data, index) => {
                const { templateName, templateMessage } = data;
                const plainText = htmlToPlainText(templateMessage);
                return (
                  <React.Fragment key={getKey(data, "templateName", index)}>
                    <div
                      className={`list-name ${
                        templateData.name === templateName ? "active" : ""
                      }`}
                      onClick={handleClick(templateName, templateMessage)}
                    >
                      <h2>{templateName}</h2>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        </div>
        <div className="template-container">
          <p className="heading">Template</p>
          <div
            dangerouslySetInnerHTML={{ __html: templateData.msg }}
            className="template-msg-container"
          ></div>
          <div className="btn-container">
            <PrimaryButton variant={"contained"} onClick={handleSubmit}>
              Apply
            </PrimaryButton>
          </div>
        </div>
      </div>
    </QuickReplyModalStyles>
  );
};

export default QuickReplyModal;
