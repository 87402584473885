import React from 'react'
import styled from 'styled-components'

const EmailProductMessage = ({ productDetails, message }) => {
  const { imageUrl, quantity, title, price, grams, name, productUrl, variant } =
    productDetails || {};
  return (
    <EmailProductMessageStyles>
      <div className="container">
        <img src={imageUrl} alt="" className="img" />
        <div className="details">
          <h3 className="product-name">{title}</h3>
          <div className="product-desc">{}</div>
          <div className="product-price">
            <b>Price : </b> {price}
          </div>
          <a
            className="btn"
            target="_blank"
            rel="noopener noreferrer"
            href={productUrl}
          >
            {"View Product"}
          </a>
        </div>
      </div>
    </EmailProductMessageStyles>
  );
}

export default EmailProductMessage;

const EmailProductMessageStyles = styled.div`
  .img {
    height: 200px;
    width: 200px;
    padding: 1rem;
  }
  .container {
    width: 200px;
    box-shadow: 0 0.25rem 0.25rem .25rem #ececec;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .1rem;
    padding: 0.5rem;

  }
  .product-name {
    /* margin-bottom: .5rem; */
  }
  .btn {
    font-size: 12px;
    width: fit-content;
    display: block;
    margin-top: 5px;
    text-decoration: none;
    font-size: 12px;
    background: black;
    color: white;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: .5rem;
  }
  .product-name {
    text-align: center;
  }
`;