import React, { useState, useContext } from "react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Link, useNavigate } from "react-router-dom";

import RegisterStyles from "../assets/styledComponents/Register";
import login12 from "../assets/images/signup.png";
import logo from "../assets/images/logoTitle.svg";

import { TextField, InputAdornment, Button } from "@mui/material";
import { EMAIL_REGEX } from "../utils/constants";

import { DEFAULT_PASSWORD_CONSTRAINT } from "../utils/helperData";
import { registerUser } from "../service/service";
import CustomSnackbar from "../components/snackbar/CustomSnackbar";
import { TenantContext } from "../context/TenantContext";
import LogoSpinner from "../components/reusable/Loader/LogoSpinner";

const Register = () => {
  const { setToken } = useContext(TenantContext);
  const navigate = useNavigate();

  const [value, setValue] = useState({ name: "", email: "", password: "" });
  const [showPasswordAlert, setPasswordAlert] = useState(
    DEFAULT_PASSWORD_CONSTRAINT
  );
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
  });
  const [backendError, setBackendError] = useState({ msg: "", type: "error" });
  const [isLoading, setLoading] = useState(false);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    const newErrors = { ...errors };
    if (name === "name") {
      if (name !== "") {
        newErrors[name] = "";
      }
    }
    if (name === "email") {
      const regex = EMAIL_REGEX;
      const isEmail = regex.test(value);
      if (!isEmail) {
        newErrors[name] = "Invalid Email";
      } else {
        newErrors[name] = "";
      }
    } else if (name === "password") {
      if (value !== "") {
        newErrors[name] = "";
      }
      let currentPassword = value;
      let newPassConstraintCheck = [...DEFAULT_PASSWORD_CONSTRAINT];

      newPassConstraintCheck = await Promise.all(
        newPassConstraintCheck.map((passConstraintData) => {
          let result = {
            ...passConstraintData,
          };

          if (passConstraintData.regexPattern) {
            if (passConstraintData.regexPattern.test(currentPassword)) {
              result.valid = true;
            } else {
              result.valid = false;
            }
          }
          if (passConstraintData.minLength) {
            if (currentPassword.length >= passConstraintData.minLength) {
              result.valid = true;
            } else {
              result.valid = false;
            }
          }
          return result;
        })
      );

      setPasswordAlert(newPassConstraintCheck);
    }

    setValue((prev) => ({ ...prev, [name]: value }));
    setErrors(newErrors);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setBackendError({ msg: "", type: "error" });

    setTimeout(() => {
      setBackendError({ msg: "", type: "" });
    }, [5000])

    const newErrors = { ...errors };
    let isDirty = false;

    if (!value.email) {
      newErrors.email = "Email is Required";
      isDirty = true;
    }

    if (!value.name) {
      newErrors.name = "Name is Required";
      isDirty = true;
    } else if(value.name.length < 2) {
      newErrors.name = "Name is too short";
      isDirty = true;
    }

    if (!value.password) {
      newErrors.password = "Password is Required";
      isDirty = true;
    }

    const isPasswordValid = showPasswordAlert.every(
      ({ valid }) => valid === true
    );

    if (!isPasswordValid) {
      newErrors.password = "Invalid Password";
      isDirty = true;
    }

    if (isDirty) {
      setErrors(newErrors);
      return;
    }

    if (!isDirty) {
      try {
        setLoading(true);
        const result = await registerUser({
          payload: {
            email: value.email,
            password: value.password,
            name: value.name,
          },
        });
        const token = result?.data.token;
        const verifyUrl =  result?.data.verifyUrl;
        console.log({verifyUrl})

        setToken(token);
        setLoading(false);
         window.location.href = verifyUrl;
        // window.open(result.data);
      } catch (error) {
        setLoading(false);
        setBackendError({ msg: error.response.data.message, type: "error" });
      }
    }
  };


const handleKeyPress = (event) => {
  if (event.key === "Enter") {
    handleSubmit(event);
  }
};
  return (
    <RegisterStyles>
      {backendError.msg && backendError.type === 'success' ? <CustomSnackbar payload={backendError} /> : ""}
      <div className="container">
        <div className="left">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <img className="register-img" src={login12} alt="" />
        </div>
        <div className="right">
          <div className="right-container">
            <div className="sign-up-title">Create your HelpIQ account</div>
            <div className="vertical-spacer-20" />
            {/* <p className="welcome-msg">Welcome to HelpIQ explore the new</p>
            <p className="welcome-msg">things</p> */}
            <div className="form">
              <div className="vertical-spacer-20" />
              <div className="signup-form">
                <div className="text1">
                  {" "}
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    onChange={(e) => handleChange(e)}
                    onKeyDown={handleKeyPress}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlineOutlinedIcon htmlColor="#8db178" />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </div>

                <div className="vertical-spacer-20" />
                <div className="text1">
                  {" "}
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    onChange={(e) => handleChange(e)}
                    onKeyDown={handleKeyPress}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailOutlinedIcon htmlColor="#8db178" />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </div>

                <div className="vertical-spacer-20" />
                <div className="text1">
                  {" "}
                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                    onKeyDown={handleKeyPress}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOutlinedIcon htmlColor="#8db178" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          onClick={() => setShowPassword(!showPassword)}
                          sx={{ cursor: "pointer" }}
                        >
                          {showPassword ? (
                            <VisibilityOutlinedIcon htmlColor="#8db178" />
                          ) : (
                            <VisibilityOffOutlinedIcon htmlColor="#8db178" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </div>

                <div>
                  <div className="password-error">
                    <ul>
                      {value.password &&
                        showPasswordAlert.map(({ id, valid, condition }) => {
                          return (
                            <div className="list" key={id}>
                              {valid ? (
                                <CheckCircleIcon
                                  style={{
                                    fontSize: "0.8rem",
                                    margin: "1px 0.5rem",
                                    color: "#B9A1D6",
                                  }}
                                />
                              ) : (
                                <CancelIcon
                                  style={{
                                    fontSize: "0.8rem",
                                    margin: "1px 0.5rem",
                                    color: "red",
                                  }}
                                />
                              )}
                              <li>{condition}</li>
                            </div>
                          );
                        })}
                    </ul>
                  </div>
                </div>

                <div className="vertical-spacer-10" />
                {backendError.type === "error" && backendError.msg && (
                  <div className="error">{backendError.msg}</div>
                )}
                <div className="button1">
                  {isLoading ? (
                    <Button
                      // onClick={(e) => handleSubmit(e)}
                      color="primary"
                      variant="contained"
                      fullWidth
                      sx={{
                        color: "white",
                        height: 50,
                      }}
                    >
                      <LogoSpinner />
                    </Button>
                  ) : (
                    <Button
                      onClick={(e) => handleSubmit(e)}
                      color="primary"
                      variant="contained"
                      fullWidth
                      sx={{
                        color: "white",
                        padding: "10px",
                      }}
                    >
                      Sign Up
                    </Button>
                  )}
                </div>
                <div className="vertical-spacer-10" />
              </div>
            </div>
            <div className="vertical-spacer-10" />
            <p className="login-user">
              Already have an account{"  "}? <Link to={"/login"}>Login</Link>
            </p>
            <div className="terms-and-condition">
              {`By selecting "Signup," you acknowledge your agreement with the`}{" "}
              <Link to = {'/terms-and-conditions'}>
                Terms and Conditions  
              </Link>
              {' '} as well as the {' '}
              <Link to = {'/privacy-and-policy'}>
                Privacy Policy 
              </Link>
              {"  "}outlined in HelpIQ.
            </div>
          </div>
        </div>
      </div>
    </RegisterStyles>
  );
};

export default Register;
