import React from "react";
import MessageViewStyles from "./MessageViewNewStyle";
import { Avatar } from "@mui/material";
import MessageImage from "../../chatBox/MessageImage";
import TicketNote from "../../message/TicketNote";
import DoneIcon from "@mui/icons-material/Done";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MessageView = ({
  msg,
  time,
  avatarSrc,
  sender = false,
  textColor = "black",
  bgColor = "white",
  image,
  _id,
  showCheckIcon,
  type,
  onClick,
  disable,
  extraCssClass = ""
}) => {
  return (
    <MessageViewStyles textColor={textColor} bgColor={bgColor}>
      <div className="msg-wrapper">
        <div
          className={`txt-container-msg ${
            sender ? "sender-container" : "receiver-container"
          } ${image ? "ticket-note" : ""} ${type ? "bubble" : ""}  `}
        >
          {image && (
            <div className="image-container">
              <MessageImage
                width={200}
                height={150}
                imageSrc={image}
                id={_id}
              />
            </div>
          )}
          {msg && (
            <p className={`msg ${disable ? "disable" : ""} ${extraCssClass}`} onClick={onClick}>
              {msg}
            </p>
          )}
          <div className="time">
            {time}
            {showCheckIcon &&
              (sender ? (
                <DoneIcon
                  sx={{ fontSize: "15px", marginLeft: "5px" }}
                  fontSize="inherit"
                />
              ) : (
                <FontAwesomeIcon
                  style={{ marginLeft: "5px", color: "#03A9F4" }}
                  icon="fa-solid fa-check-double"
                />
              ))}
          </div>
        </div>
      </div>
    </MessageViewStyles>
  );
};

export default MessageView;
