import React from "react";
import styled from "styled-components";

const MessageTypingBoxContainer = styled.div`
  // padding: 5px;
  // display: flex;
  // border: 1px solid silver;
  // background: white;
  // width: 90%;
  // margin: 0 auto;
  // border-radius: 10px;

  #send-button {
    padding: 10px;
    color: rgb(133, 130, 130);
    border: none;
    border-radius: 10px;
    z-index: 100000;
    cursor: pointer;
    display: block;
    margin-left: auto;
  }

  #user-input {
    width: -webkit-fill-available;
    padding: 10px;
    display: inline-flex;
    float: left;
    border: none;
  }

  #user-input:focus-visible {
    outline: none;
  }

  #message-typing-box-container {
    padding: 5px;
    display: flex;
    border: 1px solid silver;
    background: white;
    width: 90%;
    margin: 0px auto;
    border-radius: 10px;
  }

  #message-typing-box-container:hover {
    border-color: ${(props) => props.themeColor};
   }
`;

// const UserInput = styled.input`
//   width: 100%;
//   padding: 10px;
//   display: inline-flex;
//   float: left;
//   border: none;
// `;

// const SendButton = styled.div`
//   padding: 10px;
//   color: rgb(133, 130, 130);
//   border: none;
//   border-radius: 10px;
//   z-index: 100000;
//   cursor: pointer;
//   display: block;
//   margin-left: 10px;
// `;

const InputBox = ({ themeColor }) => {
  return (
    <MessageTypingBoxContainer themeColor={themeColor}>
      <div id="message-typing-box-container">
        <input type="text" id="user-input" placeholder="Type your message..." />
        <div id="send-button">
          <i className="fas fa-paper-plane"></i>
        </div>
      </div>
    </MessageTypingBoxContainer>
  );
};

export default InputBox;
