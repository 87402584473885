import { useRef, useState } from "react";
import { formatFileSize } from "../utils/helperFunction";

const useFile = (attachmentSize) => {
  /**
   * @type {React.RefObject<HTMLInputElement>} fileRef
   */
  const fileRef = useRef(null);
  const [file, setFile] = useState(null);
  const [fileDetails, setFileDetails] = useState({
    fileName: "",
    fileSize: "",
    fileSizeError: "",
  });

  const handleFileClick = () => {
    fileRef.current.click();
  };

  const handleFileChange = (e) => {
    /**
     * @type {File | undefined}
     */
    const selectedFile = e.target.files[0];

    if (selectedFile.size <= attachmentSize) {
      setFile(selectedFile);
      setFileDetails({
        fileName: selectedFile.name,
        fileSize: formatFileSize(selectedFile.size),
      });
    } else {
      setFileDetails((prev) => ({
        ...prev,
        fileSizeError: "File size should be less than 5 MB",
      }));
    }
  };

  const resetFileValue = () => {
    fileRef.current.value = null;
    setFile(null);
    setFileDetails({
      fileName: "",
      fileSize: "",
    });
  };

  return [
    fileRef,
    handleFileClick,
    handleFileChange,
    file,
    fileDetails,
    resetFileValue,
  ];
};

export default useFile;
