import { Box, Stack } from "@mui/material";
import React from "react";
import Skeleton from '@mui/material/Skeleton';

const QuickReplySkeleton = () => {
  return (
    <Stack sx={{ padding: ".5rem 1rem" }} spacing={1}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "start",
            gap: "2rem",
            marginTop: '2rem'
          }}
        >
          <Skeleton variant="rectangular" sx={{marginLeft : '1.5rem'}} height={20} width={250}  />
          <Skeleton variant="rectangular" sx={{marginLeft : '1.5rem'}} height={20} width={250}  />
          <Skeleton variant="rectangular" sx={{marginLeft : '1.5rem'}} height={20} width={250}  />
          <Skeleton variant="rectangular" sx={{marginLeft : '1.5rem'}} height={20} width={250}  />
          <Skeleton variant="rectangular" sx={{marginLeft : '1.5rem'}} height={20} width={250}  />
          <Skeleton variant="rectangular" sx={{marginLeft : '1.5rem'}} height={20} width={250}  />
          <Skeleton variant="rectangular" sx={{marginLeft : '1.5rem'}} height={20} width={250}  />
          <Skeleton variant="rectangular" sx={{marginLeft : '1.5rem'}} height={20} width={250}  />
        </Box>
      </Box>
    </Stack>
  );
};

export default QuickReplySkeleton;
