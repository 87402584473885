import React, { useState, useEffect } from "react";
import ReportStyles from "../../../assets/styledComponents/Report";
import HeaderBar from "../../reusable/HeaderBar/HeaderBar";
import CustomDate from "../../reusable/ReportFeature.js/CustomDate";
import CustomReportFilter from "../CustomReportFilter";
import CustomReportTable from "../CustomReportTabel";
import FullPageSpinner from "../../FullPageSpinner"; // here import statement for fullPageSpinner
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import { getAllLabelReports } from "../../../service/service";
import { useTenantContext } from "../../../context/TenantContext";
import { getLabel } from "../../../service/service";
import { Tooltip } from "@mui/material";
const LabelReport = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [labelTableDetails, setLabelTableDetails] = useState([]);

  const [labelList, setLabelList] = useState([]);
  const [originalLabelTableData, setOriginalLabelTableData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [labelFilterValue, setLabelFilterValue] = useState("All");

  const [displayComponents, setDisplayComponents] = useState(false);
  const [backendError, setBackendError] = useState({ msg: "", type: "error" });
  const { tenantId } = useTenantContext();


  const NewHeader = (
    <Tooltip title="New Tickets"  placement="top-start"  >
      <span style={{ cursor: 'pointer'}}>
      New 
      </span>
    </Tooltip>)
  
  const ClosedHeader = (
    <Tooltip title="Closed Tickets" placement="top-start">
      <span style={{ cursor: 'pointer' }}>
        Closed
      </span>
    </Tooltip>
  );
  
  const AFRTHeader = (
    <Tooltip title="Average First Response Time (mins)" placement="top-start">
      <span style={{ cursor: 'pointer' }}>
        AFRT
      </span>
    </Tooltip>
  );
  
  const ARTHeader = (
    <Tooltip title="Average Revolution Time (mins)" placement="top-start">
      <span style={{ cursor: 'pointer' }}>
        ART
      </span>
    </Tooltip>
  );
  
  const MsgRecieveHeader = (
    <Tooltip title="Message Recieve" placement="top-start">
      <span style={{ cursor: 'pointer' }}>
        Msg Recieve
      </span>
    </Tooltip>
  );
  
  const MsgSentHeader = (
    <Tooltip title="Message Sent" placement="top-start">
      <span style={{ cursor: 'pointer' }}>
        Msg Sent
      </span>
    </Tooltip>
  );
  
  const IntervalMsgHeader = (
    <Tooltip title="Interval Message" placement="top-start">
      <span style={{ cursor: 'pointer' }}>
        Interval Msg
      </span>
    </Tooltip>
  );

  
  const columns = React.useMemo(
    () => [
      { Header: "Label", accessor: "label" },
      { Header: NewHeader, accessor: "newConversations" },
      { Header: ClosedHeader, accessor: "closedConversations" },
      {
        Header: AFRTHeader,
        accessor: "averageFirstResponse",
      },
      { Header: ARTHeader, accessor: "averageResolutionTime" },
      { Header: MsgRecieveHeader, accessor: "messageRecieved" },
      { Header: MsgSentHeader, accessor: "messageSent" },
      { Header: IntervalMsgHeader, accessor: "internalMessage" },
    ],
    []
  );

  const fetchAllLabelData = async () => {
    try {
      const result = await getLabel(tenantId, "");
      if (result.data) {
        const newData = [...result.data];
        let newData1 = newData.map((item, index) => {
          return { name: item.labelName, id: index, value: item._id };
        });
        setLabelList(newData1);
      } else {
        // Handle the case where result.data is undefined or empty
      }
    } catch (error) {
      console.log("Error::", error);
      // setBackendError({ msg: error.response.data.message, type: "error" });
    }
  };

  useEffect(() => {
    fetchAllLabelData();
  }, []);

  const fetchLabel = async (customStartDate, customEndDate, shop) => {
    console.log("this is calling or not ");
    try {
      const result = await getAllLabelReports(
        tenantId,
        customStartDate,
        customEndDate,
        shop
      );
      console.log("..", result);
      setLabelTableDetails([...result.data]);
      setOriginalLabelTableData(result.data);
      setShowTable(true);
    } catch (error) {
      setBackendError({ msg: error.response.data.message, type: "error" });
    }
  };

  const handleClick = async (customStartDate, customEndDate,shop) => {
    try {
      setShowDropdown(true);
      setLoading(true);
      await fetchLabel(customStartDate, customEndDate,shop);
      setDisplayComponents(true);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setLabelFilterValue(selectedValue);
    setTableLoading(true);
    if (selectedValue === "All") {
      setLabelTableDetails(originalLabelTableData);
    } else {
      const newTableData = originalLabelTableData.filter(
        (tableRow) => tableRow.labelId === selectedValue
      );
      setLabelTableDetails(newTableData);
    }

    setTimeout(() => {
      setTableLoading(false);
    }, 1000);
  };

  return (
    <ReportStyles>
      <div className="header-wrapper">
        <HeaderBar
          title="Label Report"
          notification="Data last updated 5min ago"
          hidePrimaryButton={true}
          hideSecondaryButton={true}
          showDescription={true}
          paperHeader={true}
          description="Gain Insight how much traffic on your channels get, how customer prefer more contact you "
        />
      </div>
      <div className="report-container">

      <div className="date-container">
      <CustomDate setShowDropdown={setShowDropdown} handleClick={handleClick} />
      </div>
      {displayComponents && (
        <div className="filter-container">
        <CustomReportFilter
          heading="label"
          label="Label"
          marginRight="20px"
          selectList={[{ id: "All", name: "All" }, ...labelList]}
          icon={<LabelOutlinedIcon />}
          handleChange={handleChange}
          value={labelFilterValue}
        />
        </div>
      )}
      {displayComponents ? (
        isLoading ? (
          <FullPageSpinner />
        ) : (
          <div  className="report-table paper-card report-table-container">
            <CustomReportTable
              columns={columns}
              data={labelTableDetails}
              tableLoading={tableLoading}
            />
          </div>
        )
      ) : null}
      </div>
    </ReportStyles>
  );
};

export default LabelReport;
