import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import ActionButtonGroupStyle from "./ActionButtonGroupStyle";

const useStyles = makeStyles({
  customButton: {
    color: "white !important",
  },
});

const ActionButtonGroup = ({
  handlePrimaryButtonClick,
  handleSecondaryButtonClick,
  secondaryButtonLabel,
  primaryButtonLabel,
  hidePrimaryButton = false,
  primaryButtonDisable = false,
  secondaryButtonDisable = false,
  hideSecondaryButton = false,
  primaryButtonLoading = false,
  primaryButtonType = "button",
  secondaryButtonType = "button",
  hideThirdButton = true,
  thirdButtonLabel="",
  thirdButtonDisable=false,
  handleThirdButtonClick,
}) => {
  const classes = useStyles();

  return (
    <ActionButtonGroupStyle>
      <div className="button-container">
        {!hideThirdButton && (
          <Button
            onClick={() => handleThirdButtonClick()}
            variant="outlined"
            disabled={thirdButtonDisable}
          >
            {thirdButtonLabel}
          </Button>
        )}
        {!hideSecondaryButton && (
          <Button
            onClick={
              secondaryButtonType !== "submit"
                ? () => handleSecondaryButtonClick()
                : () => {}
            }
            variant="outlined"
            disabled={secondaryButtonDisable}
            type={secondaryButtonType}
          >
            {secondaryButtonLabel}
          </Button>
        )}
        {!hidePrimaryButton && (
          <Button
            className={classes.customButton}
            onClick={
              primaryButtonType !== "submit"
                ? () => handlePrimaryButtonClick()
                : () => {}
            }
            variant="contained"
            disabled={primaryButtonDisable}
            type={primaryButtonType}
          >
            {primaryButtonLoading ? (
              <div className="loading-icon">
                <CircularProgress
                  size={25}
                  sx={{ color: "white" }}
                  color="inherit"
                />
              </div>
            ) : (
              primaryButtonLabel
            )}
          </Button>
        )}
      </div>
    </ActionButtonGroupStyle>
  );
};

export default ActionButtonGroup;
