import React, { useContext, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import InputLabel from "@mui/material/InputLabel";
import LabelStyles from "../../assets/styledComponents/settings/Label";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import { getKey } from "../../utils/helperFunction";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import {
  Grid,
  Box,
  Button,
  Chip,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Select,
  FormHelperText,
  FormControl,
} from "@mui/material";

import { addLabel, getLabel, deleteLabel } from "../../service/service";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import CustomTextField from "../reusable/CustomTextField";
import { TenantContext } from "../../context/TenantContext";
import HeaderBar from "../reusable/HeaderBar/HeaderBar";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import QuickReplySkeleton from "../skeleton/QuickReplySkeleton";

const useStyles = makeStyles({
  coloredIcon: {
    marginRight: "5px",
    marginBottom: "5px",
    marginTop: "6px",
    marginLeft: "35px",
  },
});

const channelList = [
  "All",
  "Email",
  "Instagram",
  "Whatsapp",
  "Facebook",
  "Live Chat",
];
// const sortList = ["Latest First", "Ascending", "Descending"];

const Label = () => {
  const { tenantId } = useContext(TenantContext);
  const classes = useStyles();

  const [backendError, setBackendError] = useState({ msg: "", type: "error" });
  const [labelList, setLabelList] = useState([]);
  const [query, setQuery] = useState("");
  const [value, setValue] = useState({
    labelName: "",
    selectChannel: [],
    sort: "",
  });
  const [errors, setErrors] = useState({
    labelName: "",
    selectChannel: "",
    sort: "",
  });
  const [snackBarValue, setSnackBarValue] = useState({ msg: "", type: "" });

  const [labelLoading, setLabelLoading] = useState(false)
  const [disableSaveBtn, setDisableSaveBtn] = useState(false)

  const lastRef = useRef();

  const scrollToBottom = () => {
    lastRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };


  const fetchAllLabelData = async (loading) => {
    try {
      setLabelLoading(loading)
      const result = await getLabel(tenantId);
      setLabelList(result.data);
      setLabelLoading(false)
    } catch (error) {
      setBackendError({ msg: error.response.data.message, type: "error" });
      setLabelLoading(false)
    }
  };

  useEffect(() => {
    fetchAllLabelData(true);
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };
    setValue((prev) => ({ ...prev, [name]: value }));
    newErrors[name] = "";
    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { labelName, selectChannel, sort } = value;
    const newErrors = { ...errors };
    let isDirty = false;
    if (!labelName) {
      newErrors.labelName = "Label Name is Required";
      isDirty = true;
    }
    // if (selectChannel.length === 0) {
    //   newErrors.selectChannel = "Channel is Required";
    //   isDirty = true;
    // }
    // if (!sort) {
    //   newErrors.sort = "Sorting is Required";
    //   isDirty = true;
    // }
    if (isDirty) {
      setErrors(newErrors);
    }
    if (!isDirty) {
      setDisableSaveBtn(true)
      try {
        const selectedChannelForPayload = selectChannel.includes("All")
          ? channelList.filter((item) => item !== "All")
          : selectChannel;
        const labelPanel = await addLabel({
          labelName: labelName,
          selectChannel: selectedChannelForPayload,
          sort,
          tenantId: tenantId,
          isDeleted: false,
        });
        setDisableSaveBtn(false)
        setSnackBarValue({ msg: labelPanel.message, type: "success" });
        fetchAllLabelData(false);

        setValue({
          labelName: "",
          selectChannel: [],
          sort: "",
        });
        setTimeout(() => {
          scrollToBottom()
        }, 100);
      } catch (error) {
        setDisableSaveBtn(false)
        setSnackBarValue({ msg: error.response.data.message, type: "error" });
      }
    }
  };

  /*
    Might we need this code in future, this function is to delete a channel from the select channel dropdown
  */
  // const handleDelete = (e, value) => {
  //   e.preventDefault();
  //   const newSelect = value.selectChannel.filter(
  //     (item) => item !== e.target.value
  //   );
  //   setValue((prev) => ({ ...prev, selectChannel: newSelect }));
  // };
  const handleDelete = async (labelId, labelName) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure ?",
        text: "All chat sessions will be dissociated from this label ",
        icon: "warning",
        showCancelButton: true,
        reverseButtons:true,
        confirmButtonColor: "#8db178",
        cancelButtonColor: "#D9D9D9",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const deletionResult = await deleteLabel(labelId);

        if (deletionResult) {
          Swal.fire({
            title: "Deleted!",
            text: `The label "${labelName}" has been deleted.`,
            confirmButtonColor:"#8db178",
            icon: "success",
          });
        }
        fetchAllLabelData(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
const handleKeyPress = (event) => {
  if (event.key === "Enter") {
    handleSubmit(event);
  }
};

  return (
    <LabelStyles>
      {snackBarValue.msg && <CustomSnackbar payload={snackBarValue} />}
      <HeaderBar
        title="Label"
        hidePrimaryButton={true}
        hideSecondaryButton={true}
      />
      <div className="label-container">
        <div className="input-wrapper">
          <div className="input-container">
            <div className="search-icon">
              <SearchIcon />
            </div>
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <div
            className="scroll-div"
          >
            {labelLoading ? (
              <QuickReplySkeleton />
            ) : (
              labelList
                .filter((result) =>
                  result.labelName.toLowerCase().includes(query.toLowerCase())
                )
                .map((data, index) => {
                  const { _id, labelName } = data;
                  return (
                    <div
                      key={getKey(data, "labelName", index)}
                      className="label-card"
                    >
                      <LabelOutlinedIcon className={classes.coloredIcon} />
                      <span className="label-list-item-name">{labelName}</span>
                      {data.tenantId ? (
                        <div className="delete-icon">
                          <DeleteOutlineOutlinedIcon
                            onClick={() => handleDelete(_id, labelName)}
                          />
                        </div>
                      ) : (
                        <div className="delete-icon"></div>
                      )}
                    </div>
                  );
                })
            )}
            <div ref={lastRef}></div>
          </div>
        </div>
        <div className="text-editor-container">
          <div className="label-name">
            <span>Label Name</span>
          </div>
          <div className="text-editor">
            <CustomTextField
              sx={{
                width: '100%',
                marginBottom: 3,
                // marginLeft: -3,
                marginTop: 1,
              }}
              error={errors.labelName}
              value={value.labelName}
              placeholder={"Write label name"}
              name={"labelName"}
              onKeyDown={handleKeyPress}
              handleChange={handleChange}
              margin={"normal"}
              helperText={errors.labelName}
            ></CustomTextField>
          {/* <div className="channel-name">
            <span>Select Channel</span>
          </div> */}
          {/* <FormControl
            sx={{
              marginTop: 1,
              borderRadius: "8px",
              width: 635,
              marginLeft: -3,
              marginBottom: 3,
            }}
            error={errors.selectChannel}
          >
            <InputLabel>Select Channel</InputLabel>
            <Select
              multiple
              sx={{ boxShadow: 7 }}
              name="selectChannel"
              label="Select Channel"
              value={value.selectChannel}
              onChange={handleChange}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      sx={{ background: "#A8C398" }}
                      key={value}
                      label={value}
                      error={errors.selectChannel}
                      helperText={errors.selectChannel}
                      onDelete={(e) => handleDelete(e, value)}
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                    />
                  ))}
                </Box>
              )}
            >
              {channelList.map((channelName) => (
                <MenuItem key={channelName} value={channelName}>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={value.selectChannel.includes(channelName)}
                      />
                    }
                  />
                  {channelName}
                </MenuItem>
              ))}
            </Select>
            {errors.selectChannel && (
              <FormHelperText>{errors.selectChannel}</FormHelperText>
            )}
          </FormControl>
          <div className="sort-name">
            <span>Sort</span>
          </div>
          <FormControl
            sx={{
              marginTop: 1,
              borderRadius: "8px",
              width: 635,
              marginLeft: -3,
              marginBottom: 3,
            }}
            error={errors.sort}
          >
            <InputLabel>Select sort</InputLabel>
            <Select
              name="sort"
              sx={{ boxShadow: 7 }}
              value={value.sort}
              error={errors.sort}
              helperText={errors.sort}
              label="sort here"
              onChange={handleChange}
            >
              <MenuItem value="latest">latest First</MenuItem>
              <MenuItem value="ascending">Ascending</MenuItem>
              <MenuItem value="descending">descending</MenuItem>
            </Select>
            {errors.sort && <FormHelperText>{errors.sort}</FormHelperText>}
          </FormControl> */}

          <div className="save-btn">
            <Button
              onClick={(e) => handleSubmit(e)}
              variant="contained"
              style={{ color: "white", padding: ".7rem 3rem" }}
              disabled={disableSaveBtn}
              // sx={{ marginLeft: 64, width: 10, marginTop: 7 }}
            >
              Save
            </Button>
          </div>
          </div>
        </div>
      </div>
    </LabelStyles>
  );
};
export default Label;
