
import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
const PrivateEmailPopoverStyles= styled.div`
  width: 300px;
  .popover-container {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background-color: white;
    margin-left: 1rem;
    width: fit-content;
    border-radius: 4px;
    // padding: 1rem;
    // box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    //   0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .popover-container li {
    padding: 6px 20px;
    transition: background-color 0.2s;
    cursor: pointer;
  }

  .popover-container li:hover {
    background-color: #f0f0f0;
  }

`;

const PrivateEmailPopover = ({
  userList,
  text,
  setTaggedUserDetails,
  setOpenModal,
  mentionIndex,
  setMentionIndex,
  setText,
  userId,
  quillRef,
  position,
}) => {
  const popoverRef = useRef(null);
  const [filteredUserList, setFilteredUserList] = useState([]);

  const filterUser = () => {
       const tag = text.slice(mentionIndex , text.length - 1 ).toLowerCase();
    const newFilteredUserList = userList.filter((item) => {
      const result = tag.replace('@', '')
      console.log(item?.name?.toLowerCase().includes(result))
      return item?.name?.toLowerCase().includes(result) && item._id !== userId  
    })
    console.log({tag, newFilteredUserList, text, mentionIndex , })

    if (
      newFilteredUserList.length === 0
    ) {
      setOpenModal(false);
    } else  {
      setOpenModal(true);
    }
    setFilteredUserList(newFilteredUserList);
  };

  useEffect(() => {
    filterUser()
  },[text, userId, userList])


  const handleNameSelect = (name) => {
    if (quillRef?.current) {
      const ed = quillRef.current.getEditor();
      ed.editor.deleteText(mentionIndex , name.length + 1);
      ed.editor.insertText(mentionIndex, mentionIndex === 0 ? '@' + name : name);
    }
    // const editor = quillRef.current.getEditor();
    // const newContent = editor.getContents();
    // const text1 = newContent.ops.map((op) => op.insert ).join("");
    // setText(text1);
    const currentText = quillRef.current.getEditor().getContents();
    const html = quillRef.current.getEditor().root.innerHTML;
    setText(html)
    setOpenModal(false)
    setMentionIndex(-1);
  };
  console.log({text})

  const handleClick = (user) => {
    return () => {
      handleNameSelect(user.name);
      setOpenModal(false);
      setTaggedUserDetails((prev) => [...prev, user]);
    };
  };

  return (
    <PrivateEmailPopoverStyles>
      <div className="popover-container" ref={popoverRef}>
        <ul>
          {filteredUserList.map((user, index) => (
            <li key={index} onClick={handleClick(user)}>
              {user.name}
            </li>
          ))}
        </ul>
      </div>
    </PrivateEmailPopoverStyles>
  );
};

export default PrivateEmailPopover;
