import React from "react";
import FacebookCardStyles from "../../../assets/styledComponents/settings/FacebookCard";
import Rectangle73 from "../../../assets/images/Rectangle 73.png"
import { Button } from "@mui/material";
import MetaAddPage from "../../reusable/ChannelConfiguration/MetaAddPage";
import InstagramCardStyles from "../../../assets/styledComponents/settings/InstagramCard";
const InstagramIntegration = () => {
  const data = {
    name:"Instagram Page",
    logo: Rectangle73 ,
    url:"example-myshopify.com"
  
  };
  return (
    <>
 <InstagramCardStyles>
      <div className="top">
          <div className="">
            <h2>Instagram Configuration</h2>
          </div>
          <div className="saveButton">
          <Button variant="contained">Add Page</Button>
        </div>
        </div>
        <div className="underline"></div>
       <MetaAddPage {...data} />
       <MetaAddPage {...data} />
       <MetaAddPage {...data} />
       </InstagramCardStyles>
 
    </>
  );
};
export default InstagramIntegration;
