import React from "react";
import CustomTextField from "../../reusable/CustomTextField";
import { Tooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ContentPasteTwoToneIcon from '@mui/icons-material/ContentPasteTwoTone';
import styled from 'styled-components'
import PrimaryButton from "../../reusable/PrimaryButton/PrimaryButton";
import ClipboardButton from "../../reusable/ClipboardButton";


const WhatsappConfigModal = ({
  values,
  whatsappConfig,
  handleChange,
  open,
  setOpenModal,
  handleTooltipOpen,
}) => {
  return (
    <WhatsappConfigModalStyles>
    <div className="whatsapp-config"> 
      {values.map((item, index) => {
        const { name, label, value, disabled } = item;
        if (name === "webHookUrl" || name === "verifyToken") {
          return (
            <div key={index}>
              <div className="business-name">
                <span>{label}</span>
              </div>
              <CustomTextField
                disabled={Boolean(disabled)}
                name={name}
                value={whatsappConfig[name]}
                placeholder={label}
                onChange={handleChange}
                margin={"normal"}
                row={1}
                endAdornment={
                  <>
                    {/* <Tooltip
                      title={
                        !whatsappConfig[name]
                          ? "Value will be autogenerated once you hit save"
                          : "This value is configured by system, you can't change it."
                      }
                    >
                      <InfoIcon color="primary" />
                    </Tooltip> */}
                      <div className="clipboard">
                        <ClipboardButton text={whatsappConfig[name]} /> 
                      </div>
                  </>
                }
              />
            </div>
          );
        }
      })}
      <div className="close-btn">
      <PrimaryButton onClick={() => setOpenModal(false)} variant="contained">Close</PrimaryButton>
      </div>
    </div>
    </WhatsappConfigModalStyles>
  );
};

const WhatsappConfigModalStyles = styled.div`
.whatsapp-config {
    padding: 1rem;
    width: 500px
}
.close-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: .25rem;
}

`

export default WhatsappConfigModal;

