import { useEffect, useState } from "react";

/**
 * Custom hook for fetching data from a specified service.
 *
 * @param {function} service - The service function to fetch data from
 * @return {array} An array containing the fetch function, loading state, result, and backend message
 */
const useFetch = (service) => {
  const [loading, setLoading] = useState(false);
  const [backEndMessage, setBackendMessage] = useState({ msg: "", type: "" });
  const [result, setResult] = useState(null);
  const [resultBackup, setResultBackup] = useState(null);
  const fetch = async (payload) => {
    setLoading(true);
    try {
      const result = await service(payload);
      setLoading(false);
      setResult(result);
      setResultBackup(result);
      console.log(result, 'result')
      setBackendMessage({ msg: result.message, type: "success" });
      return result
    } catch (error) {
      console.log(error.response.data.message, 'err');
      setLoading(false);
      setBackendMessage({ msg: error.response.data.message, type: "error" });
      throw Error(error.response.data.message); 
    }
  };

  return [ fetch, loading, result, backEndMessage];
};

export default useFetch;
