import React from "react";
import { Link } from "react-router-dom";
import TermsAndConditionStyles from "../assets/styledComponents/TermsAndCondition";
import TermsAndConditionHeader from "../components/reusable/HeaderBar/TermsAndConditionHeader";

const PrivacyAndPolicy = () => {
  return (
    <TermsAndConditionStyles>
      <TermsAndConditionHeader heading={"Privacy Policy"} />
      <div className="paragraph-container">
        <h2 className="table-of-contents">Table of Contents</h2>
        <ul className="table-of-contents-container">
          <li>
            <a href="#section1">1. WHAT INFORMATION DO WE COLLECT?</a>
          </li>
          <li>
            <a href="#section2">2. HOW DO WE PROCESS YOUR INFORMATION?</a>
          </li>
          <li>
            <a href="#section3">
              3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
          </li>
          <li>
            <a href="#section4">
              4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </a>
          </li>
          <li>
            <a href="#section5">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
          </li>
          <li>
            <a href="#section6">
              6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
            </a>
          </li>
          <li>
            <a href="#section7">7. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
          </li>
          <li>
            <a href="#section8">8. DO WE COLLECT INFORMATION FROM MINORS?</a>
          </li>
          <li>
            <a href="#section9">9. WHAT ARE YOUR PRIVACY RIGHTS?</a>
          </li>
          <li>
            <a href="#section10">10. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
          </li>
          <li>
            <a href="#section11">11. DO WE MAKE UPDATES TO THIS NOTICE?</a>
          </li>
          <li>
            <a href="#section12">
              12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </a>
          </li>
          <li>
            <a href="#section13">
              13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </a>
          </li>
        </ul>

        <div className="paragraph privacy-para" id="section1">
          <div className="heading">1. WHAT INFORMATION DO WE COLLECT?</div>
          <div className="content">
            <div className="para">
              <b>In Short:</b>
              We collect personal information that you provide to us.
            </div>
            <div className="para">
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </div>
            <div className="para">
              <b>Sensitive Information.</b>
              We do not process sensitive information.
            </div>
            <div className="para">
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </div>
            <h3 className="sub-heading">Information automatically collected</h3>
            <div className="para">
              <b>In Short:</b> Some information — such as your Internet Protocol
              (IP) address and/or browser and device characteristics — is
              collected automatically when you visit our Services.
            </div>
            <div className="para">
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </div>
            <div className="para">
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </div>
          </div>
        </div>
        <div className="paragraph" id="section2">
          <div className="heading">2. HOW DO WE PROCESS YOUR INFORMATION?</div>
          <div className="content">
            <div className="para">
              <b> In Short:</b> We process your information to provide, improve,
              and administer our Services, communicate with you, for security
              and fraud prevention, and to comply with law. We may also process
              your information for other purposes with your consent.
            </div>
            <div className="para para-bold">
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </div>
          </div>
        </div>

        <div className="paragraph" id="section3">
          <div className="heading">
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </div>
          <div className="content">
            <div className="para">
              {" "}
              <b> In Short: </b>We may share information in specific situations
              described in this section and/or with the following third parties.
            </div>
            <div className="para">
              We may need to share your personal information in the following
              situations:
            </div>
            <ul>
              <li>
                {" "}
                <b> Business Transfers.</b> We may share or transfer your
                information in connection with, or during negotiations of, any
                merger, sale of company assets, financing, or acquisition of all
                or a portion of our business to another company.
              </li>
              <li>
                {" "}
                <b> Affiliates.</b> We may share your information with our
                affiliates, in which case we will require those affiliates to
                honor this privacy notice. Affiliates include our parent company
                and any subsidiaries, joint venture partners, or other companies
                that we control or that are under common control with us.
              </li>
              <li>
                {" "}
                <b> Business Partners. </b>We may share your information with
                our business partners to offer you certain products, services,
                or promotions.
              </li>
            </ul>
          </div>
        </div>
        <div className="paragraph" id="section4">
          <div className="heading">
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </div>
          <div className="content">
            <div className="para">
              <b>In Short:</b> We may use cookies and other tracking
              technologies to collect and store your information.
            </div>
            <div className="para">
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </div>
          </div>
        </div>

        <div className="paragraph" id="section5">
          <div className="heading">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</div>
          <div className="content">
            <div className="para">
              <b> In Short: </b>If you choose to register or log in to our
              Services using a social media account, we may have access to
              certain information about you.
            </div>
            <div className="para">
              Our Services offer you the ability to register and log in using
              your third-party social media account details (like your Facebook
              or Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, and profile picture, as well as
              other information you choose to make public on such a social media
              platform.
            </div>
            <div className="para">
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use, and share your personal information, and how
              you can set your privacy preferences on their sites and apps.
            </div>
          </div>
        </div>
        <div className="paragraph" id="section6">
          <div className="heading">
            6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
          </div>
          <div className="content">
            <div className="para">
              {" "}
              <b>In Short: </b>We may transfer, store, and process your
              information in countries other than your own.
            </div>
            <div className="para">
              Our servers are located in. If you are accessing our Services from
              outside, please be aware that your information may be transferred
              to, stored, and processed by us in our facilities and by those
              third parties with whom we may share your personal information
              (see &quot;WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
              INFORMATION?&quot; above), in and other countries.
            </div>
            <div className="para">
              If you are a resident in the European Economic Area (EEA), United
              Kingdom (UK), or Switzerland, then these countries may not
              necessarily have data protection laws or other similar laws as
              comprehensive as those in your country. However, we will take all
              necessary measures to protect your personal information in
              accordance with this privacy notice and applicable law.
            </div>
          </div>
        </div>
        <div className="paragraph" id="section7">
          <div className="heading">
            7. HOW LONG DO WE KEEP YOUR INFORMATION?
          </div>
          <div className="content">
            <div className="para">
              {" "}
              <b>In Short: </b>We keep your information for as long as necessary
              to fulfill the purposes outlined in this privacy notice unless
              otherwise required by law.
            </div>
            <div className="para">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements).
            </div>
            <div className="para">
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </div>
          </div>
        </div>
        <div className="paragraph" id="section8">
          <div className="heading">
            8. DO WE COLLECT INFORMATION FROM MINORS?
          </div>
          <div className="content">
            <div className="para">
              <b> In Short: </b>We do not knowingly collect data from or market
              to children under 18 years of age.
            </div>
            <div className="para">
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at support@helpiq.app.
            </div>
          </div>
        </div>
        <div className="paragraph" id="section9">
          <div className="heading"></div>
          <div className="content">
            <div className="para">
              {" "}
              <b> In Short:</b> You may review, change, or terminate your
              account at any time.
            </div>
            <div className="para">
              Withdrawing your consent: If we are relying on your consent to
              process your personal information, which may be express and/or
              implied consent depending on the applicable law, you have the
              right to withdraw your consent at any time. You can withdraw your
              consent at any time by contacting us by using the contact details
              provided in the section &quot;HOW CAN YOU CONTACT US ABOUT THIS
              NOTICE?&quot; below.
            </div>
            <div className="para">
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </div>
            <h3 className="sub-heading">Account Information</h3>
            <div className="para">
              If you would at any time like to review or change the information
              in your account or terminate your account, you can: Upon your
              request to terminate your account, we will deactivate or delete
              your account and information from our active databases. However,
              we may retain some information in our files to prevent fraud,
              troubleshoot problems, assist with any investigations, enforce our
              legal terms and/or comply with applicable legal requirements.
            </div>
          </div>
        </div>
        <div className="paragraph" id="section10">
          <div className="heading">10. CONTROLS FOR DO-NOT-TRACK FEATURES</div>
          <div className="content">
            <div className="para">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (&quot;DNT&quot;) feature or
              setting you can activate to signal your privacy preference not to
              have data about your online browsing activities monitored and
              collected. At this stage no uniform technology standard for
              recognizing and implementing DNT signals has been finalized. As
              such, we do not currently respond to DNT browser signals or any
              other mechanism that automatically communicates your choice not to
              be tracked online. If a standard for online tracking is adopted
              that we must follow in the future, we will inform you about that
              practice in a revised version of this privacy notice.
            </div>
          </div>
        </div>
        <div className="paragraph" id="section11">
          <div className="heading"></div>
          <div className="content">
            <div className="para">
              {" "}
              <b> In Short:</b> Yes, we will update this notice as necessary to
              stay compliant with relevant laws.
            </div>
            <div className="para">
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated &quot;Revised&quot; date
              and the updated version will be effective as soon as it is
              accessible. If we make material changes to this privacy notice, we
              may notify you either by prominently posting a notice of such
              changes or by directly sending you a notification. We encourage
              you to review this privacy notice frequently to be informed of how
              we are protecting your information.
            </div>
          </div>
        </div>
        <div className="paragraph" id="section12">
          <div className="heading">
            12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </div>
          <div className="content">
            <div className="para">
              If you have questions or comments about this notice, you may
              contact us by email at support@helpiq.app
            </div>
          </div>
        </div>
        <div className="paragraph" id="section13">
          <div className="heading">
            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </div>
          <div className="content">
            <div className="para">
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it. To request to
              review, update, or delete your personal information,
            </div>
          </div>
        </div>
      </div>
    </TermsAndConditionStyles>
  );
};

// In Short: If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.

// Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.

// We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.

// 6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?

// In Short: We may transfer, store, and process your information in countries other than your own.

// Our servers are located in. If you are accessing our Services from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" above), in and other countries.

// If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.

// 7. HOW LONG DO WE KEEP YOUR INFORMATION?

// In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.

// We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).

// When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.

// 8. DO WE COLLECT INFORMATION FROM MINORS?

// In Short: We do not knowingly collect data from or market to children under 18 years of age.

// We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at __________.

// 9. WHAT ARE YOUR PRIVACY RIGHTS?

// In Short:  You may review, change, or terminate your account at any time.

// Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.

// However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.

// Account Information

// If you would at any time like to review or change the information in your account or terminate your account, you can:
// Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.

// 10. CONTROLS FOR DO-NOT-TRACK FEATURES

// Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.

// 11. DO WE MAKE UPDATES TO THIS NOTICE?

// In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.

// We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.

export default PrivacyAndPolicy;
