import React from "react";
import HeaderStyles from "../../../assets/styledComponents/Header";
import ActionButtonGroup from "../ActionButtonGroup/ActionButtonGroup";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { Button, CircularProgress, IconButton } from "@mui/material";
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';

const HeaderBar = ({
  title,
  handlePrimaryButtonClick,
  handleSecondaryButtonClick,
  secondaryButtonLabel,
  primaryButtonLabel,
  hidePrimaryButton = false,
  primaryButtonDisable = false,
  secondaryButtonDisable = false,
  hideSecondaryButton = false,
  primaryButtonLoading,
  showDescription = false,
  description = "",
  thirdButton,
  fourthButton,
  fourthButtonLoading = false,
  thirdButtonLoading = false,
  handleFourthButtonClick,
  hideFourthButton = true,
  handleThirdButtonClick,
  hideThirdButton = true,
  showIcon, 
  primaryButtonType,
  secondaryButtonType,
  handleIconClick, 
  paperHeader = false,
  fifthButton
}) => {
  return (
    <HeaderStyles>
      <div className={`${paperHeader  ? 'paper-header' :  'non-paper-header'}`}>
        <div className="top">
          <div>
            <h3 className="top-heading">{title}</h3>
            {showDescription && <p className="top-desc">{description}</p>}
          </div>
        
          <div className="button-container">
            {fifthButton && fifthButton }
          {showIcon && ( 
              <IconButton className="icon-container" onClick={handleIconClick}>
                <QuestionMarkOutlinedIcon />
              </IconButton>
            )}
            {!hideThirdButton && (
              <Button variant="outlined" onClick={handleThirdButtonClick}>
                {thirdButtonLoading ? (
                  <div className="loading-icon">
                    <CircularProgress color="inherit" size={25} />
                  </div>
                ) : (
                  thirdButton
                )}
              </Button>
            )}
            {!hideFourthButton && (
              <Button variant="outlined" onClick={handleFourthButtonClick}>
                {fourthButtonLoading ? (
                  <div className="loading-icon">
                    <CircularProgress color="inherit" size={25} />
                  </div>
                ) : (
                  fourthButton
                )}
              </Button>
            )}


            <ActionButtonGroup
              primaryButtonLabel={primaryButtonLabel}
              secondaryButtonLabel={secondaryButtonLabel}
              handlePrimaryButtonClick={handlePrimaryButtonClick}
              handleSecondaryButtonClick={handleSecondaryButtonClick}
              primaryButtonDisable={primaryButtonDisable}
              secondaryButtonDisable={secondaryButtonDisable}
              hidePrimaryButton={hidePrimaryButton}
              hideSecondaryButton={hideSecondaryButton}
              primaryButtonLoading={primaryButtonLoading}
              primaryButtonType={primaryButtonType}
              secondaryButtonType={secondaryButtonType}
            />
          </div>
        </div>
        <div className="underline"></div>
      </div>
    </HeaderStyles>
  );
};

export default HeaderBar;
