import styled from "styled-components";
const RegisterStyles = styled.div`
  .container {
    display: flex;
  }

  .link {
    display: inline-block;
  }
  .logo {
    display: flex;
    align-items: center;
    // margin-top: 10px;
    // margin-left: 20px;
    top: 10px;
    left: 40px;
    position: absolute;

  }
  .register-img {
    margin-left: 1px;
    // width: 750px;
    // height: 650px;
    padding-bottom:20px
    
  }
  .logo img {
    width: 170px;
    height: 70px;
  }

  .left {
    margin-left: 20px;
    width: 45%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

  }

  .right {
    width: 50%;
    margin-left: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .right-container {
    width: 500px;
    margin: 0 auto;
    margin-top: 20px;
  }
  .sign-up-title {
    font-size: 30px;
    font-weight: 500;
  }

  .welcome-msg {
    font-size: 20px;
    font-weight: 300;
    margin: 15 0px;
  }

  .password-error {
    margin-top: 1rem;
    width: 400px;
  }
  .password-error ul {
    font-size: 0.8rem;
    display: flex;
    flex-wrap: wrap;
  }
  .list {
    display: flex;
    align-items: center;
    width: 180px;
  }

  .list:last-child {
    width: 300px;
  }
  .list li {
    margin-right: 1rem;
  }
  .terms-and-condition {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  .login-user {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  a {
    color: #504bd0;
  }
  .error {
    color: var(--danger);
    text-align: left;
    margin-left: .25rem;
    margin-bottom: 0.25rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
  @media (max-width: 400px) {
    display: block;

    .register-img {
      display: none;
    }
    .logo img {
      height: 30px;
      width: 80px;
      padding-left: -30px;
      padding-top: -70px;
    }
    .sign-up-title {
      margin-left: -59px;
      margin-bottom: -19px;
      margin-top: 50px;
    }
    .welcome-msg {
      margin-left: -115px;
      font-size: 14px;
      font-weight: 300;
      margin-top: 10px;
    }
    .text1 {
      width: 300px;
      padding-right: 50px;
      margin-left: -112px;
    }
    .button1 {
      width: 300px;
      padding-right: 50px;
      margin-left: -112px;
    }
    .login-user {
      width: 300px;
      padding-right: 50px;
      margin-left: -100px;
    }
    .terms-and-condition {
      width: 300px;
      padding-right: 50px;
      margin-left: -100px;
    }
  }
`;
export default RegisterStyles;
//for image what css remove
//display none but form shou
