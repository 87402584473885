import { TextField } from "@mui/material";
import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import styled from "styled-components";

const BigInputFieldStyles = styled.div`
  .wrapper {
    width: ${(props) => props.width}px;
    margin-top: 15px;
    margin-left: 1px;
    gap: 1rem;
    border: 1px solid ${(props) => (props.error ? "red" : "#8a909")};
    border-radius: ${(props) => (props.error ? "8px" : "")};
  }
  .error {
  }
  .error-text {
    color: #d32f2f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
  }
  .container {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    border: 0.5px solid #8a9099;
  }
  .container:hover {
    border-radius: 8px;
    box-shadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px";
  }
  .icons-container {
    color: #666666;
    border-top: 0.5px solid #666666;
    padding: 0.5rem 1.5rem;
  }
  .icons-container span {
    display: flex;
    gap: 1rem;
    cursor: pointer;
  }
`;

const BigInputField = ({
  children,
  width,
  row,
  placeholder,
  name,
  value,
  handleChange,
  error,
  helperText,
  handleBlur,
}) => {
  return (
    <BigInputFieldStyles width={width} error={error}>
      <div className="wrapper">
        <div className="container ">
          <TextField
            sx={{
              width: { width },
              "& fieldset": { border: "none" },
            }}
            multiline
            placeholder={placeholder}
            rows={row}
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error}
          />
          <div className="icons-container">
            <span>{children}</span>
          </div>
        </div>
      </div>
      <p className="error-text">{helperText}</p>
    </BigInputFieldStyles>
  );
};

export default BigInputField;
