import React, { useState } from "react";
import { CardContent } from "@mui/material";
import Card from "@mui/material/Card";
import { getKey } from "../../utils/helperFunction";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import CustomInputDropDown from "../CustomInputDropDown";

import ReportCardDetailStyles from "../../assets/styledComponents/settings/ReportCard";

const useStyles = makeStyles({
  customCard: {
    // border: "1px solid #C2C2C2 !important",
    // boxShadow: "0px 1px 7px 0px #00000040",
    // margin: "30px 40px 0px 35px",
    // height: "97px",
    // borderRadius: "5px !important",
    // backgroundColor : 'red !important',
  },
  coloredIcon: {
    marginLeft: 1,
  },
  customButton: {
    color: "#FFFFFF !important",
  },
  customTitle: {
    marginLeft: 10,
    fontSize: 16,
  },
  customArrow: {
    color: "#5A7B47",
    height: 10,
    marginLeft: 7,
    marginTop: 2,
  },
  customText: {
    marginTop: 5,
  },
});

const ReportCard = ({ name, icon, time1, time2, time3, unit }) => {
  const classes = useStyles();

  return (
    <ReportCardDetailStyles>
      <div className="card">
        <div className="head-section">
          <span className={classes.coloredIcon}>{icon}</span>
          <span className={classes.customTitle}> {name}</span>
        </div>
        <div className="number">
          {" "}
          <span className="time1">
            {time1} <span>{unit || ""}</span>
          </span>
          <span className="container"></span>
        </div>
      </div>
    </ReportCardDetailStyles>
  );
};
export default ReportCard;
