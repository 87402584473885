import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

const ChannelIcon = ({ channelName }) => {
  const messageIcon =
    channelName === "instagram" ? (
      <InstagramIcon sx={{ color: '#E4405F' }} />
    ) : channelName === "mail" ? (
      <MailOutlineOutlinedIcon />
    ) : channelName === "whatsapp" ? (
      <WhatsAppIcon sx={{ color: "green" }} />
    ) : channelName === "facebook" ? (
      <FacebookIcon sx={{ color: '#316FF6' }} />
    ) : (
      <ChatBubbleOutlineIcon />
    );

  return messageIcon;
};

export default ChannelIcon;
