import { TableCell, TableRow } from "@mui/material";
import React from "react";
import Skeleton from '@mui/material/Skeleton';

const TableSkeleton = () => {
  return (
    <TableRow >
      <TableCell component="th" scope="row">
        <Skeleton animation="wave" variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="text" />
      </TableCell>
    </TableRow>
  );
};

export default TableSkeleton;
