import React from "react";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "../../hooks/useDebounce";

const CustomSearchBoxStyles = styled.div`
  input {
    all: unset;
    border-radius: 0.5rem;
    width: 75%;
    padding: 0.5rem;
    background-color: #d9d9d9;
    border: none;
    padding-left: 3rem;
    padding-top: 0.6rem;
  }
  .search-container {
    position: relative;
  }
  .search-btn {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 12px;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
`;

const CustomSearchBox = ({ handleSearch, placeholder, value }) => {
  // const [debouncedValue] = useDebounce(handleSearch, 500);
  return (
    <CustomSearchBoxStyles>
      <div className="search-container">
        <input
          className="search-box"
          onChange={handleSearch}
          type="search"
          placeholder={placeholder}
          value={value}
        />
        <div className="search-btn">
          <SearchIcon />
        </div>
      </div>
    </CustomSearchBoxStyles>
  );
};

export default CustomSearchBox;
