import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import UserProfileStyles from "../../assets/styledComponents/settings/UserProfile";
import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import InviteTeamMember from "./InviteTeamMember";
import { deleteUserDetail, getAllUserSetup } from "../../service/service";
import userProfile from "../../assets/images/userProfile.png";
import { getKey, stringAvatar } from "../../utils/helperFunction";
import { useTenantContext } from "../../context/TenantContext";
import FullPageSpinner from "../FullPageSpinner";
import Avatar from "@mui/material/Avatar";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import HeaderBar from "../reusable/HeaderBar/HeaderBar";

const UserProfile = () => {
  const { tenantId, profile } = useTenantContext();

  const [showInviteUser, setShowInviteUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editUserDetail, setEditUserDetail] = useState({});
  const [userDetails, setUserDetails] = useState([]);
  const [snackMessage, setSnackMessage] = useState({ msg: "", type: "error" });

  const fetchUserSetup = async () => {
    try {
      const result = await getAllUserSetup({ tenantId });
      setUserDetails(result.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserSetup();
  }, [showInviteUser]);

  const handleCancelUser = () => {
    setEditUserDetail({});
    setShowInviteUser(false);
  };

  if (showInviteUser) {
    return (
      <InviteTeamMember
        setShowInviteUser={setShowInviteUser}
        editUserDetail={editUserDetail}
        setEditUserDetail={setEditUserDetail}
        handleCancelUser={handleCancelUser}
      />
    );
  }

  const handleEdit = (userDetail) => {
    setShowInviteUser(true);
    setEditUserDetail(userDetail);
  };

  const handleDelete = async (userId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Once you delete this user, you will not be able to revert the changes.",
        icon: "warning",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#8db178",
        cancelButtonColor: "#D9D9D9",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        setIsLoading(true);
        const deletedDetails = await deleteUserDetail({ userId });
        fetchUserSetup();

        Swal.fire({
          title: "Deleted!",
          text: "The user has been deleted.",
          icon: "success",
          confirmButtonColor: "#8db178",
        });
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  // function stringAvatar(name) {
  //   return {
  //     children: `${name.split(" ")[0][0]}`,
  //   };
  // }

  const handleInviteUserClick = (e) => {
    e?.preventDefault();

    if (profile?.token?.pricingPlan?.numberOfAgents <= userDetails.length) {
      setSnackMessage({
        msg: `Your plan reach the agent limit. Please upgrade`,
        type: "error",
      });
      return;
      // setInviteForm({ ...initialFormState });
      // setEditUserDetail({});
      // setTimeout(() => {
      //   setShowInviteUser(false);
      // }, 1000);
    }
    setShowInviteUser(!showInviteUser);
  };

  return (
    <UserProfileStyles>
      {snackMessage.msg && <CustomSnackbar payload={snackMessage} />}
      <HeaderBar
        title={"User Setup"}
        primaryButtonLabel={"Invite User"}
        handlePrimaryButtonClick={handleInviteUserClick}
        hideSecondaryButton={true}
      />
      {isLoading ? (
        <FullPageSpinner />
      ) : (
        <div className="table-container">
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <h3 className="table-cell">Profile</h3>
                  </TableCell>
                  <TableCell align="left">
                    <h3 className="table-cell">Role</h3>
                  </TableCell>
                  <TableCell align="left">
                    <h3 className="table-cell">Status</h3>
                  </TableCell>
                  <TableCell align="left">
                    <h3 className="table-cell">Action</h3>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userDetails.map((userDetail, idx) => {
                  const {
                    _id,
                    name,
                    email,
                    role,
                    activeStatus,
                    profileImageUrl,
                  } = userDetail;
                  return (
                    <TableRow key={getKey(name, "userDetails", idx)}>
                      <TableCell component="th" scope="row">
                        <div className="profile" >
                          {profileImageUrl ? (
                            <img
                              src={profileImageUrl}
                              alt=""
                              width={60}
                              height={60}
                              className="preview-img"
                            />
                          ) : (
                            <Avatar {...stringAvatar(name)}style={{ width: "60px", height: "60px" }} ></Avatar>
                          )}
                          <div className="name">
                            <h4>{name}</h4>
                            <p>{email}</p>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <h4 className="items">{role}</h4>
                      </TableCell>
                      <TableCell align="left">
                        <h4 className="items">{activeStatus}</h4>
                      </TableCell>
                      <TableCell align="left">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          {role !== "Owner" && (
                            <Button
                              style={{ color: "black" }}
                              onClick={() => handleEdit(userDetail)}
                            >
                              <ModeEditOutlineOutlinedIcon />
                            </Button>
                          )}
                          {role !== "Owner" && (
                            <Button style={{ color: "black" }}>
                              <DeleteOutlinedIcon
                                onClick={() => handleDelete(_id)}
                              />
                            </Button>
                          )}
                          {role !== "Owner" && false && (
                            <Button variant="outlined">Reset Password</Button>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </UserProfileStyles>
  );
};

export default UserProfile;
