import React, { useState } from "react";
import { Button } from "@mui/material";
import Modal from "../../reusable/DialogModal/Modal";
import CustomTextField from "../../reusable/CustomTextField";
import PrimaryButton from "../../reusable/PrimaryButton/PrimaryButton";
import { createFaQTraining, updateFaQ } from "../../../service/service";
import CustomSnackbar from "../../snackbar/CustomSnackbar";

const FaqModal = ({ openModal, setOpenModal, shopName, tenantId, refreshTable , question, setQuestion, answer, setAnswer, isEdit, faqId}) => {
    // const [question, setQuestion] = useState("");
    // const [answer, setAnswer] = useState("");
    const [errors, setErrors] = useState({
      question: "",
      answer: "",
    });
    const [snackMessage, setSnackMessage] = useState({ msg: "", type: "error" });
    const [disableSave, setDisableSave] = useState(false)


    const resetValues = () => {
        setErrors({
            question: "",
            answer: "",
        })
        setAnswer("");
        setQuestion("");
        setDisableSave(false)
    }
  
    const handleEditSubmit = async () => {
        const newErrors = { ...errors };
        let isDirty = false;
    
        if (!question) {
          newErrors.question = "Question is required";
          isDirty = true;
        }
    
        if (!answer) {
          newErrors.answer = "Answer is required";
          isDirty = true;
        }
    
        if (isDirty) {
          setErrors(newErrors);
          return;
        }
        if (!isDirty) {
          setDisableSave(true)
          try {
            let updatedFAQItem;
            if (isEdit) {
              updatedFAQItem = await updateFaQ({
              tenantId,
              id : faqId,
              question: question,
              answer: answer,
              store: shopName,
            });
            }else {
              updatedFAQItem = await createFaQTraining({
              tenantId,
              question: question,
              answer: answer,
              store: shopName,
            });
            }

            resetValues();
            if (updatedFAQItem.success) {
               if (isEdit) {
                setSnackMessage({
                    msg: `FAQ updated successfully for ${shopName}`,
                    type: "success",
                  });
               }else {
                setSnackMessage({
                    msg: `FAQ Added successfully for ${shopName}`,
                    type: "success",
                  });
               }
                refreshTable();
            }
            setOpenModal(false);
          } catch (error) {
            console.error("Error editing FAQ item:", error);
            setSnackMessage({
                msg: `Something went wrong`,
                type: "error",
              });
            setDisableSave(false) 
          }
        }
    };

    const handleClose = () => {
        resetValues();
        setOpenModal(false);
    }

    const handleChange = (e) => {
        if (e.target.name === "question") {
          setQuestion(e.target.value);
          setErrors((prev) => ({ ...prev, question: "" }));
        } else if (e.target.name === "answer") {
          setAnswer(e.target.value);
          setErrors((prev) => ({ ...prev, answer: "" }));
        }
    }

      
  return (
    <>
          {snackMessage.msg && <CustomSnackbar payload={snackMessage} />}

        <Modal
      name="FAQ Training"
      open={openModal}
      onClose={() => handleClose()}
    >
      <div
        style={{
          width: "500px",
          height: "auto",
          marginLeft: "12px",
          marginRight: "20px",
          padding: "10px",
          overflow: "auto",
        }}
      >
        <div>
          <h4 style={{ marginTop: "10px" }}> Question</h4>
          {/* <span style={{ fontSize: "13px", color: "Gray" }}>
            Edit the Question Content
          </span> */}
          <CustomTextField
            variant="outlined"
            value={question}
            name="question"
            onChange={ handleChange}
            error={Boolean(errors.question)}
            helperText={errors.question}
            sx={{
              marginTop: "10px",
              width: "100%",
            }}
          />
        </div>
        <h4 style={{ marginTop: "10px" }}>Answer</h4>
        {/* <span style={{ fontSize: "13px", color: "Gray" }}>
          Edit the Answer Content
        </span> */}
        <CustomTextField
          variant="outlined"
          multiline
          name="answer"
          rows={8}
          value={answer}
          onChange={ handleChange}
          error={Boolean(errors.answer)}
          helperText={errors.answer}
          sx={{
            marginTop: "10px",
            width: "100%",
          }}
        />
        <div style={{ marginTop: "20px" }}>
          <PrimaryButton
            variant={"contained"}
            style={{ marginBottom: "10px" }}
            disabled={disableSave}
            onClick={() => handleEditSubmit()}
            // disabled={!isFormValid}
          >
           {isEdit ? "Update" : "Save"} 
          </PrimaryButton>
          <Button
            variant={"outlined"}
            color="primary"
            style={{
              marginLeft: "10px",
              marginBottom: "10px",
            }}
            onClick={() => handleClose()}
          >
            <p>Cancel</p>
          </Button>
        </div>
      </div>
    </Modal>
    </> 

  );
};

export default FaqModal;
