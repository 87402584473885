import styled from "styled-components";
const QuickReplyModalStyles = styled.div`
  .wrapper {
    width: 550px;
    height: 50vh;
    display: flex;
  }
  .list-container {
    width: 40%;
    padding: 1rem;
    padding-right: 0;
  }
  .list {
    height: 35vh;
    overflow-y: auto;
    margin-top: 1rem;
  }
  .template-container {
    width: 60%;
    border-left: 1px solid var(--border-clr);
  }
  .template-msg-container {
    margin: 0 auto;
    width: 90%;
    height: 70%;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: pre-wrap;
  }
  .active {
    background-color: var(--primary-100);
    border-radius: 0.2rem;
  }
  .list-name h2 {
    padding-left: 10px;
    font-size: 15px;
    font-weight: 430;
    padding: 0.5rem 0.25rem;
    cursor : pointer;
  }
  .template-container {
    position: relative;
  }

  .template-container .heading {
    font-size: 15px;
    font-weight: 500;
    padding: 1rem;
  }
  .btn-container {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
  .search-container{
    padding-right: 1rem;
  }
`;
export default QuickReplyModalStyles;
