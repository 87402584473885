import _ from "lodash";
import { useEffect, useState } from "react";

const useDebounce = (value, delay) => {
  const [debounceValue, setDebounceValue] = useState('');

  useEffect(() => {
    const debouncedSearch = _.debounce(() => {
      setDebounceValue(value);
    }, delay);
    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [value, delay]);

  return [debounceValue];
};

export default useDebounce;
