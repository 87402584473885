import React, { useState } from 'react'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { likeComment } from '../../service/service';
import { useInboxContext } from '../../context/inboxContext/inboxContext';
const Like = ({isLiked,commentId, messageId  }) => {
  const [like ,setLike] = useState(isLiked)
  const {currentSessionData} = useInboxContext()
  const shop = currentSessionData.storeName
  console.log({shop})
  const handleLike = async() => {
    try {
        setLike(!like)
        const result = await likeComment({commentId , shop , isLiked : like, messageId})
    } catch (error) {
        console.log(error)
    }
    
  }
  return (
    <button onClick={handleLike} style={{cursor : 'pointer'}} >
    {like ? <FavoriteIcon sx={{color : '#eb3223'}}/> : <FavoriteBorderIcon />}
    </button>
  )
}

export default Like