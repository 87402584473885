import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Register from "../pages/Register";
import Login from "../pages/Login";
import VerifyOtp from "../pages/VerifyOtp";
import NewVerifyOtp from "../pages/UserOnboarding/NewVerifyOtp";
import VerifySubscription from "../pages/VerifySubscription";
import NonAuthenticated from "../pages/NonAuthenticated";
import ForgotPassword from "../pages/ForgotPassword";
import TermsAndCondition from "../pages/TermsAndCondition";
import PrivacyAndPolicy from "../pages/PrivacyAndPolicy";

const PublicRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword/>} />
        <Route path="/non-authenticated" element={<NonAuthenticated />} />
        <Route path="/verifyOtp" element={<NewVerifyOtp />} />
        <Route path="/verifyOtp" element={<NewVerifyOtp />} />
        <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
        <Route path="/privacy-and-policy" element={<PrivacyAndPolicy />} />
        <Route
          path="/verifySubscription/:tenantId/:pricingType"
          element={<VerifySubscription />}
        />
        <Route
          path="/verifySubscription/:tenantId"
          element={<VerifySubscription />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default PublicRoute;
