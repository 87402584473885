import React from 'react'
import logo from '../../../assets/images/logo.png'
import Logo from '../../Logo'

const TermsAndConditionHeader = ({heading}) => {
  return (
    <div className="header">
        {/* <img className="logo"  src="https://helpiq.s3.us-east-2.amazonaws.com/helpIqLogo.png" /> */}

        <div className="logo-container">
          <img width={60} height={60} src={logo} alt="" />
          <Logo/>
        </div>
        <h1>{heading}</h1>
      </div>
  )
}

export default TermsAndConditionHeader