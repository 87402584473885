import React from "react";
import styled from "styled-components";

const OrderContainer = () => {
  return (
    <StyledOrderContainer>
      <div className="order-details-page">
        <div className="order-number-row">
          <span className="order-id-element">Order Id #1052</span>
          <span className="fulfilled-element">FullFilled</span>
          <span className="financial-element">Paid</span>
        </div>
        <div className="total-row">
          <span>21 Nov, 23</span>
          <span className="dot"></span>
          <span>INR 2866.655</span>
          <span className="dot"></span>
          <span>1 Items</span>
        </div>
        <div className="order-items-details-block">
          <div className="head-line">Order Details</div>
          <div className="product-block">
            <div>The Collection Snowboard: Hydrogen</div>
            <div>
              <span className="item-name">Quantity: </span>1
            </div>
            <div>
              <span className="item-name">Amount: </span>INR 600.00
            </div>
          </div>
        </div>
      </div>
    </StyledOrderContainer>
  );
};

const StyledOrderContainer = styled.div`
  clear: both;
  width: 80%;
  padding: 0.5rem;
  float: right;
  background: white;
  border-radius: 10px;
  border: 1px solid rgb(241, 241, 241);
  margin: 10px;
  box-shadow: rgb(208, 204, 235) 0px 0px 1rem -0.5rem;

  .order-details-page {
    padding: 10px;
  }

  .order-number-row {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .order-id-element {
    font-size: 14px;
    font-weight: 450;
  }

  .fulfilled-element {
    background-color: yellow;
    padding: 4px 12px;
    border-radius: 20px;
    text-transform: capitalize;
    font-size: 12px;
  }

  .financial-element {
    background-color: #c3d6b8;
    padding: 4px 12px;
    border-radius: 20px;
    text-transform: capitalize;
    font-size: 12px;
  }

  .total-row {
    color: gray;
    margin: 4px 0px;
    font-size: 12px;
  }

  .dot {
    background: gray;
    padding: 1.5px;
    font-size: px;
    line-height: 18px;
    border-radius: 50%;
    margin: 2px;
    display: inline-block;
  }

  .order-items-details-block {
    margin: 10px 0px;
  }

  .head-line {
    font-weight: bold;
  }

  .product-block {
    margin-top: 10px;
  }

  .item-name {
    color: gray;
  }
`;

export default OrderContainer;
