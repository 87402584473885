import React, { useEffect, useRef, useState } from "react";
import EmailSignatureStyles from "../../assets/styledComponents/settings/EmailSignature";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import StorefrontIcon from "@mui/icons-material/Storefront";
import BigInputField from "../BigInputField";
import { emailSignatureButtonIcons } from "../../utils/helperData";
import LinkModal from "./LinkModal";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import MarkDownInputField from "../reusable/MarkDownInputField";
import TextEditor from "../reusable/TextEditor";
import CustomTextField from "../reusable/CustomTextField";
import StoreTabs from "../reusable/StoreTabs";
import { createEmailSignature, getAllTenantStore } from "../../service/service";
import { useTenantContext } from "../../context/TenantContext";
import EmailSignatureEditor from "./EmailSignatureEditor";
import useFetch from "../../hooks/useFetch";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import FullPageSpinner from "../FullPageSpinner";
import _ from "lodash";
let toolbarOptions = [
  ["image"],
  ["bold"],
  ["italic"],
  ["underline"],
  ["emoji"],
];

const storeList = [
  { label: "example.mywork.shopify.com", value: "1" },
  { label: "example.mywork.shopify.com", value: "2" },
  { label: "example.mywork.shopify.com", value: "3" },
];

const initialState = {signatureName : '', personalizeSignature : '', textEditorValue : ''}
const initialError= {signatureName : '', personalizeSignature : '', textEditorValue : ''}
const EmailSignature = () => {
  const {tenantId} = useTenantContext()
  const [value, setValue] = useState([{...initialState} ]);
  const [initialValue, setInitialValue] = useState([{...initialState}])
  const [error, setError] = useState([{...initialError}])
  const [signatureValue, setSignatureValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [storeTabs, setStoreTabs] = useState([]);
  const currentStore = storeTabs[activeTab]?.shop
  const [imageUploading, setImageUploading] = useState(false)

  const fetchStores = async (tab) => {
    setLoading(true);
    try {
      if (!tenantId) return;
      const tabsRes = await getAllTenantStore(tenantId);
      setLoading(false);
      if (tabsRes.success) {
        setStoreTabs(tabsRes.data);
        let error = []
        let val = tabsRes.data.map((store) => {
          error.push({...initialError})
          return {...store?.emailSignature , textEditorValue   : store?.emailSignature?.personalizeSignature || '', signatureName : store?.emailSignature?.signatureName|| ''};
        });
        setError(error)
        setValue(val);
        const newValue = structuredClone(val)
        setInitialValue(newValue);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const isValueEqual = _.isEqual(initialValue , value)

  useEffect(() => {
    fetchStores();
  }, []);

  const updateError = (tabIndex, key, value) => {
    setError((prev) => {
      let updatedValue = [...prev]
      updatedValue[tabIndex][key] = value
      return updatedValue
    })
  }
  const updateValue = (tabIndex, key, value) => {
    setValue((prev) => {
      let updatedValue = [...prev]
      updatedValue[tabIndex][key] = value
      return updatedValue
    })
  }
  const handleChange = (e) => {
    const {name, value} = e.target
    if (name === 'signatureName') {
      updateError(activeTab, 'signatureName', '')
      updateValue(activeTab, name, value)
    }
  }
  const handleTemplateChange = ({html}) => {
    updateValue(activeTab, 'personalizeSignature', html)
    updateError(activeTab, 'textEditorValue', '')
  }
  const [ createSignature, signatureLoading, signatureResult, signatureBackEndMessage]  = useFetch(createEmailSignature)

  const activeValue = value[activeTab]
  const activeError = error[activeTab]

  const isUpdate = activeValue._id
  const signatureName = value[activeTab].signatureName;
  const personalizeSignature= value[activeTab].personalizeSignature
  const textEditorValue = value[activeTab].textEditorValue

  const signatureError = activeError.signatureName 
  const personalizeSignatureError = activeError.personalizeSignature;
  const textEditorValueError = activeError.textEditorValue;


  const handleSave = async () => {
    if (!signatureName) {
      updateError(activeTab, 'signatureName', 'Please enter signature name')
      return 
    }
    if ( !personalizeSignature) {
      updateError(activeTab, 'textEditorValue', 'Please enter Personalize Signature')
      return 
    }
    try {
      const result = await createSignature({shopName : currentStore, tenantId, ...value[activeTab], value : structuredClone(value)});
      updateValue(activeTab, 'textEditorValue', value[activeTab].personalizeSignature)
      setInitialValue(prevData => {
      const updatedData = [...prevData];
      if (activeTab>= 0 && activeTab< updatedData.length) {
        updatedData[activeTab].textEditorValue = result.data.emailSignature.personalizeSignature;
        updatedData[activeTab].personalizeSignature= result.data.emailSignature.personalizeSignature;
        updatedData[activeTab].signatureName= result.data.emailSignature.signatureName;
      }
      return updatedData;
    });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
  },[signatureLoading])


  
  return (
    <EmailSignatureStyles>
      {signatureBackEndMessage.msg ? <CustomSnackbar payload={signatureBackEndMessage} /> : ""}
      {loading ? <FullPageSpinner/>  :
      <div className="wrapper">
        <div className="start">
          <div className="top">
            <div className="this">
              <p>Email Signature</p>
            </div>
          </div>
          <div className="underline"></div>

          <div className="section1">
            <div className="store-tabs">
              <StoreTabs
                storeTabs={storeTabs}
                setStoreTabs={setStoreTabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setLoading={setLoading}
                setValue={setValue}
                setError={setError}
                initialError={initialError}
              />
            </div>
            <div className="container">


            <h2>Signature name</h2>
            <CustomTextField
              sx={{
                width: "50%", 
                boxShadow: 7,
                borderRadius: "10px",
                marginTop: 2,
              }}
              name="signatureName"
              value={signatureName}
              onChange={handleChange}
              placeholder={"Formal"}
              error={!!signatureError}
              helperText={signatureError}
            />
            <div className="third">
              <h2>Personalize Signature</h2>
            </div>
            <div className="signature-textBox">
              <EmailSignatureEditor 
                onChange={handleTemplateChange}
                width={'51.8%'}
                height={300}
                value={textEditorValue}
                toolbarOptions={toolbarOptions}
                loading={imageUploading}
                setLoading={setImageUploading}
                />
            {textEditorValueError && (
              <label className="error-label">{textEditorValueError}</label>
            )}
              </div>

            <div className="save-button">
              <Button
                variant="contained"
                style={{ color: "white", padding: ".7rem 3rem" }}
                sx={{ m: 0.5 }}
                disabled={signatureLoading || isValueEqual || imageUploading }
                onClick={handleSave}
              >
                {isUpdate  ? "Update" : "Save"} 
              </Button>
            </div>
          </div>

            </div>
        </div>
      </div>
      }
    </EmailSignatureStyles>
  );
};
export default EmailSignature;
