import React from "react";

function HighlightWords({ text, phrasesToHighlight }) {
  const highlighWords = phrasesToHighlight.map((item) => "@" + item);
  const regex = new RegExp(`(${highlighWords.join("|")})`, "gi");

  const segments = text.split(regex);
  if (phrasesToHighlight.length === 0) return <div>{text}</div>;

  console.log({ highlighWords, phrasesToHighlight });
  return <div style={{ display: "flex", flexDirection: "row" }}>
    {segments.map((segment, index) => {
      if (highlighWords.includes(segment)) {
        return (
          <span key={`Highlight Words-${index + 1}`} style={{ color: "blue" }}>
            {segment}
            &nbsp;
          </span>
        );
      }
      return <span key={`Highlight Words-${index + 1}`} style={{ width: 'fit-content' }}>{segment}</span>

    })}
  </div> 
}



export default HighlightWords;
