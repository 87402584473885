import { TextField } from "@material-ui/core";
import { Button } from "@mui/material";
import React from "react";
import SendIcon from "@mui/icons-material/Send";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
const InputField = ({placeholder, setQuestion, question, handleSubmit, disabled}) => {
  const handleChange = (e) => {
    setQuestion(e.target.value);
  }
 const handleKeyDown = (e) => {
   if (disabled) return;
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };
  return (
    <div className="input-wrapper">
      <div className="input-container">
        <input onChange={handleChange} onKeyDown={handleKeyDown} value={question} className="input" placeholder={placeholder}/>
        <div className="send-btn" onClick={handleSubmit}>
          <button disabled={disabled} >
            <SendOutlinedIcon  />
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputField;
