import { useTenantContext } from "../../context/TenantContext";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import { sendMessage } from "../../service/service";

const useMessageInput = () => {
  const { userId, tenantId } = useTenantContext()
  const { sessionId } = useInboxContext()

  const getChannelName = async (channelName, text) => {
    if (channelName === 'chat') {

      // if (attachmentFile) {
      //   // uploadAttachmentFile = await uploadToS3(attachmentFile);
      //   await uploadFile();
      // }
      const result = await sendMessage({
        chatSession: sessionId,
        userId,
        tenantId,
        content: text,
        sender: "agent",
        // image: uploadedImage,
        // attachment: uploadAttachmentFile,
        // productDetails: imageLinkDetails,
        // isPrivate: isMessagePrivate,
        // taggedUser: taggedUserDetails.map((item) => item._id),
        // taggedUserName: taggedUserDetails.map((item) => item.name),
      });
    }
  }



  return [getChannelName]



}
export default useMessageInput 
