import styled from "styled-components";
const AutoReplyStyles = styled.div`
  /* width: 81vw; */

  .height {
    height: 86vh;
    overflow-y: auto;
  }

  .auto-reply-container {
    margin-right: 40px;
    margin-left: 40px;
  }

  .auto-reply-container h2 {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 2px;
  }
  .auto-reply-field-container {
    margin-top: 30px;
  }

  .custom-days-title { 
    float: left;
  }
  .custom-days-title p{
    color:#81848A
  }
  .custom-days-switch {
    float: right;
  }

  .custom-days-list {
    clear: both;
  }

  .custom-days-list .days {
    float: left;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 8px;
    color:#666666;
    font-size:14px;
    font-weight:500
  }
  .save-button {
    clear: both;
    float: right;
    margin-right:20px
  }
  .scroll-div {
    overflow-y: scroll;
    height: 80vh;
    padding-bottom: 8rem;
  }

`;
export default AutoReplyStyles;
