import styled from "styled-components";
const MessagePreviewStyles = styled.div`
  .message-preview-container {
    margin: 0;
    padding: 15px 10px;
    padding-right: 0;
    display: flex;
    gap: 0.5rem;
    padding-bottom: 2px !important;
  }
  .message-content {
    width: 80%;
  }
  .star-icon {
   height:20px;
   width:20px
  }
  border-bottom: 1px solid var(--border-clr);
  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .icons {
    display: flex;
    gap: 0.5rem;
  }
  .icons-container {
    margin-left: 0.5rem;
    margin-top: 0.25rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    /* width: 50%; */
  }

  .ticket-id {
    font-size: 12px;
    color: #666;
    font-weight: 400;
  }
  .name {
    margin-left: -1rem;
    font-weight: 400;
  }
  // .bottom {
  //   display: flex;
  //   align-items: center;
  //   width: 100%;
  //   margin-bottom: 0.5rem;
  //   // padding-left: 2rem;
  //   // padding-right: 0.5rem;
  // }
  // .bottom:first-child {
  //   margin-right: auto;
  // }
  // .bottom:last-child {
  //   margin-left: auto;
  // }
  // .label {
  //   font-size: 10px;
  //   /* margin-right: 1rem; */
  //   background-color: var(--primary-800);
  //   border-radius: 2rem;
  //   padding: 4px 8px;
  //   color: white;
  //   margin-top: -3px;
  //   font-weight: 400;
  //   text-align: center;
  // }
  // .days {
  //   color: #969696;
  //   font-weight: 400;
  //   font-size: 0.7rem;
  //   // margin-left: -20px;
  // }

  .bottom-msg-bar {
    display: inline-block;
    width: -webkit-fill-available;
    align-items: center;
  }

  .bottom-msg-bar .common-icons-container {
    float: left;
    font-size: 12px;
    align-items: center;
    display: flex;
    gap: 2px;
    height:20px
  }

  .bottom-msg-bar .label {
    float: right;
    font-size: 10px;
    background-color: var(--primary-800);
    border-radius: 2rem;
    padding: 4px 8px;
    color: white;
    font-weight: 400;
    text-align: center;
  }

  .bottom-msg-bar .time {
    color: #969696;
    font-weight: 400;
    width: 60px;
  }

  .message {
    width: 70%;
    height: 40px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 20px;
    font-size: 16px;
    margin: 4px 0px;

    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
  }
  .snooze-container {
    color: var(--primary-800);
    display: flex;
    flex-direction: row;
    /* margin-left: 40px; */
    align-items: center;
    gap: 2px;
    height:25px;
    width:70px;
  }
  .snooze-label {
    font-size: 8px;
    font-weight: 500;
    //margin-left:20px
  }
  .message-preview-container {
  }

  .top {
    /* background-color: red; */
    border-bottom: none;
  }
  .message-icon {
    margin-left: auto;
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  /* .star-icon {
    margin-bottom: 1px;
  } */
 .label-icon{
  height:20px;
  width:20px
 }
 @media (max-width : 1300px) {
  .message {
    width: 180px;
    font-size: 14px;
  }
 }
`;
export default MessagePreviewStyles;
