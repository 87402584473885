import React, { useState } from "react";
import PrimaryButton from "../../components/reusable/PrimaryButton/PrimaryButton";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import { useTenantContext } from "../../context/TenantContext";
import { sendWhatsappMessage } from "../../service/service";
import CircularProgress from '@mui/material/CircularProgress';
const productHtml = ({productLink, src, title, currency, price, grams, weight_unit}) => {
  return `<span>
  <img id="product-img" src=${src} alt="product" width="100px" height="100px" style="border-radius: 5px 5px 0px 0px; width: 100px; height: 100px;"><br>
  <p  class="product-title"  style=margin:2px 0px; font-size: 16px; font-weight: 500; color: dimgray;><b>Name:</b> ${title}</p>
  <p class="product-price"  style=margin:2px 0px; font-size: 14px;><span><b>Price: </b> ${currency} ${price}</span></p>
  <p class="product-weight"  style=margin:2px 0px; font-size: 14px;><span><b>Weight: </b>${grams} ${weight_unit}</span></p>
  <p class="product-link"  style=margin:2px 0px; font-size: 14px;><span><b>Product: </b> ${productLink}</span></p><br>
  </span>
  `
}

const ProductList = (props) => {
  const { title, variants, name, image, handle, shopName, product, currency } = props;
  const { socket, sendMessages, sendImageLink, sessionId, currentSessionData, normalMessage , setText, setIsProductAdded} =
    useInboxContext();
  const [addToChatLoading, setAddToChatLoading] = useState(false)

  const { channelName, status } = currentSessionData || {};

  let disableBtn = status !== 'progress'

  const productLink = `https://${shopName}/products/${handle}/?variant=${product.id}`;

  const { userId, tenantId } = useTenantContext();
  const src = image?.src || "";

  const { grams, weight_unit, price, inventory_quantity } = product || {};
  
const productMessage = `*Product Name*: ${title}\n*Price*: ${price}\n*Link*: ${productLink}`;


  const handleAddToChat = async () => {
    setAddToChatLoading(true)
    try {
    setAddToChatLoading(true)
    let payload = {
       userId,
      tenantId,
      sessionId,
      channelName,
      imageLinkDetails: {
        title,
        productUrl: productLink,
        imageUrl: src,
        grams,
        weightUnit: weight_unit,
        price: `${currency} ${price}`,
      },     
    }
    if (channelName === "mail") {
      let productText  = productHtml({
        productLink,
        src,
        title,
        currency,
        price,
        grams,
        weight_unit
      })
      setText((prev) => prev ? prev + productText :  productText);
      setIsProductAdded(true)
    }
    if (channelName !== "mail") {
      const result = await sendImageLink(payload);
      sendMessages(result.data);
    }
    setAddToChatLoading(false)
    // console.log({find : result.data})
    } catch (error) {
    setAddToChatLoading(false)
      console.log(error)
    }
  };

  return (
    <div>
      <div
        style={{
          width: "-webkit-fill-available",
          background: "white",
          borderRadius: "5px",
          border: "1px solid silver",
          padding: "15px",
          margin: "15px",
        }}
      >
        <img
          src={src || ""}
          // height="120"
          // width="150"
          alt=""
          style={{
            borderRadius: "5px 5px 0px 0px",
            width: '100%'
          }}
        />
        <div
          style={{
            margin: "2px 0px",
            fontSize: "16px",
            fontWeight: 500,
            color: "dimgray",
          }}
        >
          {title}
        </div>
        <div
          style={{
            margin: "2px 0px",
            fontSize: "14px",
          }}
        >
          <span>
            <b>Price: </b> {currency} {price}
          </span>
        </div>
        <div
          style={{
            margin: "2px 0px",
            fontSize: "14px",
          }}
        >
          <span>
            <b>Qty: </b> {inventory_quantity < 0 ? 0 : inventory_quantity}
          </span>
        </div>
        <br />
        <PrimaryButton
          variant={"contained"}
          size="small"
          disabled={disableBtn}
          sx={
            {
              width : '-webkit-fill-available'
            }
          }
          onClick={handleAddToChat}
        >
          {addToChatLoading ? <CircularProgress sx={{color : 'white' }} size='1.2rem'   />: 
         'Add To Chat'
          } 
        </PrimaryButton>
      </div>
    </div>
  );
  // return (
  //   <div className="order-card">
  //     <div
  //       style={{
  //         width: "100%",
  //         display: "flex",
  //         marginTop: "10px",
  //         gap: "4px",
  //         padding: "5px",
  //         justifyContent: "space-between",
  //       }}
  //     >
  //       <div className="">
  //         <img src={src || ""} height="80" alt="" />
  //       </div>
  //       <div>
  //         <span
  //           style={{
  //             display: "block",
  //             fontSize: "14px",
  //             color: "black",
  //             fontWeight: "bold",
  //           }}
  //         >
  //           {name}
  //         </span>
  //         <span
  //           style={{
  //             color: "black",
  //             display: "block",
  //             fontSize: "12px",
  //             marginTop: ".5rem",
  //           }}
  //         >
  //           <span style={{ fontWeight: "530" }}>{title}</span>{" "}
  //         </span>
  //         <span
  //           style={{
  //             color: "black",
  //             display: "block",
  //             fontSize: "12px",
  //           }}
  //         >
  //           <b>Price: </b>
  //           {price}
  //         </span>
  //         <span
  //           style={{
  //             color: "black",
  //             display: "block",
  //             fontSize: "12px",
  //           }}
  //         >
  //           <b>Qty: </b>
  //           {inventory_quantity < 0 ? 0 : inventory_quantity}
  //         </span>

  //         {/*
  //         <span
  //           style={{
  //             color: "black",
  //             display: "block",
  //             fontSize: "12px",
  //             marginTop: ".5rem",
  //           }}
  //         >
  //           <span style={{ fontWeight: "bold" }}>{handle}:</span>{" "}
  //         </span> */}
  //       </div>
  //       <PrimaryButton
  //         variant={"contained"}
  //         size="small"
  //         sx={{
  //           marginLeft: "1rem",
  //           alignSelf: "center",
  //           height: "2rem",
  //           fontSize: ".5rem",
  //           padding: ".1rem",
  //           paddingBlock: ".5rem",
  //         }}
  //         onClick={handleAddToChat}
  //       >
  //         Add To Chat
  //       </PrimaryButton>
  //     </div>
  //   </div>
  // );
};

export default ProductList;
