
import React, { useEffect, useState } from "react";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

import { ChatContainerStyles } from "../../assets/styledComponents/message";
import TicketSummary from "./TicketSummary";
import { MessageConversation, MessageInput } from "../chatBox";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import { useTenantContext } from "../../context/TenantContext";
import usePushNotification from "../../hooks/usePushNotification";
import SessionInfo from "../chatBox/SessionInfo";
import EmailConversation from "../chatBox/EmailConversation";
import { Tooltip } from "@mui/material";
import CustomPopover from "../reusable/CustomPopover";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import TicketNote from "./TicketNote";


const ChatContainer = ({setShowStore, showStore, showCopilot , setShowCopilot ,setShowSidebar ,showSideBar}) => {
  const [isTicketNoteOpen, setIsTicketNoteOpen] = useState(false);
  const { tenantId, userId } = useTenantContext();
  const [aiTicketSummary,setAiTicketSummary] =useState("")
  const {
    currentSessionData,
    normalMessage,
    ticketNoteRef,
  } = useInboxContext();

  
  const {status , customerInfo, assignUser, assignTo, channelName } = currentSessionData || {}
  let name = customerInfo?.["First Name"] || "Unknown";
  const agentName = assignUser?.name || assignTo?.name || '' 

  const handleClick = () => {
    setIsTicketNoteOpen((prev) => !prev);
  };
  const handleClose = () => {
    setIsTicketNoteOpen(false);
  };
  const handleCopilot = () => {
    // setShowStore(false)
    setShowCopilot(true)
    if (showCopilot) {
      setShowCopilot(false)
      setShowStore(true)
    }else {
      setShowCopilot(true)
      setShowStore(false)
    }
    if (!showSideBar) {
      setShowCopilot(true)
      setShowSidebar(true)
      setShowStore(false)
    }
  }
  const handleRightSideBar= () => {
    setShowSidebar ((prev)=> !prev)
    setShowCopilot(false)
    setShowStore(true)
  }
  let margin = showSideBar ? 0 : 1
  const isSessionExist  = !!normalMessage.length
  return (
    <ChatContainerStyles margin={margin} id="chat-conatiner">
      <div className={`chat-conatiner-box`}>
        {currentSessionData?._id && (
          <div className="ticket-note-container">
            <CustomPopover
              buttonRef={ticketNoteRef}
              button={
                <div className="ticket-icon">
                  <Tooltip onClick={()=>setShowSidebar(true)} title={"Ticket Note"}>
                    <LocalActivityIcon />
                  </Tooltip>
                </div>
              }
              style={{ marginLeft: ".8rem" }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <TicketNote />
            </CustomPopover>
            {/* </div> */}
          </div>
        )}
        <button
          className="collapsable-icon floating-icon"
          onClick={handleRightSideBar}
        >
          {showSideBar ? (
            <i className="fa-solid fa-circle-chevron-right"></i>
          ) : (
            <i className="fa-solid fa-circle-chevron-left"></i>
          )}
        </button>
        {currentSessionData?._id && (
          <button
            onClick={handleCopilot}
            className="floating-icon copilot-icon"
          >
            <Tooltip title={showCopilot ? "Store" : "Copilot"}>
              <AddBoxOutlinedIcon />
            </Tooltip>
          </button>
        )}
        <TicketSummary
          aiTicketSummary={aiTicketSummary}
          setAiTicketSummary={setAiTicketSummary}
        />
        {isSessionExist && (
          <SessionInfo
            name={name}
            agentName={agentName}
            currentSessionData={currentSessionData}
            status={status}
          />
        )}
        {channelName === "mail" && isSessionExist ? (
          <EmailConversation />
        ) : (
          <MessageConversation />
        )}

        {isSessionExist && channelName !== "mail" && (
          <div style={{ marginBottom: ".25rem" }}>
            {currentSessionData?.status === "complete" ? (
              <MessageInput disabled={true} />
            ) : (
              <MessageInput />
            )}
          </div>
        )}
      </div>
      {/* <div onClick={handleClick} className="ticket-icon">
        <LocalActivityIcon />
      </div> */}
    </ChatContainerStyles>
  );
};

export default ChatContainer;
