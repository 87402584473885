import React, { useEffect, useRef, useState } from "react";
import { getKey } from "../../utils/helperFunction";

import styled from "styled-components";
const PrivateMessagePopoverStyles = styled.div`
  width: 300px;
  .popover-container {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background-color: white;
    margin-left: 1rem;
    width: fit-content;
    border-radius: 4px;
    // padding: 1rem;
    // box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    //   0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .popover-container li {
    padding: 6px 20px;
    transition: background-color 0.2s;
    cursor: pointer;
  }

  .popover-container li:hover {
    background-color: #f0f0f0;
  }

`;

const PrivateMessagePopover = ({
  userList,
  text,
  setTaggedUserDetails,
  setOpenModal,
  mentionIndex,
  setMentionIndex,
  setText,
  userId,
  quillRef,
  position,
}) => {
  const popoverRef = useRef(null);
  const [filteredUserList, setFilteredUserList] = useState([]);

  const filterUser = () => {
    const tag = text.slice(mentionIndex + 1, text.length).toLowerCase();
    const newFilteredUserList = userList.filter((item) => {
      return item?.name?.toLowerCase().includes(tag) && item._id !== userId
    })
    console.log({tag, newFilteredUserList, text})

    if (
      newFilteredUserList.length === 0
    ) {
      setOpenModal(false);
    } else {
      setOpenModal(true);
    }
    setFilteredUserList(newFilteredUserList);
  };

  useEffect(() => {
    filterUser()
  },[text, userId, userList])



  const handleNameSelect = (name) => {
    if (quillRef?.current) {
    const ed = quillRef.current.getEditor();
    ed.editor.insertText(position, name);
    }else {
      setText((prev) => prev.substring(0, mentionIndex + 1) + name);
    }
    setMentionIndex(-1);
  };

  const handleClick = (user) => {
    return () => {
      handleNameSelect(user.name);
      setOpenModal(false);
      setTaggedUserDetails((prev) => [...prev, user]);
    };
  };

  return (
    <PrivateMessagePopoverStyles>
      <div className="popover-container" ref={popoverRef}>
        <ul>
          {filteredUserList.map((user, index) => (
            <li key={index} onClick={handleClick(user)}>
              {user.name}
            </li>
          ))}
        </ul>
      </div>
    </PrivateMessagePopoverStyles>
  );
};

export default PrivateMessagePopover;
