import React, { useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PropTypes from "prop-types";

import { SnoozeStyles } from "../assets/styledComponents/message";
import SnoozeDatePicker from "./SnoozeDatePicker";
import { snoozeMessage } from "../service/service";
import { useInboxContext } from "../context/inboxContext/inboxContext";
import PrimaryButton from "./reusable/PrimaryButton/PrimaryButton";

const SnoozeNotification = ({ onClose }) => {
  const { sessionId, setRefreshNotification } = useInboxContext();
  const [openSchedule, setOpenSchedule] = useState(false);
  const [selectedList, setSelectedList] = useState("30");

  const handleScheduleClick = () => {
    setSelectedList("custom");
    setOpenSchedule(true);
  };

  const applySnooze = async () => {
    try {
      const result = await snoozeMessage({
        sessionId,
        snoozeTime: selectedList,
      });
      setOpenSchedule(false);
      setRefreshNotification((prev) => !prev);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {openSchedule ? (
        <SnoozeDatePicker
          setOpenSchedule={setOpenSchedule}
          onSnoozeListClose={onClose}
        />
      ) : (
        <SnoozeStyles>
          <ul>
            <li
              onClick={() => setSelectedList("30")}
              className={`${selectedList === "30" ? "active" : null}`}
            >
              <AccessTimeIcon style={{ color: " #00A0AC" }}/>
              <span>30 minutes</span>
            </li>
            <li
              onClick={() => setSelectedList("60")}
              className={`${selectedList === "60" ? "active" : null}`}
            >
              <AccessTimeIcon style={{ color: "#FD5749" }} />
              <span>1 hour</span>
            </li>
            <li
              onClick={() => setSelectedList("120")}
              className={`${selectedList === "120" ? "active" : null}`}
            >
              <AccessTimeIcon style={{ color: "#B98900" }} />
              <span>2 hour</span>
            </li>
          </ul>
          <div
            className={`custom ${selectedList === "custom" ? "active" : null}`}
            onClick={() => handleScheduleClick()}
          >
            <NotificationsNoneIcon />
            <span>Custom Scheduling</span>
          </div>

          <div className="snooze-btn">
            <PrimaryButton onClick={() => applySnooze()} variant={"contained"}>
              Snooze
            </PrimaryButton>
          </div>
        </SnoozeStyles>
      )}
    </>
  );
};

export default SnoozeNotification;

SnoozeNotification.propTypes = {
  onClose: PropTypes.func,
};
