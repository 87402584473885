import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Avatar, Badge } from "@mui/material";
import MenuStyles from "../assets/styledComponents/Menu";
import logo from "../assets/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { menuItemList } from "../utils/helperData";
import { useTenantContext } from "../context/TenantContext";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import NotificationDrawer from "./notification/NotificationDrawer";
import { getAllInstagramPosts,  getAllNotification,  getPagePosts } from "../service/service";
import { useInboxContext } from "../context/inboxContext/inboxContext";

const Menu = () => {
  const { profile, setOpenSideBar, tenantId, userId, iconTitle, setIconTitle, tenantStores, setPreviewActive  } =
    useTenantContext();

  const { refreshMessageNotification ,  messageNotifications, setMessageNotification, socket, refreshChatSessionsListOnly, setCurrentSessionData, unReadMessage } = useInboxContext();



  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const [menuList, setMenuList] = useState([]);

  // useEffect(() => {
  //   socket
  //     ?.off("PRIVATE_NOTIFICATION")
  //     .on("PRIVATE_NOTIFICATION", (message) => {
  //       console.log("received from socket", message);
  //       console.log("ran private");
  //       setRefreshNotification((prev) => !prev);
  //     });
  // });

  const fetchMessageNotification  = async () => {
    try {
      const result = await getAllNotification(
        tenantId,
        userId,
      );
      setMessageNotification(result.data)
    } catch (error) {
      console.log(error);
    }
  };
  console.log({messageNotifications})

  useEffect(() => {
    fetchMessageNotification();
  }, [refreshMessageNotification]);

  useEffect(() => {
    setIconTitle(path);
    if (path === "") {
      setIconTitle("inbox");
    }
    if (!profile?.role) {
      setMenuList([...menuItemList]);
      return;
    }
    if (profile?.role === "Agent") {
      let permission = profile.permissions;
      let arr = [
        {
          icon: <MessageOutlinedIcon />,
          title: "inbox",
        },
      ];
      for (let item of permission) {
        if (item.label === "managingSettings") {
          arr.push({
            icon: <SettingsOutlinedIcon />,
            title: "settings",
          });
        }
        if (item.label === "reportAccess") {
          arr.push({
            icon: <AnalyticsOutlinedIcon />,
            title: "report",
          });
        }
      }
      setMenuList([...arr]);
    }
  }, []);
  
  const handleClick = async (title) => {

    setPreviewActive({
      isActive: false,
      sessionId: "",
    });

    if (title !== 'notification' ) {
      if (title === 'facebook' || title === 'instagram') {
        console.log('set current session data')
      }else {
        setCurrentSessionData({})
      }
    }
    
    if (title === 'facebook') {
      try {
      const result = await getPagePosts({ tenantId, shop: tenantStores[0].name});  
      refreshChatSessionsListOnly()
      } catch (error) {
        console.log(error)
      }
      return
    }

    if (title === 'instagram') {
      try {
      const result = await getAllInstagramPosts({ tenantId, shop: tenantStores[0].name});  
      refreshChatSessionsListOnly()
      } catch (error) {
        console.log(error)
      }
      return
    }
    
    if (title === "notification") {
      console.log(title);
      setOpenSideBar(true);
      return;
    }
    if (profile && profile.isMenuDisabled && title !== 'myProfile' ) {
      if (title === 'settings/businessHours') {
        navigate('/settings/connect-shopify')
        return
      }
      return;
    }
    setIconTitle(title);
    if (title === "inbox") {
      title = "/";
    }
    navigate(title);
  };


  const userHavePermission = (permission, title) => {
    if ((title === 'facebook' || title === 'instagram') && process.env.REACT_APP_FB_FLAG=='true') {
        const userEmail = profile?.token?.email ?? ''
        return userEmail === process.env.REACT_APP_FB_BYPASS 
    }


    if (profile?.token?.role === "Owner" || !permission || profile?.token?.role === "Admin") {
      return true;
    }

    const userPermissions = profile?.token?.permissions;

    if (
      !userPermissions ||
      !Array.isArray(userPermissions) ||
      !userPermissions.length
    ) {
      return false;
    }

    const isPermissionExists = userPermissions.filter(
      (userPermissionData) => userPermissionData.label === permission
    );

    if (isPermissionExists && isPermissionExists.length) {

      return true;
    } else {
      return false;
    }
  };

  return (
    <MenuStyles>
      <div className="container">
        <div style={{ display: "none" }}>
          <NotificationDrawer />
        </div>
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="menu-items">
          {menuItemList.map(({ icon, title, permission }, index) => {
            const badgeCount = title === 'notification' ? messageNotifications?.length : title == 'inbox' ? unReadMessage : 0 
            const isAllowed = userHavePermission(permission, title);
            if (!isAllowed) return <></>;

            return (
              <div
                className={`menu-item ${
                  iconTitle === title && "menu-item-active"
                }`}
                onClick={() => handleClick(title)}
                key={`menu-item-${title}-${index}`}
              >
                <IconButton
                  className={`menu-item ${
                    iconTitle === title && "menu-item-active"
                  }`}
                >
                    <Badge
                      badgeContent={badgeCount}
                      color="primary"
                    >
                      {icon}
                    </Badge>

                </IconButton>
              </div>
            );
          })}
        </div>
      </div>
      <div className="profile" onClick={() => handleClick("myProfile")}>
        {!profile?.token?.profileImageUrl ? (
          <Avatar>{profile?.token?.name[0].toUpperCase()}</Avatar>
        ) : (
          <Avatar src={profile?.token?.profileImageUrl}></Avatar>
        )}
      </div>
    </MenuStyles>
  );
};

export default Menu;
