import React, { useState } from "react";

import InboxMenuStyles from "../../assets/styledComponents/InboxMenu";
import { inboxIcons } from "../../utils/helperData";
import Dropdown from "./Dropdown";
import Logo from "../Logo";
import { Outlet, useLocation } from "react-router-dom";
const InboxMenu = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const [collapsableMenu, setCollapsableMenu] = useState(false);

  const hanleOnCollapse = () => {
    const collapseStatus = !collapsableMenu;
    const elementChatContainer = document.getElementById("chat-conatiner");

    if (collapseStatus) {
      if (elementChatContainer) {
        elementChatContainer?.classList.add("collapse-chat-container");
      }
    } else {
      if (elementChatContainer) {
        elementChatContainer?.classList.remove("collapse-chat-container");
      }
    }
    setCollapsableMenu(collapseStatus);
  };

  return (
    <InboxMenuStyles>
      <div
        className={`inbox-menu-container  ${
          collapsableMenu ? "collapsable-inbox-menu" : ""
        }`}
      >
        {collapsableMenu ? (
          <div style={{ padding: "24px 0px" }}>
            <h2> </h2>
          </div>
        ) : (
          <Logo />
        )}
        <span className="collapsable-icon" onClick={hanleOnCollapse}>
          {collapsableMenu ? (
            <i className="fa-solid fa-circle-chevron-right"></i>
          ) : (
            <i className="fa-solid fa-circle-chevron-left"></i>
          )}
        </span>
        <h3 className="title">{collapsableMenu ? "" : "Inbox"}</h3>
        <div className="inbox-container">
          <Dropdown
            isActive={location.pathname.split("/").length === 2}
            iconList={inboxIcons}
            collapsableMenu={collapsableMenu}
            name={"Universal Inbox"}
            path={"universal"}
            firstRoute={"all"}
            noOfPath={1}
            icon={<i className="fa-solid fa-globe"></i>}
          />
          <Dropdown
            isActive={path === "myInbox"}
            iconList={inboxIcons}
            name={"My Inbox"}
            path={"myInbox"}
            firstRoute={""}
            noOfPath={2}
            collapsableMenu={collapsableMenu}
            icon={<i className="fa-solid fa-inbox"></i>}
          />
          <Dropdown
            collapsableMenu={collapsableMenu}
            icon={<i className="fa-solid fa-stopwatch"></i>}
            isActive={path === "snoozedMessage"}
            iconList={inboxIcons}
            name={"Snoozed"}
            path={"snoozedMessage"}
            firstRoute={"all"}
            noOfPath={2}
          />
        </div>
      </div>
      <Outlet />
    </InboxMenuStyles>
  );
};

export default InboxMenu;
