import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { useTenantContext } from "../../../context/TenantContext";
import HeaderBar from "../../reusable/HeaderBar/HeaderBar";
import Swal from "sweetalert2";
import CustomReportTable from "../../Report/CustomReportTabel";
import FaQStyles from "../../../assets/styledComponents/FaqTraining";
import {
  getAllFaQTraining,
  getAllTenantStore,
  deleteFaq,
} from "../../../service/service";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import FaqModal from "./FaqModal";
import FaqAccordion from "../../reusable/FaqAccordion";
import FullPageSpinner from "../../FullPageSpinner";

const FaQTraining = () => {
  const { tenantId } = useTenantContext();
  const [openModal, setOpenModal] = useState(false);
  const [faqTableDetails, setFaqTableDetails] = useState([]);
  const [originalFaqTableData, setOriginalFaqTableData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [storeTabs, setStoreTabs] = useState([]);
  const [shopName, setShopName] = useState("");
  const [loading, setLoading] = useState(true)
  const [faqLoading,setFaqLoading] = useState(false)
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isEdit, setIsEdit] = useState(false)
  const [selectFaqId, setSelectedFaqId] = useState('')


  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setShopName(storeTabs[newValue]?.shop || "");
  };

  const fetchStores = async () => {
    if (!tenantId) return;
    setLoading(true)
    try {
    const tabsRes = await getAllTenantStore(tenantId);
    if (tabsRes.success) {
      setStoreTabs(tabsRes.data);
      setShopName(tabsRes.data[0].shop);
      setActiveTab(0);
      setLoading(false)
      return tabsRes.data;
    }
    } catch (error) {
      setLoading(false)
      console.log('error')
    }
  };

  const handleButtonClick = () => {
    setOpenModal(true);
  };

  const handleDelete = async (faQId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure ?",
        text: "All chat sessions will be dissociated from this faq ",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#8db178",
        cancelButtonColor: "#D9D9D9",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const deletionResult = await deleteFaq(faQId);
        if (deletionResult) {
          Swal.fire({
            title: "Deleted!",
            text: `The faq has been deleted.`,
            confirmButtonColor: "#8db178",
            icon: "success",
          });
        }
        fetchFaqTableSetup();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = async(faqId, faqQuestion, faqAnswer) => {
    console.log({faqId, faqQuestion, faqAnswer})
    setOpenModal(true)
    setQuestion(faqQuestion)
    setAnswer(faqAnswer)
    setIsEdit(true)
    setSelectedFaqId(faqId)
  }

  const fetchFaqTableSetup = async () => {
    setFaqLoading(true)
    try {
      const result = await getAllFaQTraining(tenantId, shopName);
      const newResult = result.map((row) => {
        return {
          ...row,
          Action: (
            <span className="delete-icon" onClick={() => handleDelete(row._id)}>
              <DeleteIcon />
            </span>
          ),
          Status: row.Status === "Y" ? "Trained" : "Not Trained",
        };
      });

      setFaqTableDetails(newResult);
      setOriginalFaqTableData(newResult);
      setFaqLoading(false)
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
      setFaqLoading(false)
    }
  };

  useEffect(() => {
    fetchFaqTableSetup();
  }, [shopName]);

  useEffect(() => {
    fetchStores();
  }, []);

  const columns = React.useMemo(
    () => [
      { Header: "Question", accessor: "Question" },
      { Header: "Answer", accessor: "Answer" },
      { Header: "Status", accessor: "Status" },
      { Header: "Date of Training", accessor: "trainingDate" },
      { Header: "Action", accessor: "Action" },
    ],
    []
  );

  return (
    <FaQStyles>
      <HeaderBar
        title={"FAQ Training"}
        showDescription={true}
        description="Provide the chatbot with the web URL of the content that you want to train its AI model on"
        hideSecondaryButton={true}
        primaryButtonLabel={"Add FAQ"}
        handlePrimaryButtonClick={handleButtonClick}
      />
      <FaqModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        shopName={shopName}
        tenantId={tenantId}
        refreshTable={fetchFaqTableSetup}
        question={question}
        setQuestion={setQuestion}
        answer={answer}
        setAnswer={setAnswer}
        isEdit={isEdit}
        faqId={selectFaqId}
      />

      {loading ? (
        <FullPageSpinner />
      ) : (
        <div>
          <div className="store-tab-container">
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              {storeTabs.map((tabData) => (
                <Tab
                  label={`${tabData.shop || "New Account"}  ${
                    tabData.isDefault ? "- Default" : ""
                  }`}
                  key={`${tabData.shop || "New Account"}  ${
                    tabData.isDefault ? "- Default" : ""
                  }`}
                />
              ))}
            </Tabs>
          </div>

          <div className="accordion-container">
            {faqLoading ? (
              <FullPageSpinner />
            ) : faqTableDetails?.length ? (
              faqTableDetails.map((item) => {
                const { Answer, Question, Status } = item || {};
                return (
                  <FaqAccordion
                    question={Question}
                    answer={Answer}
                    status={Status}
                    key={item._id}
                    faqId={item._id}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    setIsEdit={setIsEdit}
                  />
                );
              })
            ) : (
              <div className="paper-card"> No Data available </div>
            )}
          </div>
        </div>
      )}
    </FaQStyles>
  );
};

export default FaQTraining;
