import React, { useState } from "react";
import Logo from "../Logo";
import { useLocation, useNavigate } from "react-router-dom";
import { myProfileList } from "../../utils/helperData";
import MyProfileMenuStyles from "../../assets/styledComponents/myProfile/MyProfileMenu";
import { useTenantContext } from "../../context/TenantContext";
import { logoutUser } from "../../service/service";

const MyProfileMenu = () => {
  const { userId } = useTenantContext();
  const navigate = useNavigate();
  const location = useLocation();
  let path = location.pathname.split("/")[2];

  const [selectedSettingsList, setSelectedSettingsList] = useState();

  const handleClick = (name, route) => {
    setSelectedSettingsList(name);
    if (route === "") {
      navigate("/myProfile");
    }
    if (route) {
      navigate(route);
    }
  };
  const unregisterServiceWorker = async () => {
    try {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration) {
        await registration.unregister();
        console.log("Service worker unregistered successfully.");
      } else {
        console.log("No service worker registration found.");
      }
    } catch (error) {
      console.error("Error during service worker unregister:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser({ userId });
      localStorage.removeItem("token");
      localStorage.removeItem("store");
      unregisterServiceWorker();
      navigate("/login");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  if (location.pathname.split("/")[2] === undefined) {
    path = "";
  }

  return (
    <MyProfileMenuStyles>
      <div className="container">
        <Logo />
        <h3 className="title">My Profile</h3>
        <div className="list-container">
          {myProfileList.map((item) => {
            const { id, name, route } = item;
            return (
              <React.Fragment key={id}>
                <li
                  className={`report-list ${path === route ? "active" : null} `}
                  onClick={() => handleClick(name, item?.route)}
                >
                  {name}
                </li>
              </React.Fragment>
            );
          })}
          <li className="report-list" onClick={handleLogout}>
            Logout
          </li>
        </div>
      </div>
    </MyProfileMenuStyles>
  );
};

export default MyProfileMenu;
