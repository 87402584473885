import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import inboxReducer from "./inboxReducer";
import {
  BEGIN_GET_CHAT_MESSAGE,
  BEGIN_SET_CURRENT_CHAT,
  DELETE_MESSAGE,
  GET_CHAT_MESSAGE,
  REMOVE_IMAGE,
  SEND_MESSAGE,
  SET_CURRENT_CHAT,
  SET_IMAGE,
  SET_MESSAGE_READ,
  SET_NORMAL_MESSAGE,
  SET_SINGLE_MESSAGE_READ,
} from "./inboxAction";
import {
  getAllMessage,
  sendMessage,
  updateUnReadMessage,
  getAllSession,
  getFilters,
  getSingleChatSession,
  getUpdatedSingleChatSession,
} from "../../service/service";
import { io } from "socket.io-client";
import { useTenantContext } from "../TenantContext";
import useNotification from "../../hooks/useNotification";
import usePushNotification from "../../hooks/usePushNotification";

const InboxContext = React.createContext();

const initialState = {
  testing: "testing",
  message: [],
  normalMessage: [],
  sessionId: "",
  isMessageLoading: false,
  numOfPages: 1,
};
const InboxProvider = ({ children }) => {
  console.log('ran in inbox provider')
  const { tenantId, userId, isTokenLoading } = useTenantContext();
  const effectRan = useRef(false);

  const [socket, setSocket] = useState();
  const [socketRoomId, setSocketRoomId] = useState("");
  const [refreshNotification, setRefreshNotification] = useState(false);
  const [name, setName] = useState("");
  const [backendMessage, setBackendMessage] = useState({
    msg: "",
    type: "",
  });

  const [playNotificationSound] = useNotification();
  const [askPermission, sendNotification] = usePushNotification();

  const [state, dispatch] = useReducer(inboxReducer, initialState);
  const [messageReceived, setMessageReceived] = useState(); // NEw Msg Recieve
  const [ticketId, setTicketId] = useState();
  const [messageSent, setMessageSent] = useState(false); // Toggling for scroll down behaviour
  const [currentSessionData, setCurrentSessionData] = useState({});
  const [labelFilterQuery, setLabelFilterQuery] = useState([]);
  const [imageUploadLoading, setImageUploading] = useState(false);
  const [messageNotifications, setMessageNotification] = useState([])
  const [refreshMessageNotification , setRefreshMessageNotification] = useState(false);
  const [highlight, setHighlight] = useState(""); // // Side Menu channel Option
  const [text, setText] = useState("");
  const [showImageLinkPreview, setShowImageLinkPreview] = useState(false);
  const [imageLinkDetails, setImageLinkDetails] = useState({});
  const [quickReplySelected, setQuickReplySelected] = useState(false);
  const [error, setError] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [chatSessions, setChatSessions] = useState([]);
  const [channelName, setChannelName] = useState("");
  const [inboxType, setInboxType] = useState("universal");
  const [sessionLoading, setSessionLoading] = useState(true);
  const [chatSessionOriginalData, setChatSessionOriginalData] = useState({});
  const [unReadMessage, setUnreadMessage] = useState(0);
  const [nextBatchSessionLoading, setNextBatchSessionLoading] = useState(false);
  const [newUserJoined, setNewUserJoined] = useState(false);
  const [priorityFilter, setPriorityFilter] = useState(null);
  const [assignedFilter, setAssignedFilter] = useState("");
  const [selectedFacebookPage, setSelectedFacebookPage] = useState("");
  const [metaConfValue, setMetaConfValue] = useState({
    instagramPageName: "",
    instagramPages: [],
    selectedInstagramPage: "",
    facebookPages: [],
    selectedFacebookPage: "",
    selectedInstagramPageId: "",
    comments: false,
    directMessages: false,
  });
  const [shoppingData, setShoppingData] = useState([]);
  const [ticketNoteValue, setTicketNoteValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const ticketNoteRef = useRef();
  const [messageIndex, setMessageIndex] = useState(0);
  const [selectedComment, setSelectedComment] = useState(false) // Boolean or selected comment
  const [isMessagePrivate, setIsMessagePrivate] = useState(false);
  const [totalMessage, setTotalMessage] = useState('')
  const [page, setPage] =useState(2)
  const [showStore , setShowStore] = useState(true)
  const [isProductAdded , setIsProductAdded]  = useState(false)
  const pathName = location.pathname.split("/")[1];
  const quillRef = useRef(null);
  const [fbCommentCount, setFbCommentCount] =useState(0)

  const getSelectedPath = () => {
    let menuName = location.pathname.split("/");
    let newInboxType;
    let newChannelName;
    if (menuName.length === 2) {
      let path = location.pathname.split("/")[1];
      newInboxType = "universal";
      newChannelName = path === "" ? "all" : path === 'email' ? 'mail' : path;
    }
    if (menuName.length === 3) {
      if (menuName[1] === "myInbox") {
        newInboxType = "myInbox";
      }
      if (menuName[1] === "snoozedMessage") {
        newInboxType = "snoozedMessage";
      }
      let path = location.pathname.split("/")[2];
      newChannelName = path === "" ? "all" : path === 'email' ? 'mail' : path;
    }

    setInboxType(newInboxType);
    setChannelName(newChannelName);
    return {
      inboxType: newInboxType,
      channelName: newChannelName,
    };
  };

  const fetchSessions = async (page = 1, refresh = false,  messageSearch) => {
    const { inboxType, channelName } = getSelectedPath();
    if (!tenantId ||  !assignedFilter || isTokenLoading) return;
    let id = inboxType === "myInbox" ? userId : "";
    let snooze = inboxType === "snoozedMessage" ? true : false;
    setNextBatchSessionLoading(true);
    setSessionLoading(true);
    const payload = {
      tenantId,
      tenantUserDetailsId: id,
      labelFilterQuery,
      messageSearch : messageSearch || "",
      isSnooze: snooze,
      page,
      channelName,
      status: "open,progress",
      priorityFilter,
    };
    if (inboxType !== "myInbox" && inboxType !== "snoozedMessage") {
      payload.assignedFilter = assignedFilter;
    }

    try {
      const result = await getAllSession(payload);
      if (messageSearch) {
        setChatSessions(result.data.docs)
        calculateUnreadMessage(result.data.docs);
        setTimeout(() => {
          setSessionLoading(false);
          setNextBatchSessionLoading(false);
        }, 200);
        return; 
      }
      setChatSessions((prev) => {
        let updatedValue = [...prev];
        // updatedValue = [...updatedValue, ...result.data.docs];
        // if (refresh) {
        //   updatedValue = [...result.data.docs];
        // }
        // return updatedValue;
        const newDocs = result.data.docs;

        // Remove objects with duplicate ticketId
        updatedValue = updatedValue.filter(
          (prevItem) =>
            !newDocs.some((newItem) => newItem.ticketId === prevItem.ticketId)
        );

        if (refresh) {
          updatedValue = newDocs;
        } else {
          updatedValue = [...updatedValue, ...newDocs];
        }
        return updatedValue;
      });
      let newSessions = [...result.data.docs];
      // if (refresh) {
      //   newSessions = [...result.data.docs];
      // }
      // console.log({ currentPage });
      // setChatSessions([...newSessions]);
      setChatSessionOriginalData({
        ...result.data,
      });
      // setUnreadMessage(
      //   [...result.data.docs].reduce((unReadMessage, item) => {
      //     return item.unReadMessage.filter(
      //       (item) => item.sender !== "agent" && item.isSeen === false
      //     ).length > 0
      //       ? unReadMessage + 1
      //       : unReadMessage + 0;
      //   }, 0)
      // );
      calculateUnreadMessage(newSessions)
    } catch (error) {
      console.log("Fetch Sessions - Error", error);
    }

    setTimeout(() => {
      setSessionLoading(false);
      setNextBatchSessionLoading(false);
    }, 200);
  };

  // const calculateUnreadMessage = (chatSessions) => {
  //   setUnreadMessage(
  //     chatSessions.reduce((unReadMessage, session) => {
  //       const unreadCount = session.unReadMessage.filter(
  //         (message) =>
  //           (message.sender !== "agent" && !message.isSeen) ||
  //           (message.sender === "agent" &&
  //             message.isPrivate &&
  //             message.userId !== userId)
  //       ).length;

  //       return unReadMessage + (unreadCount > 0 ? 1 : 0);
  //     }, 0)
  //   );
  // };
  const calculateUnreadMessage = (chatSessions) => {
      setUnreadMessage(
        [...chatSessions].reduce((unReadMessage, item) => {
          return item.unReadMessage.filter(
            (item) => item.sender !== "agent" && item.isSeen === false || item.sender === 'agent' && item.isPrivate && item.userId !== userId
          ).length > 0
            ? unReadMessage + 1
            : unReadMessage + 0;
        }, 0)
      );
  }



  const fetchFilters = async () => {
    if (!tenantId || isTokenLoading) return;
    const filterRes = await getFilters(tenantId);
    
    if (!filterRes.data) {
      setLabelFilterQuery([]);
      setAssignedFilter("all")
      setPriorityFilter(false);
    } else {
      // will be handled here
      setLabelFilterQuery(filterRes.data.labelFilter);
      setAssignedFilter(filterRes.data.assignedFilter);
      setPriorityFilter(filterRes.data.priorityFilter);
    }

    // setTimeout(() => {
    //   refreshChatSessionsListOnly();
    // }, 1000);
    // refreshChatSessionsListOnly();
  }



  const handleRestoreScrollPosition = () => {
    // Restore the scroll position
    const scrollableElement = document.getElementById("message-preview-list");
    const savedScrollPosition = localStorage.getItem("scrollPosition");
    if (scrollableElement && savedScrollPosition) {
      scrollableElement.scrollTop = parseInt(savedScrollPosition, 10) - 400;
    }
  };

  const loadNextSession = async () => {
    const newPage = chatSessionOriginalData?.nextPage;
    if (newPage === chatSessionOriginalData?.page || !newPage) return;
    await fetchSessions(newPage);

    setTimeout(() => {
      handleRestoreScrollPosition();
    }, 400);
  };

  // useEffect(() => {
  //   // fetchFilters();
  // }, []);




  useEffect(() => {
    // fetchSessions();
    fetchFilters();
  }, [tenantId]);

  useEffect(() => {
    if (tenantId) {
      // eslint-disable-next-line no-undef
      let socket = io(process.env.REACT_APP_BACKEND_SERVICE_URL);

      socket.on("connect", () => {
        console.log("you are connected with ", socket.id);
        if (userId && tenantId) {
          console.log("NEW_AGENT_JOINED_CHAT", userId, tenantId);
          socket.emit("NEW_AGNET_JOINED_CHAT", userId, tenantId)
        }

      });
      setSocket(socket);
    }
    return () => {
      effectRan.current = true;
    };
  }, [tenantId]);


  socket?.off("AGENT_NEW_ROOM_CREATED").on("AGENT_NEW_ROOM_CREATED", (socketRoomId) => {
    console.log("Socket AGENT_NEW_ROOM_CREATED", socketRoomId);
    setSocketRoomId(socketRoomId);
  });

  socket?.off("AGENT_NEW_MESSAGE_NOTIFICATION").on("AGENT_NEW_MESSAGE_NOTIFICATION", (message, snooze) => {
    console.log("Socket Notification Recieve");
    if (!snooze) {
      playNotificationSound();
    }
    setRefreshMessageNotification((prev) => !prev);
  });

  socket?.off("AGENT_NEW_MESSAGE_NOTIFICATION").on("AGENT_NEW_MESSAGE_NOTIFICATION", (message, snooze) => {
    console.log("Socket Notification Recieve");
    if (!snooze) {
      playNotificationSound();
    }
    console.log("Socket AGENT_NEW_MESSAGE_NOTIFICATION", message, snooze);
  });



  socket?.off("APP_UNINSTALLED").on("APP_UNINSTALLED", (tenantId) => {
    console.log("APP_UNINSTALLED", tenantId )
    refreshChatSessionsListOnly()
    setNormalMessage() 
    setRefreshNotification((prev) => !prev)
  });


  socket?.off("AGENT_DELETE_MESSAGE").on("AGENT_DELETE_MESSAGE", (messageId) => {
    console.log("AGENT_DELETE_MESSAGE", messageId )
    if (!messageId) return;
    deleteMessage(messageId)
    refreshChatSessionsListOnly()
  });

  socket?.off("NEW_MESSAGE_PROCESSED").on("NEW_MESSAGE_PROCESSED", (message, channelName) => {
    console.log( "Socket NEW_MESSAGE_PROCESSED", message);
    setMessageReceived((prev) => !prev);
    // This is to Update the session Preview, and session status
    updateSingleSession(message.chatSession, channelName);
    // This is to add the message in the message list
    if (message.sender !== "agent" && message.chatSession === currentSessionData._id) {
      sendMessages(message);
    }
    // This is to add them message to the list if it is sent by agent
    if (message.sender === "agent") {
      if (message.chatSession === currentSessionData._id && message?.userId._id !== userId) {
        sendMessages(message);
      }
    }
  });

  socket?.off("REFRESH_NOTIFICATION_LIST").on("REFRESH_NOTIFICATION_LIST", () => {
    console.log("Socket Refresh Notification");
    setRefreshMessageNotification((prev) => !prev);
  })

  socket?.off("REFRESH_SEEN_MESSAGE").on("REFRESH_SEEN_MESSAGE", ( seenBy , sessionId) => {
    console.log("Socket Refresh Seen Message", seenBy);
    setMessageRead({seenBy})
    setMessageReceived((prev)=> !prev);
    updateSingleSession(sessionId);
  })

  
  // useEffect(() => {
  //   socket?.off("RECEIVE_MESSAGE").on("RECEIVE_MESSAGE", (message, channel) => {
  //     console.log("RECEIVE_MESSAGE", message);
  //     setMessageReceived((prev) => !prev);
  //     updateSingleSession(message.chatSession)
  //     if (message.sender === "agent"&& message.isPrivate) {
  //       if (message.chatSession === currentSessionData._id && message?.userId._id !== userId) {
  //         sendMessages(message);
  //       }
  //     }
  //     if (message.sender !== "agent") {
  //       socket?.emit(
  //         "MESSAGE_RECEIVED",
  //         currentSessionData._id,
  //         message,
  //         channel
  //       );
  //       if (message.chatSession === currentSessionData._id) {
  //         sendMessages(message);

  //         if (channel === "chat") {
  //           setSingleMessageRead(message._id);
  //         }
  //       }
  //     }
  //     return () => {
  //       socket.disconnect();
  //     };
  //   });
  // });

  // useEffect(() => {
  //   socket
  //     ?.off("MESSAGE_RECEIVED_BY_BROWSER")
  //     .on("MESSAGE_RECEIVED_BY_BROWSER", (sessionId, message, channel) => {
  //       if (message.sender === "agent") {
  //         if (message.chatSession === currentSessionData._id) {
  //           if (channel === "chat") {
  //             setSingleMessageRead(message._id);
  //           }
  //         } else {
  //           // send notification
  //           // TO DO Commenting for now
  //           // updateUnReadMessage(message);
  //         }
  //       }
  //     });
  // });

  // useEffect(() => {
  //   socket?.off("IS_SEEN_UPDATED").on("IS_SEEN_UPDATED", async (msgArray, seenBy) => {
  //     console.log("IS_SEEN_UPDATED", msgArray);
  //     if (msgArray?.length === 0) return;
  //     // called when updating the message preview unread message
  //     // refreshChatSessionsListOnly1();
  //     updateSingleSession()
  //     // fetchSessions(currentPage, true, "update");
  //     setMessageRead({ msgArray, seenBy });
  //   });
  // });

  // useEffect(() => {
  //   socket?.off("SEND_NOTIFICATION").on("SEND_NOTIFICATION", (message) => {
  //     const playNotification = () => {
  //       askPermission();
  //       playNotificationSound();
  //       sendNotification(message.description);
  //       setRefreshMessageNotification((prev) => !prev);
  //     };
  //     if (!currentSessionData._id) {
  //       // no session is currently selected
  //       playNotification();
  //       return;
  //     }
  //     if (message.chatSessionId !== currentSessionData._id) {
  //       // when message session id is not equal to current session
  //       playNotification();
  //     }
  //   });
  // });



  // socket?.off("NEW_USER_JOINED").on("NEW_USER_JOINED", () => {
  //   console.log("New User Joined Socket Called : DEBUGGING Refresh");
  //   setNewUserJoined((prev) => !prev);
  // });

  const setCurrentChat = async (payload) => {
    const { sessionId, page = 1, messageType } = payload;
    dispatch({ type: BEGIN_SET_CURRENT_CHAT });
    try {
    const result = await getAllMessage(userId, sessionId, page, messageType);
    setTotalMessage(result.data.totalMessage)
    dispatch({
      type: SET_CURRENT_CHAT,
      payload: { data: result.data, sessionId },
    });
    } catch (error) {
      console.log(error)
    }
  };

  const setNormalMessage = () => {
    dispatch({ type: SET_NORMAL_MESSAGE });
  };

  const getChatMessage = async (payload) => {
    const { userId, sessionId, page, messageType } = payload;
    dispatch({ type: BEGIN_GET_CHAT_MESSAGE, payload: {page}});
    const result = await getAllMessage(userId, sessionId, page, messageType);
    dispatch({
      type: GET_CHAT_MESSAGE,
      payload: { data: result.data, sessionId },
    });
    return result;
  };

  const sendMessages = (currentMessage) => {
    setMessageSent((prev) => !prev);
    dispatch({ type: SEND_MESSAGE, payload: currentMessage });
  };

  const deleteMessage  = (messageId) => {
    dispatch({ type: DELETE_MESSAGE, payload : messageId});
  }

  const setMessageRead = (payload) => {
    dispatch({ type: SET_MESSAGE_READ, payload });
  };

  const setSingleMessageRead = (id) => {
    dispatch({ type: SET_SINGLE_MESSAGE_READ, payload: id });
  };

  const setImage = (imagePath, content) => {
    // adding image to the last message with the loading state
    dispatch({ type: SET_IMAGE, payload: { imagePath, content } });
  };

  const removeImage = () => {
    dispatch({ type: REMOVE_IMAGE });
  };

  const sendImageLink = async (payload) => {
    const { userId, tenantId, imageLinkDetails, sessionId, channelName, emailConfig  } = payload || {};
    try {
      let payload = {
        chatSession: sessionId,
        userId,
        tenantId,
        sender: "agent",
        productDetails: imageLinkDetails,
        channelName: channelName === 'chat' ? 'Live Chat': channelName,
        messageTypeFromClient: 'product'
      }
      if (channelName === 'mail') {
        payload = {
          ...payload,
          emailConfig
        }
      }
      if ((channelName === 'facebook' || channelName === 'instagram') && selectedComment) {
        payload = {
          ...payload,
          parentComment: selectedComment
        }
      }
      const result = await sendMessage(payload);
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const refreshChatSessions = () => {
    console.log("refreshChatSessions  called");
    setSessionLoading(true);
    setChatSessionOriginalData({});
    setChatSessions([]);
    // setCurrentSessionData({});
    setNormalMessage();
    fetchSessions(1, true);
  };

  const refreshChatSessionsListOnly = async () => {
    console.log("refreshChatSessionsListOnly  called");
    setSessionLoading(true);
    setChatSessionOriginalData({});
    setChatSessions([]);
    // setNormalMessage([])
    // setCurrentSessionData({});
    fetchSessions(1, true);
  };

  /**
   * Update the single session in message preview if id is provided it will add it to the top
   * If Id is not provided it will update the current message preview 
   */
  console.log({channelName})
  const updateSingleSession = async (id, channelName) => {
    try {

      // if (!id || !currentSessionData?._id || isTokenLoading) return;
      if (channelName) {
        const locationLength= location.pathname.split("/").length;
        let urlName = location.pathname.split("/")[locationLength-1]
        let pathChannelName = urlName  === 'chat' ? "Live Chat" : urlName === 'email' ? 'mail' :  urlName 
        if (pathChannelName !== channelName && pathChannelName !== "") return
      }

      
      console.log("Socket Update Single Session ");
      const result = await getUpdatedSingleChatSession(
        id || currentSessionData?._id
      );
      console.log("Socket New Session Messages ", {result :result.data})
      if (!result.data) return 
      if (id) {


      console.log("Update Single Session :: Result Data", result.data)
       setChatSessions((prev) => {
          console.log("Update Single Session :: Prev Value", [...prev]);

          let updatedValue = [...prev];
          let filteredData = updatedValue.filter((obj) => obj.ticketId !== result.data.ticketId);
          console.log("Update Single Session :: Filter Data", [...filteredData])

          const index = updatedValue.findIndex((obj) => obj.ticketId === result.data.ticketId );
          console.log("Update Single Session :: index", index);

          if (index === -1) {
            // add to the top if the ticket id is not found
            filteredData.unshift(result?.data);
          }else {
            // to add at the same place
            filteredData.splice(index, 0, result?.data);
          }
          console.log("Update Single Session :: Filter Data After Splice", [...filteredData]);
          calculateUnreadMessage(filteredData)
          // to add at the top 
          // filteredData.unshift(result?.data);
          return filteredData;
        });

        

        return;
      }
      // this will remove the unReadMessage, add label, add setAsPriority, add snooze 
      setChatSessions((prev) => {
        let updatedValue = [...prev];
        console.log({messageIndex})
        const index = updatedValue.findIndex((obj) => obj.ticketId === result.data.ticketId );
        updatedValue.splice(index, 1, result?.data);
        calculateUnreadMessage(updatedValue)
        return updatedValue;
      });
    } catch (error) {
      console.log(error);
    }
    setChatSessionOriginalData({});
  };

 
 console.log({currentSessionData}) 


  // useEffect(() => {
  //   console.log('useEffect called')
  //   refreshChatSessionsListOnly();
  // }, []);

  useEffect(() => {
    console.log("Use Effect Called for Refresh Sessions", assignedFilter);
    refreshChatSessionsListOnly();
  },
   [
    // inboxType,
    // channelName,
    // messageReceived, // New Message Received
    //     newUserJoined, // New User Joined
    // refreshNotification, // Refresh data
    // pathName, // Side Menu channel Option - }}}
    assignedFilter,
    labelFilterQuery,
    priorityFilter
  ]);

  const clearStoreData = () => {
    setOrderData([]);
    setSearchQuery("");
    setShoppingData([]);
  };

  return (
    <InboxContext.Provider
      value={{
        ...state,
        setCurrentChat,
        setNormalMessage,
        getChatMessage,
        sendMessages,
        socket,
        messageReceived,
        refreshNotification,
        setRefreshNotification,
        name,
        setName,
        backendMessage,
        setBackendMessage,
        messageSent,
        setMessageSent,
        currentSessionData,
        setCurrentSessionData,
        ticketId,
        setTicketId,
        labelFilterQuery,
        setLabelFilterQuery,
        imageUploadLoading,
        setImageUploading,
        setImage,
        removeImage,
        messageNotifications,
        setMessageNotification,
        sendImageLink,
        error,
        setError,
        highlight,
        setHighlight,
        text,
        setText,
        showImageLinkPreview,
        setShowImageLinkPreview,
        imageLinkDetails,
        setImageLinkDetails,
        quickReplySelected,
        setQuickReplySelected,
        orderData,
        setOrderData,
        searchQuery,
        setSearchQuery,
        openModal,
        setOpenModal,
        chatSessions,
        inboxType,
        sessionLoading,
        setSessionLoading,
        loadNextSession,
        getSelectedPath,
        refreshChatSessions,
        refreshChatSessionsListOnly,
        setPriorityFilter,
        priorityFilter,
        assignedFilter,
        setAssignedFilter,
        selectedFacebookPage,
        setSelectedFacebookPage,
        metaConfValue,
        setMetaConfValue,
        shoppingData,
        setShoppingData,
        clearStoreData,
        ticketNoteValue,
        setTicketNoteValue,
        ticketNoteRef,
        setCurrentPage,
        messageIndex,
        setMessageIndex,
        updateSingleSession,
        refreshMessageNotification,
        setRefreshMessageNotification,
        unReadMessage,
        calculateUnreadMessage,
        selectedComment,
        setSelectedComment,
        isMessagePrivate, 
        setIsMessagePrivate,
        deleteMessage,  
        totalMessage,
        page, 
        setPage,
        showStore ,
        setShowStore,
        fetchSessions,
        isProductAdded,
        setIsProductAdded,
        quillRef, 
        fbCommentCount,
        setFbCommentCount
      }}
    >
      {children}
    </InboxContext.Provider>
  );
};

InboxProvider.propTypes = {
  children: PropTypes.element,
};

const useInboxContext = () => {
  return useContext(InboxContext);
};
export { InboxProvider, useInboxContext, initialState };
