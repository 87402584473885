import React, { useState, useEffect } from "react";
import ReportStyles from "../../assets/styledComponents/Report";
import HeaderBar from "../../components/reusable/HeaderBar/HeaderBar";
import CustomDate from "../reusable/ReportFeature.js/CustomDate";
import { useTenantContext } from "../../context/TenantContext";
import { getAllUserSetup } from "../../service/service";
import ReportCard from "./ReportCard";
import ReportDropdown from "./CustomReportDropdown";
import TimelineIcon from "@mui/icons-material/Timeline";
import AvTimerRoundedIcon from "@mui/icons-material/AvTimerRounded";
import { Bar } from "react-chartjs-2";
import { responseDayType } from "./dummyData";
import TimelapseOutlinedIcon from "@mui/icons-material/TimelapseOutlined";
import { getAllResolutionReport, getLabel } from "../../service/service";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
} from "chart.js";
import { ShowChart } from "@mui/icons-material";
import FullPageSpinner from "../FullPageSpinner";
import { generateChartLabels } from "./helper";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip
);

const ResolutionReport = () => {
  const [showCard, setShowCard] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [reportCardData, setReportCardData] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reportData, setReportData] = useState();
  const [backendError, setBackendError] = useState({ msg: "", type: "error" });
  const [userDetails, setUserDetails] = useState([]);
  const [snackMessage, setSnackMessage] = useState({ msg: "", type: "error" });
  const [resolutionFilterValue, setResolutionFilterValue] = useState(
    "Average First response time"
  );
  const [channelFilterValue, setChannelFilterValue] = useState("All");
  const [labelFilterValue, setLabelFilterValue] = useState("All");
  const [userFilterValue, setUserFilterValue] = useState("All");
  const [labelList, setLabelList] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const { tenantId } = useTenantContext();

  const fetchAllLabelData = async () => {
    try {
      const result = await getLabel(tenantId, "");
      if (result.data) {
        const newData = [...result.data];
        let labelValue = newData.map((item, index) => {
          return { name: item.labelName, id: index, value: item._id };
        });
        setLabelList([{ name: "All", value: "" }, ...labelValue]);
      } else {
        // Handle the case where result.data is undefined or empty
      }
    } catch (error) {
      setBackendError({ msg: error.response.data.message, type: "error" });
    }
  };

  useEffect(() => {
    fetchAllLabelData();
  }, []);

  const fetchUserSetup = async () => {
    try {
      const result = await getAllUserSetup({ tenantId });
      if (result.data) {
        let userValue = result.data?.map((item) => {
          return { name: item.name, value: item._id };
        });
        setUserDetails([{ name: "All", value: "" }, ...userValue]);
      } else {
        console.log("error");
      }
    } catch (error) {
      setBackendError({ msg: error.response.data.message, type: "error" });
    }
  };

  useEffect(() => {
    fetchUserSetup();
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
          display: false,
        },
      },
      y: {
        grid: {
          drawOnChartArea: false,
          display: false,
        },
        beginAtZero: true,
        min: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        titleColor: "white",
        bodyColor: "white",
        callbacks: {
          title: function (context) {
            return context.raw;
          },
          label: function (context) {
            return `Value: ${context.raw}`;
          },
        },
      },
    },
    elements: {
      bar: {
        categoryPercentage: 0.5,
        max: {
          barThickness: 10, // Set your desired maximum width for the bars
        },
      },
    },
  };

  const generateChartData = async (
    originalChartData,
    customStartDate = startDate,
    customEndDate = endDate
  ) => {
    const labels = generateChartLabels(customStartDate, customEndDate);
    const dataMap = {};

    await Promise.all(
      labels.map((label) => {
        dataMap[label] = 0;
      })
    );

    await Promise.all(
      originalChartData.chartData.map((chartObj) => {
        const label = chartObj.label;
        console.log("Chart Obj", {
          label,
          averageResolutionTime: chartObj.averageResolutionTime,
        });

        console.log("Res---", labels, {
          chartData: originalChartData.chartData,
        });

        if (resolutionFilterValue === "Average First response time") {
          dataMap[label] = chartObj.averageResponseTime;
        } else if (resolutionFilterValue === "Average resolution time") {
          dataMap[label] = chartObj.averageResolutionTime;
        } else if (
          resolutionFilterValue === "Average response until resolution"
        ) {
          dataMap[label] = chartObj.averageResponsesUntilResolutions;
        }
      })
    );

    console.log("Data Map", dataMap);

    const resultChartData = {
      labels: labels,
      datasets: [
        {
          label: "Dataset 1",
          data: await Promise.all(labels.map((label) => dataMap[label])),
          backgroundColor: "#8CB077",
        },
      ],
    };

    console.log("Result Chat Data ", resultChartData);

    setGraphData(resultChartData);
  };

  useEffect(() => {
    generateChartData(reportCardData);
  }, [resolutionFilterValue]);

  const handleChange = (event) => {
    event.preventDefault();
    const selectedValue = event.target.value;
    const targetId = event.target.name;
    console.log("Handle Change Called", selectedValue, event.target);
    if (targetId === "average-filter") {
      setResolutionFilterValue(selectedValue);
    } else if (targetId === "channel-filter") {
      setChannelFilterValue(selectedValue);
    } else if (targetId === "user-filter") {
      setUserFilterValue(selectedValue);
    } else if (targetId === "label-filter") {
      setLabelFilterValue(selectedValue);
    }
  };

  const handleClick = async (formattedStartDate, formattedEndDate, selectedShop) => {
    console.log({selectedShop})
    const filterValue = {
      avgFirstResponseTime: resolutionFilterValue,
      channel: channelFilterValue,
      user: userFilterValue,
      label: labelFilterValue,
    };
    try {
      setLoading(true);
      const result = await getAllResolutionReport(
        tenantId,
        formattedStartDate,
        formattedEndDate,
        filterValue,
        selectedShop
      );
      setReportCardData(result);
      setShowCard(true);
      setShowDropdown(true);
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
      generateChartData(result, formattedStartDate, formattedEndDate);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    <ReportStyles>
      <div className="resolution-container">
        <HeaderBar
          title="Resolutions Report"
          notification="Data last updated 5min ago"
          hidePrimaryButton={true}
          hideSecondaryButton={true}
          showDescription={true}
          description="Gain Insight into how long it takes for your company to respond to customers"
          paperHeader={true}
        />
        <div className="date-container-wrapper">
          <CustomDate
            setShowCard={setShowCard}
            setShowDropdown={setShowDropdown}
            setGraphData={setGraphData}
            setReportCardData={setReportCardData}
            setLoading={setLoading}
            isLoading={isLoading}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            generateChartData={generateChartData}
            handleClick={handleClick}
          />
        </div>

        {isLoading ? (
          <FullPageSpinner />
        ) : (
          <div className="sub-container" style={{ display: "flex" }}>
            {showCard && (
              <div className="report-card paper-card time-card">
                <ReportCard
                  name="Average first response time"
                  icon={<TimelineIcon />}
                  time1={reportCardData?.averageResponseTime || 0}
                  unit="mins"
                />
                <ReportCard
                  name="Average resolution time"
                  icon={<TimelapseOutlinedIcon />}
                  time1={reportCardData?.averageResolutionTime || 0}
                  unit="mins"
                />
                <ReportCard
                  name="Average responses until resolution"
                  icon={<AvTimerRoundedIcon />}
                  time1={reportCardData?.averageResponsesUntilResolutions || 0}
                />
              </div>
            )}
            {showDropdown && (
              <ReportDropdown
                name={userDetails}
                labelName={labelList}
                handleChange={handleChange}
                resolutionFilterValue={resolutionFilterValue}
                channelFilterValue={channelFilterValue}
                userFilterValue={userFilterValue}
                labelFilterValue={labelFilterValue}
              />
            )}
            {showDropdown && (
              <div className="graph-container paper-card">
                <div className="chart">
                  {reportCardData?.chartData?.length ? (
                    Object.keys(graphData).length > 0 && (
                      <Bar options={options} data={graphData} />
                    )
                  ) : (
                    <div>No Data available</div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </ReportStyles>
  );
};

export default ResolutionReport;
