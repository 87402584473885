import styled from "styled-components";
const ChatWithUsStyles = styled.div`
  .chat-container {
    height: calc(60vh - 60px);
    overflow-y: auto;
    padding: 0 10px;
  }


  .disable-cursor {
    cursor: none !important;
  }


  .msg-input {
    height: 70px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.33);
    margin: 8px;
  }
  .send-btn {
    color: ${({ textColor }) => textColor};
    background-color: ${({ themeColor }) => themeColor};
    border-radius: 10px;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
  .icons-container {
    display: flex;
    gap: 1rem;
    margin-left: 1.5rem;
  }
  .track-order-container {
  }

  .continue-chat {
    padding: 0px;
    float: right;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    font-family: Inter, sans-serif;
    font-size: 0.85rem;
    background-color: transparent;
    color: rgb(99, 82, 82);
    outline: none;
    border: 2px solid transparent;
    border-radius: 0.5rem;
    transition: all 0.3s ease 0s;
    margin: 0px 10px 10px;
    width: 80%;
    text-transform: capitalize;
  }
  
  #footer {
    font-size: 14px;
    text-align: center;
    color: rgb(133, 130, 130);
    margin: 0;
  }

  #image-attachment-stamp {
  }


  .loading-container {
    height: 60px;
  }
`;
export default ChatWithUsStyles;
