import React from "react";
import StoreStyles from "../../assets/styledComponents/shopifyStore/Store";
import ProductListSkeleton from "../skeleton/ProductListSkeleton";
import { useTenantContext } from "../../context/TenantContext";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import ProductList from "../../pages/DashboardPage/ProductList";
import styled from 'styled-components'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CopilotProductList from "../../pages/DashboardPage/CopilotProductList";

const ProductLists = ({ setShowProduct, productData , setMessage}) => {
  const { profile, selectedShop } = useTenantContext();
  const { error, searchQuery,  } = useInboxContext();
  const handleBack = () => {
    setShowProduct(false)
    setMessage([])
  }
  return (
    <ProductListsStyles >
      {productData.map((item, index) => {
        const product = item || {};
        return (
          <div className="" key={index}>
            <CopilotProductList {...product} shopName={selectedShop} />
          </div>
        );
      })}
    </ProductListsStyles>
  );
};

export default ProductLists;

const ProductListsStyles = styled.div`
  .product-list-container {
    height: 650px;
    overflow-y: scroll;
  }
  .back-btn {
    margin-left: auto;
    display: block;
    margin-right: 1rem;
    margin-top: .5rem;
  }

`