import styled from "styled-components";
const MyProfileMenuStyles = styled.div`
  width: 196px;
  /* width: 200px; */
  border-right: 1px solid var(--border-clr);

  .list-container {
    margin-top: 1rem;
  }
  h3 {
    margin-left: 1rem;
    margin-top: 2rem;
    font-size: 16px;
    font-weight: 500;
  }

  .report-list {
    padding: 1rem;
    position: relative;
    cursor: pointer;
  }
  .report-list::after {
    content: "";
    position: absolute;
    left: 5%;
    bottom: 0;
    width: 90%;
    height: 1px;
    background-color: var(--border-clr);
  }
  .active {
    background-color: var(--primary-100);
  }
`;
export default MyProfileMenuStyles;
