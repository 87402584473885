import styled from "styled-components";
const ShopifyCardDetailStyles = styled.div`
  .logo-container {
    background: white;
    float: left;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 7px;
    border-radius: 5px;
  }

  .logo-container img {
    height: 40px;
    width: 40px;
  }

  .head-section .title {
    font-weight: 500;
    line-height: 64px;
    font-size: 24px;
    margin-left: 20px;
  }

  .features {
    clear: both;
    padding-left: 20px;
    margin-top: 35px;
  }

  .features .shopify-list .container {
    clear: both;
    display: block;
    height: auto;
  }

  .features .shopify-list .container svg {
    display: inline-block;
    float: left;
    margin: 3px !important;
    margin-bottom: 10px !important;
  }

  .features .shopify-list .container span {
    display: inline-block;
    font-size: 18px;
    float: left;
    line-height: 30px;
    margin-left: 20px;
    margin-bottom: 10px !important;
  }

  .action-button {
    clear: both;
    float: right;
    margin-top: 30px;
  }
`;
export default ShopifyCardDetailStyles;
