import React, { useCallback, useRef, useState } from "react";

const useInfiniteScroll = () => {
  const [page, setPage] = useState(1);

  const intObserver = useRef();
  const lastPostRef = useCallback((node) => {
    if (!node) return;
    if (intObserver.current) intObserver?.current?.disconnect();
    intObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        // setPage((prev) => prev + 1);
      }
    });
    if (node) intObserver.current.observe(node);
  }, []);
  return [intObserver, lastPostRef, page, setPage];
};

export default useInfiniteScroll;
