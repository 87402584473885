import React from "react";
import { getFilename } from "../../utils/helperFunction";
import AttachmentIcon from "@mui/icons-material/Attachment";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadingProgressBar from "../reusable/DownloadingProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownLong, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const MessageAttachment = ({ url, size, channelName   }) => {
  const [filename, extension] = getFilename(url, channelName);
  const decodedFileName = decodeURIComponent(filename);
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = url;
    link.download = `${filename}.${extension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <>
      <div className="file-wrapper">
        <div className="file-icon">
          <FontAwesomeIcon icon={faFilePdf} style={{  color: "var(--primary-900)", fontSize: "32px" }} />
        </div>
        <div>
            <div className="file-name">{`${decodedFileName}.${extension}`}</div>
            <div className="file-size">{size}</div>
        </div>
        <div className="download-icon" onClick={handleDownload}>
          <FontAwesomeIcon icon={faDownLong} style={{  color: "var(--primary-900)" }} />
        </div>
      </div>
    </>
  )
  // return (
  //   <div className="file-wrapper">
  //     <div className="file-container">
  //       <div className="file-icon">
  //         {/* <AttachmentIcon /> */}
  //         {/* <InsertDriveFileIcon style={{ width: "40px", height: "40px" }}/> */}
  //         <FontAwesomeIcon icon={faFilePdf} style={{  color: "var(--primary-900)", fontSize: "32px" }} />
  //       </div>
  //       <div className="file-details">
  //         <p className="file-name">{`${filename}.${extension}`}</p>
  //         <div className="file-extension">
  //           {/* <p>{extension}</p> */}
  //           <p>{size}</p>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="download-icon" onClick={handleDownload}>
  //         <FontAwesomeIcon icon={faDownLong} style={{  color: "var(--primary-900)" }} />
   
  //     </div>
  //   </div>
  // );
};

export default MessageAttachment;
