import React from "react";
import ReportMenu from "../../components/Report/ReportMenu";
import { Outlet } from "react-router-dom";
import { useTenantContext } from "../../context/TenantContext";
import NonAuthenticated from "../NonAuthenticated";
import NotAuthorized from "../NotAuthorized";

const Report = () => {
  const { profile } = useTenantContext();
  const role = profile?.role; // "Owner" || "Agent" || "Admin"
  console.log({profile, role})
  return role === "Owner" || role === "Admin" ? (
    <div style={{ display: "flex"}}>
      <ReportMenu />
      <div style={{ flex: "1", backgroundColor : '#f9f9f9ed' }}>
      <Outlet />
      </div>
    </div>
  ) : (
    <NotAuthorized />
  );
};

export default Report;
