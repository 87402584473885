import styled from "styled-components";

const MessageViewNewStyles = styled.div`
  /* margin-top: 20px; */
  .msg-wrapper {
    display: flex;
    flex-direction: column;
  }
  .bubble .msg:hover {
    cursor: pointer;
    background-color: ${({ bgColor }) => bgColor};
    color: white;
  }
  .receiver-container {
    width: 80%;
    align-self: flex-start;
  }

  .sender-container {
    align-self: flex-end;
    margin-left: auto;
  }
  .receiver-container {
    align-self: flex-start;
  }
  .receiver-container .msg {
    border-radius: 12px 12px 12px 0px;
    padding: 14px 8px;
    /* width: fit-content; */
    /* margin: 4px 20% 8px 4px; */
    /* clear: both; */
    background: #dbdbdb;
    color: black;
    font-size: 14px;
  }
  .txt-container-msg {
    /* margin-right: 0px !important;
    margin-left: 80px !important; */
  }

  .sender-container {
  }

  .ticket-note {
    margin-left: 0 !important;
    text-align: center;
  }

  .sender-container .msg {
    /* color: ${({ textColor }) => textColor}; */
    background-color: white;
    border-radius: 12px 12px 0px;
    border: 1px solid silver;
  }

  .sender-container .time {
    float: right;
  }

  .txt-container-msg .msg {
    padding: 12px;
    // border-radius: 8px;
    font-size: 16px;
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2); */
    width: fit-content;
    /* border-radius: 12px 12px 0px; */
    border-radius: 12px 12px 12px 0px;
  }

  .txt-container-msg .time {
    margin-top: 0.2rem;
    font-size: 12px;
    color: rgb(119, 119, 119);
    margin-bottom: 14px;
    margin-left: 4px;
  }
  .image-container {
    float: right;
  }
  .time {
    display: flex;
    align-items: center;
  }

  .disable {
    background-color: ${({ bgColor }) => bgColor} !important;
    color: white;
    pointer-events: none;
    cursor: not-allowed;
  }
`;
export default MessageViewNewStyles;
