import React, { useContext, useEffect, useState } from "react";
import FacebookStyles from "../../../assets/styledComponents/settings/FacebookIntegration";
import { Button } from "@mui/material";
import CustomSwitch from "../../CustomSwitch";
import InputLabel from "@mui/material/InputLabel";
import Rectangle73 from "../../../assets/images/Rectangle 73.png";
import CustomTextField from "../CustomTextField";
import { MenuItem, Select, FormControl } from "@mui/material";
import HeaderBar from "../HeaderBar/HeaderBar";
import CustomSelect from "../CustomSelect";
import { useInboxContext } from "../../../context/inboxContext/inboxContext";
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import _ from 'lodash';
import { useTenantContext } from "../../../context/TenantContext";

// const pages = [{ value: 'a', label: 'a' }, { value: 'b', label: 'b' }];

const MetaIntegrationForm = ({ 
  head,
  title,
  switch1,
  switch2,
  tabValue,
  setDisableSaveBtn,
  stateValues
 }) => {


  const { 
    storeTabs, 
    setStoreTabs,
    originalStoreTabs,
    setOriginalStoreTabs,
    setIsDataEqual,
    activeTab,
    handleDelete
  } = stateValues;
  const { setSelectedFacebookPage, setMetaConfValue, metaConfValue } = useInboxContext();
  const {
    instagramPageName,
    instagramPages,
    selectedInstagramPage,
    facebookPage,
    selectedFacebookPage,
    comments,
    directMessages,
    selectedInstagramPageId,
  } = metaConfValue || {}

  const facebookPages = tabValue?.facebookPages.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const disableSelect = !!tabValue.activePage

  const { profile  } = useTenantContext();

  const userEmail = profile?.token?.email ?? ''

  const showCommentsToggle = process.env.REACT_APP_OFF_FB_COMMENTS ? process.env.REACT_APP_FB_BYPASS === userEmail : true

  console.log("Show Comment Toggle ---", process.env.REACT_APP_OFF_FB_COMMENTS ? process.env.REACT_APP_FB_BYPASS === userEmail : true, process.env.REACT_APP_OFF_FB_COMMENTS,  process.env.REACT_APP_FB_BYPASS , userEmail )

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setMetaConfValue((prev) => ({ ...prev, [name]: checked }));
    if (type === "textarea") {
      setMetaConfValue((prev) => ({ ...prev, [name]: value }));
    }
    if (type === "checkbox") {
      setMetaConfValue((prev) => ({ ...prev, [name]: checked }));
    }
    if (type === undefined) {
      setMetaConfValue((prev) => ({ ...prev, [name]: value }));
    }

    const isEql = _.isEqual(
      originalStoreTabs[activeTab].activePage,
      storeTabs[activeTab].activePage
    )
    setIsDataEqual(isEql)

  };

  return (
    <FacebookStyles>
      <div className="container">
        <div className="personalize-heading">
          <h4>Personalize Your Chat Widget</h4>
          <p>Customize the widget as per your interest</p>
        </div>
        <div className="general">
          {/* <span>General</span> */}
        </div>
        {/* <div className="facebook-Page">
          <span>{title}</span>
        </div>
        <CustomTextField
          sx={{
            width: 455,
            marginBottom: 3,
            marginTop: 1,
          }}
          startAdornment={
            <img
              src={Rectangle73}
              style={{ width: 55, marginLeft: -13, marginTop: 2 }}
            />
          }
          placeholder={"Name of Page"}
          margin={"normal"}
          name={"instagramPageName"}
          value={instagramPageName}
          onChange={handleChange}
        /> */}
        {/* <div className="brand">
          <span>Choose you instagram business page</span>
          <p>which shopify brand associated with this account ?</p>
          <div className="custom-select">
            <CustomSelect
              name="selectedInstagramPage"
              label="Brand"
              width={455}
              // value={selectedInstagramPage}
              // list={pages}
              value={selectedFacebookPage}
              list={facebookPages}
              onChange={handleChange}
            />
          </div>
        </div> */}
        <div className="brand">
          <span>Choose you facebook business page</span>
          <p>which shopify brand associated with this account ?</p>
          <div className="custom-select">
            <CustomSelect
              name="selectedFacebookPage"
              label="Choose a page"
              // value={initialQueryValue}
              width={455}
              // value={selectedFacebookPage}
              value={selectedFacebookPage}
              list={facebookPages}
              onChange={handleChange}
              disabled={disableSelect}
            />
          </div>
        </div>
        <div className="instagram-account">
          <h3>Instagram Page</h3>
          <div className="connected">
            <span>{selectedInstagramPageId ? "Connected" : "Not Connected"}</span>
            {selectedInstagramPageId ? <TaskAltOutlinedIcon color="success" /> : <CancelOutlinedIcon color="warning" />}
            {/* <TaskAltOutlinedIcon color="success" />  */}
            {/* <CancelOutlinedIcon color ="warning" />  */}
          </div>
        </div>
        {showCommentsToggle && <div className="switch-container">
          <span>{switch1}</span>
          <div className="custom-Switch">
            <CustomSwitch
              name={"comments"}
              checked={comments}
              handleToggleSwitch={handleChange}
            />
          </div>
        </div>}
        <div className="switch-container">
          <span>{switch2}</span>
          <div className="custom-Switch">
            <CustomSwitch
              name={"directMessages"}
              checked={directMessages}
              handleToggleSwitch={handleChange}
            />
          </div>
        </div>
        <div className="delete-button">
          <Button onClick={handleDelete} variant="outlined">Delete Integration</Button>{" "}
        </div>
      </div>
    </FacebookStyles>
  );
};
export default MetaIntegrationForm;
