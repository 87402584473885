import styled from "styled-components";
const MetaConfigurationStyles = styled.div`
  width:79vw;
  .store-tab-container {
    margin-right: 40px;
    margin-left: 35px;
  }

`;
export default MetaConfigurationStyles;
