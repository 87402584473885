import React, { useEffect, useState } from "react";
import EmailConfigurationStyles from "../../../assets/styledComponents/channelConfiguration/EmailConfiguration";
import HeaderBar from "../../reusable/HeaderBar/HeaderBar";
import CustomSnackbar from "../../snackbar/CustomSnackbar";
import { Tabs, Tab, Button, TextField, Tooltip } from "@mui/material";
import CustomTextField from "../../reusable/CustomTextField";
import { useTenantContext } from "../../../context/TenantContext";
import {
  activateEmail,
  createDomain,
  createSender,
  deleteEmailIntegration,
  getAllTenantStore,
  verifyDomain,
  verifyPinCode,
} from "../../../service/service";
import PrimaryButton from "../../reusable/PrimaryButton/PrimaryButton";
import { DOMAIN_REGEX, EMAIL_REGEX, WORK_EMAIL_REGEX } from "../../../utils/constants";
import useClipboard from "../../../hooks/useClipboard";
import Swal from "sweetalert2";
import FullPageSpinner from "../../FullPageSpinner";
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import DnsTable from "./DnsTable";

  // const customDomainRegex = "lotuscrew.tech"; 
  // const WORK_EMAIL_REGEX = new RegExp(`^\\w+([\\.-]?\\w+)*@(?!gmail\\.com|yahoo\\.com|hotmail\\.com)[\\w-]+(\\.\\w+)*@${customDomainRegex}$`);
  // console.log({WORK_EMAIL_REGEX })

const initialState = {
  email: {
    show: true,
    name: "email",
    value: "",
    loading: false,
    disable: true,
    isCopy: false,
    placeholder: "Store@helpiq.email",
    label: "Email address for your customer support email channel",
    type: "email",
    error: "",
    validation: [],
    isActive: false,
    showCustomizeButton: false,
    showButton: true,
    buttonName: "Activate",
  },
  domain : {
    show: false,
    name: "domain",
    value: "",
    loading: false,
    disable: false,
    isCopy: false,
    placeholder: "helpiq.app",
    label: "Enter your  domain name",
    type: "text",
    error: "",
    validation: [
      { regex: DOMAIN_REGEX, errorMsg: "Please enter valid Domain" },
    ],
    isActive: false,
    showButton: true,
    buttonName: "Add Domain",
    domainDnsRecord  : [],
    isDomainActive: false,
    isDomainVerified : false,
  },
  domainEmail: {
    show: false,
    name: "domainEmail",
    value: "",
    loading: false,
    disable: false,
    isCopy: false,
    placeholder: "store@helpiq.tech",
    label: "Enter your Email address for your domain",
    type: "email",
    error: "",
    validation: [
      { regex: WORK_EMAIL_REGEX, errorMsg: "Please enter valid work email" },
      // { regex: EMAIL_REGEX, errorMsg: "Please enter a valid email" },
    ],

    isActive: false,
    showButton: true,
    buttonName: "Add Domain Email",
  },
  // pinCode: {
  //   show: false,
  //   name: "pinCode",
  //   value: "",
  //   loading: false,
  //   disable: false,
  //   isCopy: false,
  //   placeholder: "****",
  //   label: "Enter Pin",
  //   type: "number",
  //   error: "",
  //   validation: [
  //     { regex: /^[0-9]{6}$/, errorMsg: "Please enter a valid 6-digit number" },
  //   ],
  //   isActive: false,
  //   showButton: true,
  //   buttonName: "Verify",
  // },
  forwardingEmail: {
    show: false,
    name: "forwardingEmail",
    value: "",
    loading: false,
    disable: true,
    isCopy: false,
    placeholder: "Store@helpiq.email",
    label: "Forwarding Email Address",
    type: "email",
    error: "",
    validation: [],
    isActive: false,
    showButton: false,
  },
};

const EmailConfiguration1 = () => {
  const { tenantId } = useTenantContext();
  const [storeTabs, setStoreTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isCopied, copyToClipboard] = useClipboard();
  const [backendMessage, setBackendMessage] = useState({ msg: "", type: "" });
  const [formValue, setFormValue] = useState([{ ...initialState }]);
  const [loading, setLoading] = useState(false)

  const shop = storeTabs[activeTab]?.shop;

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchStores = async (tab) => {
    setLoading(true)
    try {
    if (!tenantId) return;
    const tabsRes = await getAllTenantStore(tenantId);
    setLoading(false)
    if (tabsRes.success) {
      setStoreTabs(tabsRes.data);
      // let formDataFromDb = [];
      const formDataFromDb = await Promise.all(tabsRes.data.map((item) => {
        const { emailConfiguration } = item;
        const {
          email,
          isActive,
          domainEmail,
          isDomainEmailActive,
          forwardingEmail,
          pincodeVerified,
          domain,
          domainDnsRecord, 
          isDomainActive, 
          isDomainVerified,
        } = emailConfiguration || {};

        let domainAddress = domain?.split('.')
        let customDomainRegex  = domainAddress?.length === 3 ?  domainAddress?.[1] + '.' + domainAddress?.[2] : domain
        const WORK_EMAIL_REGEX = new RegExp(`^[a-zA-Z0-9._%+-]+@${customDomainRegex}$`);
        return {
          ...initialState,
          email: {
            ...initialState.email,
            value: email,
            isActive,
            buttonName: isActive ? "Customize" : "Activate",
            showButton: !(isActive && domain),
          },
          domain : {
            ...initialState.domain,
            value: domain,
            disable : !!domain,
            showButton: !domain,
            show : (isActive && domain),
            domainDnsRecord, 
            isDomainActive : !!domain,
            isDomainVerified,
          },
          domainEmail: {
            ...initialState.domainEmail,
            value: domainEmail,
            isActive: !!domainEmail,
            show: !!domain,
            disable: !!domainEmail,
            showButton: !domainEmail,
            validation: [
              { regex: WORK_EMAIL_REGEX, errorMsg: "Please enter valid Domain" },
            ],
          },
          // pinCode: {
          //   ...initialState.pinCode,
          //   show: domainEmail && !pincodeVerified,
          // },
          forwardingEmail: {
            ...initialState.forwardingEmail,
            value: forwardingEmail,
            isActive: true,
            show: isActive,
          },
        };
      }));
      setFormValue(formDataFromDb);
      if (tab) {
        setActiveTab(tab);
      } else {
        setActiveTab(0);
      }
    }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchStores();
  }, []);
  const setError = (name, error) => {
    setFormValue((prev) => {
      let updatedValue = structuredClone(prev);
      let currentTab = structuredClone(updatedValue[activeTab]);
      currentTab[name] = { ...currentTab[name], error };
      updatedValue[activeTab] = currentTab;
      return updatedValue;
    });
  };
  const validateRegex = (regex, value) => {
    return regex.test(value);
  };

  const setFormState = (name, value) => {
    setFormValue((prev) => {
      let updatedValue = structuredClone(prev);
      let currentTab = structuredClone(updatedValue[activeTab]);
      currentTab[name] = { ...currentTab[name], ...value };
      console.log({ currentTab: currentTab[name] });
      updatedValue[activeTab] = currentTab;
      return updatedValue;
    });
  };

  const validate = (name, value, validation) => {
    let isValid = true;
    validation.forEach(({ regex, errorMsg }) => {
      if (!validateRegex(regex, value)) {
        isValid = false;
        setError(name, errorMsg);
        return;
      }
    });
    if (isValid) {
      setError(name, "");
    }
  };

  const handleInputChange = (e, validation) => {
    const { name, value } = e.target;
    if (name === "domainEmail") {
      validate(name, value, validation);
    }
    if (name === "pinCode") {
      // validate(name,value , validation)
      if (
        !(
          typeof value === "number" ||
          (!isNaN(parseFloat(value)) && !isNaN(value - 0))
        )
      ) {
        setError(name, "Pin code should be a number");
        if (value === "") {
          setError(name, "");
        } else {
          return;
        }
      }
      if (value.length > 6) return;
      if (value.length !== 6) {
        setError(name, "Pin code should be 6 digit long");
      } else {
        setError(name, "");
      }
    }
    if (name === 'domain') {
      validate(name, value, validation)
    }
    setFormState(name, { value });
  };

  const handleActivate = async (name, email) => {
    if (formValue[activeTab][name].error) return;
    setFormState(name, { loading: true });
    try {
      const result = await activateEmail({
        email,
        tenantId,
        shop,
        defaultEmail: true,
      });
      const forwardingEmail =result.data.emailConfiguration.forwardingEmail 
      setFormState(name, {
        showCustomizeButton: true,
        buttonName: "Customize",
        loading: false,
        isActive : true
      });

      setFormState('forwardingEmail', {
        show : true,
        value : forwardingEmail
      });
      setBackendMessage({ msg: result.message, type: "success" });
    } catch (error) {
      setFormState(name, { loading: false });
      console.log(error);
    }
  };

  const activeTabValue = formValue[activeTab];
  const handlePinCodeSubmit = async (name, value) => {
    if (activeTabValue[name].error) return;
    if (activeTabValue[name].value === "") return;
    setFormState(name, { loading: true });
    try {
      const result = await verifyPinCode({ tenantId, shop, pinCode: value });
      setFormState(name, { showButton: false, show: false, loading: false });
      setFormState("forwardingEmail", { show: true });
      setFormState("domainEmail", { isActive: true });
      setFormState("domain", { show: true });
      setBackendMessage({ msg: result.message, type: "success" });
      return;
    } catch (error) {
      setFormState(name, { loading: false });
      setBackendMessage({ msg: error.response.data.message, type: "error" });
    }
  };

  const handleSendPinCode = async (name, email) => {
    if (formValue[activeTab][name].error) return;
    if (activeTabValue[name].value === "") return;
    setFormState(name, { loading: true });
    try {
      const result = await createSender({ email, tenantId, shop });
      setFormState(name, { disable: true, showButton: false, isActive : true });
      // setFormState("pinCode", { show: true });
      setBackendMessage({ msg: result.message, type: "success" });
    } catch (error) {
      setFormState(name, { loading: false});
      setBackendMessage({ msg: error.response.data.message, type: "error" });
    }
  };

  const handleVerifyDomain = async (name, value) => {
    if (formValue[activeTab][name].error) return;
    if (activeTabValue[name].value === "") return;
    setFormState(name, { loading: true });
    try {


      const domain = activeTabValue[name].value; 
      let domainAddress = domain.split('.')
      let customDomainRegex  = domainAddress.length === 3 ?  domainAddress[1] + '.' + domainAddress[2] : domain
      const WORK_EMAIL_REGEX = new RegExp(`^[a-zA-Z0-9._%+-]+@${customDomainRegex}$`);
      const result = await createDomain({domainName:value, tenantId, shop}) 
      const dnsRecord = result?.message.data?.domainDnsRecord
      setBackendMessage({ msg: result.message.message, type: "success" });
      setFormState("domain", {
        loading: false,
        isDomainActive: true,
        value: value,
        showButton: false,
        domainDnsRecord: dnsRecord,
        disable: true,
      });

    } catch (error) {
      setFormState(name, { loading: false});
      console.log(error.response.data.message, 'error')
      setBackendMessage({ msg: error.response.data.message.message, type: "error" });
      
    }
  }

  const handleDnsVerify = async () => {
    try {
      const result = await verifyDomain({domainName : formValue[activeTab].domain.value, tenantId, shop})
      setBackendMessage({ msg: result.message.message, type: "success" });
      setFormState("domain", {
        isDomainVerified : true,
      });

      const domain = activeTabValue['domain'].value; 
      let domainAddress = domain.split('.')
      let customDomainRegex  = domainAddress?.length === 3 ?  domainAddress?.[1] + '.' + domainAddress?.[2] : domain
      const WORK_EMAIL_REGEX = new RegExp(`^[a-zA-Z0-9._%+-]+@${customDomainRegex}$`);

      setFormState("domainEmail", {
        show: true,
        validation: [
          { regex: WORK_EMAIL_REGEX, errorMsg: "Please enter valid Domain" },
           ],
      });

    } catch (error) {
      console.log(error)
      setBackendMessage({ msg: error.response.data.message.message, type: "error" });
    }
    
  }

  const handleButtonClick = async (e, name, value, buttonName) => {
    if (formValue[activeTab][name].error) return;
    switch (name) {
      case "email":
        if (buttonName === "Activate") await handleActivate(name, value);
        if (buttonName === "Customize") {
          // setFormState("domainEmail", { show: true });
          setFormState("domain", { show: true});
          setFormState("email", { showButton: false });
        }
        break;
      case "domainEmail":
        await handleSendPinCode(name, value);
        break;
      case "pinCode":
        await handlePinCodeSubmit(name, value);
        break;
      case "domain":
        await handleVerifyDomain(name,value)
        break;
      default:
        console.log("default");
    }
  };

  const handleCopyToClipboard = (name, value) => {
    copyToClipboard(value);
    setFormState(name, { isCopy: true });
  };

  const handleDeleteIntegration = async () => {
    try {
      const result = await Swal.fire({
        title: "Are you sure ?",
        text: "Upon deleting the Email account, all associated chats will be permanently removed ",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#8db178",
        cancelButtonColor: "#D9D9D9",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        const result = await deleteEmailIntegration(tenantId, shop);
        if (result) {
          Swal.fire({
            title: "Deleted!",
            text: `Successfully deleted the Email account from HelpIQ. You will no longer receive its mail in HelpIQ.`,
            confirmButtonColor: "#8db178",
            icon: "success",
          });
        }
        fetchStores(activeTab);
      }
    } catch (error) {
      console.log(error);
    }
  };
  if (loading) {
    return <FullPageSpinner/>
  }

  return (
    <EmailConfigurationStyles>
      {backendMessage.msg && <CustomSnackbar payload={backendMessage} />}
      <HeaderBar
        title={"Email"}
        showDescription={true}
        description={"Setup Your Email Widget"}
        hideSecondaryButton={true}
        hidePrimaryButton={true}
      />
      <div className="container">
        <div className="store-tab-container">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {console.log("Store Tabs --", storeTabs)}
            {storeTabs.map((tabData) => (
              <Tab
                label={`${tabData.shop || "New Account"}  ${
                  tabData.isDefault ? "- Default" : ""
                }`}
                key={`${tabData.shop || "New Account"}  ${
                  tabData.isDefault ? "- Default" : ""
                }`}
              />
            ))}
          </Tabs>

          <div className="wrapper">
            <div className="tab-content">
              {Object.values(formValue[activeTab]).map((item) => {
                let {
                  show,
                  name,
                  value,
                  loading,
                  disable,
                  isCopy,
                  placeholder,
                  label,
                  type,
                  error,
                  validation,
                  isActive,
                  domainDnsRecord,
                  showCustomizeButton,
                  showButton,
                  buttonName,
                } = item || {};
                if (!show) return;
                console.log({name,value})
                // if (name === 'pinCode') placeholder = formValue[activeTab].domainEmail.value
                return (
                  <div className="form-container" key={label}>
                    <h3 className="label">{label}</h3>
                    {name === "pinCode" && (
                      <p className="enter-pin-description">
                        HelpIq uses brevo to underlying email provider. A pin
                        code has been dispatched from Brevo on behalf of HelpIQ
                        to
                        <b> {formValue[activeTab].domainEmail.value}</b> Kindly
                        provide the pin code for verification
                      </p>
                    )}
                    <div className="input-box">
                      <div className="verification-input-box">
                        <CustomTextField
                          name={name}
                          type={type}
                          required
                          placeholder={placeholder}
                          value={value}
                          disabled={disable}
                          onChange={(e) => handleInputChange(e, validation)}
                          width={500}
                          error={!!error}
                          helperText={error}
                        />
                        {isActive &&
                          (isCopy ? (
                            <Tooltip
                              title="copied to clipboard"
                              placement="bottom"
                            >
                              <button
                                onClick={() =>
                                  handleCopyToClipboard(name, value)
                                }
                              >
                                <i className="fa-solid fa-copy clipboard-icon"></i>
                              </button>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="copy to clipboard"
                              placement="bottom"
                            >
                              <button
                                onClick={() =>
                                  handleCopyToClipboard(name, value)
                                }
                              >
                                <i className="fa-regular fa-copy clipboard-icon"></i>
                              </button>
                            </Tooltip>
                          ))}

                        {showButton && (
                          <PrimaryButton
                            disabled={loading}
                            onClick={(e) =>
                              handleButtonClick(e, name, value, buttonName)
                            }
                            type="submit"
                            variant="contained"
                          >
                            {buttonName}
                          </PrimaryButton>
                        )}
                      </div>
                    </div>
                    {name === "domain" &&
                      (formValue[activeTab].domain.isDomainActive && !formValue[activeTab].domain.isDomainVerified) &&  (
                      // (formValue[activeTab].domain.isDomainActive) &&  (
                        <div className="dns-settings">
                          <div className="dns-header">
                          <h3 className="dns-heading">DNS Settings</h3>
                          <p className="dns-sub-heading" style={{ float: 'left'}}>
                            Go to your DNS provider and add DKIM and Return Path DNS records to verify your domain and ensure effective delivery.
                          </p>
                          <div className="dns-btn">
                          {formValue[activeTab].domain.isDomainVerified ? <div className="verify-container">
                            <span>Verified</span>
                            <TaskAltOutlinedIcon color="success" />
                          </div> : 
                            <PrimaryButton onClick={(e) => handleDnsVerify(name, e)}>Verify</PrimaryButton>
                          }

                          </div>
                          </div>
                          <DnsTable domainDnsRecord={domainDnsRecord} handleDnsVerify={handleDnsVerify}/>
                        </div>
                      )}
                  </div>
                );
              })}
            </div>

            {formValue[activeTab].domainEmail.isActive && (
              <>
                <h3 className="heading">
                  Add {formValue[activeTab].forwardingEmail.value} as a
                  forwarding email to your domain provider
                </h3>

                <h4 className="sub-heading">
                  The setup process for your domain provider can be found below
                </h4>
                <ul className="domain-name">
                  <li>
                    <a href="#">NameCheap</a>
                  </li>
                  <li>
                    <a href="#">godaddy</a>
                  </li>
                  <li>
                    <a href="#">domain.com</a>
                  </li>
                  <li>
                    <a href="#">bluehost</a>
                  </li>
                  <li>
                    <a href="#">name.com</a>
                  </li>
                </ul>
              </>
            )}
            {formValue[activeTab].email.isActive && (
              <div className="delete-btn">
                <Button variant="outlined" onClick={handleDeleteIntegration}>
                  Delete Integration
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </EmailConfigurationStyles>
  );
};

export default EmailConfiguration1;
