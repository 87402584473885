import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TenantProvider } from "./context/TenantContext";
import { InboxProvider } from "./context/inboxContext/inboxContext";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMoon, faRocket, faCheckDouble, faComments } from "@fortawesome/free-solid-svg-icons";
import { clarity } from 'react-microsoft-clarity';

library.add(faMoon, faRocket, faCheckDouble, faComments);

const theme = createTheme({
  palette: {
    primary: {
      main: "#8db178",
      black: "#000000",
    },
  },
});

clarity.init('mjzjdh2jjg');

function App() {
  return (
    <ThemeProvider theme={theme}>
      <TenantProvider>
        <InboxProvider>
          <div>
            <PrivateRoute />
            <PublicRoute />
          </div>
        </InboxProvider>
      </TenantProvider>
    </ThemeProvider >
  );
}

export default App;
