import { useEffect, useState } from "react";
import { getAllAutoReply, getAllTenantStore } from "../service/service";
import { useTenantContext } from "../context/TenantContext";
import _ from "lodash";
import { autoReplyErrors } from "../utils/helperData";

const useShopifyConf = (initialData, setErrors) => {
  const { tenantId } = useTenantContext();
  const [storeTabs, setStoreTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [tabContent, setTabContent] = useState([{ ...initialData }]);
  const [activeTabContent, setActiveTabContent] = useState([]);
  const [tabContentExist, setTabContentExits] = useState([]);
  const [fetchedTabContent, setFetchedTabContent] = useState([]);
  const [changes, setChanges] = useState([]);

  const handleChange = (content) => {
    const currentContent = content ? content  : tabContent 
    const sortedTabContent = _.sortBy(structuredClone(currentContent), "createdAt");
    const sortedFetchedTabContent = _.sortBy(structuredClone(fetchedTabContent), "createdAt");
    const isEqual = _.isEqual(sortedTabContent, sortedFetchedTabContent);
    const isEqualArray = sortedTabContent.map((tabObj, index) => {
      const fetchedObj = sortedFetchedTabContent[index];
      return _.isEqual(tabObj, fetchedObj) ? 1 : 0;
    });
    console.log(isEqualArray, "isEqu");
    setChanges(isEqualArray);
  };
  console.log(changes, "changes");

  const fetchStoreTabs = async () => {
    const tabsRes = await getAllTenantStore(tenantId);
    const autoReplyRes = await getAllAutoReply(tenantId);
    const tabResData = tabsRes.data
    const autoReplyResData = autoReplyRes.data
    const groupedByStore = _.groupBy(tabResData.concat(autoReplyResData), 'shop');
    let values = Object.values(groupedByStore);
    let currentValue = values.map((item) => item[1] ? item[1] : { ...initialData, shop: item[0] })
    console.log({values, currentValue, groupedByStore})
    setTabContent(currentValue);
    if (tabsRes.success) {
      setStoreTabs(tabsRes.data);
      setActiveTab(0);
      let content = [];
      let contentExist = [];
      let change = [];
      let error = [];
      for (const tab of tabsRes.data) {
        content.push({ ...initialData, shop: tab.shop });
        contentExist.push(0);
        change.push(1);
        error.push({ ...autoReplyErrors[0] });
      }
      setErrors(error);
      // setTabContent(content);
      // setTabContentExits(contentExist);
      setChanges(change);
      // setTabContent([])
      //   setSelectedWidgetShop(tabsRes.data[0].shop);
      return tabsRes.data;
    }
      handleChange();

  };

  const handleTabChange = (event, tabNumber) => {
    setActiveTab(tabNumber);
  };

  const handleTabContentChange = (e, activeTab) => {
    const { name, value } = e.target;
    setTabContent((prev) => {
      let updatedValue = [...prev];
      updatedValue[activeTab][name] = value;
      return updatedValue;
    });
    setErrors((prev) => {
      let updatedValue = [...prev];
      updatedValue[activeTab] = { ...updatedValue[activeTab], [name]: "" };
      return updatedValue;
    })
    handleChange();
  };

  const handleCustomizeDaysChange = (event, activeTab, currentTab) => {
    console.log({ activeTab });
    const { name, checked } = event.target;
    setTabContent((prev) => {
      let updatedValue = structuredClone([...prev]);
      updatedValue[activeTab].customizeDays[name] = checked;
      return updatedValue;
    });
    setTabContent((prev) => {
      let updatedValue = [...prev];
      let customizeDaysValue = Object.values(
        updatedValue[activeTab]?.customizeDays
      );
      const isCustomizeDaysOpen = customizeDaysValue.every(
        (item) => item === false
      );
      updatedValue[activeTab].toggleSwitch = !isCustomizeDaysOpen;
      handleChange(updatedValue);
      return updatedValue;
    });
  };

  const handleToggleSwitch = (e, activeTab) => {
    setTabContent((prev) => {
      let updatedValue = [...prev];
      updatedValue[activeTab].toggleSwitch = e.target.checked;
      return updatedValue;
    });
    handleChange();
  };

  const setParticularTabContent = (currentTabContent, activeTab) => {
    setTabContent((prev) => {
      let updatedValue = [...prev];
      updatedValue[activeTab] = currentTabContent;
      return updatedValue;
    });
  };

  const updateTabContentExist = (activeTab) => {
    setTabContentExits((prev) => {
      let updatedValue = [...prev];
      updatedValue[activeTab] = 1;
      return updatedValue;
    });
  };

  const setTabContentValue = (currentTabContent) => {
    let length = currentTabContent.length;
    console.log({ length });
    let stores = [...storeTabs];
    // setTabContentExits((prev) => {
    //   let updatedValue = [...prev];
    //   for (let i = 0; i < stores.length; i++) {
    //     console.log({ shop111: stores[i].shop });
    //     let outerShop = stores[i].shop;
    //     for (let j = 0; j < length; j++) {
    //       let innerShop = currentTabContent[j].shop;
    //       console.log(currentTabContent[j].shop, "shop11");
    //       if (outerShop === innerShop) {
    //         updatedValue[i] = 1;
    //       } else {
    //         updatedValue[i] = 0;
    //       }
    //     }
    //   }
    //   return updatedValue;
    // });
    setTabContentExits((prev) => {
      let updatedValue = [...prev];
      for (let i = 0; i < length; i++) {
        updatedValue[i] = 1;
      }
      return updatedValue;
    });
    // setTabContent((prev) => {
    //   return prev.map((prevItem) => {
    //     const matchingItem = currentTabContent.find(
    //       (currentItem) => currentItem.shop === prevItem.shop
    //     );
    //     return matchingItem ? { ...matchingItem } : { ...prevItem };
    //   });
    // });
  };

  const handleChipDelete = (currentValue, activeTab) => {
    setTabContent((prev) => {
      let updatedValue = [...prev];
      updatedValue[activeTab].channel = updatedValue[activeTab].channel.filter(
        (item) => item !== currentValue
      );
      return updatedValue;
    });
    handleChange() 
  };
  const disableSaveBtn = (activeTab) => {
    setChanges((prev) => {
      let updatedValue = [...prev];
      updatedValue[activeTab] = 1;
      console.log({ updatedValue });
      return updatedValue;
    });
  };
  console.log({ fetchedTabContent });
  const discardChanges = (activeTab) => {
    let fetchedTabDeepCopy = structuredClone(fetchedTabContent);
    setTabContent((prev) => {
      let updatedValue = [...prev];
      let currentShop = storeTabs[activeTab]?.shop;
      let currentFetchedContent = fetchedTabDeepCopy.filter((item) => {
        return item.shop === currentShop;
      });
      console.log({ currentFetchedContent });
      if (currentFetchedContent.length > 0) {
        updatedValue[activeTab] = currentFetchedContent[0];
      }
      return updatedValue;
    });
    setChanges((prev) => {
      let updatedValue = [...prev];
      updatedValue[activeTab] = 1;
      return updatedValue;
    });
  };

  const fetchData = async () => {
    await fetchStoreTabs();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return [
    storeTabs,
    activeTab,
    tabContent,
    setFetchedTabContent,
    setTabContentValue,
    setParticularTabContent,
    activeTabContent,
    tabContentExist,
    changes,
    handleTabChange,
    handleTabContentChange,
    handleToggleSwitch,
    handleCustomizeDaysChange,
    handleChipDelete,
    updateTabContentExist,
    disableSaveBtn,
    discardChanges,
  ];
};

export default useShopifyConf;
