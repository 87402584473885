import React, { useState, useContext, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import {
  Button,
  FormHelperText,
  Typography,
  Popover,
  TextField,
} from "@mui/material";
import QuickReplyStyles from "../../assets/styledComponents/settings/QuickReply";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import BigInputField from "../BigInputField";
import { quickButtonIcons } from "../../utils/helperData";
import CustomTextField from "../reusable/CustomTextField";
import HeaderBar from "../reusable/HeaderBar/HeaderBar";
import { TenantContext } from "../../context/TenantContext";
import { extractTextFromHtml, getKey } from "../../utils/helperFunction";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import {
  quickReply,
  getAllQuickReply,
  updateQuickReply,
  deleteQuickReply,
} from "../../service/service";
import DataObjectIcon from "@mui/icons-material/DataObject";
import TextEditor from "../reusable/TextEditor";
import CustomPopover from "../reusable/CustomPopover";
import CustomTextEditor from "../reusable/CustomTextEditor";
import AttachmentIcon from "@mui/icons-material/Attachment";
import QuickReplySkeleton from "../skeleton/QuickReplySkeleton";
// let toolbarOptions = [["link"], ["emoji"],  ["image"], ["underline"], ['italic'], ['bold']];
let toolbarOptions = [["emoji"]];
const list = [
  "Greetings",
  "Apologies",
  "Exchange",
  "Return",
  "Damaged Item",
  "Returned Order",
  "Refund Last Order",
  "Cancel Last Order",
];

const QuickReply = () => {
  const { tenantId } = useContext(TenantContext);
  const [backendError, setBackendError] = useState({ msg: "", type: "error" });
  const [quickReplyList, setQuickReplyList] = useState([]);
  const [query, setQuery] = useState("");
  const [resetValue, setResetValue] = useState(false);
  const [disableTextEditor, setDisableTextEditor] = useState(false);
  const [update, setUpdate] = useState(false);
  const [listIndex, setListIndex] = useState(0);
  const lastRef = useRef();
  const [quickReplyLoading , setQuickReplyLoading] = useState(false)
  const [disableSaveBtn , setDisableSaveBtn] = useState(false)
  const fetchAllQuickReplyData = async (loading) => {
    try {
      setQuickReplyLoading(loading)
      const result = await getAllQuickReply(tenantId);
      setQuickReplyList(result.data);
      setDisableTextEditor(false);
      setQuickReplyLoading(false)
    } catch (error) {
      setQuickReplyLoading(false)
      setBackendError({ msg: error.response.data.message, type: "error" });
    }
  };

  useEffect(() => {
    fetchAllQuickReplyData(true);
  }, []);
  const [value, setValue] = useState({
    templateName: "",
    templateMessage: "",
    quickReplyId: "",
  });
  const [errors, setErrors] = useState({
    templateName: "",
    templateMessage: "",
  });

  const [snackBarValue, setSnackBarValue] = useState({ msg: "", type: "" });
  const [textEditorValue, setTextEditorValue] = useState("");
  const [templateMessageText , setTemplateMessageText] = useState('')

  const handleEditorChange = ({ html, text}) => {
    console.log({text})
    setTemplateMessageText(text?.trim()) 
    setValue((prev) => ({
      ...prev,
      templateMessage: html,
    }));
    setErrors((prev) => ({
      ...prev,
      templateMessage: "",
    }));
  };

  const handleReactQuillChange = (value) => {
    setValue((prev) => ({
      ...prev,
      templateMessage: value,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };
    setValue((prev) => ({
      ...prev,
      [name]: value,
    }));
    newErrors[name] = "";
    setErrors(newErrors);
  };
  const popupList = [
    "First Name",
    "Last Name",
    "Ticket Id",
    "Order Id",
    "Order Status",
    "Order Coupon",
    "Shipping Address",
  ];

  const [hideSaveBtn, setHideSaveBtn] = useState(true);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const handleListItemClick = (
    templateName,
    templateMessage,
    quickReplyId,
    tenantId,
    index
  ) => {
    console.log(
      "Clicked Item:",
      templateName,
      templateMessage,
      quickReplyId,
      tenantId
    );
    if (tenantId) {
      setUpdate(true);
      setHideSaveBtn(true);
      setDisableTextEditor(false);
      setSelectedItemId(quickReplyId);
    } else {
      setListIndex(index);
      setHideSaveBtn(false);
      setDisableTextEditor(true);
      setUpdate(false);
    }
    setValue({
      templateName,
      templateMessage,
      quickReplyId,
    });
    const templateMessageText = extractTextFromHtml(templateMessage);
    setTemplateMessageText(templateMessageText)
    setTextEditorValue(templateMessage);
    setSelectedItemId(quickReplyId);
    setErrors({
      templateName: "",
      templateMessage: "",
    });
  };
  const setSubmitState = (result) => {
    setSnackBarValue({ msg: result.message, type: "success" });
    setSelectedItemId("");
    fetchAllQuickReplyData(false);
    setResetValue((prev) => !prev);
    setValue({
      templateName: "",
      templateMessage: "",
    });
    setTextEditorValue("");
    setUpdate(false);
  };
  const scrollToBottom = () => {
    lastRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  const handleCloneTemplate = async (e) => {
    const currentValue = quickReplyList.slice(listIndex, listIndex + 1);
    const { templateName, templateMessage } = currentValue[0] || {};

    try {
      const quickReplyPanel = await quickReply({
        templateName: templateName + " clone",
        templateMessage: templateMessage,
        tenantId: tenantId,
        isDeleted: false,
      });
      const quickReplyId = quickReplyPanel.data._id;
      setValue({
        templateName,
        templateMessage,
        quickReplyId,
      });
      handleListItemClick(
        templateName + " clone",
        templateMessage,
        quickReplyId,
        "tenantId",
        1
      );
      setTemplateMessageText(templateMessage)
      fetchAllQuickReplyData(false)
        .then(() => {
          // setTimeout(() => {
          //   scrollToBottom() 
          // }, 100);
        })
        .catch((error) => console.log(error));

      setSnackBarValue({ msg: "Template Cloned", type: "success" });
    } catch (error) {
      console.log(error);
      setSnackBarValue({ msg: error.response.data, type: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { templateName, templateMessage, quickReplyId } = value;
    console.log({templateMessage, templateMessageText})
    const newErrors = { ...errors };
    let isDirty = false;
    if (!templateName) {
      newErrors.templateName = "Template Name is Required";
      isDirty = true;
    }

    if (!templateMessageText) {
      newErrors.templateMessage = "Template Message is Required";
      isDirty = true;
    }
    if (isDirty) {
      setErrors({...newErrors});
    }
    setDisableSaveBtn(true);
    if (!isDirty && update) {
      try {
        const result = await updateQuickReply({
          quickReplyId,
          templateName: templateName.trim(),
          templateMessage: templateMessage,
          tenantId: tenantId,
          isDeleted: false,
        });
        setDisableSaveBtn(false);
        setSubmitState(result);
        return;
      } catch (error) {
        setSnackBarValue({ msg: error.response.data, type: "error" });
        setDisableSaveBtn(false);
        return;
      }
    }
    if (!isDirty) {
      try {
        const quickReplyPanel = await quickReply({
          templateName: templateName.trim(),
          templateMessage: templateMessage,
          tenantId: tenantId,
          isDeleted: false,
        });
        setDisableSaveBtn(false);
        setSubmitState(quickReplyPanel);
        setTimeout(() => {
          scrollToBottom() 
        }, 100);
      } catch (error) {
        setSnackBarValue({ msg: error.response.data, type: "error" });
        setDisableSaveBtn(false);
      }
    }
  };
  const handleNewBtnClick = () => {
    setDisableTextEditor(false);
    setUpdate(false);
    setSelectedItemId(null);
    setValue({
      templateName: "",
      templateMessage: "",
    });
    setHideSaveBtn(true);
    setTextEditorValue("");
    // setResetValue((prev) => !prev);
  };

  const handleDeleteQuickReply = async (quickReplyId, templateName) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Once you delete this you will not able to revert this .",
        icon: "warning",
        reverseButtons:true,
        confirmButtonColor:"#8db178",
        showCancelButton: true,
        cancelButtonColor: "#D9D9D9",
        confirmButtonText: "Yes, delete it!",
      });
  
      if (result.isConfirmed) {
        const deletionResult = await deleteQuickReply(quickReplyId);
        setUpdate(false)
        setResetValue((prev) => !prev)
  
        if (deletionResult) {
          Swal.fire({
            title: "Deleted!",
            text: `The template Name "${templateName}" has been deleted.`,
            confirmButtonColor: "#8db178",
            icon: "success",
          });
        }
  
        setTimeout(() => {
          setValue({
            templateName: "",
            templateMessage: "",
            quickReplyId: "",
          });
          setTextEditorValue("")
        });
  
        await fetchAllQuickReplyData(false);
  
        if (quickReplyList && quickReplyList.filter) {
          setQuickReplyList((prevList) =>
            prevList.filter((item) => item._id !== quickReplyId)
          );
        }
        setSubmitState()
  
        if (selectedItemId === quickReplyId) {
          setValue({
            templateName: "",
            templateMessage: "",
            quickReplyId: "",
          });
          setTextEditorValue("")
        }
      }
    } catch (error) {
      console.error("Error deleting quick reply:", error);
    }
  };
  
  
  
  return (
    <QuickReplyStyles>
      {snackBarValue.msg && <CustomSnackbar payload={snackBarValue} />}
      <HeaderBar
        title="Quick Reply"
        hidePrimaryButton={true}
        hideSecondaryButton={false}
        handleSecondaryButtonClick={handleNewBtnClick}
        secondaryButtonLabel={"New Template"}
      />
      <div className="quick-reply-container">
        <div className="input-wrapper">
          <div className="input-container">
            <div className="search-icon">
              <SearchIcon />
            </div>
            <input
              type="text"
              placeholder="Search triggers"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <div className="scroll-div"> {quickReplyLoading ? (
              <QuickReplySkeleton />
            ) : (
              quickReplyList
                .filter((result, filterIndex) =>
                  result.templateName.toLowerCase().includes(query.toLowerCase())
                )
                .map((data, index, arr) => {
                  const { templateName, templateMessage, _id, tenantId } = data;
                  const isSelected = selectedItemId === _id;
                  // let lastItem = index === arr.length - 1 ? lastRef : null;
                  return (
                    <div
                      // ref={lastItem}
                      key={getKey(
                        data,
                        "templateName",
                        "templateMessage",
                        index
                      )}
                      className={`return1 quick-reply-label ${
                        isSelected
                          ? "selected-return1 active template-item "
                          : "template-item"
                      }`}
                      onClick={() =>
                        handleListItemClick(
                          templateName,
                          templateMessage,
                          _id,
                          tenantId,
                          index
                        )
                      }
                    >
                      <div className="template-list">
                        <h2>{templateName}</h2>
                        {tenantId && (
                          <div className="delete-icon">
                            <DeleteOutlineOutlinedIcon
                              onClick={() => handleDeleteQuickReply(_id, templateName)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
            )}
            <div ref={lastRef}></div>
          </div>
        </div>

        {/* <div>
          <div className="vertical-line"></div>
        </div> */}

        <div className="text-editor-container">
          <div className="template-container">
            <div className="heading2">
              <h3>Template Name</h3>
            </div>
            <div className="text1">
              {" "}
              <CustomTextField
                sx={{
                  width: '100%',
                  marginBottom: 3,
                  marginTop: 1,
                }}
                error={errors.templateName}
                value={value.templateName}
                placeholder={"Write template name"}
                name={"templateName"}
                handleChange={handleChange}
                margin={"normal"}
                helperText={errors.templateName}
                disabled={disableTextEditor}
              />
              {/* <TextField
                sx={{
                  width: 640,
                  boxShadow: 7,
                  marginBottom: 3,
                  marginLeft: -5,
                  borderRadius: "10px",
                }}
                error={errors.templateName}
                helperText={errors.templateName}
                label="write template name"
                variant="outlined"
                margin="normal"
                name="templateName"
                value={value.templateName}
                onChange={handleChange}
              /> */}
            </div>

            <div className="heading3">
              <h3>Template Message</h3>
            </div>
            <div className="template-message">
              <TextEditor
                disable={disableTextEditor}
                resetValue={resetValue}
                onChange={handleEditorChange}
                width={'100%'}
                value={textEditorValue}
// textEditorValue
                toolbarOptions={toolbarOptions}
                variablePosition={"8%"}
                quickReplyList={popupList}
              />
            </div>
            {errors.templateMessage && (
              <label className="error-label">{errors.templateMessage}</label>
            )}
            {!hideSaveBtn && (
              <div className="save-button">
                <Button
                  onClick={handleCloneTemplate}
                  style={{ color: "white", padding: ".7rem 3rem" }}
                  variant="contained"
                >
                  Clone Template
                </Button>
              </div>
            )}

            {hideSaveBtn && (
              <div className="save-button">
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  style={{ color: "white", padding: ".7rem 3rem" }}
                  disabled={disableSaveBtn}
                >
                  {update ? "Update" : "Save"}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </QuickReplyStyles>
  );
};
export default QuickReply;
