import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { MessageInputStyles } from "../../assets/styledComponents/chatBox";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import AttachmentIcon from "@mui/icons-material/Attachment";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import SendIcon from "@mui/icons-material/Send";
import { Button, CircularProgress,  TextField, } from "@mui/material";
import CustomPopover from "../reusable/CustomPopover";
import EmojiPicker from "emoji-picker-react";
import ReplyIcon from "@mui/icons-material/Reply";

import {
  createChatNotification,
  createPrivateNoteNotification,
  getAllUserSetup,
  getSuggestReply,
  quickReply,
  sendMessage,
  sendSocialMediaMessage,
  sendWhatsappMessage,
} from "../../service/service";
import { useTenantContext } from "../../context/TenantContext";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import QuickreplyOutlinedIcon from "@mui/icons-material/QuickreplyOutlined";
import Modal from "../reusable/DialogModal/Modal";
import QuickReplyModal from "../inboxMessage/QuickReplyModal";
import { uploadToS3 } from "../../utils/uploadImageToS3";
import useFile from "../../hooks/useFile";
import {
  getKey,
  htmlToPlainText,
  isEmpty,
} from "../../utils/helperFunction";
import PrivateMessagePopover from "./PrivateMessagePopover";
import useMessageInput from "./useMessageInput";
import useAttachmentUpload from "./useAttachmentUpload";
import FullPageSpinner from "../FullPageSpinner";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import Swal from "sweetalert2";
import SuggestReplyLoading from "./SuggestReplyLoading";

const MessageInput = ({ changeMessage, disabled = false}) => {
  const attachmentSize = 5 * 1024 * 1024;
  const { tenantId, userId, profile } = useTenantContext();
  const {
    socket,
    sendMessages,
    sessionId,
    removeImage,
    setImageUploading,
    currentSessionData,
    text,
    setText,
    setShowImageLinkPreview,
    setImageLinkDetails,
    imageLinkDetails,
    quickReplySelected,
    normalMessage,
    setRefreshMessageNotification,
    selectedComment,
    setSelectedComment,
    isMessagePrivate, 
    setIsMessagePrivate,
    setQuickReplySelected, 
  } = useInboxContext();
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [isUserTagged, setUserTagged] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [imageFile, setImageFile] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [backendError, setBackend] = useState({ msg: "", type: "error" });
  const [userNames, setUserNames] = useState([]);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [suggestReplyLoading, setSuggestReplyLoading] = useState(false)

  const [
    attachmentRef,
    handleAttachmentClick,
    handleAttachmentChange,
    attachmentFile,
    attachmentFileDetails,
    resetFileValue,
  ] = useFile(attachmentSize);
  const {
    fileName: attachmentFileName,
    fileSize: attachmentFileSize,
    fileSizeError,
  } = attachmentFileDetails || {};

  const {
    channelName,
    ticketId,
    whatsappPhoneId,
    taggedUser,
    assignTo,
    facebookDetails,
    instagramDetails, 
    storeName,
    storeId,
    facebookMessageType,
    instagramMessageType,
  } = currentSessionData || {};
  const { pageId, senderId } = facebookDetails || instagramDetails ||    {};

  const isSessionTagged =
    userId === assignTo ? true : taggedUser?.includes(userId);

  const isNotAssignedUser =
    userId === assignTo ? false : taggedUser?.includes(userId);

  useEffect(() => {
    setBackend({ msg: fileSizeError, type: "error" });
  }, [fileSizeError]);

  const uploadDemo = async () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(
          "https://txta.s3.us-east-2.amazonaws.com/1694597459102.nvimtree.png"
        );
      }, 2000);
    });
  };
  const fileUploadDemo = async () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(
          "https://txta.s3.us-east-2.amazonaws.com/1694676212569.hello.c"
        );
      }, 2000);
    });
  };

  
  const fetchUserName = async () => {
    try {
      const result = await getAllUserSetup({ tenantId, });
      setUserNames(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserName();
  }, []);

  console.log({ currentSessionData });
  const [uploadFile, attachmentLoading] = useAttachmentUpload();

  const [getChannelName] = useMessageInput();

  const clearInputValue = () => {
    setText('');
    setIsMessagePrivate(false);
    setImageLoading(false);
    setImageSrc(null);
    setWidth(0)
    setHeight(0)
    setImageFile(null);
    setSelectedComment(false);
    setQuickReplySelected(false)
    setButtonLoading(false)
    setTimeout(() => {
      document.getElementById("input-field").focus();
    }, 100);
  }
  const clearImageData = () => {
    setImageSrc(null);
    setWidth(0)
    setHeight(0)
  }
  
  const handleSubmit = async () => {
    console.log("                      ");
    console.log(" Inside HandleSubmit  ");
    if (disabled || isButtonLoading) return;
    console.log(" Channel Name ", channelName); 
    const plainText = htmlToPlainText(text);
    let uploadedImage = "";
    try {
      console.log("Inside try message Input");

      if (!attachmentFile && !imageSrc && !text.trim()) {
        return
      }
      setButtonLoading(true);

      if (imageSrc) {
        setImageLoading(true);
        uploadedImage = await uploadToS3(imageFile);
        setImageLoading(false);
      }

      let uploadedValue;
      if (attachmentFile) {
        uploadedValue = await uploadFile(attachmentFile, resetFileValue);
      }    
      let payload = {
        tenantId,
        content: plainText,
        sender: "agent",
        channelName: channelName === 'chat' ? 'Live Chat': channelName,
        userId,
        isPrivate: isMessagePrivate || isNotAssignedUser,
        taggedUser: taggedUserDetails.map((item) => item._id),
        taggedUserName: taggedUserDetails.map((item) => item.name),
        productDetails: _.isEmpty(imageLinkDetails) ? null : imageLinkDetails,
        chatSession: sessionId,
        image: uploadedImage,
        width : width , 
        height : height,
        attachment: uploadedValue,
        attachmentSize: attachmentFileSize,
        documentName: attachmentFile?.name,
      };

      if (channelName === 'facebook' && facebookMessageType === 'comment') {
        if (!selectedComment) {
          // TO DO need to throw error here
          return;
        }

        payload = {
          ...payload,
          parentComment: selectedComment,
          messageTypeFromClient: 'reply'
        }

      } else if (channelName === 'instagram' && instagramMessageType === 'comment') {

        if (!selectedComment) {
          // TO DO need to throw error here
          return;
        }

        payload = {
          ...payload,
          parentComment: selectedComment,
          messageTypeFromClient: 'reply'
        }
      }

  
      const result = await sendMessage(payload);
  
      if (isMessagePrivate && isUserTagged) {
        let payload = {
          tenantId,
          isPrivate :  true,
          chatSessionId: sessionId,
          ticketId,
          taggedUser: taggedUserDetails.map((item) => item._id),
          taggedUserName: taggedUserDetails.map((item) => item.name),
          message: result.data._id,
          userId,
          description : text,
          // storeName, 
          // storeId,
        };
        // const notification = await createChatNotification(payload);
  
        let userIdList = taggedUserDetails
          .filter((item) => item._id !== userId)
          .map((item) => item._id);
        setRefreshMessageNotification((prev) => !prev)
        setTaggedUserDetails([]);
        // socket?.emit("PRIVATE_NOTE_NOTIFICATION", userIdList, text);
        setOpenModal(false);
      }
  
      if (attachmentRef.current) {
        resetFileValue();
      }
  
      // switch handle the notifications

      // socket?.emit("SEND_MESSAGE", {...result.data , tenantId, ticketId}, channelName);
      sendMessages(result.data);
      clearInputValue();
    } catch(err) {
      console.log("Error :: ", err)
    }
  

  }

  const handleEmojiSelect = (emoji ) => {
    if (disabled) return;
    const emojiLength = emoji.emoji?.length

    const startPos = cursorPosition  ;
    const endPos = cursorPosition ;
    const newText = text.slice(0, startPos) + emoji.emoji + text.slice(endPos);
    console.log({newText, startPos , endPos, em : emoji.emoji })
    setCursorPosition((prev) => prev + emojiLength)
    setText(newText);
    setTimeout(() => {
      inputRef.current.focus();
    }, 0);
  };


  const handleOnBlur = (e) => {
    socket?.emit("AGENT_STOP_TYPING", null, "chat");
  };

  const handleInputChange = (e) => {
    if (disabled) return;
    if (e.target.innerHTML) {
      socket?.emit("AGENT_TYPING", sessionId, tenantId);
    } else {
      socket?.emit("AGENT_STOP_TYPING", sessionId, tenantId);
    }
    setText(e.target.innerHTML);
    let newText = e.target.innerHTML;
    const atIndex = newText.lastIndexOf("@");
    if (atIndex !== -1) {
      const lastWord = newText.substring(atIndex - 1);
      console.log(lastWord);
      console.log(lastWord[0]);
      if (lastWord[0] === "@" || lastWord[0] === " ") {
        setOpenModal(true);
        console.log("now open the model");
      } else {
        setOpenModal(false);
      }
    }
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const clonedRange = range.cloneRange();
    clonedRange.selectNodeContents(inputRef.current);
    clonedRange.setEnd(range.endContainer, range.endOffset);
    const fragment = document.createDocumentFragment();
    fragment.appendChild(clonedRange.cloneContents());
    const clonedRangeHTML = Array.from(fragment.childNodes)
      .map((node) => node.outerHTML || node.textContent)
      .join("");
    console.log({ clonedRange: clonedRangeHTML, cursorPosition });
    const cursorPosition = clonedRange.toString().length;
    setCursorPosition(cursorPosition);
    const lines = e.target.innerHTML.split("\n").length;
    const newRowValue = Math.min(7, Math.max(2, lines));
    inputRef.current.rows = newRowValue;
  };
  console.log({cursorPosition })

  const handleMouseDown2 = (e) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const clonedRange = range.cloneRange();
    clonedRange.selectNodeContents(inputRef.current);
    clonedRange.setEnd(range.endContainer, range.endOffset);
    const fragment = document.createDocumentFragment();
    fragment.appendChild(clonedRange.cloneContents());
    const clonedRangeHTML = Array.from(fragment.childNodes)
      .map((node) => node.outerHTML || node.textContent)
      .join("");
    const cursorPosition = clonedRangeHTML.length;
    setCursorPosition(cursorPosition -4);
  };

  console.log({cursorPosition })



  const handleChange = (e) => {
    if (disabled) return;
    if (e.target.value) {
      socket?.emit("AGENT_TYPING", sessionId, tenantId);
    } else {
      socket?.emit("AGENT_STOP_TYPING", sessionId, tenantId);
    }
    setText(e.target.value);
    let newText = e.target.value;
    const atIndex = newText.lastIndexOf("@");
    console.log({ atIndex });
    if (atIndex !== -1) {
      const lastWord = newText.substring(atIndex - 1);
      console.log(lastWord[0]);
      if (lastWord[0] === "@" || lastWord[0] === " ") {
        setOpenModal(true);
        console.log("now open the model");
      } else {
        setOpenModal(false);
      }
    }
    setCursorPosition(e.target.selectionStart);
    const lines = e.target.value.split("\n").length;
    const newRowValue = Math.min(7, Math.max(2, lines));
    inputRef.current.rows = newRowValue;
  };

  const handleMouseDown = (e) => {
    if (disabled) return;

    setCursorPosition(e.target.selectionStart);
  };

  useEffect(() => {
    // Set cursor position at the end when text changes
    if (inputRef.current && quickReplySelected) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(inputRef.current);
      range.collapse(false); // Set the cursor to the end
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, [text]);

  const handleKeyDown = (e) => {
    if (disabled) return;

    if (e.key === "@") {
      // setOpenModal(true);
      setMentionIndex(text.length);
    }
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handlePrivateBtn = (e) => {
    if (disabled) return;
    setIsMessagePrivate((prev) => !prev);
  };

  const imageRef = useRef(null);
  const handleImageClick = () => {
    imageRef.current.click();
  };

  const handleImageChange = (e) => {
    if (disabled) return;
    const selectedFile = e.target.files[0];
    const imageURL = URL.createObjectURL(selectedFile);
    const image = new Image();
      image.src = imageURL;
      
      image.onload = () => {
        const imageWidth = image.width;
        const imageHeight = image.height;
        
        console.log(`Image Width: ${imageWidth}, Image Height: ${imageHeight}`);
        setHeight(imageHeight)
        setWidth(imageWidth)
        setImageSrc(imageURL);
        setImageFile(selectedFile);
      };
      // setImageSrc(imageURL);
    // setImageFile(selectedFile);
    e.target.value = "";
  };

  const handlePaste = (e) => {
    if (disabled) return;

    const items = e.clipboardData.items;
    for (const item of items) {
      if (item.type.indexOf("image") !== -1) {
        const blob = item.getAsFile();
        const imageURL = URL.createObjectURL(blob);
        setImageSrc(imageURL);
      }
    }
  };

  //  private note
  const [mentionIndex, setMentionIndex] = useState(-1);
  const [taggedUserDetails, setTaggedUserDetails] = useState([]);
  const [quickReplyOpen, setQuickReplyOpen] = useState(false);
  const [suggestionIconText, setSuggestionIconText] = useState("");

  const handleQuickReply = () => {
    console.log({disabled})
    if (disabled) return;

    setQuickReplyOpen(true);
  };

  const handleSuggestionIcon = async() => {
    if (disabled || attachmentLoading || !isSessionTagged) {
      return;
    }

    const userMessage = normalMessage.filter((item) => {
    if (item?.sender=== "user") {
      return item;
    }
    })
    const lastUserMessage = userMessage?.[0]?.content
    try {
      setButtonLoading(true);
      setSuggestReplyLoading(true)
      setText('')
      const result = await getSuggestReply({
        tenantid: tenantId,  
        PreviousResponse: lastUserMessage,
      }, process.env.REACT_APP_AI_API_KEY)
      if (result.Status === 202) {
        const suggestedReplyResponse = result.Response_text.replace(/\n/g, ' ');
        setText(suggestedReplyResponse);
        setButtonLoading(false);
        setSuggestReplyLoading(false)
        setCursorPosition(suggestedReplyResponse?.length)

      }
      if (result.Status === 404) {
        Swal.fire({
          title: "Error!",
          text: 'The AI Token allocated for this month has been utilized',
          icon: "error",
          confirmButtonColor: "#d33",
        });
        setButtonLoading(false);
       setSuggestReplyLoading(false)
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: 'Something went wrong',
        icon: "error",
        confirmButtonColor: "#d33",
      });
      console.log(error)
      setButtonLoading(false);
      setSuggestReplyLoading(false)
    }
    


   // inputRef.current.focus();
  };
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    if (text === "") {
      setOpenModal(false);
    }
    if (isMessagePrivate || isNotAssignedUser) {
      let names = userNames.map((item) => "@" + item.name);
      const includesAnyName = names.some((name) => text.includes(name));
      if (includesAnyName) {
        setUserTagged(true);
      }
      if (!includesAnyName) {
        // setOpenModal(true);
        setTaggedUserDetails([]);
        setUserTagged(false);
      }
    }
  }, [text]);

  disabled = disabled || attachmentLoading || !isSessionTagged 
  return (
    <MessageInputStyles >
      {backendError.msg ? <CustomSnackbar payload={backendError} /> : ""}
      {openModal && (isMessagePrivate || isNotAssignedUser) && (
        <PrivateMessagePopover
          userList={userNames}
          text={text}
          setTaggedUserDetails={setTaggedUserDetails}
          setOpenModal={setOpenModal}
          mentionIndex={mentionIndex}
          setText={setText}
          setMentionIndex={setMentionIndex}
          userId={userId}
        />
      )}
      <Modal
        name="Quick Reply"
        open={quickReplyOpen}
        onClose={() => setQuickReplyOpen(false)}
      >
        <QuickReplyModal
          setText={setText}
          setQuickReplyOpen={setQuickReplyOpen}
        />
      </Modal>
      <div className="search-container"  id="message-input-box">
        {imageSrc && (
          <div className="image-container">
            <img
              width={"100px"}
              height={"100px"}
              src={imageSrc}
              alt="Pasted Screenshot"
            />
            <div className="spinner">
              {imageLoading && <CircularProgress disableShrink />}
            </div>
            <button
              className="image-close-btn"
              onClick={() => clearImageData()}
            >
              <CloseIcon fontSize="inherit" className="close-icon" />
            </button>
            {/* <div className="close-btn">
              <Button
                className="image-close-btn"
                onClick={() => setImageSrc(null)}
              >
                <CloseIcon />
              </Button>
            </div> */}
          </div>
        )}

        {attachmentFile && (
          <div className="attachment-message">
            {attachmentFileName && (
              <h3 className="attachment-name">{attachmentFileName}</h3>
            )}
            {attachmentLoading && (
              <div className="loader-icon">
                <CircularProgress
                  sx={{
                    marginTop: "1rem",
                    alignSelf: "baseline",
                  }}
                  size={"1.5rem"}
                  disableShrink
                />
              </div>
            )}
            <div className="close-btn">
              <Button onClick={() => resetFileValue()}>
                <CloseIcon />
              </Button>
            </div>
          </div>
        )}

        {selectedComment ? (
          <div className="meta-reply-block">
            <ReplyIcon sx={{ height: "14px" }} />
            {selectedComment.content}
          </div>
        ) : null}

        {!quickReplySelected ? (
          <TextField
            sx={{
              width: "100%",
              height: "100%",
              "& fieldset": { border: "none" },
            }}
            id="input-field"
            multiline
            rows={2}
            placeholder={!suggestReplyLoading && "Type your text"}
            ref={inputRef}
            onKeyDown={handleKeyDown}
            // onKeyUp={handleKeyUp}
            disabled={
              disabled ||
              attachmentLoading ||
              !isSessionTagged ||
              isButtonLoading
            }
            value={text}
            onChange={handleChange}
            onMouseUp={handleMouseDown}
            onPaste={handlePaste}
            onBlur={handleOnBlur}
          />
        ) : (
          <div
            placeholder={!suggestReplyLoading && "Type your text"}
            ref={inputRef}
            onKeyDown={handleKeyDown}
            onBlur={handleInputChange}
            onMouseDown={handleMouseDown2}
            onInput={handleInputChange}
            dangerouslySetInnerHTML={{ __html: text }}
            onMouseUp={handleMouseDown2}
            className="custom-text-field"
            style={{
              "& fieldset": { border: "none" },
            }}
            title="Type you text"
            contentEditable
          ></div>
        )}

        {!suggestReplyLoading &&
        <div
          className={`icons-container ${
            disabled || attachmentLoading || !isSessionTagged ? "disabled" : ""
          }`}
        >
          <div className="left">
            {!isNotAssignedUser && (
              <CustomPopover
                button={
                  <Tooltip title="Emoji" placement="top">
                    <InsertEmoticonIcon />
                  </Tooltip>
                }
                disabled={disabled}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <EmojiPicker  hiddenEmojis={['263a-fe0f']}  onEmojiClick={handleEmojiSelect} />
              </CustomPopover>
            )}

            {!isNotAssignedUser &&
              facebookMessageType !== "comment" &&
              instagramMessageType !== "comment" && (
                <>
                  <input
                    type="file"
                    disabled={disabled}
                    accept=".png, .jpeg, .jpg .gif"
                    ref={imageRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />

                  <Tooltip title="Image" placement="bottom">
                    <div className="image-icon" onClick={handleImageClick}>
                      <ImageOutlinedIcon />
                    </div>
                  </Tooltip>
                  <input
                    type="file"
                    ref={attachmentRef}
                    accept=".pdf"
                    disabled={disabled}
                    style={{ display: "none" }}
                    onChange={handleAttachmentChange}
                  />
                    {channelName !== "instagram" && (
                      <Tooltip title="Attachment" placement="bottom">
                        <div
                          className="attachment-icon"
                          onClick={handleAttachmentClick}
                        >
                          <AttachmentIcon />
                        </div>
                      </Tooltip>
                    )}
                </>
              )}

            {!isNotAssignedUser && (
              <Tooltip title="Quick Reply" placement="bottom">
                <div className="quick-reply-btn" onClick={handleQuickReply}>
                  <QuickreplyOutlinedIcon />
                </div>
              </Tooltip>
            )}

            <Tooltip title="Private Note" placement="bottom">
              <div
                className={`private-message ${
                  isMessagePrivate || isNotAssignedUser
                    ? "private-message-active"
                    : ""
                }`}
                onClick={handlePrivateBtn}
              >
                <TextSnippetIcon />
              </div>
            </Tooltip>

            {!isNotAssignedUser && (
              <Tooltip title="Suggest Reply" placement="bottom">
                <div className="smart-toy-btn" onClick={handleSuggestionIcon}>
                  <SmartToyIcon />
                </div>
              </Tooltip>
            )}
          </div>
          <div className="right">
            {isButtonLoading ? (
              <CircularProgress disableShrink size={20} />
            ) : (
              <button onClick={handleSubmit} disabled={disabled}>
                <SendIcon style={{ color: "#8db178" }} />
              </button>
            )}
          </div>
        </div>
          }
          {suggestReplyLoading && <div className="suggest-reply-loader"><SuggestReplyLoading/></div>}
      </div>
    </MessageInputStyles>
  );
};

export default MessageInput;

MessageInput.propTypes = {
  changeMessage: PropTypes.func,
};
