/* eslint-disable react/react-in-jsx-scope */
import MarkChatUnreadOutlinedIcon from "@mui/icons-material/MarkChatUnreadOutlined";
import AllInboxOutlinedIcon from "@mui/icons-material/AllInboxOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import StarBorderIcon from "@mui/icons-material/StarBorder";
import SnoozeIcon from "@mui/icons-material/Snooze";
import LabelIcon from "@mui/icons-material/Label";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import SnoozeNotification from "../components/SnoozeNotification";

import userProfile from "../assets/images/userProfile.png";

import SmartToyIcon from "@mui/icons-material/SmartToy";
// emailSignatureIcon
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import PaletteIcon from "@mui/icons-material/Palette";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import DataObjectIcon from "@mui/icons-material/DataObject";

import AttachmentIcon from "@mui/icons-material/Attachment";
import TagFacesIcon from "@mui/icons-material/TagFaces";
// import from '@mui/icons-material/DataObject';

import {
  SMALL_LETTER_REGEX,
  CAPS_LETTER_REGEX,
  NUMBER_LETTER_REGEX,
  SPECIAL_CHAR_LETTER_REGEX,
} from "../utils/constants";
import NotificationDrawer from "../components/notification/NotificationDrawer";

export const menuItemList = [
  {
    icon: <MessageOutlinedIcon />,
    title: "inbox",
  },
  {
    icon: <AnalyticsOutlinedIcon />,
    title: "report/resolutionReport",
    permission: "reportAccess",
  },
  {
    icon: <SettingsOutlinedIcon />,
    title: "settings/businessHours",
    permission: "managingSettings",
  },
  {
    icon: <NotificationsIcon />,
    title: "notification",
    permission: "",
  },
  // {
  //   icon: <FacebookOutlinedIcon sx={{color : '#316FF6'}}/>,
  //   title: "facebook",
  //   permission: "",
  // },
  // {
  //   icon: <InstagramIcon sx={{color : '#E4405F'}}/>,
  //   title: "instagram",
  //   permission: "",
  // },
];

export const settingsSocialIcons = [
  {
    icon: <MarkChatUnreadOutlinedIcon />,
    name: "Chat",
    path: "chat",
  },
  {
    icon: <EmailOutlinedIcon />,
    name: "Email",
    path: "email",
  },
  {
    icon: <WhatsAppIcon  />,
    name: "Whatsapp",
    path: "whatsapp",
  },
  {
    icon: <FacebookOutlinedIcon sx={{color : '#316FF6'}} />,
    name: "Facebook/Instagram",
    path: "facebook/instagram",
  },
];


export const socialIcons = [
  {
    icon: <MarkChatUnreadOutlinedIcon />,
    name: "Chat",
    path: "chat",
  },
  {
    icon: <EmailOutlinedIcon />,
    name: "Email",
    path: "email",
  },
  {
    icon: <WhatsAppIcon  />,
    name: "Whatsapp",
    path: "whatsapp",
  },
  {
    icon: <FacebookOutlinedIcon sx={{color : '#316FF6'}} />,
    name: "Facebook",
    path: "facebook",
  },
  {
    icon: <InstagramIcon sx={{color : '#E4405F'}}/>,
    name: "Instagram",
    path: "instagram",
  },
];

export const aiIcons = [
  {
    icon: <MarkChatUnreadOutlinedIcon />,
    name: "FAQ Training", 
    path: "faqTraining",
  },
  {
    icon: <EmailOutlinedIcon/>,
    name: "AI Logs",
    path: "aiLogs",
  },
  {
    icon: <SmartToyIcon/>,
    name: "AI Training",
    path: "aiTraining",
  },
];

export const inboxIcons = [
  {
    icon: <AllInboxOutlinedIcon />,
    name: "All",
    path: "",
  },
  ...socialIcons,
];

export const reportList = [
  { id: 1, name: "Resolution Report" ,route:"resolutionReport"},
  { id: 2, name: "Channels Report",route:"channelReport" },
  { id: 3, name: "Users Report" ,route:"userReport"},
  { id: 4, name: "Labels Report" ,route:"labelReport"},
];

export const myProfileList = [
  { id: 1, name: "Profile Settings", route: "" },
  { id: 2, name: "Change Password", route: "changePassword" },
];

export const settingsList = [
  { id: 1, name: "Business Hours", route: "businessHours" },
  { id: 2, name: "Email Signature", route: "emailSignature" },
  { id: 3, name: "Quick Reply", route: "quickReply" },
  { id: 4, name: "Auto Reply", route: "autoReply" },
  { id: 5, name: "Label", route: "label" },
  {
    id: 6,
    name: "Channel Configuration",

    // subList: [...socialIcons],
    path:"channelConfiguration",
    firstRoute:"chat",

    subList: [...settingsSocialIcons],

    route: "channelConfiguration",
  },
  { id: 7, name: "Shopify Configuration", route: "connect-shopify" },
  {
    id: 8,
    name: "AI Configuration",
    subList: [...aiIcons],
    path:"aiConfiguration",
    firstRoute:"faqTraining",
    route: "aiConfiguration",
  },
  { id: 9, name: "User Setup", route: "userSetup" },
  { id: 10, name: "Pricing", route: "pricing" },
];

export const ticketSummeryOptions = [
  { icon: <StarBorderIcon />, name: "Set as priority" },
  {
    icon: <SnoozeIcon />,
    name: "Snoozed",
    element: <SnoozeNotification />,
  },
  { icon: <LabelIcon />, name: "Label" },
  { icon: <MarkEmailUnreadIcon />, name: "Mark as Unread" },
  { icon: <ReceiptLongIcon />, name: "User History" },
];

export const filterLableOptions = [
  { name: "All Label" },
  { name: "Damaged Item" },
  { name: "Order Return" },
  { name: "Return" },
  { name: "Order Edit" },
  { name: "Missing Item" },
];

export const messageList = [
  {
    id: 1,
    name: "laura33",
    messagefrom: "instagram",
    ticketid: "#453456",
    message: [
      {
        id: 1,
        message:
          "Here is the required file that i Iam attaching, please let me know if you need anything from my side if you need anything from my side if you need anything from my side if you need anything from my side if you need anything from my side if you need anything from my side",
        type: "receiver",
      },
      {
        id: 3,
        message:
          "Thank You I will check and give you updates Here is the required file that i Iam attaching, please let me know if you need anything from my side if you need anything from my side if you need anything from my side if you need anything from my side if you need anything from my side if you need anything from my side",
        type: "sender",
      },
      { id: 4, message: "sure thanks", type: "receiver" },
      { id: 5, message: "ok", type: "sender" },
      { id: 5, message: "ok", type: "sender" },
      { id: 5, message: "ok", type: "sender" },
      { id: 5, message: "ok", type: "sender" },
      { id: 5, message: "ok", type: "sender" },
      { id: 5, message: "ok", type: "sender" },
      { id: 5, message: "ok", type: "sender" },
      { id: 5, message: "ok", type: "sender" },
      { id: 5, message: "ok", type: "sender" },
      { id: 5, message: "ok", type: "sender" },
      { id: 5, message: "ok", type: "sender" },
      { id: 5, message: "ok", type: "sender" },
      { id: 5, message: "ok", type: "sender" },
      { id: 5, message: "ok", type: "sender" },
      { id: 5, message: "ok", type: "sender" },
    ],
  },
  {
    id: 2,
    name: "laura2",
    messagefrom: "whatsapp",
    ticketid: "#453456",
    message: [
      { id: 1, message: "hello", type: "sender" },
      { id: 2, message: "hello", type: "receiver" },
      { id: 3, message: "hello", type: "sender" },
      { id: 4, message: "hello", type: "receiver" },
      { id: 5, message: "hello", type: "sender" },
    ],
  },
  {
    id: 3,
    name: "laura",
    messagefrom: "normalMessage",
    ticketid: "#453456",
    message: [
      { id: 1, message: "hello", type: "sender" },
      { id: 2, message: "hello", type: "receiver" },
      { id: 3, message: "hello", type: "sender" },
      { id: 4, message: "hello", type: "receiver" },
      { id: 5, message: "hello", type: "sender" },
    ],
  },
  {
    id: 4,
    name: "laura",
    messagefrom: "mail",
    ticketid: "#453456",
    message: [
      { id: 1, message: "hello", type: "sender" },
      { id: 2, message: "hello", type: "receiver" },
      { id: 3, message: "hello", type: "sender" },
      { id: 4, message: "hello", type: "receiver" },
      { id: 5, message: "hello", type: "sender" },
    ],
  },
  {
    id: 5,
    name: "laura",
    messagefrom: "instagram",
    ticketid: "#453456",
    message: [],
  },
  {
    id: 6,
    name: "laura",
    messagefrom: "instagram",
    ticketid: "#453456",
    message: [],
  },
  {
    id: 7,
    name: "laura",
    messagefrom: "instagram",
    ticketid: "#453456",
    message: [],
  },
  {
    id: 8,
    name: "laura",
    messagefrom: "instagram",
    ticketid: "#453456",
    message: [],
  },
];

export const DEFAULT_PASSWORD_CONSTRAINT = [
  {
    id: 1,
    valid: false,
    regexPattern: SMALL_LETTER_REGEX,
    condition: "One Lowercase",
  },
  {
    id: 2,
    valid: false,
    regexPattern: CAPS_LETTER_REGEX,
    condition: "One Uppercase",
  },
  {
    id: 3,
    valid: false,
    regexPattern: NUMBER_LETTER_REGEX,
    condition: "One number",
  },
  {
    id: 4,
    valid: false,
    regexPattern: SPECIAL_CHAR_LETTER_REGEX,
    condition: "One special character",
  },
  {
    id: 5,
    valid: false,
    regexPattern: null,
    minLength: 8,
    condition: "Must be atleast 8 characters",
  },
];

export const profileData = [
  {
    profilePic: userProfile,
    name: "Christina D'souza",
    email: "example@gmail.com",
    role: "Agent",
    status: "Register",
  },
  {
    profilePic: userProfile,
    name: "Lyra",
    email: "example@gmail.com",
    role: "Agent",
    status: "Register",
  },
  {
    profilePic: userProfile,
    name: "Kuzmin",
    email: "example@gmail.com",
    role: "Agent",
    status: "Register",
  },
  {
    profilePic: userProfile,
    name: "Bimassa",
    email: "example@gmail.com",
    role: "Agent",
    status: "Register",
  },
];

export const emailSignatureButtonIcons = [
  {
    id: 1,
    name: "link",
    icon: <InsertLinkIcon />,
  },
  {
    id: 2,
    name: "emoji",
    icon: <InsertEmoticonIcon />,
  },
  {
    id: 3,
    name: "image",
    icon: <InsertPhotoIcon />,
  },
  {
    id: 4,
    name: "",
    icon: <DataObjectIcon />,
  },
  {
    id: 5,
    name: "",
    icon: <FormatBoldIcon />,
  },
  {
    id: 6,
    name: "",
    icon: <FormatItalicIcon />,
  },
  {
    id: 7,
    name: "",
    icon: <FormatUnderlinedIcon />,
  },
  {
    id: 8,
    name: "",
    icon: <PaletteIcon />,
  },
];
export const quickButtonIcons = [
  
  {
    id: 1,
    icon: <TagFacesIcon />,
    name: "TagFacesIcon",
  }
  
];

let startTime = new Date();
startTime.setHours(9, 0, 0, 0);
startTime = startTime.toLocaleTimeString("en-IN", { hour12: true });
let endTime = new Date();
endTime.setHours(18, 0, 0, 0);
endTime = endTime.toLocaleTimeString("en-IN", { hour12: true });

export const openingHoursData = [
  {
    id: 1,
    day: "Monday",
    isOpen: true,
    time: [{ startTime , endTime }],
  },
  {
    id: 2,
    day: "Tuesday",
    isOpen: true,

    time: [{ startTime, endTime }],
  },
  {
    id: 3,
    day: "Wednesday",
    isOpen: true,
    time: [{ startTime, endTime }],
  },
  {
    id: 4,
    day: "Thursday",
    isOpen: true,
    time: [{ startTime, endTime }],
  },
  {
    id: 5,
    day: "Friday",
    isOpen: true,
    time: [{ startTime, endTime }],
    startTime,
    endTime,
  },
  {
    id: 6,
    day: "Saturday",
    isOpen: false,
    time: [{ startTime, endTime }],
    startTime,
    endTime,
  },
  {
    id: 7,
    day: "Sunday",
    isOpen: false,
    time: [{ startTime, endTime }],
    startTime,
    endTime,
  },
];

export const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

export const autoReplyErrors = [
  {
    title: "",
    channel: "",
    insideAutoReply: "",
    outsideAutoReply: "",
    customizeDays: "",
  },
];
