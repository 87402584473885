import React, { useState } from "react";
import { CardContent } from "@mui/material";
import Card from "@mui/material/Card";
import { getKey } from "../../utils/helperFunction";
import Button from "@mui/material/Button";
import ShopifyCardDetailStyles from "../../assets/styledComponents/settings/ShopifyCard";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ShopifyIntegrationDialog from "./ShopifyIntegrationDialog";
const useStyles = makeStyles({
  customCard: {
    border: "1px solid #C2C2C2 !important",
    boxShadow: "0px 1px 7px 0px #00000040",
    width: "70%",
    height: 350,
    margin: "0 auto",
    marginTop: 40,
    borderRadius: "10px !important",
    padding: "20px",
  },
  coloredIcon: {
    fill: "#8CB077 !important",
    marginLeft: 4,
    marginTop: 1,
  },
  customButton: {
    color: "#FFFFFF !important",
  },
});
const ShopifyCard = ({ name, logo, list ,buttonName, button, text}) => {
  const classes = useStyles();

  const [openModel, setOpenModel] = useState(false);
  const handleAddShopifyButton = () => {
    setOpenModel(true);
  };

  const handleCloseModal = () => {
    setOpenModel(false);
  };

  return (
    <ShopifyCardDetailStyles>
      <Card className={classes.customCard} variant="outlined">
        <CardContent>
          <div className="card-body">
            <div className="head-section">
              <div className="logo-container">
                <img src={logo} />
              </div>{" "}
              <span className="title">{name}</span>
            </div>

            <div className="features">
              {list ? list.map((item, index) => {
                return (
                    <div
                      className="shopify-list"
                      key={getKey(item, "data", index)}
                    >
                      <div className="container">
                        <CheckIcon
                          className={classes.coloredIcon}
                          sx={{ marginTop: 2 }}
                        />
                        <span>{item}</span>
                      </div>
                    </div>
                );
              }) : text}
            </div>
            <div className="action-button">
              {" "}
              {buttonName && 
              <Button
                className={classes.customButton}
                onClick={handleAddShopifyButton}
                variant="contained"
              >
                {" "}
                {buttonName}
              </Button>

              }
              {button}
            </div>
          </div>
        </CardContent>
      </Card>
      {openModel && (
        <ShopifyIntegrationDialog
          open={openModel}
          handleCloseModal={handleCloseModal}
        />
      )}
    </ShopifyCardDetailStyles>
  );
};
export default ShopifyCard;
