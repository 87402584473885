import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import styled from "styled-components";
import { convertTimeToDay, getKey } from "../utils/helperFunction";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment-timezone";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


dayjs.extend(utc);
dayjs.extend(timezone);

const StyledTimePicker = styled(TimePicker)`
  width: 50px;
  font-size: 2px;
  .MuiInputBase-input {
    padding: 0.5rem;
  }
`;

const TimeRangePicker = ({
  time,
  handleAddHours,
  handleRemoveHours,
  handleTimeChange,
  timezone,
  index,
}) => {
  return (
    <>
      {time.map((item, timeIndex) => {
        const { startTime, endTime } = item;
        const formattedStartTime = convertTimeToDay(timezone, startTime);
        const formattedEndTime = convertTimeToDay(timezone, endTime);

        return (
          <LocalizationProvider
            key={getKey('item', "time", timeIndex)}
            dateAdapter={AdapterDayjs}
          >
            <DemoContainer
              components={["TimePicker"]}

              sx={{ overflowY: "hidden" }}
            >
              <StyledTimePicker
                timezone={timezone}
                value = {dayjs.utc(formattedStartTime)}
                onChange={(newValue) =>
                  handleTimeChange(newValue, index, timeIndex, "startTime")
                }
              />

              <StyledTimePicker
                value = {dayjs.utc(formattedEndTime)}
                timezone={timezone}
                onChange={(newValue) =>
                  handleTimeChange(newValue, index, timeIndex, "endTime")
                }
              />
              <div className="btn-container" style={{ marginLeft: "8rem" }}>
                {timeIndex === 0 && (
                  <div className="add-btn-container">
                    <button
                      type="button"
                      className="add-btn"
                      onClick={(e) => handleAddHours(e, index)}
                    >
                      <AddIcon fontSize="small" />
                    </button>
                    <div className="name">Add</div>
                  </div>
                )}
                {timeIndex > 0 && (
                  <button onClick={() => handleRemoveHours(index, timeIndex)}>
                    <CloseIcon />
                  </button>
                )}
              </div>
            </DemoContainer>
          </LocalizationProvider>
        );
      })}
    </>
  );
};

export default TimeRangePicker;
