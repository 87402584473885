import { Chip, CircularProgress } from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";

const UploadPreviewChip = ({loading, name, handleImageDelete}) => {
  return (
    <Chip
      icon={loading? <CircularProgress size={"16px"} /> : <DoneIcon />}
      label={loading? "Uploading" : name}
      style={{ maxWidth: "140px" }}
      onDelete={handleImageDelete}
      deleteIcon={<DeleteIcon />}
    />
  );
};

export default UploadPreviewChip;
