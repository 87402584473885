import React from "react";
import SettingsMenu from "../../components/settings/SettingsMenu";
import { Outlet } from "react-router-dom";
import { useTenantContext } from "../../context/TenantContext";
import NotAuthorized from "../NotAuthorized";

const Settings = () => {
  const { profile } = useTenantContext();
  const role = profile.role; // "Owner" || "Agent"

  return role === "Owner" || role === "Admin" ? (
    <div style={{ display: "flex" }}>
      <SettingsMenu />
      <div style={{ flex: "1" }}>
        <Outlet />
      </div>
    </div>
  ) : (
    <NotAuthorized />
  );
};

export default Settings;
