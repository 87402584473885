export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const SMALL_LETTER_REGEX = /[a-z]/;
export const CAPS_LETTER_REGEX = /[A-Z]/;
export const NUMBER_LETTER_REGEX = /[0-9]/;
export const SPECIAL_CHAR_LETTER_REGEX = /[^a-zA-Z0-9]/;
export const WORK_EMAIL_REGEX = /^\w+([\.-]?\w+)*@(?!gmail\.com|yahoo\.com|hotmail\.com)[\w-]+(\.[\w-]+)+$/;
export const DOMAIN_REGEX =  /^(?!www\.)([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/; 

export const PUBLIC_PATHS = [
  "/login",
  "/register",
  "/non-authenticated",
  "/verifyOtp",
  "/verifySubscription",
  "/forgot-password",
  "/terms-and-conditions",
  "/privacy-and-policy"
];

export const TIME_ZONES = [
  { name: "USA (EST / EDT - UTC -5:00 / -4:00)", value: "America/New_York", locale: "en-US" },
  { name: "USA (CST / CDT - UTC -6:00 / -5:00)", value: "America/Chicago", locale: "en-US" },
  { name: "USA (MST / MDT - UTC -7:00 / -6:00)", value: "America/Denver", locale: "en-US" },
  { name: "USA (PST / PDT - UTC -8:00 / -7:00)", value: "America/Los_Angeles", locale: "en-US" },
  { name: "USA (AKST / AKDT - UTC -9:00 / -8:00)", value: "America/Anchorage", locale: "en-US" },
  { name: "USA (HST / HDT - UTC -10:00 / -9:00)", value: "Pacific/Honolulu", locale: "en-US" },
  { name: "UK (GMT / BST - UTC +0:00 / +1:00)", value: "Europe/London", locale: "en-GB" },
  { name: "Australia (EST / EDT - UTC +10:00 / +11:00)", value: "Australia/Sydney", locale: "en-AU" },
  { name: "Australia (CST / CDT - UTC +9:30 / +10:30)", value: "Australia/Adelaide", locale: "en-AU" },
  { name: "Australia (WST / WDT - UTC +8:00 / +9:00)", value: "Australia/Perth", locale: "en-AU" },
  { name: "Canada (EST / EDT - UTC -5:00 / -4:00)", value: "America/Toronto", locale: "en-CA" },
  { name: "Canada (CST / CDT - UTC -6:00 / -5:00)", value: "America/Winnipeg", locale: "en-CA" },
  { name: "Canada (MST / MDT - UTC -7:00 / -6:00)", value: "America/Edmonton", locale: "en-CA" },
  { name: "Canada (PST / PDT - UTC -8:00 / -7:00)", value: "America/Vancouver", locale: "en-CA" },
  { name: "India (IST - UTC +5:30)", value: "Asia/Kolkata", locale: "en-IN" },
];


