import React, { useState, useEffect } from "react";
import ReportStyles from "../../../assets/styledComponents/Report";
import HeaderBar from "../../reusable/HeaderBar/HeaderBar";
import CustomDate from "../../reusable/ReportFeature.js/CustomDate";
import CustomReportFilter from "../CustomReportFilter";
import CustomReportTable from "../CustomReportTabel";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FullPageSpinner from "../../FullPageSpinner";
import { useTenantContext } from "../../../context/TenantContext";
import { getAllUserReports } from "../../../service/service";
import { getAllUserSetup } from "../../../service/service";
import { Tooltip } from "@mui/material";


const UserReport = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [userTableDetails, setUserTableDetails] = useState([]);
  const [originalTableDetails, setOriginalTableDetails] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [userFilterValue, setUserFilterValue] = useState('All');

  const [displayComponents, setDisplayComponents] = useState(false);

  const NewHeader = (
    <Tooltip title="New Tickets"  placement="top-start"  >
      <span style={{ cursor: 'pointer'}}>
      New 
      </span>
    </Tooltip>)
  
  const ClosedHeader = (
    <Tooltip title="Closed Tickets" placement="top-start">
      <span style={{ cursor: 'pointer' }}>
        Closed
      </span>
    </Tooltip>
  );
  
  const AFRTHeader = (
    <Tooltip title="Average First Response Time (mins)" placement="top-start">
      <span style={{ cursor: 'pointer' }}>
        AFRT
      </span>
    </Tooltip>
  );
  
  const ARTHeader = (
    <Tooltip title="Average Revolution Time (mins)" placement="top-start">
      <span style={{ cursor: 'pointer' }}>
        ART
      </span>
    </Tooltip>
  );
  
  const MsgRecieveHeader = (
    <Tooltip title="Message Recieve" placement="top-start">
      <span style={{ cursor: 'pointer' }}>
        Msg Recieve
      </span>
    </Tooltip>
  );
  
  const MsgSentHeader = (
    <Tooltip title="Message Sent" placement="top-start">
      <span style={{ cursor: 'pointer' }}>
        Msg Sent
      </span>
    </Tooltip>
  );
  
  const IntervalMsgHeader = (
    <Tooltip title="Interval Message" placement="top-start">
      <span style={{ cursor: 'pointer' }}>
        Interval Msg
      </span>
    </Tooltip>
  );
  
  const { tenantId } = useTenantContext();
  const columns = React.useMemo(
    () => [
      { Header: "User", accessor: "name" },
      { Header: NewHeader, accessor: "newConversations" },
      { Header: ClosedHeader, accessor: "closedConversations" },
      {
        Header: AFRTHeader,
        accessor: "averageFirstResponse",
      },
      { Header: ARTHeader, accessor: "averageResolutionTime" },
      { Header: MsgRecieveHeader, accessor: "messageRecieved" },
      { Header: MsgSentHeader, accessor: "messageSent" },
      { Header: IntervalMsgHeader, accessor: "internalMessage" },
    ],
    []
  );

  const fetchUserSetup = async () => {
    try {
      const result = await getAllUserSetup({ tenantId });
      if (result.data) {
        const newData = [...result.data];
        let newData1 = newData.map((item, index) => {
          return { name: item.name, id: index, value: item._id };
        });

        setUserDetails(newData1);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("Error::", error);

      // setBackendError({ msg: error.response.data.message, type: "error" });
    }
  };

  useEffect(() => {
    fetchUserSetup();
  }, []);

  const fetchUserTableSetup = async (customStartDate, customEndDate, shop) => {
    try {
      const result = await getAllUserReports(
        tenantId,
        customStartDate,
        customEndDate,
        shop
      );
      // console.log("..", result);
      setUserTableDetails(result.data);
      setOriginalTableDetails(result.data);
    } catch (error) {
      // setBackendError({ msg: error.response.data.message, type: "error" });
    }
  };

  useEffect(() => {
    fetchUserSetup();
  }, []);



  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setUserFilterValue(selectedValue);
    
    setTableLoading(true); 

    console.log("Selected Value ", selectedValue, originalTableDetails);
    if (selectedValue === 'All') {
      setUserTableDetails(originalTableDetails);
    } else  {
      const newTableData = originalTableDetails.filter((tableRow) => tableRow.userId === selectedValue);
      setUserTableDetails(newTableData);
    }


    setTimeout(() => {
      setTableLoading(false);
    }, 1000)
  };


  const handleClick = async (customStartDate, customEndDate,shop) => {
    try {
      setShowDropdown(true);
      setLoading(true); // here updating loading state true
      await fetchUserTableSetup(customStartDate, customEndDate,shop);
      setDisplayComponents(true);
      setLoading(false); // here again loading state to false to stop loader
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    <ReportStyles>
      <HeaderBar
        title="User Report"
        notification="Data last updated 5min ago"
        hidePrimaryButton={true}
        hideSecondaryButton={true}
        showDescription={true}
        paperHeader={true}
        description="Gain Insight how much traffic on your channels get, how customer prefer more contact you "
      />
      <div className="report-container">
      <CustomDate setShowDropdown={setShowDropdown} handleClick={handleClick} />

      {displayComponents && <CustomReportFilter
        heading="user"
        label="User"
        marginRight="20px"
        selectList={[{name: 'All', value: 'All'}, ...userDetails]}
        icon={<PeopleAltIcon />}
        handleChange={handleChange}
        value={userFilterValue}
      />}
      {displayComponents ? (
        isLoading ? (
          <FullPageSpinner />
        ) : (
          <div className="report-table paper-card">
            <CustomReportTable columns={columns} data={userTableDetails} tableLoading={tableLoading} />
          </div>
        )
      ) : null}
    </div>
    </ReportStyles>
  );
};

export default UserReport;
