import React, { useState } from "react";
import CustomSwitch from "../../../components/CustomSwitch";
import CustomTextField from "../../../components/reusable/CustomTextField";
import CustomSelect from "../../../components/reusable/CustomSelect";
import ContactFormStyles from "../../../assets/styledComponents/channelConfiguration/chat/ContactForm";
import { useChannelContext } from "../../../context/channelConfigurationContext/channelContext";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Button } from "@mui/material";
import PrimaryButton from "../../../components/reusable/PrimaryButton/PrimaryButton";
const ContactForm = () => {
  const {
    // setAppearance,
    // appearance,
    uploadIcon,
    widgetFormattedList,
    setWidgetFormattedList,
    selectedWidgetShop,
    // setQueryList,
    queryValue,
    orderStatus,
    setQueryValue,
  } = useChannelContext();

  const selectedWidgetData = widgetFormattedList[selectedWidgetShop];
  if (!selectedWidgetData) return;

  const { contactFormValue, queryList } = selectedWidgetData;
  console.log({ queryList });
  // const {
  //   contactFormValue,
  //   setContactForm,
  //   queryList,
  //   setQueryList,
  //   queryValue,
  //   setQueryValue,
  //   setIsQueryAdded,
  // } = useChannelContext();

  const setContactForm = (key, value) => {
    setWidgetFormattedList({
      ...widgetFormattedList,
      [selectedWidgetShop]: {
        ...widgetFormattedList[selectedWidgetShop],
        contactFormValue: {
          ...widgetFormattedList[selectedWidgetShop].contactFormValue,
          [key]: value,
        },
      },
    });
  };
  const setQueryList = (value) => {
    setWidgetFormattedList((prev) => {
      console.log({ prev });
      return {
        ...prev,
        [selectedWidgetShop]: {
          ...prev[selectedWidgetShop],
          contactFormValue: {
            ...prev[selectedWidgetShop].contactFormValue,
            typeOfQuery: [
              ...prev[selectedWidgetShop].contactFormValue.typeOfQuery,
              value,
            ],
          },
          queryList: [...prev[selectedWidgetShop].queryList, value],
        },
      };
    });
  };
  console.log({ widgetFormattedList });
  const [initialQueryValue, setInitialQueryValue] = useState("Your Query");
  const [showAddQuery, setShowAddQuery] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setContactForm(name, checked);
      return;
    }
    setContactForm(name, value);
    return;
  };
  const handleAddQuery = () => {
    setContactForm({ ...contactFormValue, typeOfQuery: queryList });
  };

  const {
    contactForm,
    destinationEmail,
    customerName,
    customerEmail,
    typeOfQuery,
    messageInput,
    attachment,
  } = contactFormValue;

  const handleQueryChange = (e) => {
    console.log(e.target.value);
    // setQueryValue(e.target.value);
    setInitialQueryValue(e.target.value);
  };
  const handleQueryValueChange = (e) => {
    setQueryValue(e.target.value);
  };
  const handleQueryValueSubmit = (e) => {
    e.preventDefault();
    if (queryValue === "") return;
    setQueryList({ value: queryValue, label: queryValue });
    // setContactForm({
    //   ...contactFormValue,
    //   typeOfQuery: [...queryList, { value: queryValue, label: queryValue }],
    // });
    setQueryValue("");
    // setIsQueryAdded();
  };

  const validationEmail = (email) => {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailPattern.test(email)) {
      setEmailError("Invalid email");
      return false;
    }
    setEmailError("");
    console.log("this", emailPattern);
    return true;
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    handleChange(e);
    console.log(email);
    validationEmail(email);
  };

  return (
    <ContactFormStyles>
      <div className="container">
        <div className="switch-container contact-form">
          <h3 className="heading">Contact Form</h3>
          <div className="switch">
            <CustomSwitch
              name={"contactForm"}
              checked={contactForm}
              handleToggleSwitch={handleChange}
            />
          </div>
        </div>
        <div className="email-address">
          <h3 className="heading">Destination Email Address</h3>
          <p>New form Entries will delivered to this email </p>
          <CustomTextField
            placeholder={"Email Address"}
            name={"destinationEmail"}
            type={"email"}
            value={destinationEmail}
            handleChange={handleEmailChange}
            error={emailError}
          />
        </div>
        <h3 className="heading contact-fields">Contact Fields</h3>
        <div className="switch-container">
          <h3 className="heading">Customer Name</h3>
          <div className="switch">
            <CustomSwitch
              name={"customerName"}
              checked={customerName}
              handleToggleSwitch={handleChange}
            />
          </div>
        </div>
        <div className="switch-container">
          <h3 className="heading">Customer Email Address</h3>
          <div className="switch">
            <CustomSwitch
              name={"customerEmail"}
              checked={customerEmail}
              handleToggleSwitch={handleChange}
            />
          </div>
        </div>
        <div className="query-type">
          <h3 className="heading">Type of Query</h3>
          <div className="select">
            <CustomSelect
              label="Your Query"
              value={initialQueryValue}
              list={queryList}
              onChange={handleQueryChange}
            />
          </div>
        </div>
        <div
          className="add-query"
          onClick={() => setShowAddQuery((prev) => !prev)}
        >
          <div className="query-btn">
            <AddCircleOutlineOutlinedIcon sx={{ fontSize: "1.6rem" }} />
          </div>
          <p>Add Other Query</p>
        </div>
        {showAddQuery && (
          <form className="query-form" onSubmit={handleQueryValueSubmit}>
            <CustomTextField
              placeholder={"Add Query"}
              value={queryValue}
              handleChange={handleQueryValueChange}
              required={true}
            />
            <PrimaryButton variant="contained" type="submit">
              Add
            </PrimaryButton>
          </form>
        )}
        <div className="switch-container">
          <h3 className="heading">Message Input Field</h3>
          <div className="switch">
            <CustomSwitch
              name={"messageInput"}
              checked={messageInput}
              handleToggleSwitch={handleChange}
            />
          </div>
        </div>
        <div className="switch-container attachment-container">
          <h3 className="heading ">Attachments</h3>
          <div className="switch">
            <CustomSwitch
              name={"attachment"}
              checked={attachment}
              handleToggleSwitch={handleChange}
            />
          </div>
        </div>
      </div>
    </ContactFormStyles>
  );
};

export default ContactForm;
