import styled from "styled-components";
const NewVerifyOtpStyles = styled.div`
   display: flex;
  .left-container {
    margin-left: 20px;
    width: 45%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .verifyOtp-onboarding-screen {
    width: fit-content;
  }

  .logo {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    top: 10px;
    left: 40px;
    position: absolute;
  }

  .logo img {
    width: 22vh;
    height: 9vh;
  }
  .right-container {
    width: 50%;
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
  }

  .right-container .title {
    font-size: 30px;
    font-weight: 500;
  }

  .right-container .sub-title {
    font-size: 20px;
    font-weight: 400;
    margin: 15px 0px;
    font-style: normal;
    font-weight: 300;
  }

  .verifyBtn {
    width: max-content;
    margin: 20px auto;
  }

  .password-input {
    margin-top: 20px;
    width: 70%;
  }
  .submit-btn {
    margin-top: 20px;
  }

  .password-error {
    margin-top: 10px;
    width: 500px;
    // margin-bottom: -1rem;
  }
  .password-error ul {
    font-size: 0.8rem;
    display: flex;
    flex-wrap: wrap;
  }
  .password-error .list {
    display: flex;
    align-items: center;
    width: 180px;
  }
  .resend-otp {
    margin-top: 1rem;
    color :  var(--primary-500);
    cursor: pointer;
    float: right;
  }

`;
export default NewVerifyOtpStyles;
