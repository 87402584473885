export const SET_APPEARANCE = "SET_APPEARANCE";
export const SET_CONTACT_FORM = "SET_CONTACT_FORM";
export const SET_ORDER_STATUS = "SET_ORDER_STATUS";
export const SHOW_ORDER_STATUS = "SHOW_ORDER_STATUS ";
export const SET_TAB_DATA = "SET_TAB_DATA";
export const CREATE_CHAT_WIDGET_BEGIN = "CREATE_CHAT_WIDGET_BEGIN";
export const CREATE_CHAT_WIDGET_SUCCESS = "CREATE_CHAT_WIDGET_SUCCESS";
export const CREATE_CHAT_WIDGET_ERROR = "CREATE_CHAT_WIDGET_ERROR";
export const GET_CHAT_WIDGET_BEGIN = "GET_CHAT_WIDGET_BEGIN";
export const GET_CHAT_WIDGET_SUCCESS = "GET_CHAT_WIDGET_SUCCESS";
export const GET_CHAT_WIDGET_ERROR = "GET_CHAT_WIDGET_ERROR";
export const UPLOAD_ICON_BEGIN = "UPLOAD_ICON_BEGIN";
export const UPLOAD_ICON_SUCCESS = "UPLOAD_ICON_SUCCESS";
export const UPLOAD_ICON_ERROR = "UPLOAD_ICON_ERROR";
export const SET_IS_QUERY_ADDED = "SET_IS_QUERY_ADDED";
