import React from "react";
import styled from "styled-components";
import CustomAvatar from "../reusable/CustomAvatar";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-regular-svg-icons";

const FacebookPost = ({likeCount, commentCount, postCaption ,postImage, userName, pageImage , socialUserId}) => {
   return (
    <FacebookPostStyles>
      <div className="container">
        <div className="user-details">
          <div className="icon">
            <CustomAvatar src={pageImage} name={userName}/>
          </div>
          <div className="name">{userName}</div>
        </div>
        <div className="post-image">
          <img
            src={
              postImage
            }
            width={260}
            height={230}
          />
        </div>
        <p className="post-caption">{postCaption ?  (socialUserId ? socialUserId : userName) 
        + ": " + postCaption : null}</p>
        <div className="post-action">
          <div className="post-like">
            <button>
              <FavoriteBorderIcon fontSize="small" />
            </button>
            <p>{likeCount}</p>
          </div>
          <div className="post-comment">
            <button>
              <FontAwesomeIcon icon={faComment} />
            </button>
            <p>{commentCount}</p>
          </div>
        </div>
      </div>
    </FacebookPostStyles>
  );
};

export default FacebookPost;

const FacebookPostStyles = styled.div`
  .container {
    width: 260px;
    background-color: #fff;
    box-shadow: 0px 4px 22px 2px rgba(0, 0, 0, 0.31);
    border-radius: 5px;
    margin-left: 4.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .user-details {
    margin-left: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-block: 0.5rem;
  }
  .post-action,
  .post-caption {
    margin-inline: 0.75rem;
  }
  .post-caption {
    display: -webkit-box;
    max-width: 260px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: .5rem;
    font-size: 14px;
  }
  .post-action,
  .post-like,
  .post-comment {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .post-action {
    margin-top: 1rem;
    padding-bottom: 1rem;
    gap: 1.5rem;
  }
`;
