import styled from "styled-components";
const LabelStyles = styled.div`
  /* width: 81vw; */

  .label-container {
    display: flex;
    gap: 3rem;
  }

  .input-wrapper{
    flex: 1.7;
    /* padding-right: 1rem; */
    height: calc(  100vh - 80px);
    border-right: 1px solid var(--border-clr);
    margin-top: -10px;
  }
  .scroll-div{
    max-height: calc(100vh - 180px) !important;
    overflow-y: auto;
  }

  .text-editor-container{
    margin-top: 1.5rem;
    flex :3;
  }
  .save-btn {
    margin-left: auto;
    margin-top: 2rem;
  }
  .text-editor {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  .input-container {
    width: 85%;
    display: flex;
    background-color: #D9D9D9;
    padding: 0.5rem;
    border-radius: 6px;
    margin-bottom: 1rem;
    margin-top: 2rem;
    margin-left: 2rem;
  }
  .search-icon{
    cursor:none
  }
  .input-container input {
    all: unset;
    margin-left: 0.75rem; 
  }
  .header {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    padding: 20px;
    padding-left: 40px;
  }
  .header h2 {
    font-size: 22px;
    font-weight: 500;
  }
  .header button {
    margin-right: 20px;
  }
  .underline {
    background: #999999;
    width: 100%;
    height: 0.5px;
    margin-top: 0.1px;
    margin-bottom: 10px;
  }
  .features {
    margin-left: 40px;
  }
 
  .label-card span {
    font-size: 17px;
    margin-top: 10px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #333333;
    cursor:pointer
  }

  .label-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
  }
  .label-info {
    display: flex;
    align-items: center;
  }
  .delete-icon {
    cursor: pointer;
    margin-left:auto
  }
  .vertical-line {
    height: 87.5vh;
    background: #999999;
    width: 0.5px;
    margin-top: -0.6rem;
    margin-left: 15px;
  }
  .label-name span {
    font-size: 20px;
    font-weight: 500;
    /* margin-left: -20px; */
    cursor:none
  }
  .channel-name span {
    font-size: 20px;
    font-weight: 500;
    margin-left: -20px;
  }
  .sort-name span {
    font-size: 20px;
    font-weight: 500;
    margin-left: -20px;
  }

  .label-list-item-name {
    margin-left: 10px
  }
`;
export default LabelStyles;
