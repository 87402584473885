import React from 'react';
import logo from "../../../assets/images/logoWhite.png";
import { LogoSpinnerStyle } from './SpineerStyles';
const LogoSpinner = () => {
  return (
    <LogoSpinnerStyle className="logo-spinner">
        <img src={logo} />
    </LogoSpinnerStyle>
  );
};

export default LogoSpinner;