import React from "react";
import styled, { keyframes } from "styled-components";

const dotCarouselAnimation = keyframes`
  /* 0%, 100% {
    transform: scale(1);
  }
  50% {
      transform: scale(1.2);
      transform: scaleX(.8);
  } */


    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1.5);
    }
    50% {
        transform: scale(1, 0.67);
    }
    75% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 1.5);
    }
    75% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }

    0% {
        box-shadow: 9984px 0 0 -1px #202021, 9999px 0 0 1px #202021, 10014px 0 0 -1px #202021;
    }
    50% {
        box-shadow: 10014px 0 0 -1px #202021, 9984px 0 0 -1px #202021, 9999px 0 0 1px #202021;
    }
    100% {
        box-shadow: 9999px 0 0 1px #202021, 10014px 0 0 -1px #202021, 9984px 0 0 -1px #202021;
    }

`;

const StyledLoadingAnimation = styled.div`
  height: 40px;
  margin-top: 20px;
`;

const StyledDotCarouselContainer = styled.div`
  text-align: center;
  margin-top: 6px;
  color: gray;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 0.85rem;
`;

const StyledDotCarousel = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: black;
  display: inline-block;
  margin-right: 5px;
  animation: ${dotCarouselAnimation} 1.5s infinite ease-in-out;
`;

const MessageLoading = () => {
  return (
    <StyledLoadingAnimation id="loading-animation">
      <StyledDotCarouselContainer id="dot-carousel-container">
        <div className="stage">
          <StyledDotCarousel className="dot-carousel"></StyledDotCarousel>
          <StyledDotCarousel className="dot-carousel"></StyledDotCarousel>
          <StyledDotCarousel className="dot-carousel"></StyledDotCarousel>
        </div>
        <div>
          <div>Giving thought to</div>
        </div>
      </StyledDotCarouselContainer>
    </StyledLoadingAnimation>
  );
};

export default MessageLoading;
