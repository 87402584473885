import styled from "styled-components";
const ContactFormScreenStyles = styled.div`
  position: fixed;
  width: 380px;
  .contact-wrapper {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: relative;
    padding-bottom: ${({ page }) => (page === "contact" ? "10px" : "0px")};
    overflow-x: hidden;
  }

  .tabsOfApperance li {
    font-size: 16px;
    margin: 0px 8px;
    cursor: pointer;
    border-bottom: 1.5px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  .tabsOfApperance li svg {
    margin-right: 2px;
  }

  .heading {
    font-weight: 400;
    font-size: 18px;
  }

  .chat-banner {
    margin: 0.5rem 0;
    text-align: center;
  }

  .subHeading {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }

  .subHeading-18 {
    font-size: 18px;
    font-size: 18px;
    font-weight: bold;
    font-family: Noto Sans JP Variable;
    color: ${({ textColor }) => (textColor == "#ffffff" ? "black" : textColor)};
  }

  .magic-icon svg {
    fill: white;
  }

  .magic-icon {
    border: 2px solid white;
    border-radius: 50%;
  }

  .navbar {

    position: relative;
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 0.5px solid rgb(218, 216, 216);
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 20px;
  }
  .close-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: black;
    cursor: pointer;
    z-index: 999999;
  }
  .img-container h3 {
    /* margin-left: -0.5rem; */
    border-radius: 1rem;
    font-weight: 600;
    font-size: 16px;
  }
  .logo-msg {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
  }

  .intro-msg {
    font-size: 12px;
  }

  .navbar .img-container,
  .profile-container {
    display: flex;
    align-items: center;
    gap: 0px !important;
    position: relative !important;
  }

  .navbar .img-container img{
    border-radius: 4px;
  }

  .clipped-image {
    margin-left: -20px;
  }

  .profile-container .MuiAvatar-root {
    width: 50px;
    height: 50px;
    border: 2px solid ${props => props.themeColor};
    border-radius: 50%;
  }

  .content {
    background-color: #fff;
    height: 60vh;
    padding-top: 10px;
  }
  .icon-container {
    background-color: ${({ themeColor }) => themeColor};
    /* background-color: var(--primary-700); */
    z-index: 100;
    border-radius: 0.2rem;
    color: "white !important";
    padding: 4.5px 8px;
    margin-left: -13px;
  }

  .icon-container svg {
    padding-top: 4px !important;
  }
  .text-field-container {
    padding: 0.7rem 1.5rem;
  }
  .text-field-container h3 {
    margin-bottom: 0.5rem;
  }
  .select-field {
    display: flex;
    align-items: center;
  }
  .select-icon {
    background-color: var(--primary-700);
    padding: 0.3rem 0.5rem;
    z-index: 100;
    border-radius: 0.2rem;
    color: "white";
  }
  .attachment {
    width: fit-content;
    padding: 1rem 3rem 1rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.25);
  }
  .attachment p {
    color: #666;
    font-size: 1rem;
    font-weight: 500;
  }

  /* order status */
  .order-id {
    // font-size: 1.5rem;
    // font-weight: 500;
    margin-top: 1.5rem;
    margin-left: 1rem;
  }
  .order-id span:nth-child(1) {
    color: #969696;
  }
  .order-card {
    border-radius: 8px;
    box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.25);
    margin: 1rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .order-details {
    display: grid;
    gap: 0.5rem;
  }
  .order-details p {
    font-size: 0.75rem;
  }
  .order-spec {
    display: flex;
    gap: 2rem;
  }
  .size,
  .qty {
    font-size: 0.75rem;
    color: var(--gray-700);
  }
  .unit {
    color: black;
  }
  .status {
    padding: 5px 10px;
    border-radius: 21px;
    background-color: var(--primary-200);
    color: var(--primary-900);
    font-size: 12px;
  }

  /* appearance screen */
  .welcome-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .welcome-container p {
  }
  .profile {
    /* display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    width: 80px; */
    /* margin-left: -2rem; */
  }

  // .chatbot-avtar-box {
  //   gap: 10px !important;
  // }
  .profile p {
    font-size: 14px;
    text-align: center;
  }
  .list-btn {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    width: 100%;

    padding: 12px 0px;
    font-size: 16px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    // margin: 0 auto;
    // padding-bottom: 0.5rem;
    // border-bottom: 0.5px solid #c2c2c2;
    // margin-top: 10px;
    // margin-left: 1rem;
    /* overflow-x: hidden; */
  }
  .list-btn li {
    cursor: pointer;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    // margin-right: 20px;
    margin-bottom: -0.5rem;
    /* margin-left: 1rem; */
  }

  .list-btn li.active {
    font-size: 18px !important ;
    font-style: normal !important;
    font-weight: 400 !important;
    color: ${({ themeColor }) => themeColor};
    border-bottom: 0.5px solid;
    border-color: ${({ themeColor }) => themeColor};
  }
  .check-order-container {
    height: 485px;
    overflow-y: scroll;
  }

  .order-status {
    margin-left: 1.3rem;
    margin-top: 1rem;
  }
  .gray-txt {
    color: #81848a;
  }
  .order-btn {
    margin: 1rem 0;
    text-align: center;
  }

  .orderstatus-brief-message {
    margin-top: 2px;
  }

  /* .message-content {
    position: absolute;
    bottom: 0;
  } */
  .tab-heading {
    display: flex;
    align-items: center;
  }
  .image-container {
    position: relative;
    width: 40px;
    height: 40px;
  }
  .online-symbol {
    background: #8bc34a;
    width: 12px;
    height: 12px;
    border: 1.5px solid white;
    border-radius: 50%;
    position: absolute;
    left: 34px;
    bottom: -6px;
  }
  .order-id {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
  }

  #branding-text {

    font-size: 14px;
    text-align: center;
    color: rgb(133, 130, 130);
    margin: 10px 0px;

    a {
      text-decoration: none;
      font-weight: bold;
      color: black;
      font-family: "Inter", sans-serif;
    }
  }
`;
export default ContactFormScreenStyles;
