import React from 'react'
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";

const OrderInfoSkeleton = () => {
  return (
    <Stack sx={{ padding: ".5rem 1rem" }} >
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: '1rem', justifyContent: 'start', gap: "1rem" }}>
        <Skeleton variant="rectangular" height={10} width={50} />
        <Skeleton variant="rectangular" height={30} width={60} sx={{ borderRadius: '.5rem' }} />
        <Skeleton variant="rectangular" height={30} width={60} sx={{ borderRadius: '.5rem' }} />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: '1rem', justifyContent: 'start', gap: "2rem" }}>
        <Skeleton variant="rectangular" height={10} width={'100%'} />
      </Box>
      {
        Array(7).fill(0).map((_, index) => {
          return (
            <Box key={index} sx={{ display: "flex", marginBottom: '1rem', alignItems: "center", justifyContent: 'start', gap: "2rem" }}>
              <Skeleton variant="rectangular" height={10} width={'100%'} />
            </Box>
          )
        })
      }
      <Skeleton variant="rectangular" height={1} width={'100%'} />

      <Skeleton variant="rectangular" height={10} width={70} sx={{ marginTop: '1rem' }} />
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: '1rem', marginTop: '1rem', justifyContent: 'start', gap: "1rem" }}>
        <Skeleton variant="rectangular" height={10} width={50} />
        <Skeleton variant="rectangular" height={20} width={40} sx={{ borderRadius: '1rem' }} />
        <Skeleton variant="rectangular" height={20} width={40} sx={{ borderRadius: '1rem' }} />
      </Box>

      {
        Array(7).fill(0).map((_, index) => {
          return (
            <Box key={index} sx={{ display: "flex", marginBottom: '1rem', alignItems: "center", justifyContent: 'start', gap: "2rem" }}>
              <Skeleton variant="rectangular" height={10} width={'100%'} />
            </Box>
          )
        })
      }
    </Stack >
  )
}

export default OrderInfoSkeleton
