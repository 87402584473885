import styled from "styled-components";

const AssignToStyles = styled.div`
  .search-container {
    margin: 1rem 0;
    margin-left: 1rem;
  }
  .modal-container {
    width: 300px;
  }
  ul {
    margin-bottom: 1rem;
  }

  li:hover {
    background-color: var(--primary-100);
  }

  li {
    cursor: pointer;
  }
  .person {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .active {
    background-color: var(--primary-100);
  }
`;

export default AssignToStyles;
