import styled from "styled-components";
const EmailSignatureStyles = styled.div`
  .wrapper {
    /* width: 81vw; */
  }
  .store-tabs {
    margin-left: 40px;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    padding: 20px;
    margin-left: 1rem;
  }
  .top button {
    margin-right: 18px;
  }
  .save-button {
    margin-left: calc(50% - 155px);
  }
  .underline {
    background-color: var(--border-clr);
    width: 94%;
    height: 1px;
    margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .container {
    /* width: 600px; */
    margin-left: 40px;
  }
  .section1 {
    width: 95%;
    height: calc(100vh - 70px);
    overflow-y: scroll;
  }
  .section1 h2 {
    font-weight: 500;
    font-size: 20px;
    margin-top: 20px;
  }
  .store-description {
    margin-top: 4px;
  }

  .line {
    background: gray;
    width: 42%;
    height: 0.1px;
    position: relative;
    bottom: 55px;
    left: 50px;
  }
  .this {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
  }
  .third {
    margin-bottom: 10px;
  }
  .start {
    height: 50%;
  }
  .signature-textBox {
    margin-top: 1rem;
    margin-left: -40px;
  }
  /* link modal styles */
  .modal-container {
    width: 603px;
    padding: 1.5rem;
  }
  .textfield-container {
    margin-bottom: 1rem;
  }
  .btn-container {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 1rem;
  }
  .heading {
    font-size: 21px;
    font-weight: 500;
  }
  .first-heading {
    margin-bottom: 0.5rem;
  }

  .sub-heading {
    color: #81848a;
    font-size: 16px;
    font-weight: 400;
  }

  .error-label {
    margin-top: 0.5rem;
    margin-left: 40px;
    font-size: small;
    color: #d32f2f;
  }
  @media (max-width: 1368px) {
    .wrapper {
      width: 100%;
    }
  }
`;
export default EmailSignatureStyles;
