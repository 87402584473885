import styled from "styled-components";
const MetaAddPageStyles = styled.div`
  width: 81vw;
  .image {
    width: 70px;
    height: 70px;
    margin-left: 50px;
    margin-top: 40px;
    display: flex;
    gap: 30px;
  }
  .name-container {
    margin-top: 12px;
  }
  .name-container .title {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 18px;
  }
  .url {
    display: flex;
    font-weight: 400;
    font-size: 15px;
    width: 90vw;
  }
  .delete {
    margin-left: 800px;
    margin-top: -15px;
  }
  .action-icon {
    display: flex;
    margin-left: 880px;
    margin-top: -55px;
    gap: 24px;
  }
  .custom-icon {
    display: flex;
    flex-direction: column;
    margin-top: -10px;
  }
`
export default MetaAddPageStyles;
