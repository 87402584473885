import styled from "styled-components";
const TermsAndConditionStyles = styled.div`
  width: 100%;
  height: 100vh;
  .header {
    width: 100%;
    background-color: var(--primary-100);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    // position: relative;
    position: sticky;
  }
  .table-of-contents {
    margin-bottom: 1rem;
  }
  .table-of-contents-container {
    margin-bottom: 1rem;
  }
  .logo {
    position: absolute;
    left: 2rem;
  }
  .logo-container {
    position: absolute;
    left: 2rem;
    display: flex;
  }
  .logo-container .heading {
    padding-left: 0;
  }
  .paragraph-container {
    /* width: 70vw; */
    height: calc(100vh - 100px);
    margin-inline: auto;
    padding-top: 2rem;
    overflow-y: auto;
    padding: 0 15%;
    padding-top: 30px;
  }

  .paragraph {
    margin-bottom: 1.5rem;
  }
  .heading {
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .sub-heading {
    margin-bottom: 0.5rem;
  }
  .para,
  .content {
    margin-bottom: 0.5rem;
    color: #595959;
  }

  li {
    margin-bottom: 8px;
    list-style: disc;
  }
  ul {
    list-style-type: disc !important;
    margin-left: 20px;
  }
  .privacy-para {
    margin-top: 2rem;
  }
`;

export default TermsAndConditionStyles;
