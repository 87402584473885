import styled from "styled-components";
const FacebookCardStyles = styled.div`
  width: 81vw;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  .top h2 {
    font-weight: 500;
    font-size: 20px;
  }
  
`;
export default FacebookCardStyles;
