import React, { useRef, useState } from "react";
import HeaderStyles from "../../assets/styledComponents/Header";
import { Avatar, Button, MenuItem, Select, TextField } from "@mui/material";
import CustomSwitch from "../CustomSwitch";
import MyProfileSettingStyles from "../../assets/styledComponents/myProfile/MyProfileSetting";
import { getKey } from "../../utils/helperFunction";
import { getAllUserSetup, getSingleUserDetails, updateUserDetail } from "../../service/service";
import { uploadToS3 } from "../../utils/uploadImageToS3";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import { useTenantContext } from "../../context/TenantContext";
import HeaderBar from "../reusable/HeaderBar/HeaderBar";
import CustomProgressBar from "../reusable/CustomProgressBar";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import { useEffect } from "react";
import FullPageSpinner from "../FullPageSpinner";
const MyProfileSettings = () => {
  const { userId, profile, setToken, tenantId } = useTenantContext();
  const [progress, setProgress] = useState(0);
  const {socket,currentSessionData } = useInboxContext()
  const [pageLoading, setPageLoading] = useState(false)

  const { _id: sessionId } = currentSessionData;

  const initialState = [
    {
      id: 1,
      name: "Name",
      type: "textField",
      valueType: "",
      value:"", 
    },
    {
      id: 2,
      name: "Email Address",
      type: "textField",
      valueType: "email",
      value: "",
    },
    { id: 3, name: "Status", type: "switch", value: false},
  ];
  const [formValue, setFormValue] = useState([...initialState]);
  const [dataInDb, setDataInDb] = useState(structuredClone(initialState))
  const [imageLocation, setImageLocation] = useState("");
  const [snackBarError, setSnackBarError] = useState({ msg: "", type: " " });
  const fileInputRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const [showProgress, setShowProgress] = useState(false);
  const [changesMade, setChangesMade] = useState(false); 


  
  const handleChange = (e, index) => {
    setFormValue((prev) => {
      const updatedValue = [...prev];
      updatedValue[index].value = e.target.value;
      setChangesMade(true);
      return updatedValue;
    });

    setDisableSaveBtn(false)
  };

  const handleToggleSwitch = (e, index) => {
    setFormValue((prev) => {
      const updatedValue = [...prev];
      updatedValue[index].value = e.target.checked;
      setChangesMade(false);
      return updatedValue;
    });
    setDisableSaveBtn(false);
  };

  const handleDiscard = (e) => {
    setFormValue(structuredClone(dataInDb));
    setImageLocation("");
    setPreviewImage(null);
    setDisableSaveBtn(true)
    setShowProgress(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSave = async (e) => {
    // e.preventDefault();
    console.log(formValue);
    const payload = {
      userId,
      profileImageUrl: imageLocation || profile.token.profileImageUrl,
      name: formValue[0].value,
      email: formValue[1].value,
      status: formValue[2].value,
    };
    setLoading(true);
    try {
      const result = await updateUserDetail(payload);
      setSnackBarError({
        msg: "Profile Updated Successfully",
        type: "success",
      });
      socket?.emit(
        "AGENT_STATUS",
        userId,
        tenantId,
        formValue[2].value
      );
      setToken(result.data);
      setLoading(false);
      setDisableSaveBtn(true);
      setDataInDb(structuredClone(formValue));
    } catch (error) {
      setSnackBarError({ msg: error.response.data.message, type: "error" });
      setDisableSaveBtn(true);
      setLoading(false);
    }
    // setChangesMade(false);
  };

  
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file.type.startsWith("image")) {
      setSnackBarError({ msg: "Please upload a image", type: "error" });
      return;
    }
    if (file.size > 1000000) {
      setSnackBarError({
        msg: "File size is should be less than 1Mb",
        type: "error",
      });
      return;
    }
    if (file) {
      setLoading(true)
      const reader = new FileReader();
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      reader.onload = (e) => {
        const uploadedImage = e.target.result;
        setPreviewImage(uploadedImage);
      };
      reader.readAsDataURL(file);
      setShowProgress(true);
      setProgress(0);
      try {
        const location = await uploadToS3(file);
        setImageLocation(location);
        setDisableSaveBtn(false);
        setLoading(false)
        setProgress(100);
      } catch (error) {
        console.log(error);
        setDisableSaveBtn(false);
        setLoading(false)
      }
    }
  };
  
  const fetchUserData = async()  => {
    setPageLoading(true)
    try {
      const result = await getSingleUserDetails({userId})
      const data = structuredClone(result.data)
      setFormValue((prev) => {
        let updatedValue = [...prev] 
        updatedValue[0].value  = data.name
        updatedValue[1].value  = data.email
        updatedValue[2].value  = data.onlineStatus
        return updatedValue
      })
      const dbData = structuredClone(result.data)
      setDataInDb((prev) => {
        let updatedValue = [...prev] 
        updatedValue[0].value  = dbData.name
        updatedValue[1].value  = dbData.email
        updatedValue[2].value  = dbData.onlineStatus
        return updatedValue
      })
      setPageLoading(false)
      console.log({result})
    } catch (error) {
      setPageLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  return (
    <MyProfileSettingStyles>
      {snackBarError.msg && <CustomSnackbar payload={snackBarError} />}
      <div>
        <HeaderBar
          title={"My Profile"}
          hideSecondaryButton={false}
          hidePrimaryButton={false}
          primaryButtonLabel={"Save"}
          primaryButtonDisable={disableSaveBtn || isLoading}
          secondaryButtonDisable={disableSaveBtn || isLoading} 
          secondaryButtonLabel={"Discard Changes"}
          handlePrimaryButtonClick={handleSave}
          handleSecondaryButtonClick={handleDiscard}
        />
        {pageLoading ? <FullPageSpinner/> : 
        <div className="container">
          <div className="profile-content">
            <p className="profile-heading">Profile Settings</p>
            <span className="profile-sub-heading">
              Customize the profile as per your interest
            </span>

            <p className="pic-heading">Profile Picture</p>
            <div className="profile-container">
              {previewImage ? (
                <img
                  className="img-preview"
                  src={previewImage}
                  width={60}
                  height={60}
                />
              ) : (
                <Avatar
                  alt=""
                  sx={{ bgcolor: "#729b5a", width: 60, height: 60 }}
                  src={profile?.token?.profileImageUrl}
                >
                  {profile.token.name[0].toUpperCase()}
                </Avatar>
              )}

              <div className="name">
                <input
                  type="file"
                  id="text-file"
                  accept=".jpeg"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />

                {/* <p className="pic-size">640*640px recommended</p> */}
                {showProgress && (
                  <div className="progress-bar">
                    <CustomProgressBar
                      progress={progress}
                      setProgress={setProgress}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {formValue.map((item, index) => {
            const { name, type, id, value, valueType } = item;
            return (
              <div
                key={getKey(name, "formValue", index)}
                className="form-container"
              >
                {type !== "switch" && <p className="heading">{name}</p>}
                {type === "textField" && (
                  <TextField
                    required
                    name={name}
                    value={value}
                    onChange={(e) => handleChange(e, index)}
                    type={valueType}
                    disabled={name === "Email Address"}
                    sx={{
                      width: 520,
                      boxShadow: 7,
                      marginTop: 2,
                      borderRadius: "10px",
                    }}
                    // label={name}
                    placeholder={name}
                  />
                )}
                {/* Enable in future for language select */}
                {/* 
              {type === "select" && (
                <Select
                  sx={{
                    width: 520,
                    boxShadow: 7,
                    marginTop: 2,
                    borderRadius: "10px",
                  }}
                  value={value}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p className="placeHolder">Select Role</p>;
                  }
                }
              }
                >

                  <MenuItem value={10}>English</MenuItem>
                  <MenuItem value={11}>French</MenuItem>
                  )}
                  </Select>
               */}

                {/* Enable in future for status */}

                {type === "switch" && (
                  <div className="status">
                    <h3 className="heading">{name} : {value ? <span className="status-online">Online</span> : <span className="status-offline">Offline</span> } </h3>
                    <CustomSwitch
                      index={index}
                      checked={value}
                      handleToggleSwitch={handleToggleSwitch}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        }
      </div>
    </MyProfileSettingStyles>
  );
};

export default MyProfileSettings;
