import * as React from "react";
import Drawer from "@mui/material/Drawer";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationUi from "./NotificationUi";
import { useTenantContext } from "../../context/TenantContext";

const NotificationDrawer = () => {
  const { openSidebar, setOpenSideBar } = useTenantContext();

  const toggleDrawer = (anchor, open) => (event) => {
    event.stopPropagation();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenSideBar(open);
  };

  return (
    <div onClick={toggleDrawer("left", true)} className="icon">
      <NotificationsIcon />
      <Drawer
        anchor={"left"}
        open={openSidebar}
        onClose={toggleDrawer("left", false)}
      >
        <NotificationUi toggleDrawer={toggleDrawer} />
      </Drawer>
    </div>
  );
};

export default NotificationDrawer;
