import styled from "styled-components";
const AppearanceStyles = styled.div`
  .appearance-heading {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .show-avatars {
    margin-top: 1rem;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .switch {
    margin-right: -1.5rem;
  }
  .gradient-picker {
    margin-top: 1rem;
  }
  .icon-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .progress-bar {
    width: 200px;
  }
`;
export default AppearanceStyles;
