import React from "react";
import Rectangle73 from "../../../assets/images/Rectangle 73.png"
import { Button } from "@mui/material";
import MetaAddPage from "../../reusable/ChannelConfiguration/MetaAddPage";
import InstagramCardStyles from "../../../assets/styledComponents/settings/InstagramCard";
const WhatsappConfiguration= () => {
  const data = {
    name:"Whatsapp Name",
    logo: Rectangle73 ,
    url:"example-myshopify.com"
   };
  return (
    <>
 <InstagramCardStyles>
      <div className="top">
          <div className="">
            <h2>Whatsapp Configuration</h2>
          </div>
          <div className="saveButton">
          <Button variant="contained">Add Page</Button>
        </div>
        </div>
        <div className="underline"></div>
       <MetaAddPage {...data} />
       <MetaAddPage {...data} />
       <MetaAddPage {...data} />
       </InstagramCardStyles>
        </>
  );
};
export default WhatsappConfiguration;
