import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Button, InputAdornment, TextField } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation, useNavigate } from "react-router-dom";

import NewVerifyOtpStyles from "./NewVerifyOtpStyles";
import loginHeroImg from "../../assets/images/login123.png";
import {
  verifyOtp,
  logoutUser,
  setPassword,
  changePassword,
  resendOtp,
} from "../../service/service";
import PrimaryButton from "../../components/reusable/PrimaryButton/PrimaryButton";
import logo from "../../assets/images/logoTitle.svg";
import CustomSnackbar from "../../components/snackbar/CustomSnackbar";
import { DEFAULT_PASSWORD_CONSTRAINT } from "../../utils/helperData";
import { TenantContext } from "../../context/TenantContext";
import styled from 'styled-components' 

const MuiOtpInputStyled = styled(MuiOtpInput)`
.MuiInputBase-input {
  height: 3.3vh;
}
`

const NewVerifyOtp = () => {
  const initialErrors = {
    password: "",
    confirmPassword: "",
  };

  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isResendOptLoading, setResendOptLoading] = useState(false)
  const [backendError, setBackendError] = useState({ msg: "", type: "error" });
  const [showSuccess, setShowSuccess] = useState(false);
  const [passwordValue, setPasswordValue] = useState({
    password: "",
    confirmPassword: "",
  });
  const [showPasswordPage, setShowPasswordPage] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({
    ...initialErrors,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordAlert, setPasswordAlert] = useState(
    DEFAULT_PASSWORD_CONSTRAINT
  );

  const { profile } = useContext(TenantContext);
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlToken = urlParams.get("token");
  const urlType = urlParams.get("type");

  const handleLogout = async () => {
    if (profile && profile.token && profile.token.userId) {
      await logoutUser({ userId: profile.token.userId });
    }
    localStorage.removeItem("token");
    localStorage.removeItem("store");
  };

  useEffect(() => {
    const { pathname, search } = window.location;

    const parsed = queryString.parse(search);
    setToken(parsed?.token);

    if (parsed.token) {
      if (urlType !== 'register') {
        handleLogout();
      }
    }
    setLoading(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!otp) {
      setBackendError({ msg: "Otp is required", type: "error" });
      return;
    }

    if (!token) {
      setBackendError({ msg: "Invalid Verification Link", type: "error" });
      return;
    }

    try {
      const result = await verifyOtp({ payload: { otp, secureKey: token } });
      setShowSuccess(true);
      setBackendError({ msg: "Verified Successfully", type: "success" });
      if (urlType !== 'register') {
        setShowPasswordPage(true);
      }else {
        // navigate('/login')
        navigate("/settings/connect-shopify");
        window.location.reload();
      }
    } catch (error) {
      setBackendError({
        msg: error.response.data.message || "Something is wrong",
        type: "error",
      });
    }
  };

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handlePasswordChange = async (e) => {
    const { name, value } = e.target;

    setPasswordValue((prev) => {
      let updatedValue = { ...prev };
      updatedValue[name] = value;
      if (updatedValue.password !== updatedValue.confirmPassword) {
        setErrors({
          password: "",
          confirmPassword: "password and confirm password should be same",
        });
      } else {
        setErrors({
          ...initialErrors,
        });
      }

      return { ...prev, [name]: value };
    });
    let currentPassword = value;
    let newPassConstraintCheck = [...DEFAULT_PASSWORD_CONSTRAINT];

    newPassConstraintCheck = await Promise.all(
      newPassConstraintCheck.map((passConstraintData) => {
        let result = {
          ...passConstraintData,
        };

        if (passConstraintData.regexPattern) {
          if (passConstraintData.regexPattern.test(currentPassword)) {
            result.valid = true;
          } else {
            result.valid = false;
          }
        }
        if (passConstraintData.minLength) {
          if (currentPassword.length >= passConstraintData.minLength) {
            result.valid = true;
          } else {
            result.valid = false;
          }
        }
        return result;
      })
    );

    setPasswordAlert(newPassConstraintCheck);
  
  };

  const resendOtpValue = async () => {
    setResendOptLoading(true)
    try {
      setResendOptLoading(false)
      const result = await resendOtp({ payload: { secureKey: token, type : urlType } });
      setBackendError({
        msg: "Otp sent successfully",
        type: "success",
      });
    } catch (error) {
      setResendOptLoading(false)
      setBackendError({
        msg: error.response.data.message || "Something is wrong",
        type: "error",
      });
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = passwordValue;
    console.log(password, confirmPassword);

    if (password === "") {
      setErrors({ ...initialErrors, password: "Please provide password" });
      return;
    } else if (confirmPassword === "") {
      setErrors({
        ...initialErrors,
        confirmPassword: "Please provide password",
      });
      return;
    }
    if (errors.confirmPassword !== "") {
      return;
    }
    const isPasswordValid = showPasswordAlert.every(
      ({ valid }) => valid === true
    );

    if (!isPasswordValid) {
      setErrors((prev) => ({ ...prev, password: "Invalid Password" }));
      return;
    }

    try {
      if (urlType === "forgotPassword") {
        const result = await setPassword({
          password: passwordValue.confirmPassword,
          secureKey: token,
          token: urlToken,
          type: urlType,
        });
      } else {
        const result = await setPassword({
          password: passwordValue.confirmPassword,
          secureKey: token,
        });
      }

      window.location = "/login";
    } catch (error) {
      setBackendError({ msg: error.response.data.message, type: "error" });
    }
  };

  const title = urlType === "forgotPassword" ? "Verify OTP" : "Onboarding User";
  const subTitle =
    urlType === "forgotPassword"
      ? "Enter the OTP sent to your email to verify your identity"
      : "Please enter the verification code sent to your email in order to proceed";

  const inputOtpUI = (
    <div className="verifyOtp-onboarding-screen">
      <p className="title"> {title} </p>
      <p className="sub-title">{subTitle}</p>
      <div className="otp-container">
      <MuiOtpInputStyled length={6} value={otp} onChange={handleChange} />
      </div>
      <br />
      <Button
        onClick={(e) => handleSubmit(e)}
        color="primary"
        variant="contained"
        fullWidth
        sx={{
          color: "white",
          height: 50,
        }}
      >
        VERIFY NOW
      </Button>
      <button disabled={isResendOptLoading} className="resend-otp" onClick={() => resendOtpValue()}>
        RESEND OTP 
      </button>
    </div>
  );

  const setPasswordUI = (
    <form className="password-wrapper" onSubmit={handlePasswordSubmit}>
      <h3 className="title">Set Password</h3>
      <div className="password-input">
        <TextField
          id="password"
          name="password"
          label="Password"
          helperText={errors.password}
          error={Boolean(errors.password)}
          value={passwordValue.password}
          type={showPassword ? "text" : "password"}
          variant="outlined"
          onChange={handlePasswordChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon htmlColor="#8db178" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="start"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityOutlinedIcon htmlColor="#8db178" />
                ) : (
                  <VisibilityOffOutlinedIcon htmlColor="#8db178" />
                )}
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </div>
      {passwordValue.password && (
        <div className="password-error">
          <ul>
            {passwordValue.password &&
              showPasswordAlert.map(({ id, valid, condition }) => {
                return (
                  <div className="list" key={id}>
                    {valid ? (
                      <CheckCircleIcon
                        style={{
                          fontSize: "0.8rem",
                          margin: "1px 0.5rem",
                          color: "#B9A1D6",
                        }}
                      />
                    ) : (
                      <CancelIcon
                        style={{
                          fontSize: "0.8rem",
                          margin: "1px 0.5rem",
                          color: "red",
                        }}
                      />
                    )}
                    <li>{condition}</li>
                  </div>
                );
              })}
          </ul>
        </div>
      )}
      <div className="password-input">
        <TextField
          id="password"
          name="confirmPassword"
          label="Confirm Password"
          helperText={errors.confirmPassword}
          error={Boolean(errors.confirmPassword)}
          value={passwordValue.confirmPassword}
          type={showConfirmPassword ? "text" : "password"}
          variant="outlined"
          onChange={handlePasswordChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon htmlColor="#8db178" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="start"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <VisibilityOutlinedIcon htmlColor="#8db178" sx={{ cursor: 'pointer'}} />
                ) : (
                  <VisibilityOffOutlinedIcon htmlColor="#8db178"  sx={{ cursor: 'pointer'}} />
                )}
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </div>

      <div className="submit-btn">
        <Button sx={{ color: "white" }} type="submit" variant={"contained"}>
          Submit
        </Button>
      </div>
    </form>
  );
  return (
    <NewVerifyOtpStyles>
      {backendError.msg && <CustomSnackbar payload={backendError} />}

      {backendError.msg && (
        <CustomSnackbar payload={backendError} duration={"10000"} />
      )}
      <div className="left-container">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <img src={loginHeroImg} />
      </div>
      <div className="right-container">
        {showPasswordPage ? (
          setPasswordUI
        ) : isLoading && token ? (
          <div className="container">Link is not valid</div>
        ) : showSuccess ? (
          <div className="verified">
            <p>Verified SuccessFully</p>
            <CheckCircleIcon style={{ fontSize: "3rem", color: "#8db178" }} />
          </div>
        ) : (
          inputOtpUI
        )}
      </div>
    </NewVerifyOtpStyles>
  );
};

export default NewVerifyOtp;
