
import React, { useState, useContext, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";

import ShopifyConfigPageStyles from "../../assets/styledComponents/shopifyStore/ShopifyConfigPage";
import HeaderBar from "../../components/reusable/HeaderBar/HeaderBar";
import { TenantContext, useTenantContext } from "../../context/TenantContext";
import ShopifyLogo from "../../assets/images/logos_shopify.png";
import ShopifyCard from "../../components/settings/ShopifyCard";
import { deleteTenantStore, getAllTenantStore, getMLLogs } from "../../service/service";
import ShopifyCIntegration from "../../components/settings/ShopifyIntegration";
import Swal from "sweetalert2";
import FullPageSpinner from "../../components/FullPageSpinner";
import AITrainingStyles from "../../assets/styledComponents/AITraining";
import AITrainingCard from "./AITrainingCard";
import useFetch from "../../hooks/useFetch";


const AITraining= () => {
  const [tabs, setTabs] = useState([]);
  const [originalTabs, setOriginalTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const {tenantId, } = useTenantContext()


  const { profile } = useContext(TenantContext);



  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchStores = async () => {
    setLoading(true);

    if (!profile?.token?.tenantId) return;
    
    const tabsRes = await getAllTenantStore(profile.token.tenantId);

    if (tabsRes.success) {
      setTabs(tabsRes.data);
      setOriginalTabs(tabsRes.data);
      setActiveTab(0);
      setLoading(false);
    }
  };


  
  useEffect(() => {
    fetchStores();
  }, []);


  return (
    <AITrainingStyles>
      <HeaderBar
        title={"AI Training"}
        hideSecondaryButton={true}
        hidePrimaryButton={true}
        showDescription={false}
        primaryButtonDisable={isLoading}
      />
      {isLoading ? <FullPageSpinner /> : <div>
          <div className="shopify-tabs-container">
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              {tabs.map((tabData) => (
                <Tab
                  key={`${tabData.shop || "New Account"} `}
                  label={`${tabData.shop || "New Account"}  ${
                    tabData.isDefault ? "- Default" : ""
                  }`}
                />
              ))}
            </Tabs>
          </div>
            <div>
                <AITrainingCard activeTab={activeTab} shop={tabs[activeTab]?.shop} date={'03/27/2024'}/>
            </div>
      </div>}
    </AITrainingStyles>
  );
};

export default AITraining;
