import React, { useState, useContext, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";

import ShopifyConfigPageStyles from "../../assets/styledComponents/shopifyStore/ShopifyConfigPage";
import HeaderBar from "../../components/reusable/HeaderBar/HeaderBar";
import { TenantContext } from "../../context/TenantContext";
import ShopifyLogo from "../../assets/images/logos_shopify.png";
import ShopifyCard from "../../components/settings/ShopifyCard";
import { deleteTenantStore, getAllTenantStore } from "../../service/service";
import ShopifyCIntegration from "../../components/settings/ShopifyIntegration";
import Swal from "sweetalert2";
import FullPageSpinner from "../../components/FullPageSpinner";

const CARD_DATA = {
  name: "Shopify Setup",
  logo: ShopifyLogo,
  list: [
    "Engage on the worlds biggest social media platform",
    "work together on conversation in one shared inbox",
    "meet your customers in their moment of need",
  ],
  buttonName: "Add Shopify",
};

const ShopifyConfig = () => {
  const [tabs, setTabs] = useState([]);
  const [originalTabs, setOriginalTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setLoading] = useState(true);


  const { profile } = useContext(TenantContext);


  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchStores = async () => {
    setLoading(true);

    if (!profile?.token?.tenantId) return;
    
    const tabsRes = await getAllTenantStore(profile.token.tenantId);

    if (tabsRes.success) {
      setTabs(tabsRes.data);
      setOriginalTabs(tabsRes.data);
      setActiveTab(0);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStores();
  }, []);

  const handleNewShopifyAccountClick = () => {
    const ifEmptyTabExists = tabs.filter((tabData) => !tabData._id);
    if (ifEmptyTabExists.length) {
      const emptyIndex = tabs.findIndex((obj) => Object.keys(obj).length === 0);
      setActiveTab(emptyIndex);
    } else {
      const newTabs = [...tabs, {}];
      setTabs(newTabs);
      setActiveTab(newTabs.length - 1);
    }
  };

  const handleDeleteIntegrarion = async () => {
    if (!profile?.token?.tenantId) return;

    console.log("Handle Delete Integration Called", tabs[activeTab]);

    // const result = await Swal.fire({
    //   title: "Are you sure ?",
    //   text: "Removing a Shopify store from HelpIQ will render the app inaccessible and eliminate all associated data, including messages, configurations (widgets), autoreply settings, and business information.",
    //   icon: "warning",
    //   showCancelButton: true,
    //   reverseButtons: true,
    //   confirmButtonColor: "#8db178",
    //   cancelButtonColor: "#D9D9D9",
    //   confirmButtonText: "Yes, delete it!",
    // });

    Swal.fire({
      title: "Please uninstall the app from shopify store",
      text: "Removing a Shopify store from HelpIQ will render the app inaccessible and eliminate all associated data, including messages, configurations (widgets), autoreply settings, and business information.",
      confirmButtonColor: "#8db178",
      icon: "warning",
    });


    // if (result.isConfirmed) {
    //   try {
    //     const res = await deleteTenantStore(profile.token.tenantId, tabs[activeTab]._id)
    //     console.log("Res - Deleted Integratoin", res);

    //     if (res.success) {
    //       fetchStores();
    //       Swal.fire({
    //         title: "Deleted!",
    //         text: `Successfully removed the store from HelpIQ. Messages will no longer be received in the app. Ensure to uninstall the app from the app store. `,
    //         confirmButtonColor: "#8db178",
    //         icon: "success",
    //       });
    //     }
    //   } catch (err) {
    //     console.log("Error while deleting shopify config")
    //     Swal.fire({
    //       title: "Error",
    //       text: "Encountered an issue during store deletion. Kindly reach out to the admin at support@helpiq.app for assistance",
    //       icon: "error",
    //       confirmButtonColor: "#8db178",
    //     });
    //   }
    // }

  }


  return (
    <ShopifyConfigPageStyles>
      <HeaderBar
        title={"Shopify Configuration"}
        hideSecondaryButton={true}
        showDescription={false}
        primaryButtonDisable={isLoading}
        hidePrimaryButton={
          !originalTabs.length ||
          // originalTabs.length >= profile?.token?.pricingPlan?.shopifyAccounts ||
          !profile?.token?.pricingPlan
            ? true
            : false
        }
        primaryButtonLabel={"Add New Account"}
        handlePrimaryButtonClick={handleNewShopifyAccountClick}
      />
      {isLoading ? <FullPageSpinner /> : <div>
        {/* {tabs.length ? } */}
        {profile?.token?.shop ? (
          <div className="shopify-tabs-container">
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              {tabs.map((tabData) => (
                <Tab
                  key={`${tabData.shop || "New Account"} `}
                  label={`${tabData.shop || "New Account"}  ${
                    tabData.isDefault ? "- Default" : ""
                  }`}
                />
              ))}
            </Tabs>

            <div>
              {tabs[activeTab]?._id ? (
                <ShopifyCIntegration data={tabs[activeTab]}  handleDeleteIntegrarion={handleDeleteIntegrarion} />
              ) : (
                <ShopifyCard {...CARD_DATA} />
              )}
            </div>
          </div>
        ) : (
          <ShopifyCard {...CARD_DATA} />
        )}
      </div>}
    </ShopifyConfigPageStyles>
  );
};

export default ShopifyConfig;
