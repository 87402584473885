import styled from "styled-components";
const MyProfileSettingStyles = styled.div`
  /* width: 81vw; */
  .container {
    height: 88vh;
  }
  .heading-container {
    margin-left: 46px;
    margin-right: 40px;
  }
  .heading {
    font-weight: 500;
    margin-bottom: -0.3rem;
  }
  .pic-heading {
    font-weight: 500;
    margin-top: 15px;
  }
  .form-container {
    margin-left: 46px;
    margin-bottom: 1.5rem;
  }
  .profile-heading {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  .profile-sub-heading {
    font-size: 16px;
    font-weight: 300;
    color: rgb(129, 132, 138);
    margin-bottom: 2rem;
  }
  .profile-container {
    display: flex;
    margin-bottom: 1.5rem;
    margin-top: 10px;
  }
  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;
  }
  .status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 540px;
  }

  .pic-size {
    color: #81848a;
  }
  .heading-container {
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
  }
  .password-heading {
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  .heading-container .subheading {
    font-size: 16px;
    font-weight: 300;
    color: rgb(129, 132, 138);
    margin-bottom: 2rem;
  }
  .profile-content {
    margin-left: 46px;
  }
  .textfield-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .error-label {
    color: rgb(244, 67, 54);
  }
  .custom-upload {
    display: inline-block;
    position: relative;
    cursor: pointer;
    text-decoration: underline;
  }

  .custom-upload input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .img-preview {
    border-radius: 6rem;
  }
  .progress-bar {
    margin-top: 0.5rem;
  }
  .status-online {
    color: var(--primary-800);
  }
  .status-offline{
    color: gray;
  }
`;
export default MyProfileSettingStyles;

