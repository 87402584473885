import styled from "styled-components";
const EmailMessageStyles = styled.div`
  position: relative;
  .profile {
    display: flex;
    gap: 0.5rem;
    cursor: pointer;
  }
  .name-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .sender-email-container {
    /* height: 50vh; */
    border-top: 0.5px solid gray;
    padding-top: 1rem;
    margin-bottom: 1rem;
  }
  .private-email {
    background: #fdfdc5;
    padding-bottom: 1rem;
  }
  .private-note {
    align-self: flex-end;
    color: #8364e8;
  }
  .email-content {
    margin-left: 3rem;
    margin-right: 1rem;
    margin-top: 1rem;
    max-width: 570px;
    white-space: pre-line;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
  }
  .date-container {
    margin-left: auto;
    margin-right: 0.5rem;
    color: #666666;
    font-size: 0.75rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  .icons {
    align-self: flex-end;
    display: flex;
    gap: 1rem;
    color: #9c9c9c;
  }
  .remaining-messages {
    position: absolute;
    top: -0.3rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    padding-inline: 0.25rem;
    color: #8364e8;
    font-size: 0.75rem;
    font-weight: 500;
    /* transform: translate(-50%, -50%); */
  }
  .isActive {
    color: #8364e8;
  }
  .link-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  }
  .product-msg {
    background-color: var(--primary-200);
    width: fit-content;
    border-radius: 1rem;
    padding: 0.5rem;
    padding-top: 1rem;
  }
  .subject {
    display: flex;
    gap: 3px;
    max-width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .text-message {
    margin-top: 1rem;
  }
  .quil-images {
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
   }
`;
export default EmailMessageStyles;
