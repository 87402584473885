import styled from "styled-components";
const AiLogsStyles = styled.div`
  .underline {
    border-bottom: 1px solid var(--underline-clr);
  }
  .heading {
    font-size: 16px;
    font-weight: 600;
    max-width: 350px;
  }
  .date {
    color: gray;
    font-size: 13px;
  }
  .sub-heading {
    color: gray;
    font-size: 12px;
  }
  .token-count {
    font-weight: 600;
    font-size: 18px;
  }
  .remaining-days {
    font-size: 12px;
    margin-top: 0.25rem;
    font-weight: 500;
  }

  .training-container {
    display: flex;
    flex-wrap: wrap;
    /* gap: 1rem; */
    /* justify-content: space-between; */
  }
  .training-history {
    flex-grow: 1;
    margin-left: 0;
  }
  
  .training-data {
  }
  .token-heading {
    margin-bottom: 0.5rem;
  }
  .pending-tokens .underline {
    margin-top: 0.5rem;
  }
  .remaining-token {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
  }
  .refill {
    text-align: right;
  }
  .paper-card {
    margin-left: 40px;
    margin-right: 40px;
    padding: 20px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background: white !important;
    padding-bottom: 25px;
    margin-top: 20px;
  }
  .store-select {
    display: flex;
    gap: 2rem;
  }
  .training-data {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
  }
  .btn-container {
align-self: flex-end;
  display: flex;
  gap : 2rem
  }
  .training-container .training-history  {
    margin-right: 0;
  }

  .pending-tokens {
    width: calc(34% - 80px);
  }


`;
export default AiLogsStyles;
