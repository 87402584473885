import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import styled from "styled-components";
import CustomTab from "../reusable/CustomTab";
import NotificationPreview from "./NotificationPreview";
const NotificationUiStyles = styled.div`
  width: 300px;
  .nav {
    padding: 0.75rem 1.5rem;
  }
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .underline {
    height: 1px;
    width: 100%;
    content: "";
    background-color: gray;
    margin-top: 0.25rem;
  }
  .nav button {
    min-width: 0;
  }
`;

const tabLabels = ["Tickets"];

const NotificationUi = ({ toggleDrawer }) => {
  const tabContents = [
    <NotificationPreview toggleDrawer={toggleDrawer} key={1} />,
  ];

  return (
    <NotificationUiStyles>
      <nav className="nav">
        <h4>Notification</h4>
        <Button onClick={toggleDrawer("left", false)}>
          <CloseIcon />
        </Button>
      </nav>
      <div className="underline"></div>
      <div className="tab-container">
        <CustomTab tabLabels={tabLabels} tabContents={tabContents} />
      </div>
    </NotificationUiStyles>
  );
};

export default NotificationUi;
