import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NotAuthorized = () => {
  return (
    <NotAuthorizedStyles>
      <div className="wrapper">
        <img className="logo" src="https://helpiq.s3.us-east-2.amazonaws.com/helpIqLogo.png" />
        <h1 className="heading">Unauthorized Access</h1>
        <p className="message">
          Oops! It seems you are trying to access an administrative page.
        </p>
        <p className="message">
          As an agent, you do not have permission to view this content.
        </p>
        <p className="message">
          Please contact your administrator for assistance.
        </p>
        <Link className="back-link" to="/">Go Back to Home</Link>
      </div>
    </NotAuthorizedStyles>
  );
};

const NotAuthorizedStyles = styled.div`
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #eff2ed;
  }
  .heading {
    font-size: 24px;
    margin-bottom: 20px;
    /* color: #dc3545; */
  }
  .message {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .back-link {
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
  margin-top: 20px;
  cursor: pointer;
  }
  .logo {
    margin-bottom: 20px;
    text-align:  center;
    margin-right:20px;
  }
`;
export default NotAuthorized;
