import styled from "styled-components";
const LoginStyles = styled.div`
  .container {
    display: flex;
  }

  .terms-and-condition {
    margin-top: 1rem;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  .logo {
    display: flex;
    align-items: center;
    top: 10px;
    left: 40px;
    position: absolute;
  }
  .login-img {
    margin-left: 3.3rem;
    margin-bottom: 40px;
  }
  .logo img {
    width: 170px;
    height: 70px;
  }

  .left {
    margin-left: 20px;
    width: 45%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .right {
    width: 50%;
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .right-container {
    width: 500px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .login-title {
    font-size: 30px;
    font-weight: 500;
  }

  .login-user-link {
    padding: 10px 0px;
    font-size: 18px;
  }

  .welcome-msg {
    font-size: 24px;
    font-weight: 400;
    margin: 15 0px;
    font-style: normal;
    font-weight: 300;
  }
  .login-user {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    margin-left: 5rem;
    margin-top: 3rem;
  }
  .forgot-password {
    margin-top: 25px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .error {
    color: var(--danger);
    text-align: left;
    margin-left: .25rem;
    margin-bottom: 0.25rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
  @media (max-width: 400px) {
    display: block;

    .login-img {
      display: none;
    }
    .logo img {
      height: 30px;
      width: 80px;
      padding-left: -80px;
      margin-left: -40px;
      padding-top: -70px;
    }
    .login-title {
      margin-left: -49px;
      margin-bottom: -19px;
      margin-top: 50px;
    }
    .welcome-msg {
      margin-left: -115px;
      font-size: 14px;
      font-weight: 300;
      margin-top: 10px;
    }
    .text {
      width: 300px;
      padding-right: 50px;
      margin-left: -112px;
    }
    .middle {
      margin-left: -112px;
      font-size: 12px;
      font-weight: 400;
    }
    .middle a {
      margin-left: 68px;
      font-size: 12px;
      font-weight: 400;
    }
    .login-user {
      margin-left: -112px;
      font-size: 20px;
      font-weight: 400;
      margin-top: 19px;
    }
  }
`;
export default LoginStyles;
