import React, { useEffect } from 'react'
import AITrainingStyles from '../../assets/styledComponents/AITraining'
import PrimaryButton from '../../components/reusable/PrimaryButton/PrimaryButton'
import useFetch from '../../hooks/useFetch'
import { useTenantContext } from '../../context/TenantContext'
import CustomSnackbar from '../../components/snackbar/CustomSnackbar'
import { apiTraining, getMLLogs } from '../../service/service'

const AITrainingCard = ({activeTab, shop }) => {

  const [ trainingFunction, trainingLoading, trainingResult, trainingBendMessage]  = useFetch(apiTraining)
  const {tenantId, } = useTenantContext()

  const limit = 10;
  const [ getMLLogFunction, mlLogLoading, mLLogResult, MLLogBackEndMessage]  = useFetch(getMLLogs)
  const handleTraining = async() => {
    const result = await trainingFunction({ Tenantid : tenantId, shop })
  }

  const fetchMLLogs = async() => {
      const result = await getMLLogFunction({tenantId,page : 1, limit , store: shop , status : '', type : '' , startValue : '', endValue : ''});
  }
  const date =  mLLogResult?.data?.logs.map((log) => {
    return log
  })[0]?.Date

  useEffect(() => {
    fetchMLLogs ()
  },[])
  return (
    <AITrainingStyles>
      {trainingBendMessage.msg && <CustomSnackbar payload={trainingBendMessage} />}
        <div className="training-container paper-card">
            <p>Last AI Bot Training for {shop} on {date}  </p>
            <p style={{ color: 'gray'}}>AI Bot is auto trained once every day</p>
            <PrimaryButton disabled={trainingLoading} onClick={handleTraining}>
                Train AI Bot
            </PrimaryButton>
        </div>
    </AITrainingStyles>
  )
}

export default AITrainingCard