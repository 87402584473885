import React, { useState } from "react";
import InboxMenu from "../../components/inboxMenu/InboxMenu";
import { InboxMessage } from "../../components/inboxMessage";
import ChatContainer from "../../components/message/ChatContainer";
import Store from "./Store";
import Copilot from "../../components/copilot/Copilot";
import { useInboxContext } from "../../context/inboxContext/inboxContext";

export const InboxContainer = () => {
  const {showStore , setShowStore} = useInboxContext()

  const [showCopilot , setShowCopilot] = useState(false)
  const [showSideBar, setShowSidebar] = useState(false)
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <InboxMessage />
      <ChatContainer
        setShowStore={setShowStore}
        showStore={showStore}
        showCopilot={showCopilot}
        setShowCopilot={setShowCopilot}
        showSideBar={showSideBar}
        setShowSidebar={setShowSidebar}
      />
      {showSideBar && (
        <>
          {showStore && <Store />}
          {showCopilot && <Copilot />}
        </>
      )}
    </div>
  );
};
