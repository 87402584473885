import styled from "styled-components";
const ChatContainerStyles = styled.div`
  /* width: calc(100% - 800px); */
  /* background-color: red; */
  /* width: 100%; */
  flex-grow: 1;
  border-right: 1px solid var(--border-clr);
  position: relative;
  height: 100vh;
  margin-right: ${(props) => props.margin}rem;

  .chat-conatiner-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* height: 100vh; */
    height: 100%;
  }
  .floating-icon {
    color: var(--primary-500);
    cursor: pointer;
    font-size: 24px;
    background: white;
    position: absolute;
    right: 0;
    transform: translate(50%);
  }
   .collapsable-icon {
    top: 18px;
  }
  .copilot-icon  {

  }


   .collapsable-icon svg {
    height: 14px !important;
    width: 14px !important;
  }


  .ticket-icon {
    color: var(--primary-500);
    position: absolute;
    // top: 50%;
    right: -0.75rem;
    background: white;
  }
  .ticket-note-container {
    position: absolute;
    bottom: 20%;
    right: 0;
    transform: translateX(100%);
  }
  .mobile-ticket-summary-right-side {
    flex-direction: column;
    gap: 4px;
    align-items: end;
  }
  /* @media (max-width : 1050px) {
    max-width: 350px;
  } */
  /* @media (max-width : 1250px) {
    width: 450px;
  }
@media (max-width : 1300px) {
    width: 500px;
  } */


`;
export default ChatContainerStyles;
