import React, { useEffect, useState } from "react";
import { FilterLabelStyles } from "../../../assets/styledComponents/inboxMessage";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Stack,
} from "@mui/material";
import { getLabel } from "../../../service/service";
import { useTenantContext } from "../../../context/TenantContext";
import { getKey } from "../../../utils/helperFunction";
import PrimaryButton from "../../reusable/PrimaryButton/PrimaryButton";
import { useInboxContext } from "../../../context/inboxContext/inboxContext";

const FilterLabel = ({
  handleClose,
  filterLabel,
  setFilterLabel,
  actionGroup,
}) => {
  const ORIGINAL_OPTIONS = [
    {
      _id: "all",
      labelName: "All",
      isChecked:  false,
    },
    {
      _id: "assigned",
      labelName: "Assigned",
      isChecked:  false,
    },
    {
      _id: "unassigned",
      labelName: "Unassigned",
      isChecked: false
    },
  ]
  const { assignedFilter } = useInboxContext();
  const [masteAssignedOptions, setMasteAssignedOptions] = useState([...ORIGINAL_OPTIONS]);

  const fetchOptions = async() => {
    const newOptions = [...ORIGINAL_OPTIONS].map((optionData) => {
      const isChecked = optionData._id === assignedFilter;
      return {
        ...optionData,
        isChecked,
      }
    })
    setMasteAssignedOptions(newOptions)
  }

  useEffect(() => {
    fetchOptions()
  }, [assignedFilter]);

  const handleChange = async (e, _id, labelName, idx) => {
    const newFilterLabel = [...filterLabel];
    const isFilterLabelExists = newFilterLabel.filter(
      (filterDataObj) => filterDataObj._id === _id
    );

    if (isFilterLabelExists.length) {
      setFilterLabel(
        [...filterLabel].filter((filterDataObj) => filterDataObj._id !== _id)
      );
    } else {
      setFilterLabel([
        {
          labelName,
          _id,
        },
      ]);
    }


    const newOptions = [...ORIGINAL_OPTIONS];
    newOptions[idx].isChecked = !masteAssignedOptions[idx].isChecked
    setMasteAssignedOptions(newOptions);
  };

  return (
    <FilterLabelStyles>
      <FormGroup>
        <div className="top-box">
          <h3>Filter By Assigned</h3>
        </div>

        <div className="label-container priority-container">
          {masteAssignedOptions.map(({ _id, labelName, isChecked }, idx) => {
            return (
              <React.Fragment
                key={getKey("filterLabelOptions", labelName, idx)}
              >
                <FormControlLabel
                  style={{ paddingLeft: "0.2rem" }}
                  control={<Checkbox />}
                  label={labelName}
                  onChange={(e) => handleChange(e, _id, labelName, idx)}
                  value={labelName}
                  checked={isChecked}
                />
              </React.Fragment>
            );
          })}
        </div>
      </FormGroup>
      <div className="btn-container">{actionGroup}</div>
    </FilterLabelStyles>
  );
};

export default FilterLabel;
