import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button,  Grid, IconButton, Tooltip, } from "@mui/material";
import React, {  useState } from "react";
import Ellipse38 from "../../../assets/images/Ellipse 38.png";
import CustomTextField from "../../reusable/CustomTextField";
import { getKey } from "../../../utils/helperFunction";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

import ActionButtonGroup from "../../reusable/ActionButtonGroup/ActionButtonGroup";
import useClipboard from "../../../hooks/useClipboard";
import WhatsappConfigModal from "./WhatsappConfigModal";
import PrimaryButton from "../../reusable/PrimaryButton/PrimaryButton";
import Modal from "../../reusable/DialogModal/Modal";
const WhatsappSetupForm = ({
  values,
  tabValue,
  handleChange,
  whatsappConfig,
  handleSave,
  handleCancel,
  disableSaveBtn,
  handleDeleteWhatsappAccount
}) => {

  const [clipboardValue, setClipboardValue] = useState();
  const [isCopied, copyToClipboard] = useClipboard(clipboardValue);
  const [open, setOpen] =  useState(false);
  const [openModal , setOpenModal] = useState(false)

  const handleTooltipOpen= (value) => {
    setOpen(true);
    copyToClipboard(value);
  };
  const handleModalOpen = () => {
    setOpenModal(true)
  }
  const isValueExist  = whatsappConfig?.webHookUrl 
  return (
    <>
      <Modal
        name="Webhook Setting"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <WhatsappConfigModal
          values={values}
          handleChange={handleChange}
          whatsappConfig={whatsappConfig}
          handleTooltipOpen={handleTooltipOpen}
          open={open}
          setOpenModal={setOpenModal}
        />
      </Modal>
      <TabContext value={tabValue} sx={{ padding: "20px 0px" }}>
        <TabPanel value="1" sx={{ padding: "20px 0px" }}>
          <Grid container>
            {values.map((item, index) => {
              const { name, label, value, disabled } = item;
              if (name === "webHookUrl" || name === "verifyToken") {
                return null;
              }
              return (
                <Grid
                  key={getKey("value", label, index)}
                  item
                  xs={12}
                  sx={12}
                  md={12}
                  lg={12}
                  padding={1}
                >
                  <div>
                    <div className="business-name">
                      <span>{label}</span>
                    </div>
                    <CustomTextField
                      sx={{
                        width: "95%",
                        marginTop: 1,
                      }}
                      disabled={Boolean(disabled)}
                      name={name}
                      value={whatsappConfig[name]}
                      placeholder={label}
                      onChange={handleChange}
                      error={
                        Boolean(whatsappConfig["error"]) &&
                        whatsappConfig["errorField"] === name
                      }
                      helperText={
                        whatsappConfig["errorField"] === name &&
                        whatsappConfig["error"]
                      }
                      margin={"normal"}
                      row={name === "accessToken" ? 3 : 1}
                    />
                    {console.log(
                      "> Whatsapp  IN Setup config--",
                      whatsappConfig
                    )}
                  </div>
                </Grid>
              );
            })}

            <Grid item lg={12} md={12} sx={12} xs={12} padding={1}>
              <div
                style={{
                  marginRight: "5%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent : "space-between",
                  gap: "1rem",
                }}
              >
                {isValueExist && (
                  <PrimaryButton
                    sx={{  marginBlock: "1rem" }}
                    onClick={handleModalOpen}
                  >
                    Webhook Settings
                  </PrimaryButton>
                )}
                <ActionButtonGroup
                  primaryButtonLabel={
                    whatsappConfig.webHookUrl && whatsappConfig._id
                      ? "Update"
                      : "Save"
                  }
                  secondaryButtonLabel={"Cancel"}
                  handlePrimaryButtonClick={handleSave}
                  primaryButtonDisable={disableSaveBtn}
                  secondaryButtonDisable={disableSaveBtn}
                  handleSecondaryButtonClick={handleCancel}
                  hideThirdButton={!whatsappConfig._id}
                  thirdButtonLabel={"Delete"}
                  thirdButtonDisable={false}
                  handleThirdButtonClick={handleDeleteWhatsappAccount}
                />
              </div>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value="2">
          <div className="business-name">
            <span>WhatsApp Display Picture</span>{" "}
          </div>
          <div className="image">
            <img src={Ellipse38} />
            <div className="name-container">
              <span className="title">Upload Profile Picture</span>{" "}
              <span className="url">640*640px recommended</span>
            </div>
          </div>
          <div className="business-address">
            <span>Display Name</span>{" "}
          </div>
          <CustomTextField
            sx={{
              width: 455,
              marginBottom: 3,
              marginLeft: 2,
              marginTop: 1,
            }}
            placeholder={"WhatsApp Display Name"}
            margin={"normal"}
          />
          <div className="country">
            <span>Contact Number</span>{" "}
          </div>
          <CustomTextField
            sx={{
              width: 455,
              marginBottom: 3,
              marginLeft: 2,
              marginTop: 1,
            }}
            placeholder={"Contact Number"}
            margin={"normal"}
          />
          <div className="delete-button">
            <Button variant="outlined">Delete Integration</Button>{" "}
          </div>
        </TabPanel>
      </TabContext>
    </>
  );
};

export default WhatsappSetupForm;
