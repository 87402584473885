import React, {
  useCallback,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import styled from "styled-components";
import {
  convertHtmlToQuill,
  convertQuillToText,
  getKey,
  htmlToPlainText,
} from "../../utils/helperFunction";
import DataObjectIcon from "@mui/icons-material/DataObject";
import CustomPopover from "../reusable/CustomPopover";
import ImageResize from "quill-image-resize-module-react";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import { uploadToS3 } from "../../utils/uploadImageToS3";

Quill.register("modules/imageResize", ImageResize);

const TextEditorStyles = styled.div`
  position: relative;
  width: ${(props) => props.width};
  .ql-container {
    height: 300px;
    overflow-y: auto;
  }
  .container .ql-editor {
    /* height: 200px; */
  }
  .container {
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    display: flex;
    flex-direction: column-reverse;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25);
  }
  .disable-icon {
    pointer-events: none;
  }

  .disable {
    /* pointer-events: none; */
    color: #e5e5e5;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
  }

  .template-icon {
    position: absolute;
    bottom: 0rem;
    right: 0;
  }
  .variable-icon {
    cursor: pointer;
    font-size: 17px;
    position: absolute;
    bottom: 1.7rem;
    left: ${(props) => props.variablePosition};
  }
  .custom-variable {
    width: 200px;
    border-radius: 1rem;
    padding: 1rem;
  }
  .variable-list {
    width: 200px;
    padding: 0.2rem;
    cursor: pointer;
  }
  .heading {
    margin-bottom: 0.3rem;
  }
  .underline {
    height: 2px;
    width: 100%;
    background-color: #999;
    margin-bottom: 0.5rem;
  }
  .nav {
    display: flex;
    justify-content: space-between;
  }
`;

const EmailSignatureEditor = forwardRef(
  (
    {
      width,
      height,
      name,
      toolbarOptions,
      onChange,
      value,
      resetValue,
      variablePosition,
      quickReplyList,
      disable,
      loading,
      setLoading,
    },
    ref
  ) => {
    const quillRef = useRef(null);
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const wrapperRef = useCallback(
      (wrapper) => {
        if (wrapper === null) return;
        wrapper.innerHTML = "";
        const editor = document.createElement("div");
        wrapper.append(editor);
        const quillInstance = new Quill(editor, {
          theme: "snow",
          modules: {
            imageResize: {
              modules: ["Resize", "DisplaySize", "Toolbar"],
            },
            toolbar: {
              container: toolbarOptions,
              handlers: {
                image: function () {
                  const input = document.createElement("input");
                  input.setAttribute("type", "file");
                  input.setAttribute("accept", "image/*");
                  input.click();

                  input.onchange = async () => {
                    const file = input.files[0];
                    if (!file.type.startsWith("image/")) {
                      setErrorMsg("Only image files are allowed");
                      setShowError(true);
                      return;
                    }
                    if (file.type === "image/svg+xml") {
                      setErrorMsg("SVG images are not allowed");
                      setShowError(true);
                      return;
                    }
                    if (file.size > 2 * 1024 * 1024) {
                      setErrorMsg("Image size should be less than 2MB");
                      setShowError(true);
                      return;
                    }

                    try {
                      setLoading(true) 
                      const imageUrl = await uploadToS3(file);
                      const range = quillInstance.getSelection();
                      quillInstance.insertEmbed(range.index, "image", imageUrl);
                      setLoading(false)
                    } catch (error) {
                      setErrorMsg("Failed to upload image");
                      setShowError(true);
                      setLoading(false)
                    }
                  };
                },
              },
            },
            "emoji-toolbar": true,
            "emoji-shortname": true,
          },
        });

        if (disable) {
          quillInstance.enable(false);
        } else {
          quillInstance.enable(true);
        }

        quillRef.current = quillInstance;
        setTimeout(() => quillInstance.clipboard.dangerouslyPasteHTML(value), 0);
        quillInstance.on("text-change", () => {
          getEditorContent();
        });
      },
      [value, disable]
    );

    const getEditorContent = () => {
      if (quillRef.current) {
        const content = quillRef.current.getContents();
        const html = quillRef.current.root.innerHTML;
        onChange({ html });
        if (html === "<p><br></p>") {
          onChange({ html: "" });
        }
      }
    };

    useEffect(() => {
      if (quillRef.current) {
        quillRef.current.setText("");
      }
    }, [resetValue]);

    const variableList = [
      "First name",
      "Last name",
      "Ticket Id",
      "Order Id",
      "Order Status",
      "Order Coupon",
      "Shipping Address",
    ];

    const handleVariableClick = (variable) => {
      const quill = quillRef.current;
      const position = quill.getSelection(true).index;
      quill.insertText(position, `{{${variable}}}`);
      quill.setSelection(position + variable.length + 4);
    };

    return (
      <TextEditorStyles
        variablePosition={variablePosition}
        width={width}
        height={height}
      >
        <div className={`${disable ? "disable" : ""} container `} ref={wrapperRef}></div>
        <CustomPopover
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          button={
            <div
              // onClick={() => setShowVariable((prev) => !prev)}
              className={`${disable ? "disable-icon" : ""}  variable-icon`}
            >
              {quickReplyList && <DataObjectIcon fontSize="inherit" />}
            </div>
          }
        >
          <TextEditorStyles>
            <div className="custom-variable">
              <div className="nav">
                <h3 className="heading">Variable</                h3>
              </div>
              <div className="underline"></div>
              {quickReplyList &&
                quickReplyList.map((item, index) => (
                  <li
                    className="variable-list"
                    onClick={() => handleVariableClick(item)}
                    key={getKey("quickReplyList", item, index)}
                  >
                    {item}
                  </li>
                ))}
            </div>
          </TextEditorStyles>
        </CustomPopover>
        <Snackbar
          open={showError}
          autoHideDuration={6000}
          onClose={() => setShowError(false)}
        >
          <Alert onClose={() => setShowError(false)} severity="error">
            {errorMsg}
          </Alert>
        </Snackbar>
      </TextEditorStyles>
    );
  }
);

EmailSignatureEditor.displayName = "TextEditor";
export default EmailSignatureEditor;
