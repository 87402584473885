import styled from "styled-components";
const TicketNoteStyles = styled.div`
  .message-container {
    width: 280px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 360px;
    overflow-x: clip;
  }
  .chat-container {
    height: 400px;
    background-color: #f9f8b7;
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
  .user-details {
    padding-top: 0.75rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .msg-input {
    width: inherit;
    /* height: 70px; */
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    border-top: 1px solid black;
    /* border-radius: 1rem; */
    box-shadow: rgba(0, 0, 0, 0.33) 0px 1px 15px 0px;
    /* margin-top: 1rem; */
}
  .send-btn {
    color: ${({ textColor }) => textColor};
    background-color: ${({ themeColor }) => themeColor};
    border-radius: 1rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
  .icons-container {
    display: flex;
    gap: 1rem;
    margin-left: 1.5rem;
  }
  .message-wrapper {
    margin-right: 0.5rem;
  }
`;
export default TicketNoteStyles;
