import styled from "styled-components";
const InstagramCardStyles = styled.div`
  width: 81vw;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    padding: 20px;
    padding-left: 40px;
  }
  .top h2 {
    font-weight: 500;
    font-size: 20px;
  }
  .underline {
    border-top: 1px solid;
    border-color: #999999;
    height: 10px;
    margin: 0px 20px 0px 40px;
  }
`;
export default InstagramCardStyles;
