import styled from "styled-components";

const MessageBoxStyles = styled.div`
  .msg-container {
    margin-left: 1rem;
    margin-right: 1rem;
    width: calc(100% - 45px);
    overflow-x: hidden;
    padding-bottom: 0.25rem;
  }
  .receiver-container {
    display: flex;
    margin-top: 0.75rem;
    width: fit-content;
    max-width: 70%;
    word-break: break-all;
  }
  .image-space {
    margin-bottom: 1rem;
  }
  .sender-container {
    display: flex;
    max-width: 70%;
    margin-left: auto;
    margin-top: 0.75rem;
  }
  .avatar-container {
    margin-top: 0.5rem;
  }
  .chat-image {
    /* display: flex; */
    position: relative;
  }

  .chat-image img {
    border-radius: 10px;
  }

  .receiver-msg {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding: 1rem;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid #999999;
    word-wrap: break-word;
    white-space: pre-line;
  }
  .private-receiver-msg {
    border: 0;
  }
  .sender-msg {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    box-shadow: 0px 2px 4px 0px #00000026;
    padding: 1rem;
    background-color: var(--primary-200);
    color: black;
    word-wrap: break-word;
    white-space: pre-line;
  }
  .msg-container {
    display: flex;
    flex-direction: column;
  }
  .time {
    margin-top: 0.5rem;
    color: #858585;
    font-size: 0.9rem;
  }
  .sender-time {
    text-align: right;
    margin-right: 0.5rem;
  }
  .receiver-time {
    margin-left: 0.5rem;
  }
  .seen-icon {
    color: "#8db178";
    align-self: center;
    margin-bottom: 1rem;
    margin-left: -0.5rem;
  }

  /*  message image */
  .cart-message {
    display: flex;
  }
  .item-details {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #dee9d8;
    padding: 0.3rem;
    color: black;
    font-size: 10px;
    height: 100px;
    width: 200px;
    background-color: #858585;
    padding: 10px;
  }
  .product-image {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .product .name {
    font-weight: 500;
    font-size: 14px;
  }

  .product .price {
    font-size: 12px;
    margin-top: 5px;
  }

  .product .btn {
    font-size: 12px;
    width: fit-content;
    display: block;
    margin-top: 5px;
    text-decoration: none;
    font-size: 12px;
    background: black;
    color: white;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  .desc {
    display: flex;
    gap: 3rem;
  }
  .product-link {
    display: inline-block;
    font-size: 14px;
    width: 260px;
    margin-top: 0.5rem;
  }

  .empty-msg {
    padding: 0 !important;
  }

  .image-uploading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .hide-upload-icon {
    display: none;
  }
  /* attachment icon */

  .file-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .file-wrapper .file-size {
    font-size: 12px;
    color: gray;
    margin-top: 2px;
  }

  .file-wrapper .file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 165px;
  }

  .file-container,
  .file-extension {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .file-extension {
    margin-top: 0.3rem;
    font-size: 0.8rem;
  }
  .seen-icon-private {
    align-self: center;
    margin-bottom: 1rem;
    margin-left: -0.5rem;
    color: #ffc453;
  }
  .private-msg {
    background: #ffc453;
    color: black;
  }
  .message {
    margin-top: 1rem;
  }
  .download-icon {
    cursor: pointer;
    border: 1.5px solid var(--primary-900);
    padding: 8px 12px;
    border-radius: 50%;
  }

  .download-icon svg {
    fill: var(--primary-900);
  }
  .attachment-msg {
    width: 200px;
    padding-top: 1rem;
  }
  .attachment-container {
    padding: 0.5rem;
    border-radius: 1rem;
  }
  .tagged-user-name {
    margin-top: 0.2rem;
    font-size: 14px;
  }
  .private-time-stamp {
    margin-top: 0.2rem;
    color: #858585;
    font-size: 0.9rem;
  }
  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  .sender-timestamp {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
  @media (max-width: 1300px) {
    .sender-msg,
    .receiver-msg {
      padding: 0.75rem;
    }
    .sender-container,
    .receiver-container {
      max-width: 300px;
    }
    .msg-container {
      /* max-width: 280px; */
      /* max-width: 300px; */
      /* margin-left: auto; */
    }
    .receiver-container {
      /* margin-right: auto; */
    }
    
  }
`;
export default MessageBoxStyles;
