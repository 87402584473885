import styled from "styled-components";
const PricingPlanStyles = styled.div`
      /* width: ${props => (props.fullWidth ? "100%" : "81vw")};  */
      width: 100%;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 2rem;
        padding: 20px;
        padding-left: 40px;
      }
      .top h2 {
        font-weight: 500;
        font-size: 20px;
      }
      .underline {
        border-top: 1px solid;
        border-color: #999999;
        height: 10px;
        margin: 0px 20px 0px 40px;
      }
      .second {
        margin-left: 40px;
        margin-bottom: 20px;
      }
      .second .paragraph {
        font-weight: 500;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .second .desc {
        font-weight: 300;
        color: #81848A;
        font-size: 16px;
      }

      .plans {
        margin: 0px 20px 0px 60px;
      }

      .plan-icon {
        display: inline-block;
        float: left;
        margin-right: 10px;
        margin-top: 10px;
      }

      .plan-title {
        font-weight: 400;
        font-size: 24px;
      }

      .plans-desc {
        color: #666666;
        font-weight: 300;
        font-size: 14px;
        margin-bottom: 10px;
      }

      .plan-price {
        font-size: 20px
      }

      .plan-time {
        font-weight: 300;
      }

      .pricing-list h2 {
        padding-left: 40px;
        font-size: 15px;
        padding-top: 15px;
        margin-top: 5px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 430;
      }
      .section3 {
        display: flex;
        position: absolute;
        top: "20px";
      }

      .pricing-card {
        display: flex;
      }
      .pricing-card h2 {
        display: flex;
        margin-top: 1px;
        margin-left: -20px;
        margin-bottom: 4px;
      }
    
      .pricing-card img{
        display:flex;
        height: 20px;
        margin-top:20px
      }
      .head {
        display: flex;
      }
      .head img {
        margin-left: -20px;
        height: 16px;
        margin-top: 7px;
      }
      .head h2 {
        margin-left: 10px;
        padding-top: 1px;
        font-size: 19px;
      }

      .head2 {
        display: flex;
      }
      .head2 img {
        margin-left: -20px;
        height: 14px;
        margin-top: 12px;
      }
      .head2 h2 {
        margin-left: 10px;
        padding-top: 1px;
        font-size: 19px;
      }

      .head3 {
        display: flex;
      }
      .head3 img {
        margin-left: -20px;
        height: 16px;
        margin-top: 6px;
      }
      .head3 h2 {
        margin-left: 10px;
        padding-top: 1px;
        font-size: 19px;
      }
      .mid {
        display: flex;
      }
      .mid p {
        display: flex;
        font-size: 19px;
        margin-left: 6px;
        margin-top: 22px;
      }
      .mid span {
        font-size: 40px;
      }

      .pricing-wrapper {
        height: 88vh;
        overflow-y: scroll;
      }
      .pricing-card-container {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        max-width: 1100px;
        margin: 0px auto;
        padding: 0 30px;
      }
  
      @media (max-width: 1300px) {
        .pricing-card-container {
          flex-direction: column;
          gap: 2rem;
          align-items: center;
        }
      }
      @media (max-width: 768px) {
      margin-bottom: 15px; /* Adjust spacing for tablets */

    }
      @media (max-width: 480px) {
      margin-bottom: 10px; /* Adjust spacing for mobile devices */
    }
`
export default PricingPlanStyles;
