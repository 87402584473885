import React, { useEffect, useState } from "react";
import UserHistoryStyles from "../../assets/styledComponents/message/UserHistory";
import { Card } from "@mui/material";
import MessagePreview from "../inboxMessage/MessagePreview";
import CustomSearchBox from "../reusable/CustomSearchBox";
import { useTenantContext } from "../../context/TenantContext";
import { getSessionByCustomerId } from "../../service/service";
import UserHistoryPreview from "./UserHistoryPreview";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import useDebounce from "../../hooks/useDebounce";

const UserHistory = (props) => {
  const { customerId, handleClose } = props;
  console.log({ props });
  const { tenantId } = useTenantContext();
  const [sessionsData, setSessionData] = useState([]);
  const [originalSessionData, setOriginalSessionData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedValue] = useDebounce(searchQuery, 500);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const fetchSessions = async () => {
    try {
      const result = await getSessionByCustomerId(tenantId, customerId);
      setSessionData(result);
      setOriginalSessionData(result);
    } catch (error) {
      console.log(error);
    }
  };
  const filteredSessionData = () => {
    const newSessionData = originalSessionData.filter(
      (item) => item && item.ticketId && item.ticketId.includes(searchQuery)
    );
    setSessionData(newSessionData);
  };

  useEffect(() => {
    filteredSessionData();
  }, [debouncedValue]);

  useEffect(() => {
    fetchSessions();
},[]);
  return (
    <UserHistoryStyles>
      <div className="user-history-container">
        <div className="open-container">
          <p>Open Tickets</p>
          <div className="search-bar">
            <CustomSearchBox
              placeholder={"Search"}
              handleSearch={handleSearchChange}
            />
          </div>
        </div>
        <div className="session-card-container">
          {sessionsData.map((item) => {
            return (
              <div
                key={item._id}
                className="message-card"
                onClick={handleClose}
              >
                <Card sx={{ borderRadius: ".5rem", marginBottom: "1rem" }}>
                  <UserHistoryPreview {...item} />
                </Card>
              </div>
            );
          })}
        </div>
      </div>
      <div className="closed-container"></div>
    </UserHistoryStyles>
  );
};

export default UserHistory;
