import React from "react";
import MyProfileMenu from "../../components/myProfile/MyProfileMenu";
import { Outlet } from "react-router-dom";

const MyProfile = () => {
  return (
    <div style={{ display: "flex" }}>
      <MyProfileMenu />
      <div style={{ flexGrow: "1" }}>
        <Outlet />
      </div>
    </div>
  );
};

export default MyProfile;
