import React, { useContext } from "react";
import facebook1 from "../../../assets/images/facebook 1.png";
import FacebookCardStyles from "../../../assets/styledComponents/settings/FacebookCard";
import MetaIntegrationForm from "../../reusable/ChannelConfiguration/MetaIntegrationForm";
import { TenantContext } from "../../../context/TenantContext";
import HeaderBar from "../../reusable/HeaderBar/HeaderBar";
const FacebookConfiguration = () => {
  const { profile } = useContext(TenantContext);
  const data = {
    head: "Facebook Configuration",
    title: "Facebook Page",
    switch1: "Facebook Messenger",
    switch2: "Post & Comments",
  };
  return (
    <>
      <FacebookCardStyles>
      <HeaderBar
        title={"Channel Configuration"}
        hideSecondaryButton={true}
        primaryButtonLabel={"save"}
      />
      <MetaIntegrationForm {...data} /> 
      </FacebookCardStyles>
    </>
  );
};
export default FacebookConfiguration;
