import React, {
  useCallback,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
// import "quill-emoji/dist/quill-emoji.css"
import styled from "styled-components";
import {
  convertHtmlToQuill,
  convertQuillToText,
  getKey,
  htmlToPlainText,
} from "../../utils/helperFunction";
import DataObjectIcon from "@mui/icons-material/DataObject";
import CustomPopover from "./CustomPopover";
import CloseIcon from "@mui/icons-material/Close";

const TextEditorStyles = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  .container .ql-editor {
    height: 200px;
  }
  .container {
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    display: flex;
    flex-direction: column-reverse;
    /* box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25); */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  .disable-icon {
    pointer-events: none;
  }

  .disable {
    /* pointer-events: none; */
    color: #e5e5e5;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
  }

  .template-icon {
    position: absolute;
    bottom: 0rem;
    right: 0;
  }
  .variable-icon {
    cursor: pointer;
    font-size: 17px;
    position: absolute;
    bottom: 1.7rem;
    left: ${(props) => props.variablePosition};
  }
  .custom-variable {
    width: 200px;
    border-radius: 1rem;
    padding: 1rem;
  }
  .variable-list {
    width: 200px;
    padding: 0.2rem;
    cursor: pointer;
  }
  .heading {
    margin-bottom: 0.3rem;
  }
  .underline {
    height: 2px;
    width: 100%;
    background-color: #999;
    margin-bottom: 0.5rem;
  }
  .nav {
    display: flex;
    justify-content: space-between;
  }
`;

const TextEditor = forwardRef(
  (
    {
      width,
      height,
      name,
      toolbarOptions,
      onChange,
      value,
      resetValue,
      variablePosition,
      quickReplyList,
      disable,
    },
    ref
  ) => {
    const quillRef = useRef(null);
    const wrapperRef = useCallback(
      (wrapper) => {
        console.log({ wrapper });
        if (wrapper === null) return;
        wrapper.innerHTML = "";
        const editor = document.createElement("div");
        wrapper.append(editor);
        const quillInstance = new Quill(editor, {
          theme: "snow",
          modules: {
            toolbar: { container: toolbarOptions },
            "emoji-toolbar": true,
            // "emoji-textarea": true,
            "emoji-shortname": true,
          },
        });

        if (disable) {
          quillInstance.enable(false);
        } else {
          quillInstance.enable(true);
        }

        const position = quillInstance.getSelection(true).index;
        console.log({ position });
        quillRef.current = quillInstance;
        quillInstance.clipboard.dangerouslyPasteHTML(value);
        // const convertedQuillFormat = convertHtmlToQuill(value)
        // console.log(convertedQuillFormat)
        quillInstance.on("text-change", () => {
          getEditorContent();
        });
      },
      [value, disable]
    );
    const getEditorContent = () => {
      if (quillRef.current) {
        const content = quillRef.current.getContents();
        const text = quillRef.current.getText();
        const html = quillRef.current.root.innerHTML;
        // const convertedText = convertQuillToText(content);
        console.log({valueText: text})

        const htmlToText = htmlToPlainText(html);
        onChange({ html, text });
        if (html === '<p><br></p>') {
          onChange({html: ""})
        }
      }
    };
    useEffect(() => {
      if (quillRef.current) {
        quillRef.current.setText("");
      }
    }, [resetValue]);

    const variableList = [
      "First name",
      "Last name",
      "Ticket Id",
      "Order Id",
      "Order Status",
      "Order Coupon",
      "Shipping Address",
    ];

    const [showVariable, setShowVariable] = useState(true);
    const handleVariableClick = (variable) => {
      const quill = quillRef.current;
      const position = quill.getSelection(true).index;
      quill.insertText(position, `{{${variable}}}`);
      quill.setSelection(position + variable.length + 4);
    };

    return (
      <TextEditorStyles
        variablePosition={variablePosition}
        width={width}
        height={height}
      >
        <div
          className={`${disable ? "disable" : ""} container`}
          ref={wrapperRef}
        ></div>
        <CustomPopover
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          button={
            <div
              onClick={() => setShowVariable((prev) => !prev)}
              className={`${disable ? "disable-icon" : ""}  variable-icon`}
            >
              {quickReplyList && <DataObjectIcon fontSize="inherit" />}
            </div>
          }
        >
          <TextEditorStyles>
            <div className="custom-variable">
              <div className="nav">
                <h3 className="heading">Variable</h3>
              </div>
              <div className="underline"></div>

              {quickReplyList &&
                quickReplyList.map((item, index) => {
                  return (
                    <li
                      className="variable-list"
                      onClick={() => handleVariableClick(item)}
                      key={getKey("quickReplyList", item, index)}
                    >
                      {item}
                    </li>
                  );
                })}
            </div>
          </TextEditorStyles>
        </CustomPopover>
      </TextEditorStyles>
    );
  }
);
TextEditor.displayName = "TextEditor";
export default TextEditor;
