import React from "react";
import { ChannelProvider } from "../../../context/channelConfigurationContext/channelContext";
import Chat from "./Chat";

const ChannelChat = () => {
  return (
    <ChannelProvider>
      <Chat />
    </ChannelProvider>
  );
};

export default ChannelChat;
