import React, { useState } from "react";
import Button from "@mui/material/Button";
import ReportStyles from "../../../assets/styledComponents/Report";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import LogoSpinner from "../../reusable/Loader/LogoSpinner";
import CustomInputDropDown from "../../CustomInputDropDown";
import { useTenantContext } from "../../../context/TenantContext";

const ChannelsList = [
  { name: "All" },
  { name: "Chat" },
  { name: "Instagram" },
  { name: "Whatsapp" },
  { name: "Facebook" },
  { name: "Email" },
];

const CustomDate = ({
  setShowCard,
  setReportCardData,
  setShowDropdown,
  setGraphData,
  setLoading,
  setStartDate,
  setEndDate,
  generateChartData,
  handleClick,
  isLoading,
}) => {
  const today = moment();
  const sixMonthsAgo = moment().subtract(6, "months");
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [error, setError] = useState("");

  const { profile , tenantStores, 
  selectedShop, setSelectedShop
  } = useTenantContext();

  const handleDropDownChange = (e) => {
    const { name, value } = e.target || {};
    setSelectedShop(value);
  };

  const handleSelectedFromDate = (date) => {
    setSelectedFromDate(date);
    // Clear "To" date if it's before the selected "From" date
    if (selectedToDate && date.isAfter(selectedToDate)) {
      setSelectedToDate(null);
    }
    setError("")
  };

  const handleSelectedToDate = (date) => {
    setSelectedToDate(date);
    setError("")
  };

  const shouldDisableToDate = (date) => {
    return (
      selectedFromDate && (date.isBefore(selectedFromDate) || date.isAfter(today))
    );
  };

  const handleButtonClick = async () => {

    if (!selectedFromDate && !selectedToDate) {
      setError("Please Select Valid Dates");
      return;
    }

    if (!selectedFromDate) {
      setError("Please Select From Date");
      return;
    }
    if (!selectedToDate) {
      setError("Please Select To Date");
      return;
    }

    const formattedStartDate = selectedFromDate
      ? selectedFromDate.format("DD/MM/YYYY")
      : null;

    const formattedEndDate = selectedToDate
      ? selectedToDate.format("DD/MM/YYYY")
      : null;

    handleClick(formattedStartDate, formattedEndDate, selectedShop);
  };

  return (
    <ReportStyles className="paper-card">
      <div className="date-container">
        <div className="select-dat">
          <h4>Select Date</h4>
        </div>

        <div className="date-bo">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                sx={{width: '100px'}}
                label="From"
                shouldDisableDate={(date) =>
                  date.isAfter(today) ||
                  date.isBefore(moment().subtract(6, "months"))
                }
                value={selectedFromDate}
                onChange={handleSelectedFromDate}
                renderInput={(startProps) => (
                  <input
                    {...startProps.inputProps}
                    placeholder="DD/MM/YYYY"
                    readOnly
                  />
                )}
                format="DD/MM/YYYY"
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>

        <div className="date-bo">

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DemoContainer  components={["DatePicker"]}>
              <DatePicker
                sx={{width: '100px'}}
                label="To"
                shouldDisableDate={shouldDisableToDate}
                value={selectedToDate}
                onChange={handleSelectedToDate}
                renderInput={(endProps) => (
                  <input
                    {...endProps.inputProps}
                    placeholder="DD/MM/YYYY"
                    readOnly
                  />
                )}
                error={error}
                helperText={error}
                format="DD/MM/YYYY"
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>


          <CustomInputDropDown
              label={"Select Store"}
              name={"shop"}
              value={ selectedShop ||  profile?.token?.shop}
              selectList={tenantStores}
              required={true}
              onChange={handleDropDownChange}
            height={"50px"}
            width ={'150px'}
            id="channel-store"
          />


        <div className="display-button">
          {isLoading ? (
            <Button
              variant="contained"
              style={{ color: "white", padding: ".7rem 3rem" }}
            >
              <LogoSpinner />
            </Button>
          ) : (
            <Button
              onClick={handleButtonClick}
              variant="contained"
              fullWidth
              style={{ color: "white", padding: ".7rem 3rem" }}
            >
              Display
            </Button>
          )}
        </div>

      </div>
      {error && <div className="date-error">{error}</div>}

    </ReportStyles>
  );
};

export default CustomDate;
