import { React, useState, useContext, useEffect } from "react";
import AutoReplyStyles from "../../assets/styledComponents/settings/AutoReply";
import BigInputField from "../BigInputField";
import {
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  Box,
  Button,
  Chip,
  MenuItem,
  Tabs,
  Tab,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import CustomTextField from "../reusable/CustomTextField";
import { autoReply, getAllAutoReply } from "../../service/service";
import { autoReplyErrors, quickButtonIcons } from "../../utils/helperData";
import CustomSwitch from "../CustomSwitch";
import { makeStyles } from "@mui/styles";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import { getKey } from "../../utils/helperFunction";
import { TenantContext } from "../../context/TenantContext";
import CancelIcon from "@mui/icons-material/Cancel";
import HeaderBar from "../reusable/HeaderBar/HeaderBar";
import ActionButtonGroup from "../reusable/ActionButtonGroup/ActionButtonGroup";
import useShopifyConf from "../../hooks/useShopifyConf";
import FullPageSpinner from "../FullPageSpinner";
import CustomPopover from "../reusable/CustomPopover";
import EmojiPicker from "emoji-picker-react";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";

const useStyles = makeStyles({
  coloredIcon: {
    fill: "#8CB077 !important",
    width: "15px",
    marginBottom: "8px",
  },
});

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const list = ["Live Chat", "Email", "Instagram", "Whatsapp", "Facebook"];

const initialState = {
  title: "",
  channel: [],
  insideAutoReply: "",
  outsideAutoReply: "",
  toggleSwitch: false,
  customizeDays: {
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  },
};

const AutoReply = () => {
  const { tenantId, userId } = useContext(TenantContext);
  const classes = useStyles();

  const [backendError, setBackendError] = useState({ msg: "", type: "error" });
  const [channel, setChannel] = useState([]);
  const [value, setValue] = useState({ ...initialState });
  const initialErrors = [...autoReplyErrors];
  const [errors, setErrors] = useState([...initialErrors]);
  const [snackBarValue, setSnackBarValue] = useState({ msg: "", type: "" });
  const [autoRelyExist, setAutoReplyExist] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [
    storeTabs,
    activeTab,
    tabContent,
    setFetchedTabContent,
    setTabContentValue,
    setParticularTabContent,
    activeTabContent,
    tabContentExist,
    changes,
    handleTabChange,
    handleTabContentChange,
    handleToggleSwitch,
    handleCustomizeDaysChange,
    handleChipDelete,
    updateTabContentExist,
    disableSaveBtn,
    discardChanges,
  ] = useShopifyConf({ ...initialState }, setErrors);


  const handleEditorChange = ({ html }) => {
  };

  const fetchAllAutoReplyData = async () => {
    setLoading(true);
    try {
      const result = await getAllAutoReply(tenantId);
      if (result.data.length > 0) {
        console.log(result.data);
        // setValue(result.data[0]);
        let contentValue = structuredClone([...result.data]);
        setTabContentValue([...contentValue]);
        setFetchedTabContent(structuredClone([...result.data]));
        setAutoReplyExist(true);
      }
      setLoading(false);
    } catch (error) {
      setBackendError({ msg: error.response.data.message, type: "error" });
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllAutoReplyData();
  }, []);

  const handleDiscard = () => {
    setValue({ ...initialState });
  };

  let currentTab = tabContent?.[activeTab];
  const handleSubmit = async (e) => {
    let shop = storeTabs[activeTab]?.shop;
    let currentTab = tabContent?.[activeTab];
    // if (storeTabs) return;
    let customizeDaysValue = Object.values(currentTab.customizeDays);
    let value = currentTab;
    const isCustomizeDaysOpen = customizeDaysValue.every(
      (item) => item === false
    );
    const newErrors = errors[activeTab];
    let isDirty = false;
    if (!value.title) {
      newErrors.title = "Title is Required";
      isDirty = true;
    }
    if (value.channel.length === 0) {
      newErrors.channel = "Channel is Required";
      isDirty = true;
    }
    if (!value.insideAutoReply) {
      newErrors.insideAutoReply = "insideAutoReply is required";
      isDirty = true;
    }
    console.log(newErrors, "errors", isDirty);

    if (isDirty) {
      setErrors((prev) => {
        let updateValue = [...prev];
        updateValue.splice(activeTab, 1, newErrors);
        return updateValue;
      });
      return;
    }
    if (!isDirty) {
      setErrors((prev) => {
        let updatedValue = [...prev];
        updatedValue[activeTab] = initialErrors[0];
        return updatedValue;
      });
    }

    disableSaveBtn(activeTab);
    try {
      const autoReplyPanel = await autoReply({
        tenantId,
        userId,
        title: value.title,
        channel: value.channel,
        insideAutoReply: value.insideAutoReply,
        outsideAutoReply: value.outsideAutoReply,
        toggleSwitch: value.toggleSwitch,
        customizeDays: value.customizeDays,
        shop,
      });
      setParticularTabContent(structuredClone(autoReplyPanel.data), activeTab);
      setSnackBarValue({ msg: autoReplyPanel.message, type: "success" });
      updateTabContentExist(activeTab);
      setFetchedTabContent((prev) => {
        let updatedValue = [...prev];
        updatedValue[activeTab] = structuredClone(autoReplyPanel.data);
        return updatedValue;
      })
      // console.log({autoReplyPanel })
      
    } catch (error) {
      console.log(error);
      setDisableBtn(false);
    }
  };

  const [cursorPosition, setCursorPosition] = useState(0);
  const handleBlur = (e) => {
    setCursorPosition(e.target.selectionStart);
  } 

  const handleEmojiSelect = (emoji) => {
    const startPos = cursorPosition;
    const endPos =  cursorPosition;
    const currentValue = currentTab?.insideAutoReply
    const newText = currentValue.slice(0, startPos) + emoji.emoji + currentValue.slice(endPos);
    const emojiLength = emoji.emoji?.length
    setCursorPosition((prev) => prev + emojiLength)
    const e = {target : {name : 'insideAutoReply', value : newText}}
    handleTabContentChange(e, activeTab)
  };

  if (loading) {
    return <FullPageSpinner />;
  }


  return (
    <AutoReplyStyles>
      {snackBarValue.msg && <CustomSnackbar payload={snackBarValue} />}
      <HeaderBar
        title={"Auto Reply"}
        hideSecondaryButton={true}
        showDescription={true}
        hidePrimaryButton={true}
        description={`Automate replies even when you’re not Online to let the customer know that you are concerned about them`}
      />
      <div className="auto-reply-container">
        <div className="store-tab-container">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {console.log("Store Tabs --", storeTabs)}
            {storeTabs.map((tabData) => (
              <Tab
                label={`${tabData.shop || "New Account"}  ${
                  tabData.isDefault ? "- Default" : ""
                }`}
                key={`${tabData.shop || "New Account"}  ${
                  tabData.isDefault ? "- Default" : ""
                }`}
              />
            ))}
          </Tabs>
        </div>
        {console.log({ errors })}
        <div className="height">
          <div className="scroll-div">
            <div className="scroll-object">
              <div className="title auto-reply-field-container">
                <h2>Title</h2>
                <CustomTextField
                  sx={{
                    width: 645,
                    marginBottom: 3,
                    marginTop: 1,
                  }}
                  value={currentTab?.title}
                  placeholder={"Title"}
                  name={"title"}
                  handleChange={(e) => handleTabContentChange(e, activeTab)}
                  margin={"normal"}
                  error={errors[activeTab]?.title}
                  helperText={errors[activeTab]?.title}
                />
              </div>
              <div className="channel">
                <h2>Channels</h2>
                <FormControl
                  sx={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    marginTop: 1,
                    borderRadius: "2px",
                    width: 645,
                  }}
                >
                  <Select
                    multiple
                    name="channel"
                    // value={currentTab?.channel}
                    // onChange={(e) => handleChange(e, activeTab)}
                    error={errors[activeTab]?.channel}
                    value={currentTab?.channel}
                    onChange={(e) => handleTabContentChange(e, activeTab)}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            sx={{ background: "#A8C398" }}
                            key={value}
                            label={value}
                            clickable
                            onDelete={(e) => handleChipDelete(value, activeTab)}
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {list.map((list) => (
                      <MenuItem key={list} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {errors[activeTab].channel && (
                  <FormHelperText sx={{ marginLeft: "14px" }} error={true}>
                    {errors[activeTab].channel}
                  </FormHelperText>
                )}
              </div>
              <div className="insideReply auto-reply-field-container">
                <h2>Auto reply inside Business hours</h2>
                <BigInputField
                  width={651}
                  row={3}
                  borderRadius={2}
                  placeholder={"Write text here....."}
                  name="insideAutoReply"
                  value={currentTab?.insideAutoReply}
                  handleChange={(e) => handleTabContentChange(e, activeTab)}
                  error={errors[activeTab]?.insideAutoReply}
                  helperText={errors[activeTab]?.insideAutoReply}
                  handleBlur={handleBlur}
                >
            <CustomPopover
              button={
                <Tooltip title="Emoji" placement="top">
                  <InsertEmoticonIcon />
                </Tooltip>
              }
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
            <EmojiPicker hiddenEmojis={['263a-fe0f']} onEmojiClick={(emoji) => handleEmojiSelect(emoji)} />
            </CustomPopover>
                </BigInputField>
              </div>
              {/* <div className="outsideReply">
                <h2>Auto reply outside Business hours</h2>
                <BigInputField
                  width={651}
                  row={3}
                  placeholder={"Write text here...."}
                  name="outsideAutoReply"
                  value={value.outsideAutoReply}
                  handleChange={handleChange}
                >
                  {quickButtonIcons.map(({ id, icon }) => {
                    return (
                      <div className="" key={id}>
                        {icon}
                      </div>
                    );
                  })}
                </BigInputField>
              </div> */}
              <div className="customize-days auto-reply-field-container">
                <div className="custom-days-title">
                  <h2>Customize Days </h2>
                  <p>Specify the days you want to use auto reply</p>
                </div>
                <span className="custom-days-switch">
                  <CustomSwitch
                    checked={currentTab?.toggleSwitch}
                    name="toggleSwitch"
                    handleToggleSwitch={(e) => handleToggleSwitch(e, activeTab)}
                  />
                </span>
              </div>

              {currentTab?.toggleSwitch && (
                <div className="custom-days-list">
                  {Object.keys(currentTab?.customizeDays)?.map(
                    (data, index) => {
                      return (
                        <div key={getKey(data, "days", index)} className="days">
                          <span>
                            {" "}
                            <FormControlLabel
                              sx={{ margin: 0, marginRight: "2px" }}
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleCustomizeDaysChange(
                                      e,
                                      activeTab,
                                      currentTab
                                    )
                                  }
                                  checked={
                                    Object.values(currentTab?.customizeDays)[
                                      index
                                    ]
                                  }
                                  name={data}
                                  style={{ padding: 0 }}
                                />
                              }
                            />
                            {data}
                          </span>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              <div className="save-button">
                <ActionButtonGroup
                  primaryButtonLabel={
                    tabContentExist[activeTab] ? "Update" : "Save"
                  }
                  secondaryButtonLabel={"Discard Changes"}
                  handlePrimaryButtonClick={handleSubmit}
                  handleSecondaryButtonClick={() => discardChanges(activeTab)}
                  primaryButtonDisable={changes[activeTab]}
                  hidePrimaryButton={false}
                  hideSecondaryButton={false}
                  primaryButtonLoading={false}
                />
                {/* <Button onClick={handleDiscard} variant="outlined">
                  Discard Changes
                </Button>
                <PrimaryButton
                  disabled={disableBtn}
                  onClick={(e) => handleSubmit(e)}
                  variant="contained"
                >
                  {autoRelyExist ? "Update" : "Save"}
                </PrimaryButton> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AutoReplyStyles>
  );
};
export default AutoReply;
