import React, { useEffect, useRef } from "react";
import { InboxContainer } from "./InboxContainer";
import { useTenantContext } from "../../context/TenantContext";
import { regSw, subscribePush } from "../../utils/helperFunction";

const Inbox = () => {
  const hasEffectRun = useRef(false);
  const { userId, tenantId } = useTenantContext();
  const registerAndSubscribe = async () => {
    try {
      const serviceWorkerReg = await regSw();
      await subscribePush(serviceWorkerReg, userId, tenantId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!hasEffectRun.current) {
      registerAndSubscribe();
      hasEffectRun.current = true;
    }
  }, []);
  return (
    <>
      <InboxContainer />
    </>
  );
};

export default Inbox;
