import React, { useContext } from "react";
import instagram1 from "../../../assets/images/instagram 1.png";
import facebook1 from "../../../assets/images/facebook 1.png"
import InstagramCardStyles from "../../../assets/styledComponents/settings/InstagramCard";
import ShopifyCard from "../ShopifyCard";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  customButton: {
    color: "#FFFFFF !important",
  },
});

const FacebookCard = () => {
  const classes = useStyles();
  const data = {
    name: "Facebook Setup",
    logo: facebook1,
    list: [
      "Engage on the worlds biggest social media platform",
      "work together on conversation in one shared inbox",
      "meet your customers in their moment of need",
    ],
  };
  const navigate = useNavigate();
  const handleClick = () => {
    console.log("clicked");
    navigate("/settings/facebookconfiguration");
  };
  const button = (
    <Button
      onClick={() => handleClick()}
      variant="contained"
      className={classes.customButton}
    >
      Add Facebook{" "}
    </Button>
  );

  return (
    <>
      <InstagramCardStyles>
        <ShopifyCard {...data} button={button} />
      </InstagramCardStyles>
    </>
  );
};
export default FacebookCard;
