import styled from "styled-components";
const MenuStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  border-right: 1px solid #666666;
  /* padding: 0 5px; */
  width: 50px;
  .logo {
    margin: 1rem 0;
  }
  .menu-items {
    display: grid;
    gap: 1rem;
  }
  .menu-item {
    border-radius: 0.3rem;
  }
  .menu-item-active {
    background-color: var(--primary-100);
    color: var(--primary-500);
  }
  .profile {
    margin-bottom: 0.5rem;
    cursor : pointer;
  }
`;
export default MenuStyles;
