import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useChannelContext } from "../../../context/channelConfigurationContext/channelContext";
const ViewCodeSnippetStyles = styled.div`
  padding: 2rem;
  overflow-x: hidden;
  word-wrap:break-word
`;

const ViewCodeSnippet = ({ setClipboardValue, scriptUrl }) => {
  const snippetRef = useRef();
  useEffect(() => {
    setClipboardValue(snippetRef.current.innerText);
    console.log(snippetRef.current.innerText);
  }, []);
  return (
    <ViewCodeSnippetStyles>
      <div className="code-snippet" ref={snippetRef}>
        {`<div><script  src=${scriptUrl}></script></div>`}
      </div>
    </ViewCodeSnippetStyles>
  );
};

export default ViewCodeSnippet;
