import React from "react";
import { useState } from "react";
import { Button, TextField } from "@mui/material";
import HeaderBar from "../reusable/HeaderBar/HeaderBar";
import HeaderStyles from "../../assets/styledComponents/Header";
import MyProfileSettingStyles from "../../assets/styledComponents/myProfile/MyProfileSetting";
import { DEFAULT_PASSWORD_CONSTRAINT } from "../../utils/helperData";
import { changePassword } from "../../service/service";
import { useTenantContext } from "../../context/TenantContext";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import { getKey } from "../../utils/helperFunction";

const ChangePassword = () => {
  const { userId } = useTenantContext();
  const initialState = [
    { id: 1, name: "Current Password", value: "", error: "" },
    { id: 2, name: "New Password", value: "", error: "" },
    { id: 3, name: "Confirm New Password", value: "", error: "" },
  ];

  const [formValue, setFormValue] = useState([...initialState]);
  const [showPasswordAlert, setPasswordAlert] = useState(
    DEFAULT_PASSWORD_CONSTRAINT
  );
  const [passwordError, setPasswordError] = useState(false);
  const [snackBarError, setSnackBarError] = useState({ msg: "", type: " " });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (e, index) => {
    const { name, value } = e.target;

    if (name === "New Password") {
      let currentPassword = value;
      let newPassConstraintCheck = [...DEFAULT_PASSWORD_CONSTRAINT];
      newPassConstraintCheck = await Promise.all(
        newPassConstraintCheck.map((passConstraintData) => {
          let result = {
            ...passConstraintData,
          };

          if (passConstraintData.regexPattern) {
            if (passConstraintData.regexPattern.test(currentPassword)) {
              result.valid = true;
            } else {
              result.valid = false;
            }
          }
          if (passConstraintData.minLength) {
            if (currentPassword.length >= passConstraintData.minLength) {
              result.valid = true;
            } else {
              result.valid = false;
            }
          }
          return result;
        })
      );

      const isPasswordValid = newPassConstraintCheck.every(
        ({ valid }) => valid === true
      );

      setPasswordError(!isPasswordValid);
      setPasswordAlert(newPassConstraintCheck);
    }

    setFormValue((prev) => {
      const updateValue = [...prev];
      updateValue[index].value = value;
      updateValue[index].error = ''
      return updateValue;
    });
  };

  const handleDiscard = () => {
    setFormValue([...initialState]);
  };

  const handleSave = async (e) => {
    // e.preventDefault();
    const updatedValue = [...formValue];

    const newPassword = updatedValue.find(
      (item) => item.name === "New Password"
    );
    const confirmNewPassword = updatedValue.find(
      (item) => item.name === "Confirm New Password"
    );
    const currentPassword = updatedValue.find(
      (item) => item.name === "Current Password"
    );

    let isDirty = false;
    if (!currentPassword.value) {
      updatedValue[0].error = "Old password is required";
      isDirty = true; 
    } else if (!newPassword.value) {
      updatedValue[1].error = "New password is required";
      isDirty = true; 
    }  else if (!confirmNewPassword.value) {
      updatedValue[2].error = "Confirm password is required";
      isDirty = true; 
    } else if (currentPassword.value === confirmNewPassword.value) {
      updatedValue[0].error = "Old password is same as new Password";
      updatedValue[1].error = "Old password is same as new Password";
      isDirty = true; 
    }  else if (newPassword.value !== confirmNewPassword.value) {
      updatedValue[1].error =
        "New Password and confirm Password should be same";
      updatedValue[2].error =
        "New Password and confirm Password should be same";
      isDirty = true;
    } 


    if (isDirty) {
      setFormValue(updatedValue);
      return;
    }


    let newPassConstraintCheck = [...DEFAULT_PASSWORD_CONSTRAINT];
    newPassConstraintCheck = await Promise.all(
      newPassConstraintCheck.map((passConstraintData) => {
        let result = {
          ...passConstraintData,
        };

        if (passConstraintData.regexPattern) {
          if (passConstraintData.regexPattern.test(newPassword.value)) {
            result.valid = true;
          } else {
            result.valid = false;
          }
        }
        if (passConstraintData.minLength) {
          if (newPassword.value.length >= passConstraintData.minLength) {
            result.valid = true;
          } else {
            result.valid = false;
          }
        }
        return result;
      })
    );


    console.log("New Password Constraint --", newPassConstraintCheck, newPassword);

    const isPasswordValid = newPassConstraintCheck.every(
      ({ valid }) => valid === true
    );

    setPasswordError(!isPasswordValid);
    setPasswordAlert(newPassConstraintCheck);


    // if (formValue[1].value !== formValue[2].value) {
    //   return;
    // }
    if (!isPasswordValid) {
      return;
    }

    const payload = {
      userId,
      oldPassword: formValue[0].value,
      newPassword: formValue[1].value,
    };
    try {
      setIsLoading(true)
      const result = await changePassword(payload);
      setSnackBarError({ msg: result.data, type: "success" });
      setFormValue([...initialState]);
      setIsLoading(false)

    } catch (error) {
      setSnackBarError({ msg: error.response.data.message, type: "error" });
      setIsLoading(false)
    }
  };
  return (
    <MyProfileSettingStyles>
      {snackBarError.msg && <CustomSnackbar payload={snackBarError} />}
      <form>
        <HeaderBar
          title={"My Profile"}
          handlePrimaryButtonClick={handleSave}
          hideSecondaryButton={false}
          handleSecondaryButtonClick={handleDiscard}
          hidePrimaryButton={false}
          primaryButtonLabel={"Save"}
          secondaryButtonLabel={"Discard Changes"}
          primaryButtonLoading={isLoading}

        />
        <div className="container">
          <div className="heading-container">
            <p className="password-heading heading">Change Password</p>
            <span className="subheading">
              Change Your password for better protection
            </span>
          </div>
          {formValue.map((item, index) => {
            const { name, value, error } = item;
            return (
              <div
                key={getKey(name, "formValue", index)}
                className="form-container"
              >
                <p className="heading">{name}</p>
                <div className="textfield-container">
                  <TextField
                    required
                    value={value}
                    name={name}
                    error={Boolean(error)}
                    type="password"
                    onChange={(e) => handleChange(e, index)}
                    sx={{
                      width: 520,
                      boxShadow: 7,
                      marginTop: 2,
                      borderRadius: "10px",
                    }}
                    placeholder={name}
                  />
                  {passwordError && name === "New Password" && (
                    <label className="error-label">
                      {
                        "Password should be 8 letter with one uppercase lowercase symbol and number"
                      }
                    </label>
                  )}
                  {name !== "New Password" && (
                    <label className="error-label">{error}</label>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </form>
    </MyProfileSettingStyles>
  );
};

export default ChangePassword;
