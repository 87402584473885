import styled from "styled-components";
const InviteTeamMemberStyles = styled.div`
   /* width: 81vw; */
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
  }

  .underline {
    background-color: var(--border-clr);
    width: 95%;
    height: 1px;
    margin: 0 auto;
  }
  .container {
    height: 90vh;
    padding: 1rem 2rem;
    overflow-y: auto;
  }
  .container h3 {
    font-size: 23px;
    font-weight: 500;
    line-height: 32px;
  }
  .invite-msg {
    width: 400px;
    text-align: left;
    line-height: 1.5rem;
    margin-bottom: -3rem;
    opacity: 0.7;
  }
  .form-container {
    margin-top: 2rem;
    width: 520px;
  }
  .form-row {
    display: flex;
    flex-direction: column;
  }
  .label {
    margin: 0.5rem 0;
    text-transform: capitalize;
    margin-top: 1.5rem;
  }
  .permission {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .btn {
    margin-top: 1rem;
  }
  .placeHolder {
    opacity: 0.7;
    font-weight: 500;
  }
  @media (max-width: 400px) {
    /* display: block;
    background-color: red; */
  }

  @media (max-width: 1368px) {
    width: 100%;
  }
`;

export default InviteTeamMemberStyles;
