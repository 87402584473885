import React, { useState } from "react";
import styled from "styled-components";
import { useChannelContext } from "../../../context/channelConfigurationContext/channelContext";
const countries = [
  { name: "United States", code: "+1", flag: "🇺🇸", abb: "US" },
  { name: "China", code: "+86", flag: "🇨🇳", abb: "CN" },
  { name: "India", code: "+91", flag: "🇮🇳", abb: "IN" },
  { name: "Brazil", code: "+55", flag: "🇧🇷", abb: "BR" },
  { name: "Indonesia", code: "+62", flag: "🇮🇩", abb: "ID" },
  { name: "Pakistan", code: "+92", flag: "🇵🇰", abb: "PK" },
  { name: "Nigeria", code: "+234", flag: "🇳🇬", abb: "NG" },
  { name: "Bangladesh", code: "+880", flag: "🇧🇩", abb: "BD" },
  { name: "Russia", code: "+7", flag: "🇷🇺", abb: "RU" },
  { name: "Mexico", code: "+52", flag: "🇲🇽", abb: "MX" },
  { name: "Japan", code: "+81", flag: "🇯🇵", abb: "JP" },
  { name: "Ethiopia", code: "+251", flag: "🇪🇹", abb: "ET" },
  { name: "Philippines", code: "+63", flag: "🇵🇭", abb: "PH" },
  { name: "Egypt", code: "+20", flag: "🇪🇬", abb: "EG" },
  { name: "Vietnam", code: "+84", flag: "🇻🇳", abb: "VN" },
  { name: "DR Congo", code: "+243", flag: "🇨🇩", abb: "CD" },
  { name: "Turkey", code: "+90", flag: "🇹🇷", abb: "TR" },
  { name: "Iran", code: "+98", flag: "🇮🇷", abb: "IR" },
  { name: "Germany", code: "+49", flag: "🇩🇪", abb: "DE" },
  { name: "Thailand", code: "+66", flag: "🇹🇭", abb: "TH" },
];
const TrackOrderContainer = ({ setShowOrder }) => {
  const [selectedOption, setSelectedOption] = useState("email");

  const { widgetFormattedList, selectedWidgetShop } = useChannelContext();
  const selectedWidgetData = widgetFormattedList[selectedWidgetShop];
  const { appearance } = selectedWidgetData;

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Add your logic for handling form submission here
  };
  const getOrder = () => {
    setShowOrder(true);
    // setTrackOrderCount((prev) => {
    //   let updatedValue = [...prev];
    //   updatedValue[clickedIndex] = "order";
    //   return updatedValue;
    // });
  };

  return (
    <StyledTrackOrderContainer>
      <h4>Order Details</h4>
      <StyledForm onSubmit={handleSubmit}>
        <StyledInput
          type="text"
          placeholder="Order Number*"
          className="track-order-input text-input-box"
        />
        {selectedOption === "email" ? (
          <StyledInput
            type="email"
            placeholder="E-mail*"
            className={`track-order-input email-input text-input-box ${
              selectedOption === "phone" ? "hidden" : ""
            }`}
          />
        ) : (
          <StyledInputGroup>
            <StyledSelect
              id="countryDropdown"
              style={{ display: selectedOption === "phone" ? "block" : "none" }}
            >
              {countries.map((country) => {
                const { name, code, flag, abb } = country || {};
                return (
                  <option key={country.abb}>
                    {" "}
                    <span>{flag} </span> {name} {`(${code})`}{" "}
                  </option>
                );
              })}
              {/* Add your options here */}
            </StyledSelect>
            <StyledInput
              id="phoneNumber"
              type="text"
              width={"65%"}
              placeholder="Phone Number*"
              className={`track-order-input phone-input text-input-box ${
                selectedOption === "email" ? "hidden" : ""
              }`}
            />
          </StyledInputGroup>
        )}

        <StyledInputOption bgColor={appearance.themeColor}>
          <div
            className={`email-or-phone-option ${
              selectedOption === "email" ? "selected" : ""
            }`}
            onClick={() => handleOptionChange("email")}
          >
            <span className="selection-symbol"></span>Use Email
          </div>
          <div
            className={`email-or-phone-option ${
              selectedOption === "phone" ? "selected" : ""
            }`}
            onClick={() => handleOptionChange("phone")}
          >
            <span className="selection-symbol"></span>Use Phone
          </div>
        </StyledInputOption>
        <StyledLabel>Select an option you used for your order</StyledLabel>
        <StyledErrorMessage className="order-error-message"></StyledErrorMessage>
        <StyledButton
          bgColor={appearance.themeColor}
          type="submit"
          onClick={getOrder}
        >
          Get Order
          <i className="fa-solid fa-chevron-right"></i>
        </StyledButton>
      </StyledForm>
    </StyledTrackOrderContainer>
  );
};

// Styled Components

const StyledTrackOrderContainer = styled.div`
  width: 80%;
  padding: 0.5rem;
  float: right;
  background: white;
  border-radius: 10px;
  border: 1px solid rgb(241, 241, 241);
  margin: 10px;
  box-shadow: rgb(208, 204, 235) 0px 0px 1rem -0.5rem;

  h4 {
    margin: 10px;
  }
`;

const StyledForm = styled.form`
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  width: -webkit-fill-available;
`;

const StyledInput = styled.input`
  width: ${({ width }) => width || "-webkit-fill-available" } !important;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  outline: none;
  border: 0.5px solid silver;

`;

const StyledInputGroup = styled.div`
  display: flex;
  width: inherit;
`;

const StyledSelect = styled.select`
  max-width: 35%;
  margin-bottom: 10px;
  border-radius: 5px;
  outline: none;
  border: 0.5px solid silver;
  margin-right: 8px;
  padding-left: 0.2rem;
`;

const StyledInputOption = styled.div`
  display: flex;
  gap: 15px;
  .email-or-phone-option.selected:hover,
  .email-or-phone-option:hover {
    background-color: black;
    // background-color: ${({ bgColor }) => bgColor};
    color: white;
  }
  .email-or-phone-option {
    cursor: pointer;
    font-size: 0.8rem;
    // display: inline-block;
    padding: 0.5rem 0.85rem;
    border-radius: 2rem;
    color: #5b5b5b;
    border: 1px solid #c1c1c1;
    // margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    text-decoration: none;
    transition: all 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1;
    background: #f2f2f2;
    color: #5b5b5b;
  }

  .email-or-phone-option.selected {
    background: white;
    color: #5b5b5b;
  }
  .email-or-phone-option.selected .selection-symbol {
    background: #33b772;
    border: 2px solid #45cb85;
  }
  .selection-symbol {
    display: block;
    border-radius: 50%;
    background: #bfbfbf;
    border: 2px solid #e6e6e6;
    height: 8px;
    width: 8px;
    margin-right: 0.5rem;
  }
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 0.8rem;
  text-align: center;
  color: #8e8e8e;
  margin: 0.5rem 0 0.5rem;
`;

const StyledErrorMessage = styled.p`
  margin: 0;
  margin-bottom: 4px;
  color: red;
  font-weight: 400;
`;

const StyledButton = styled.button`
  color: white;
  margin-top: 5px;
  color: white;
  width: -webkit-fill-available;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgb(53, 53, 53);
  font-size: 0.85rem;
  font-weight: 500;
  width: 100%;
  background: black;

  &:hover {
    background-color: ${({ bgColor }) => bgColor};
    border-color: ${({ bgColor }) => bgColor};
  }
`;

export default TrackOrderContainer;
