import styled from 'styled-components'
const AITrainingStyles = styled.div`
  .shopify-tabs-container {
    padding: 0px 40px;
   }
   .training-container {
    text-align: center;
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 15px;
   }

  .paper-card {
    margin-left: 40px;
    margin-right: 40px;
    padding: 20px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background: white !important;
    padding-bottom: 25px;
    margin-top: 20px;
  }
`
export default  AITrainingStyles;