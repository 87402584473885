import axios from "axios";
import {
  AI_END_POINTS,
  AI_SERVICE_URL,
  BASE_URL,
  BASE_URL_BACKEND,
  BASE_URL_SERVICE,
  END_POINTS,
} from "./config";

const makePrivateRequest = {
  async get(url, config) {
    return await this.request("get", url, config);
  },

  async post(url, data, config) {
    return await this.request("post", url, data, config);
  },

  async put(url, data, config) {
    return await this.request("put", url, data, config);
  },

  async patch(url, data, config) {
    return await this.request("patch", url, data, config);
  },

  async delete(url, config) {
    return await this.request("delete", url, config);
  },

  async request(method, url, data = null, config = {}) {
    const token = localStorage.getItem("token");
    try {

      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const response = await axios({
        method,
        url,
        data,
        ...config,
      });

      return response.data;
    } catch (error) {
      // Handle errors, e.g., redirect to login page for unauthorized access
      const status = error?.response?.status;

      console.error("Error in making request", status, error?.response?.data?.err, token);
      if (status === 400 && error.response.data.err === 'Invalid token') {
        localStorage.removeItem("token");
        localStorage.removeItem("store");
        window.location.href = '/login';
      }

      console.error("Error:", error);
      throw error;
    }
  },
};

export const connectShopify = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.connectShopify}`,
    payload
  );
  return res;
};

export const createFaQTraining = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.createFaQTraining}`,
    payload
  );
  return res;
}

export const updateFilters = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.updateFilters}`,
    payload
  );
  return res;
};

export const getFilters = async (tenantId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.fetchFilters}?tenantId=${tenantId}`
  );
  return res;
};


export const registerUser = async ({ payload }) => {
  const res = await axios.post(
    `${BASE_URL_SERVICE}${END_POINTS.registerUser}`,
    payload
  );
  return res.data;
};

export const getAllLabelReports = async (tenantId, customStartDate, customEndDate,shop) => {
  const res = await axios.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllLabelReports}?tenantId=${tenantId}&customStartDate=${customStartDate}&customEndDate=${customEndDate}&shop=${shop}`
  );
  return res.data;
}

export const getAllFaQTraining = async (tenantId,store) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllFaQTraining}?tenantId=${tenantId}&store=${store}`
  );
  return res.data;
}

export const getAllChannelReports = async (tenantId, customStartDate, customEndDate,shop) => {
  const res = await axios.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllChannelReports}?tenantId=${tenantId}&customStartDate=${customStartDate}&customEndDate=${customEndDate}&shop=${shop}`
  );
  return res.data;
};

export const getAllUserReports = async (tenantId, customStartDate, customEndDate,shop) => {
  const res = await axios.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllUserReports}?tenantId=${tenantId}&customStartDate=${customStartDate}&customEndDate=${customEndDate}&shop=${shop}`
  );
  return res.data;
};

export const autoReply = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.autoReply}`,
    payload
  );
  return res;
};

export const addLabel = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.addLabel}`,

    payload
  );
  return res;
};

export const quickReply = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.quickReply}`,

    payload
  );
  return res;
};

export const getLabel = async (tenantId, searchValue) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getLabel}?tenantId=${tenantId}&labelName=${searchValue}`
  );
  return res;
};

export const getAllAutoReply = async (tenantId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllAutoReply}?tenantId=${tenantId}`
  );
  return res;
};
 
export const getAllResolutionReport = async (tenantId,customStartDate,customEndDate, filterValue, selectedShop) => {
  const {avgFirstResponseTime ,channel ,user , label} = filterValue
  const res = await axios.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllResolutionReport}?tenantId=${tenantId}&customStartDate=${customStartDate}&customEndDate=${customEndDate}&avgFirstResponseTime=${avgFirstResponseTime}&channel=${channel}&user=${user}&label=${label}&shop=${selectedShop}`
  );
  return res.data;
};

export const getAllQuickReply = async (tenantId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllQuickReply}?tenantId=${tenantId}`
  );
  return res;
};

export const subscribePlan = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL}${END_POINTS.subscription}`,
    payload
  );
  return res;
};

export const buyNewStore  = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL}${END_POINTS.buyNewStore}`,
    payload
  );
  return res;
};

export const buyAdditionalTickets = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL}${END_POINTS.buyMoreTickets}`,
    payload
  );
  return res;
};


export const verifySubscriptionPlan = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.verifyPricingPlanSubscription}`,
    payload
  );
  return res;
};

export const verifyTicketPurchase = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.verifyTicketPurchase}`,
    payload
  );
  return res;
};

export const loginUser = async (payload) => {
  const res = await axios.post(
    `${BASE_URL_SERVICE}${END_POINTS.loginUser}`,
    payload
  );
  return res.data;
};

export const logoutUser = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.logoutUser}`,
    payload
  );
  return res;
};

export const changePassword = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.changePassword}`,
    payload
  );
  return res;
};

export const setPassword = async (payload) => {
  const res = await axios.post(
    `${BASE_URL_SERVICE}${END_POINTS.setPassword}`,
    payload
  );
  return res.data;
};

export const updateUserDetail = async (payload) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.updateUserDetail}`,
    payload
  );
  return res;
};

export const uploadProfileImg = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.uploadProfileImg}`,
    payload
  );
  return res;
};

export const verifyOtp = async ({ payload }) => {
  const res = await axios.post(
    `${BASE_URL_SERVICE}${END_POINTS.verifyOtp}`,
    payload
  );
  return res.data;
};

export const inviteUser = async ({ payload, tenantId }) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.inviteUser}?id=${tenantId}`,
    payload
  );
  return res;
};

export const getAllPermission = async () => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllPermission}`
  );
  return res;
};

export const getAllUserSetup = async ({ tenantId }) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllUserSetup}?id=${tenantId}`
  );
  return res;
};

export const getSingleUserDetails = async ({ userId }) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getSingleUserDetails}?id=${userId}`
  );
  return res;
};


export const getTeams = async (tenantId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getTeams}?id=${tenantId}`
  );
  return res;
};

export const getUserPermission = async (userId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getUserPermission}?id=${userId}`
  );
  return res;
};

export const editUserDetail = async ({ payload, userId }) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.editUserDetail}?id=${userId}`,
    payload
  );
  return res;
};

export const deleteUserDetail = async ({ userId }) => {
  const res = await makePrivateRequest.delete(
    `${BASE_URL_SERVICE}${END_POINTS.deleteUserDetail}?id=${userId} `
  );
  return res.data;
};



export const forgetPasswordApi = async ({ email }) => {
  const res = await axios.post(
    `${BASE_URL_SERVICE}${END_POINTS.forgetPassword}?email=${email} `
  );
  return res.data;
};


export const deleteLabel = async (labelId) => {
  const res = await makePrivateRequest.delete(
    `${BASE_URL_SERVICE}${END_POINTS.deleteLabel}?id=${labelId} `
  );
  return res;
};

export const deleteFaq = async (faQId,) => {
  const res = await makePrivateRequest.delete(
    `${BASE_URL_SERVICE}${END_POINTS.deleteFaq}?id=${faQId}`
  );
  return res;
};

export const updateFaQ = async (payload) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.updateFaq}`, payload
  );
  return res;
};

export const deleteQuickReply= async ( quickReplyId ) => {
  const res = await makePrivateRequest.delete(
    `${BASE_URL_SERVICE}${END_POINTS.deleteQuickReply}?id=${quickReplyId} `
  );
  return res;
};

export const getPricingPlan = async () => {
  const res = await axios.get(
    `${BASE_URL_SERVICE}${END_POINTS.getPricingPlan}`
  );
  return res.data;
};

export const createChatWidget = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.createChatWidget}`,
    payload
  );
  return res;
};

export const createBusinessHours = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.createBusinessHours}`,
    payload
  );
  return res;
};

export const getChatWidget = async (tenantId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getChatWidget}?tenantId=${tenantId}`
  );
  return res;
};

export const sendMessage = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.sendMessage}`,
    payload
  );
  return res;
};

export const getBusinessHours = async (tenantId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.createBusinessHours}?tenantId=${tenantId}`
  );
  return res;
};

export const getAllSession = async ({
  tenantId,
  tenantUserDetailsId,
  labelFilterQuery,
  messageSearch,
  page = 1,
  isSnooze,
  channelName,
  status,
  priorityFilter,
  assignedFilter,
}) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${
      END_POINTS.getAllSession
    }?tenantId=${tenantId}&tenantUserDetailsId=${tenantUserDetailsId}&labelFilterQuery=${JSON.stringify(
      labelFilterQuery
    )}&messageSearch=${messageSearch}&isSnooze=${isSnooze}&page=${page}&channelName=${channelName}&status=${status}&priorityFilter=${priorityFilter}&assignedFilter=${assignedFilter}`
  );
  return res;
};

export const getSingleChatSession = async (sessionId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getSingleChatSession}?sessionId=${sessionId}`
  );
  return res;
};

export const getAllMessage = async (userId, sessionId, page, messageType) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllMessage}?sessionId=${sessionId}&page=${page}&userId=${userId}&messageType=${messageType}`
  );
  return res;
};

export const updateUnReadMessage = async (payload) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.updateUnReadMessage}`,
    payload
  );
  return res;
};

export const setReadMessage = async (payload) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.updateUnReadMessage}`,
    payload
  );
  return res;
};

export const removeUnReadMessage = async (sessionId, seenBy) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.removeUnReadMessage}?sessionId=${sessionId}&seenBy=${seenBy}`
  );
  return res;
};

export const updateUserName = async (payload) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.updateUserName}`,
    payload
  );
  return res;
};

export const assignTo = async (payload) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.assignTo}`,
    payload
  );
  return res;
};

export const updateStatus = async (payload) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.updateStatus}`,
    payload
  );
  return res;
};

export const markSessionRead = async (payload) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.markSessionRead}`,
    payload
  );
  return res;
};

export const snoozeMessage = async (payload) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.snoozeMessage}`,
    payload
  );
  return res;
};

export const attachLabelToChatSession = async (payload) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.attachLabelToChatSession}`,

    payload
  );
  return res;
};

export const getShopifyOrders = async (shop) => {
  const res = await axios.get(
    `${BASE_URL_BACKEND}${END_POINTS.getShopifyOrders}?shop=${shop}`
  );
  return res.data;
};

export const createShopifyOrders = async (shop, payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_BACKEND}${END_POINTS.createShopifyOrders}?shop=${shop}`,
    payload
  );
  return res;
};

export const setAsPriority = async (sessionId) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.setPriority}?sessionId=${sessionId}`
  );
  return res;
};

export const getAutoReplyMessage = async (tenantId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllAutoReplyMessage}?tenantId=${tenantId}`
  );
  return res;
};

export const getAllTenantStore = async (tenantId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllStore}?tenantId=${tenantId}`
  );
  return res;
};

export const getStoreStatus = async  (shop) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.checkStoreStatus}?shop=${shop}`
  );
  return res;
};

export const deleteTenantStore = async(tenantId, storeId) => {
  const res = await makePrivateRequest.delete(
    `${BASE_URL_SERVICE}${END_POINTS.deleteStore}/${storeId}?tenantId=${tenantId}`
  );
  return res;
};


export const getAllChatWidget = async (tenantId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllChatWidget}?tenantId=${tenantId}`
  );
  return res;
};

export const subscribe = async (payload, userId, tenantId) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.subscribe}?userId=${userId}&tenantId=${tenantId}`,
    payload
  );
  return res;
};

export const sendWhatsappMessage = async (payload) => {
  const res = await makePrivateRequest.post(`${BASE_URL_SERVICE}sendMessage`, payload);
  return res;
};

export const setAsCustomer = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.setAsCustomer}`,
    payload
  );
  return res;
};

export const deployWidget = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_BACKEND}${END_POINTS.deployWidget}`,
    payload
  );
  return res;
};

export const createTicketNote = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.createTicketNote}`,
    payload
  );
  return res;
};

export const getTicketNote = async (ticketId, tenantId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.createTicketNote}?ticketId=${ticketId}&tenantId=${tenantId}`
  );
  return res;
};

export const fetchWhatsappConfig = async (tenantId, whatsappPhoneId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.fetchWhatsappConfig}?tenantId=${tenantId}&whatsappPhoneId=${whatsappPhoneId}`
  );
  return res;
};

export const updateWhatsappConfig = async (tenantId, payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.updateWhatsappConfig}?tenantId=${tenantId}`,
    payload
  );
  return res;
};

export const deleteWhatsappConfig = async  (tenantId, configId) => {
  const res = await makePrivateRequest.delete(
    `${BASE_URL_SERVICE}${END_POINTS.deleteWhatsappConfig}/${configId}?tenantId=${tenantId} `
  );
  return res.data;
};


export const createPrivateNoteNotification = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.createPrivateNoteNotification}`,
    payload
  );
  return res;
};

export const getAllPrivateNoteNotification = async ({
  tenantId,
  taggedUser,
}) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllPrivateNoteNotification}?tenantId=${tenantId}&taggedUser=${taggedUser}`
  );
  return res;
};

export const updateQuickReply = async (payload) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.quickReply}`,
    payload
  );
  return res;
};

export const getSingleShopifyOrders = async (shop, id) => {
  const res = await axios.get(
    `${BASE_URL_BACKEND}${END_POINTS.getSingleShopifyOrders}?shop=${shop}&id=${id}&type=helpIq`
  );
  return res.data;
};

export const getSessionByCustomerId = async (tenantId, customerId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getSessionByCustomerId}?=tenantId${tenantId}&customerId=${customerId}`
  );
  return res;
};

export const getSingleShopping = async (shop, productName) => {
  const res = await axios.get(
    `${BASE_URL_BACKEND}${END_POINTS.getSingleShopping}?shop=${shop}&productName=${productName}`
  );
  return res.data;
};

export const sendSocialMediaMessage = async (payload) => {
  const res = await axios.post(
    `${BASE_URL_SERVICE}${END_POINTS.sendSocialMediaMessage}`,
    payload
  );
  return res.data;
};

export const metaAccountLogin = async () => {
  const res = await axios.get(
    `${BASE_URL_SERVICE}${END_POINTS.metaAccountLogin}`
  );
  return res.data;
};

export const saveFacebookPage = async (payload) => {
  const res = await axios.post(
    `${BASE_URL_SERVICE}${END_POINTS.saveFacebookPage}`,
    payload
  );
  return res.data;
};


export const getPagePosts  = async ({tenantId, shop}) => {
  const res = await axios.get(
    `${BASE_URL_SERVICE}${END_POINTS.getPagePosts}?tenantId=${tenantId}&shop=${shop}`,
  );
  return res.data;
};

export const likeComment= async ({commentId, shop, isLiked, messageId}) => {
  const res = await axios.post(
    `${BASE_URL_SERVICE}${END_POINTS.likeComment}?commentId=${commentId}&shop=${shop}&isLiked=${isLiked}&messageId=${messageId}`,
  );
  return res.data;
}

export const getSuggestReply = async (payload, apiKey) => {
  const res = await axios.post(
    `${AI_SERVICE_URL}/${AI_END_POINTS.suggestReply}`,
    payload,
    {
      headers: {
        "api-key": apiKey,
        "opt-nikola" : process.env.REACT_APP_AI_OPT 
      }
    }
  );
  return res.data;
}

export const getTicketSummary = async (payload, apiKey) => {
  console.log('search', `/${AI_END_POINTS.getSummary}`)
  const res = await axios.post(
    `${AI_SERVICE_URL}/${AI_END_POINTS.getSummary}`,
    payload,
    {
      headers: {
        "api-key": apiKey,
        "opt-nikola" : process.env.REACT_APP_AI_OPT 
      }
    }
  );
  return res.data;
}

export const getAllMessages = async (sessionId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllMessages}?sessionId=${sessionId}`,
  );
  return res;
};


export const getInstagramAccountIsConnected = async (pageId, shop) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getInstagramAccountIsConnected}?pageId=${pageId}&shop=${shop}`,
  );
  return res;
};


export const getAllInstagramPosts = async ({tenantId, shop}) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllInstagramPosts}?tenantId=${tenantId}&shop=${shop}`,
  );
  return res;
};

// export const replyToInstagramComment  = async ({tenantId, shop, commentId}) => {
//   const res = await makePrivateRequest.get(
//     `${BASE_URL_SERVICE}${END_POINTS.replyToInstagramComment}?tenantId=${tenantId}&shop=${shop}&commentId=${commentId}&message=${message}`,
//   );
//   return res;
// };

export const deleteComment = async ({tenantId, shop, commentId, userId, messageId, sessionId}) => {
  const res = await makePrivateRequest.delete(
    `${BASE_URL_SERVICE}${END_POINTS.deleteComment }?tenantId=${tenantId}&shop=${shop}&commentId=${commentId}&userId=${userId}&messageId=${messageId}&sessionId=${sessionId}`,
  );
  return res;
};

export const deleteInstagramComment = async ({tenantId, shop, commentId, userId}) => {
  const res = await makePrivateRequest.delete(
    `${BASE_URL_SERVICE}${END_POINTS.deleteInstagramComment}?tenantId=${tenantId}&shop=${shop}&commentId=${commentId}&userId=${userId}`,
  );
  return res;
};


export const deleteFacebookIntegration = async ({tenantId, shop, userId}) => {
  const res = await makePrivateRequest.delete(
    `${BASE_URL_SERVICE}${END_POINTS.deleteFacebookIntegration}?tenantId=${tenantId}&shop=${shop}&userId=${userId}`,
  );
  return res;
};



export const getUpdatedSingleChatSession   = async (sessionId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getUpdatedSingleChatSession}?sessionId=${sessionId}`
  );
  return res;
};


export const activateEmail= async (payload) => {
  const res = await axios.post(
    `${BASE_URL_SERVICE}${END_POINTS.activateEmail}`,
    payload,
  );
  return res.data;
}

export const sendPinCode = async (payload) => {
  const res = await axios.post(
    `${BASE_URL_SERVICE}${END_POINTS.sendPinCode}`,
    payload,
  );
  return res.data;
}


export const getAllNotification = async (tenantId, userId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getAllNotification}?tenantId=${tenantId}&userId=${userId}`
  );
  return res;
};

export const  markNotificationAsSeen = async (notificationId) => {
  const res = await makePrivateRequest.patch(
    `${BASE_URL_SERVICE}${END_POINTS.markNotificationAsSeen}?notificationId=${notificationId}`
  );
  return res;
};

export const  createChatNotification = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.createChatNotification}`, payload
  );
  return res;
};

export const getMessageNotification = async (tenantId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getMessageNotification}?tenantId=${tenantId}`
  );
  return res;
};

export const uploadToFb = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.uploadToFb}`,
    payload
  );
  return res;
};

export const getRemainingTicket = async (tenantId) => {
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getRemainingTicket}?tenantId=${tenantId}`,
  );
  return res;
};

export const deleteEmailIntegration = async (tenantId, shop) => {
  const res = await makePrivateRequest.delete(
    `${BASE_URL_SERVICE}${END_POINTS.deleteEmailIntegration}?tenantId=${tenantId}&shop=${shop}`,
  );
  return res;
};


export const createSender = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.createSender}`,
    payload
  );
  return res;
};


export const verifyPinCode  = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.verifyPinCode}`,
    payload
  );
  return res;
};

export const deleteEmailMessage  = async (messageId) => {
  const res = await makePrivateRequest.delete(
    `${BASE_URL_SERVICE}${END_POINTS.deleteEmailMessage}?messageId=${messageId}`, 
  );
  return res;
};

export const createEmailSignature = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.createEmailSignature}`,payload, 
  );
  return res;
};

export const getFaq = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.getFaq}`,payload, 
  );
  return res;
};

export const getProduct= async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.getProduct}`,payload, 
  );
  return res;
};

export const getCustomerQueryType = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.getCustomerQueryType}`,payload, 
  );
  return res;
};

export const apiTraining = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.trainingApi}`,payload, 
  );
  return res;
};

export const createDomain = async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.createDomain}`,payload, 
  );
  return res;
};

export const verifyDomain= async (payload) => {
  const res = await makePrivateRequest.post(
    `${BASE_URL_SERVICE}${END_POINTS.verifyDomain}`,payload, 
  );
  return res;
};

export const getMLLogs= async ({tenantId , page, limit, store, status, type , startValue, endValue}) => {
  console.log({tenantId , page, limit})
  const res = await makePrivateRequest.get(
    `${BASE_URL_SERVICE}${END_POINTS.getMLLogs}?TenantID=${tenantId}&page=${page}&limit=${limit}&store=${store}&status=${status}&type=${type}&startValue=${startValue}&endValue=${endValue}`, 
  );
  return res;
};


export const resendOtp = async ({ payload }) => {
  const res = await axios.post(
    `${BASE_URL_SERVICE}${END_POINTS.resendOtp}`,
    payload
  );
  return res.data;
};