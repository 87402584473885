import React, { useEffect, useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import DropdownStyles from "../../assets/styledComponents/Dropdown";
import AddIcon from "@mui/icons-material/Add";

import { useLocation, useNavigate } from "react-router-dom";

import { useTenantContext } from "../../context/TenantContext";
import { useInboxContext } from "../../context/inboxContext/inboxContext";

const Dropdown = ({
  iconList,
  name,
  path,
  isActive,
  firstRoute,
  noOfPath,
  collapsableMenu,
  icon,
  settingsPage,
}) => {
  const [showDropdown, setShowDropDown] = useState(false);
  const [dropdownName, setDropdownName] = useState(iconList[0]?.name);
  const { profile,setPreviewActive } = useTenantContext();
  const { clearStoreData, setCurrentSessionData } = useInboxContext();
  const navigate = useNavigate();

  const location = useLocation();
  const pathName = location.pathname.split("/")[noOfPath];
  const currentPath = location.pathname.split("/")[noOfPath - 1];

  useEffect(() => {
    if (path === currentPath) {
      setShowDropDown(true)
    }else if (currentPath=== "" && path=== "universal"){
      setShowDropDown(true)
    }

  },[])

  useEffect(() => {
    if (path === currentPath) {
      setShowDropDown(true);
    }
  }, [pathName]);

  const [previousName, setPreviousName] = useState("");
  const handleClick = (name, path) => {
    if (profile && profile.isMenuDisabled) {
      return;
    }
    setCurrentSessionData({})
    setPreviewActive({
      isActive: false,
      sessionId: "",
    });
    setDropdownName(pathName);
    if (path === "universal") {
      navigate(`/${name?.toLowerCase()}`);
      return;
    }
    if (path) {
      navigate(`${path}/${name?.toLowerCase()}`);
    }
    if (previousName === name) {
      return;
    }
    setPreviousName(name);
    clearStoreData();
  };

  const handleExpandIcon = () => {
    if (profile && profile.isMenuDisabled) {
      return;
    }
    setShowDropDown(!showDropdown);
    if (path === "universal") {
      navigate("/");
      return;
    }
    if (!path) return;
    if (!showDropdown) {
      navigate(`${path}/`);
    }
  };

  return (
    <DropdownStyles settingsPage={settingsPage}>
      <div className={`dropdown-btn ${isActive ? "dropdown-active" : ""}`}>
        <div
          className="name"
          onClick={(e) => {
            e.preventDefault();
            handleClick("", path);
          }}
        >
          {collapsableMenu ? icon : name}
        </div>
        {!collapsableMenu ? (
          <button className="remove-btn" onClick={() => handleExpandIcon()}>
            {showDropdown ? (
              <RemoveIcon fontSize="small" />
            ) : (
              <AddIcon fontSize="small" />
            )}
          </button>
        ) : null}
      </div>
      {!collapsableMenu ? (
        <div className={`${showDropdown ? "show-dropdown" : "hide-dropdown"}`}>
          <div className="list-container">
            {iconList.map(({ icon, name: label, path: name }, idx) => {
              // {
              //   console.log(
              //     ">>>>> ",
              //     pathName === name?.toLowerCase(),
              //     idx,
              //     pathName,
              //     { name }
              //   );
              // }

              if ((name === 'facebook' || name === 'instagram') && process.env.REACT_APP_FB_FLAG=='true') {
                const userEmail = profile?.token?.email ?? ''
                if(userEmail !== process.env.REACT_APP_FB_BYPASS) {
                  return;
                }
              }

            
              return (
                <div
                  className={`items ${
                    isActive &&
                    (pathName === name?.toLowerCase() ||
                      (idx === 0 && pathName === "") ||
                      (pathName === "facebook" &&
                        path === "channelConfiguration" &&
                        idx === 3))
                      ? "active"
                      : ""
                  } `}
                  key={idx}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick(idx === 0 ? "" : name, path);
                  }}
                >
                  <div className="chat-settings-icons">{icon}</div>
                  <div className="name">{label || name}</div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </DropdownStyles>
  );
};

export default Dropdown;
