import React from "react";
import logo from "../assets/images/logo.png";
import styled from "styled-components";

const LogoStyles = styled.div`
  .heading {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-left: 18px;
  }
`;

const Logo = () => {
  return (
    <LogoStyles>
      <div className="heading">
        <h2>HELPIQ</h2>
      </div>
    </LogoStyles>
  );
};

export default Logo;