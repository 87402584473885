import styled from "styled-components";
const MessageInputStyles = styled.div`
  .disabled svg {
    color: silver;
    fill: silver;
  }
  .search-container {
    background-color: #f0f1f6;
    border-radius: 0.5rem;
    border: 1px solid #999999;
    margin: 0 1rem;
    margin-top: 0.5rem;
  }
  .left {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    flex-wrap: wrap;
  }
  .right {
    margin-right: 1rem;
  }
  .icons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 1rem;
    padding-bottom: 1rem;
    color: #666666;
  }
  .private-message-active {
    color: #8364e8;
  }
  .ticket-note-active {
    color: #8364e8;
  }
  .image-container {
    display: flex;
    padding: 0.5rem;
  }
  .link-img-container {
    margin: 0.5rem 1rem 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #d3d3d3;
    border-radius: 1rem;
  }
  .btn-container {
    align-self: flex-start;
  }
  .image-container {
    display: flex;
    /* justify-content: center; */
  }
  .spinner {
    position: relative;
    top: 2rem;
    right: 4rem;
  }

  .attachment-message {
    display: flex;
    background-color: #c3c3c3;
    width: 100%;
    align-items: center;
    border-radius: 5px;
  }
  .loader-icon {
    margin-left: 2rem;
    margin-bottom: 0.7rem;
  }
  .attachment-name {
    padding: 1rem;
  }
  .close-btn {
    margin-left: auto;
    justify-self: flex-end;
  }
  .image-container {
  }
  .image-close-btn {
    position: absolute;
    top: 0.1rem;
    left: 98px;
    background-color: rgba(0, 0, 0, 0.6);
    height: 18px;
    width: 18px;
    border-radius: 2rem;
    color: white;
  }
  .custom-text-field {
    min-height: 80px;
    padding: 1rem;
    outline: none;
  }
  .image-icon,
  .attachment-icon,
  .quick-reply-btn,
  .private-message,
  .smart-toy-btn {
    cursor: pointer;
  }

  .meta-reply-block {
    padding: 2px 20px;
    background: var(--primary-100);
    border-radius: inherit;
  }
  .suggest-reply-loader {
    /* position: absolute;
    top: 1rem;
    left: 50% ;
    transform: translateX(-50%); */
    /* padding-block: 2rem; */
    margin-top: -3.5rem;
    margin-bottom: 2rem;
  }
`;
export default MessageInputStyles;
