import React, { useState } from "react";
import styled from "styled-components";
import { useChannelContext } from "../../../context/channelConfigurationContext/channelContext";

const StyledChatIconContainer = styled.div`
  position: fixed;
  bottom: 1.5rem;
  right: 3rem;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyledChatIcon = styled.div`
  background-color: black;
  color: white;
  cursor: pointer;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${(props) => props.themeColor};
  }
  i {
    font-size: 24px;
  }
`;

const StyledGreetingMsgBubble = styled.div`
  background: white;
  align-items: center;
  display: flex;
  // padding: 0px 15px;
  // height: 50px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  position: relative;
  padding: 10px 15px;
  max-width: 170px;
  height: max-content;
`;

const StyledCloseIcon = styled.span`
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: -10px;
  background: black;
  color: white;
  padding: 6px 8px;
  font-size: 11px;
  border-radius: 50%;
`;

const ChatBubble = () => {
  const { setShowAppearanceScreen, widgetFormattedList, selectedWidgetShop } =
    useChannelContext();

  const selectedWidgetData = widgetFormattedList[selectedWidgetShop];
  const { appearance } = selectedWidgetData || {};

  const { greetingMessage, themeColor } = appearance || {};
  const [showMessage, setShowMessage] = useState(true);
  const handleBubbleClick = () => {
    setShowAppearanceScreen(true);
  };
  return (
    <StyledChatIconContainer id="chat-icon-container">
      <StyledChatIcon
        id="chat-icon"
        className="chat-icon-btn"
        onClick={handleBubbleClick}
        themeColor={themeColor}
      >
        <i className="far fa-comment"></i>
      </StyledChatIcon>
      {showMessage && (
        <StyledGreetingMsgBubble id="greeting-msg-bubble">
          {greetingMessage}
          <StyledCloseIcon
            id="close-icon-bubble"
            onClick={() => setShowMessage(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </StyledCloseIcon>
        </StyledGreetingMsgBubble>
      )}
    </StyledChatIconContainer>
  );
};

export default ChatBubble;
