import React, { useEffect, useState } from "react";
import CustomAvatar from "../reusable/CustomAvatar";
import EmailMessageStyles from "../../assets/styledComponents/inboxMessage/mail/EmailMessage";
import moment from "moment";
import ReplyIcon from '@mui/icons-material/Reply';
import DeleteIcon from '@mui/icons-material/Delete';
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import { capitalizeFirstLetter, filterLines, getImageWithoutAlt, getKey, getTextFromHtml } from "../../utils/helperFunction";
import { deleteEmailMessage } from "../../service/service";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import { useTenantContext } from "../../context/TenantContext";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import HighlightWords from "./HighlightWords";
import ProductMessage from "./ProductMessage";
import EmailProductMessage from "./EmailProductMessage";

const EmailMessage = ({
  isLast,
  _id,
  content,
  createdAt,
  index,
  sender,
  setReply,
  emailSubject,
  emailMessageId,
  highlightReply,
  page,
  setPage,
  showMoreMessage,
  setShowMoreMessage,
  isPrivate,
  taggedUserName,
  image, 
  attachment,
  productDetails,
  emailHtmlContent,
}) => {
  const [showEmailContent, setShowEmailContent] = useState(false);
  const {
    currentSessionData,
    deleteMessage,
    totalMessage,
    normalMessage,
    getChatMessage,
  } = useInboxContext();
  const { userId } = useTenantContext();
  const [backendMsg, setBackendMsg] = useState({ msg: "", type: "error" });
  useEffect(() => {
    if (index === normalMessage.length -1) {
      setShowEmailContent(true);
    }
  }, []);

  function formatTimeWithDifference(inputTime) {
    const currentTime = moment();
    const specifiedTime = moment(inputTime);
    const formattedSpecifiedTime = specifiedTime.format("h:mm A");
    const differenceInMinutes = currentTime.diff(specifiedTime, "minutes");
    const agoString = moment(inputTime).fromNow();
    return `${formattedSpecifiedTime} (${agoString})`;
  }
  const {
    fromEmail,
    fromName,
    toEmail,
    toName,
    _id: sessionId,
    channelName,
  } = currentSessionData || {};
  const agentName = capitalizeFirstLetter(toEmail.split("@")[0]);
  const email = sender === "agent" ? toEmail : fromEmail;
  const emailName = sender === "agent" ? agentName : fromName;

  const handleReply = (e) => {
    e.stopPropagation();
    setReply((prev) => {
      const updatedValue = { ...prev };
      if (updatedValue.emailMessageId === emailMessageId) {
        return { subject: "", emailMessageId: "" };
      }
      return { subject: emailSubject, emailMessageId };
    });
  };
  const handleDelete = async (e) => {
    e.stopPropagation();
    try {
      const result = await deleteEmailMessage(_id);
      deleteMessage(_id);
      setBackendMsg({ type: "success", msg: result.message.message });
    } catch (error) {
      setBackendMsg({
        type: "error",
        msg: error.response.data.message.message,
      });
      console.log(error);
    }
  };

  const messageType = channelName;
  const fetchMoreMessage = () => {
    getChatMessage({ userId, sessionId, page, messageType });
    setPage((prev) => prev + 1);
  };
  const remainingMessage = totalMessage - normalMessage.length;
  return (
    <EmailMessageStyles>
      {backendMsg.msg ? <CustomSnackbar payload={backendMsg} /> : ""}
      <div
        className={`sender-email-container ${isPrivate ? "private-email" : ""}`}
      >
        <div
          className="profile"
          onClick={() => setShowEmailContent((prev) => !prev)}
        >
          <CustomAvatar name={emailName} />
          <div className="name-container">
            <h4 className="name truncate">{email}</h4>
            <p className="email truncate">{emailName}</p>
          </div>
          <div className="subject">
            <b>Subject : </b> 
            <p  className="truncate">{emailSubject}</p>
          </div>
          <div className="date-container">
            <div className="date">{formatTimeWithDifference(createdAt)} </div>
            {showEmailContent && (

              <div className="icons">
                {/* <button
                  onClick={handleReply}
                  className={`${highlightReply ? "isActive" : ""}`}
                >
                  <ReplyIcon />
                </button> */}
                {/* <button onClick={handleDelete}>
                  <DeleteIcon />
                </button> */}
              </div>
            )}
            {isPrivate && (
              <div className="private-note">
                <TextSnippetIcon />
              </div>
            )}
          </div>
        </div>
        {showEmailContent && (
          <div className="email-content">
            {!productDetails ? (
              isPrivate ? (
                <HighlightWords
                  text={content}
                  phrasesToHighlight={taggedUserName}
                />
              ) : ( 
                
                <div>
                  <div className="quil-images">
                  {getImageWithoutAlt(emailHtmlContent).map((item, index) => {
                    return <div key={getKey('email', "image", index)} className="quil-image"  dangerouslySetInnerHTML={{ __html: item?.outerHTML }}></div>  
                  })}
                  </div>
                <div>{content}</div>
                </div>
              )
            ) : (
              <div className="product-message">
                {/* <ProductMessage productDetails={productDetails} /> */}
                <EmailProductMessage productDetails={productDetails} />
                {/* <p className="text-message"> {getTextFromHtml(emailHtmlContent) } </p> */}
                <div>
                  <div className="quil-images">
                  {getImageWithoutAlt(emailHtmlContent).map((item, index) => {
                    return <div key={getKey('email', "image", index)} className="quil-image"  dangerouslySetInnerHTML={{ __html: item?.outerHTML }}></div>  
                  })}
                  </div>
                <div  style={{marginTop : '1rem'}}>{filterLines(content)}</div>
                </div>
                {/* {filterLines(content)} */}
                {/* <div dangerouslySetInnerHTML={{ __html: emailHtmlContent }}></div> */}
              </div>
            )}

            <div className="link-container">
              {image && (
                <a href={image} target="_blank" rel="noreferrer">
                  image
                </a>
              )}
              {attachment && (
                <a href={attachment} target="_blank" rel="noreferrer">
                  Attachment
                </a>
              )}
            </div>
          </div>
        )}
      </div>

      {/* {isLast && !!remainingMessage && <button onClick={fetchMoreMessage} className="remaining-messages">{remainingMessage} Messages</button>} */}
      {isLast && showMoreMessage && normalMessage.length > 2 && (
        <button
          onClick={() => setShowMoreMessage(false)}
          className="remaining-messages"
        >
          {normalMessage.length - 2} Messages
        </button>
      )}
    </EmailMessageStyles>
  );
};

export default EmailMessage;
