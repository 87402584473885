import React from "react";
import styled from "styled-components";

const EmailSignaturePopover = ({ emailSignatures , setText, closePopover}) => {
    const handleClick = (signature) => {
      setText(signature)
      closePopover()
    }
  return (
    <EmailSignaturePopoverStyles>
      {emailSignatures.map((signature) => {
        console.log(signature, "emailSignature");
        return (
          <p onClick={() => handleClick(signature.personalizeSignature)} key={signature?._id} className="signature-name">
            {signature?.signatureName}
          </p>
        );
      })}
    </EmailSignaturePopoverStyles>
  );
};
const EmailSignaturePopoverStyles = styled.div`
  .signature-name {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
  }
`;

export default EmailSignaturePopover;
