import styled from "styled-components";
const CopilotStyles = styled.div`
  .underline {
    border: 0.5px solid #999999;
  }
  .wrapper {
    width: 300px;
    padding-left: 0.5rem;
    padding-right: 0.75rem;
    padding-top: 1rem;
    padding-bottom: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .nav-bar {
    margin-bottom: 0.75rem;
    padding-left: 0.75rem;
    margin-top: 0.25rem;
  }
  .heading-container {
    display: flex;
    justify-content: space-between;
  }
  .heading {
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
  }

  .sub-heading {
    color: #81848a;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }

  // message
  .message-wrapper {
    margin-top: 1.5rem;
  }

  .message-container {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
  }
  .message-box {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom : 1rem;
  }

  .content {
    width: 75%;
    font-size: 14px;
    font-weight: 400;
    margin-top: .75rem;
  }
  .clipboard {
    margin-top: .75rem;
  }

  .copy-btn {
    height: fit-content;
  }
  .bot-icon {
    width: 40px;
  }

  // input
  .input-wrapper {
    margin-top: auto;
  }
  .input-container {
    background-color: rgb(238, 248, 255);
    border-radius: 0.5rem;
    border-right: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5.5rem;
    /* border: 1px solid gray; */
    height: 50px;
    margin-left: .5rem;
  }
  .input {
    margin-left: 1rem;
    outline: none;
    border: none;
    width: 100%;
    background-color: inherit;
  }
  .send-btn {
    /* background-color: #8cb077; */

    padding: 0;
    padding: 1rem;
    border-radius: 0.5rem;
    color: gray;
    cursor: pointer;
  }
  .error {
    color: red;
  }
  .product-container {
    display: flex;
    margin-top: 1.5rem;
  }
`;
export default CopilotStyles;
