import React, { useEffect, useState } from "react";
import { FilterLabelStyles } from "../../../assets/styledComponents/inboxMessage";

import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";

import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Stack,
} from "@mui/material";
import { getLabel } from "../../../service/service";
import { useTenantContext } from "../../../context/TenantContext";
import { getKey } from "../../../utils/helperFunction";
import PrimaryButton from "../../reusable/PrimaryButton/PrimaryButton";
import { useInboxContext } from "../../../context/inboxContext/inboxContext";
import useDebounce from "../../../hooks/useDebounce";
const FilterLabel = ({
  handleClose,
  // label,
  // setLabel,
  filterLabel,
  setFilterLabel,
  actionGroup,
  // loading,
  // setSearchValue,
}) => {
  const { tenantId } = useTenantContext();
  const [searchValue, setSearchValue] = useState("");
  const [masterLabels, setMasterLabels] = useState([]);
  const [loading, setLoading] = useState(false);

  const { labelFilterQuery } = useInboxContext();

  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  
  const [debouncedValue] = useDebounce(searchValue, 500);

  const fetchLabels = async () => {

    if (loading) return;
    setLoading(true);
    try {
      const result = await getLabel(tenantId, searchValue);

      const newMasterLabels = await Promise.all(
        result.data.map((labelData) => {
          console.log(">>> Filter Label", filterLabel, labelData._id);
          
          // const isLabelExists = filterLabel.filter(
          //   (filterLabelData) => filterLabelData._id === labelData._id
          // );

          const isLabelExists = labelFilterQuery.filter((filterLabelItem)=> filterLabelItem === labelData._id)
          return {
            isChecked: isLabelExists.length ? true : false,
            ...labelData,
          };
        })
      );
      setMasterLabels(newMasterLabels);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };

  // useEffect(() => {
  //   fetchLabels();
  // }, []);

  useEffect(() => {
    fetchLabels();
  }, [debouncedValue]);

  const handleChange = (e, _id, labelName, idx) => {

    const newMasterLabels = [...masterLabels];
    newMasterLabels[idx].isChecked = !newMasterLabels[idx].isChecked;
    setMasterLabels(newMasterLabels);

    const newFilterLabel = [...filterLabel];
    const isFilterLabelExists = newFilterLabel.filter(
      (filterDataObj) => filterDataObj._id === _id
    );

    if (isFilterLabelExists.length) {
      setFilterLabel(
        [...filterLabel].filter((filterDataObj) => filterDataObj._id !== _id)
      );
    } else {
      setFilterLabel([
        ...filterLabel,
        {
          labelName,
          _id,
        },
      ]);
    }
  };

 
  const skeleton = (
    <Stack sx={{ width: "99%" }}>
      <div className="label-container">
      {Array(7)
        .fill(0)
        .map((item, idx) => {
          return (
            <Skeleton key={idx} variant="text" sx={{ fontSize: "2.6rem" }} />
          );
        })}
        </div>
    </Stack>
  );

  return (
    <FilterLabelStyles>
      <FormGroup>
        <div className="top-box">
          <h3>Label</h3>

          <div className="search-container">
            <input
              type="search"
              onChange={handleSearchChange}
              placeholder="Search"
            />
            <div className="search-btn">
              <SearchIcon />
            </div>
          </div>
        </div>

        {loading ? (
          skeleton
        ) : (
          <div  className="label-container">
            {masterLabels.map(({ _id, labelName, isChecked }, idx) => {
              return (
                <React.Fragment
                  key={getKey("filterLabelOptions", labelName, idx)}
                >
                  <FormControlLabel
                    style={{ paddingLeft: "0.2rem" }}
                    control={<Checkbox />}
                    label={labelName}
                    onChange={(e) => handleChange(e, _id, labelName, idx)}
                    value={labelName}
                    checked={isChecked}
                  />
                </React.Fragment>
              );
            })}
          </div>
        )}
      </FormGroup>
      <div className="btn-container">{actionGroup}</div>
    </FilterLabelStyles>
  );
};

export default FilterLabel;
