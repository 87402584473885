import React from "react";
import Modal from "../reusable/DialogModal/Modal";
import styled from "styled-components";
import ActionButtonGroup from "../reusable/ActionButtonGroup/ActionButtonGroup";
import FullPageSpinner from "../FullPageSpinner";
import CircularProgress from "@mui/material/CircularProgress";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import SuggestReplyLoading from "../chatBox/SuggestReplyLoading";


const StyledTicketSummaryModal = styled.div`
  width: 600px;
  height:500px;
  position: relative;

  .container {
    padding: 20px;
    height: 430px;
    overflow-y: auto;
  }

  .btn-container {
    position: absolute;
    bottom: 1.5rem;
    right: 2rem;
  }
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;


const TicketSummaryAiModal = ({ open, onClose ,ticketSummary , ticketSummaryLoading,}) => {
  const {setTicketNoteValue, ticketNoteRef } = useInboxContext()

  const handlePrimaryButtonClick = () => {
    setTicketNoteValue(ticketSummary)
    onClose();
    if (ticketNoteRef.current) {
      ticketNoteRef.current.click();
    }
  };

  return (
    <Modal name="Ticket Summary" open={open} onClose={onClose}>
      <StyledTicketSummaryModal>
        {ticketSummaryLoading ? (
          <div className="loader">
            {/* <CircularProgress /> */}
            <div style={{marginBottom : '3rem'}}>
            <SuggestReplyLoading/>
            </div>
          </div>
        ) : (
          <div>
            <div className="container">
              <p>{ticketSummary}</p>
            </div>
            <div className="btn-container">
              <ActionButtonGroup
                primaryButtonLabel={"Copy to Ticket Note"}
                primaryButtonDisable={!ticketSummary}
                hideSecondaryButton={"true"}
                handlePrimaryButtonClick={handlePrimaryButtonClick}
              />
            </div>
          </div>
        )}
      </StyledTicketSummaryModal>
    </Modal>
  );
};

export default TicketSummaryAiModal;
