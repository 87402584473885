import React from "react";
import ShopifyCard from "../ShopifyCard";
import whatsapp2 from "../../../assets/images/whatsapp 2.png";
import PrimaryButton from "../../reusable/PrimaryButton/PrimaryButton";

const WhatsappCard = ({
  index: selectedTabIndex,
  handleAddWhatsappClickInCard,
}) => {
  const data = {
    name: "Whatsapp Setup",
    logo: whatsapp2,
    list: [
      "Engage on the worlds biggest social media platform",
      "work together on conversation in one shared inbox",
      "meet your customers in their moment of need",
    ],
  };

  const handleWhatsAppSetupClick = () => {
    handleAddWhatsappClickInCard(selectedTabIndex);
  };

  const button = (
    <PrimaryButton onClick={handleWhatsAppSetupClick}>
      Add Whatsapp{" "}
    </PrimaryButton>
  );

  return (
    <>
      <ShopifyCard {...data} button={button} />
    </>
  );
};
export default WhatsappCard;
