import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { getKey } from "../../utils/helperFunction";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { makeStyles } from "@mui/styles";
import PrimaryButton from "../reusable/PrimaryButton/PrimaryButton";

const useStyles = makeStyles({
  customCard: {
    border: "1px solid #C2C2C2 !important",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25) !important",
  },
  coloredIcon: {
    fill: "#8CB077 !important",
    marginRight: "15px",
    marginBottom: "8px",
  },
  pricingCardContainer: {
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column", 
  },
});

const pricingCard = ({
  icon,
  description,
  features,
  name,
  price,
  planId,
  handlePlanSelection,
  isPlanActive,
  buttonStatus,
  buttonLoading,
  supportFeatures,
  currentPlanId
}) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.customCard + " " + "card"}
      variant="outlined"
      sx={{
        minWidth: 280,
        maxWidth: 350,
        width: "-webkit-fill-available",
        paddingBottom: "15px",
      }}
    >
      <CardContent>
        <div color="pack-name" style={{ marginBottom: ".5rem" }}>
          <div className="head1">
            <img className="plan-icon" src={icon} />{" "}
            <span className="plan-title">{name}</span>{" "}
          </div>
        </div>
        <div className="plans-desc">{description}</div>
        <div className="plan-price-sec mid3">
          <span className="plan-price">${price} </span>
          <span className="plan-time">per month</span>
        </div>
        <CardActions>
          {isPlanActive ? (
            <PrimaryButton
              variant="contained"
              sx={{
                m: 0.5,
                width: 1,
                marginLeft: -1,
                textTransform: "capitalize",
              }}
              // size="small"
              color={"secondary"}
              disabled={true}
              onClick={() => handlePlanSelection(planId)}
            >
              Active Plan
            </PrimaryButton>
          ) : (
            <PrimaryButton
              variant="contained"
              sx={{
                m: 0.5,
                width: 1,
                marginLeft: -1,
                textTransform: "capitalize",
              }}
              // size="small"
              disabled={ currentPlanId=== planId ?  buttonLoading : false }
              onClick={() => handlePlanSelection(planId, buttonStatus)}
            >
              {buttonStatus || `Try ${name} plan now`}
            </PrimaryButton>
          )}
        </CardActions>
        <Typography sx={{ mb: 1.5, fontWeight: 500 }}>
          {name} includes:
        </Typography>
        <div>
          <div className="return1">
            {features.map((data, index) => {
              return (
                <React.Fragment key={getKey(data, "features", index)}>
                  <div className="pricing-card">
                    <TaskAltIcon className={classes.coloredIcon} />
                    <span>{data}</span>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div style={{ height: 100 }}>
            <div
              style={{
                color: "darkgray",
                fontWeight: 500,
                margin: "5px 0px",
              }}
            >
              Support
            </div>
            {supportFeatures?.map((data, index) => {
              return (
                <React.Fragment key={getKey(data, "supportfeatures", index)}>
                  <div className="pricing-card">
                    <TaskAltIcon className={classes.coloredIcon} />
                    <span>{data}</span>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
export default pricingCard;
