import React, { useContext } from "react";
import Menu from "../components/Menu";
import { Outlet } from "react-router-dom";
import LayoutStyles from "../assets/styledComponents/Layout";
import { TenantContext } from "../context/TenantContext";
import FullPageSpinner from "../components/FullPageSpinner";

const SharedLayout = () => {
  const { profile, isTokenLoading } = useContext(TenantContext);

  return !profile || !profile.token || isTokenLoading ? (
    <FullPageSpinner />
  ) : (
    <LayoutStyles classname="sharedLayout">
      <Menu />
      <div id="shared-outlet">
        <Outlet />
      </div>
    </LayoutStyles>
  );
};

export default SharedLayout;
