import {
  Button,
  TextField,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useTenantContext } from "../../context/TenantContext";

const useStyles = makeStyles({
  customDialog: {
    borderRadius: 14,
  },
  underline: {
    borderTop: "1px solid",
    borderColor: "#999999",
    height: "2px",
    margin: "10px 0px",
  },
  text1: {
    fontWeight: 500,
  },
  text2: {
    color: "#81848A",
    fontSize: "14px",
    paddingTop: "6px",
  },
  head1: {
    fontWeight: 500,
    fontSize: 18,
    paddingBottom: "6px",
  },
  button: {
    color: "white !important",
  },
});

const ShopifyIntegrationDialog = ({ open, handleCloseModal }) => {
  const {tenantId} = useTenantContext()
  const [store, setStore] = useState("");
  const [storeError, setStoreError] = useState('')
  const classes = useStyles();

  const handleChange = (e) => {
    if(!e.target.value) {
      setStoreError('Please enter a store')
    }else {
      setStoreError('')
    }
    setStore(e.target.value);
  };

  const handleClose = () => {
    handleCloseModal();
    setStore("");
  };

  const onButtonClick = () => {
    if (!store) {
      setStoreError('Please enter a store')
      return
    }
    setStoreError('')
    // eslint-disable-next-line no-undef
    window.location = `${process.env.REACT_APP_BACKEND_APP_URL}/api/auth?shop=${store}&tenantId=${tenantId}`;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.customDialog}
    >
      <DialogTitle id="alert-dialog-title">
        {"Shopify Integration"}
        <span
          style={{ float: "right", cursor: "pointer" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </span>
        <div className={classes.underline}></div>
      </DialogTitle>
      <DialogContent>
        <div>
          <div className={classes.text1}>New Shopify Integration</div>
          <div className={classes.text2}>
            Connect your Shopify store to HelpIQ. This will allow us to show
            customer and order info within the tool
          </div>
          <br />

          <div className={classes.text1}>How To Proceed</div>
          <div className={classes.text2}>
            Install the HelpIQ app from the Shopify App Store
          </div>
{/* 
          <div className={classes.head1}>Shopify Store URL</div>
          <div style={{ width: "300px" }}>
            <TextField
              id="store"
              name="store"
              label=""
              variant="outlined"
              placeholder="mystore.myshopify.com"
              onChange={(e) => handleChange(e)}
              fullWidth
              error={!!storeError}
              helperText={storeError}
            />
          </div> */}

          <br />
          {/* <Button
            className={classes.button}
            variant="contained"
            onClick={onButtonClick}
          >
            Connect
          </Button> */}

          <Button
            className={classes.button}
            variant="contained"
            onClick={handleClose}
          >
            Ok
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ShopifyIntegrationDialog;
