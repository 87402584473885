import styled from "styled-components";
const EmailConfigurationStyles = styled.div`
  /* max-height: 700px; */
  .container {
    margin-left: 2.5rem;
  }
  .wrapper {
    overflow-y: auto;
    height: 78vh;
    padding-bottom: 1rem;
  }
  .store-tab-container {
    position: sticky;
  }
  .email-heading {
    margin-top: 20px;
  }

  .copy-button {
    background-color: var(--secondary-500);
  }
  .send-pincode {
    background-color: #e5c100;
  }
  .activate {
    background-color: #ff6666;
  }

  .input-box {
    display: flex;
    gap: 1.25rem;
    margin-top: 1.25rem;
  }
  .customize-form {
    margin-top: 2rem;

  }
  .verification-input-box {
    height: 55px;
    display: flex;
    gap: 20px;
  }
  .pin-box {
    margin-top: 1.25rem;
  }
  .pin-code-verification {
    margin-top: 1.25rem;
  }
  .customize-form {
  }
  .heading {
    margin-top: 1.5rem;
  }
  .sub-heading {
    margin-top: 1rem;
  }
  .domain-name li {
    margin-top: 0.5rem;
  }
  .enter-pin-description {
    margin-top: .5rem;
    width: 500px;
  }
  .delete-btn {
    margin-top: 2rem;
  }
  .form-container {
  }
  .label {
    margin-top: 2rem;
  }
  .clipboard-icon {
    color: var(--primary-500);
    font-size: 1.5rem;
  }

  .dns-header {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 1rem;
  }
  .dns-sub-heading {
   padding: 2rem 0rem 0.5rem;
    margin-inline: auto;
  }
  .dns-settings {
    width: 90%;
    margin-top: 2rem;
    margin-left: .1rem;
    position: relative;
  }
  .dns-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .verify-container {
    display: flex;
    align-items: center;
    gap: .2rem;
  }
`;
export default EmailConfigurationStyles;
