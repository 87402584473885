import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import Tooltip from "@mui/material/Tooltip";

import CustomAvatar from "../reusable/CustomAvatar";
import ClipboardButton from "../reusable/ClipboardButton";
import Typing from "../reusable/Typing";
import { Avatar } from "@mui/material";
import { useTenantContext } from "../../context/TenantContext";

const MessageBox = ({success, sender, content, name }) => {
  const {profile} = useTenantContext()
  let AvatarImage = sender === "agent" ? "hu" : "ai";
  return (
    <div className="message-wrapper">
      <div className="message-container">
        {sender === "agent" ? (
        !profile?.token?.profileImageUrl ? (
          <Avatar>{profile?.token?.name[0].toUpperCase()}</Avatar>
        ) : (
          <Avatar src={profile?.token?.profileImageUrl}></Avatar>
        )
        ) : (
          <div className="bot-icon">
            <SmartToyIcon  sx={{ color: "#8DB178", fontSize : '43px' }} />
          </div>
        )}
        <p className={`${success ? "success" : "error"} content`}>
          {content === '' ? <Typing/> : content }
        </p>
        {sender === "bot" && success && content && <div className="clipboard">
            <ClipboardButton text={content} />
        </div> }

      </div>
    </div>
  );
};

export default MessageBox;
