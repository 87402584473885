import React, { useEffect, useRef, useState } from "react";
import styled from 'styled-components'
import ReactQuill, { Quill } from "react-quill";

import { getAllUserSetup } from "../../../service/service";
import PrivateEmailPopover from "./PrivateEmailPopover";
import { useTenantContext } from "../../../context/TenantContext";
import { useInboxContext } from "../../../context/inboxContext/inboxContext";

const EmailEditor = ({
  text,
  setText,
  height,
  isSmall,
  setCursorPosition,
  isMessagePrivate,
  taggedUserDetails,
  setTaggedUserDetails,
  insertText,
  
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [userNames, setUserNames] = useState([]);
  const [mentionIndex, setMentionIndex] = useState(-1);

  const { tenantId, userId } = useTenantContext();
  const {quillRef} = useInboxContext()


  const [position, setPosition] = useState(0);
  const [plainText, setPlainText] = useState("");
  const handleChange = (value, delta, source, editor) => {
    setText(value);
    const plainText = editor.getText(value);

    detectOpenTag(plainText);
    setPlainText(plainText);
    const currentPosition = getCurrentCursorPosition();
    setCursorPosition(currentPosition);
    setPosition(currentPosition);
  };

  const fetchUserName = async () => {
    try {
      const result = await getAllUserSetup({ tenantId });
      setUserNames(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const detectOpenTag = (text) => {
    const atIndex = text.lastIndexOf("@");
    if (atIndex !== -1) {
      const lastWord = text.substring(atIndex - 1);
      if ((lastWord[0] === "@" || lastWord[0] === " ") && mentionIndex >= 0) {
        setOpenModal(true);
        console.log("now open the model");
        // return true
      } else {
        console.log("now close the model");
        setOpenModal(false);
        // return false
      }
    }
  };

  useEffect(() => {
    fetchUserName();
  }, []);
  const handleKeyDown = (e) => {
    // if (disabled) return;

    if (e.key === "@") {
      // setOpenModal(true);
      setMentionIndex(plainText.length);
    }
    if (e.key === "Enter") {
      e.preventDefault();
      // handleSubmit();
    }
  };

  const getCurrentCursorPosition = () => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      if (quill) {
        const range = quill.getSelection();
        if (range) {
          return range.index;
        }
      }
    }
    return 0; // Return -1 if cursor position cannot be determined
  };

  return (
    <EmailEditorStyles height={height} isSmall={isSmall}>
      {isMessagePrivate && openModal && (
        <PrivateEmailPopover
          userList={userNames}
          text={plainText}
          setTaggedUserDetails={setTaggedUserDetails}
          setOpenModal={setOpenModal}
          mentionIndex={mentionIndex}
          setText={setText}
          setMentionIndex={setMentionIndex}
          userId={userId}
          quillRef={quillRef}
          position={position}
        />
      )}
      <ReactQuill
        theme="snow"
        value={text}
        ref={quillRef}
        onChange={handleChange}
        className="custom-quill-editor"
        onKeyDown={handleKeyDown}
        modules={{
          "emoji-toolbar": true,
          // toolbar: false,
          toolbar: [
            ["emoji"],
          ],
        }}
      />
    </EmailEditorStyles>
  );
};

export default EmailEditor;

const EmailEditorStyles = styled.div`
height: 100%;
/* background-color: firebrick; */
img {
  width: 100px !important;
  height: 100px;
}
.ql-container.ql-snow{
    border: none;
    font-size: 15px;
    /* line-height: 2rem; */
}
.custom-quill-editor {
  width: 100%;
  height: ${(props) => props.height};
  /* height: 100%; */
  border: none;
  border-top: none;
}
.ql-toolbar .ql-snow {
  /* display: none; */
  border: none;
}

.ql-toolbar.ql-snow {
  height: 0;
  border: none;
  padding: 0;
}
.ql-emoji {
  position: absolute;
  bottom: ${(props) => props.isSmall ? '2rem' : '1rem'};
  left: ${(props) => props.isSmall ? '1.2rem' : '.25rem'};
}

.ql-snow.ql-toolbar button svg {
  width: 25px;
  height: 25px;
  /* stroke: gray; */
}
.ql-snow .ql-stroke {
  stroke: #666666 !important;
stroke-width: 1.5;
}
.ql-snow .ql-fill {
  fill: #666666 !important;
}
#emoji-palette {
  top: -94px !important;
  left: -253px !important;
}
`