import styled from "styled-components";
const LayoutStyles = styled.div`
  display: flex;
  width: 100% !important;

  #shared-outlet {
    /* width: 100%; */
    flex: 1;
  }
`;
export default LayoutStyles;
