import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  customButton: {
    color: "white !important",
  },
});

/**
 *
 * @param {ButtonProps} rest - rest props
 * @returns {JSX.Element}
 */

const PrimaryButton = ({
  children,
  onClick,
  variant = "contained",
  type,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Button
      {...rest}
      type={type}
      className={classes.customButton}
      onClick={onClick}
      variant={variant}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
