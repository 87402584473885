import React, { useEffect, useState } from "react";
import BusinessHoursStyles from "../../assets/styledComponents/settings/BusinessHours";
import { Button } from "@mui/material";
import HeaderStyles from "../../assets/styledComponents/Header";
import CustomInputDropDown from "../../components/CustomInputDropDown";
import LanguageIcon from "@mui/icons-material/Language";
import CustomSwitch from "../../components/CustomSwitch";
import TimeRangePicker from "../../components/TimeRangePicker";
import { openingHoursData } from "../../utils/helperData";
import AddIcon from "@mui/icons-material/Add";
import { createBusinessHours, getBusinessHours } from "../../service/service";
import { useTenantContext } from "../../context/TenantContext";
import CustomSnackbar from "../../components/snackbar/CustomSnackbar";
import PrimaryButton from "../../components/reusable/PrimaryButton/PrimaryButton";
import FullPageSpinner from "../../components/FullPageSpinner";
import { TIME_ZONES } from "../../utils/constants";


const selectList = TIME_ZONES;
let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
let locale = Intl.DateTimeFormat().resolvedOptions().locale;
const isTimeZone = selectList.find((item) => item.value === timeZone);
timeZone = isTimeZone ? isTimeZone.value : 'Asia/Kolkata' 
locale = isTimeZone ? isTimeZone.locale: 'en-US' 


const BusinessHours = () => {
  const { userId, tenantId } = useTenantContext();
  const [backendMessage, setBackendMessage] = useState({ msg: "", type: "" });
  const [timezone, setTimezone] = useState({
    location:timeZone,
    locale ,
  });
  const [location , setLocation] = useState(timeZone)
  const [loading, setLoading] = useState(true);
  const [originalBusinessHoursData, setOriginalBusinessHoursData] = useState(
    []
  );
  const [originalTimezone, setOriginalTimezone] = useState('')
  const [update, setUpdate] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  let startTime = new Date();
  startTime.setHours(9, 0, 0, 0);
  startTime = startTime.toLocaleTimeString(timezone?.locale, { timeZone : timezone?.location, hour12: true });
  let endTime = new Date();
  endTime.setHours(18, 0, 0, 0);
  endTime = endTime.toLocaleTimeString(timezone?.locale, { timeZone :  timezone?.location, hour12: true });

const openingHoursData = [
  {
    id: 1,
    day: "Monday",
    isOpen: true,
    time: [{ startTime , endTime }],
  },
  {
    id: 2,
    day: "Tuesday",
    isOpen: true,

    time: [{ startTime, endTime }],
  },
  {
    id: 3,
    day: "Wednesday",
    isOpen: true,
    time: [{ startTime, endTime }],
  },
  {
    id: 4,
    day: "Thursday",
    isOpen: true,
    time: [{ startTime, endTime }],
  },
  {
    id: 5,
    day: "Friday",
    isOpen: true,
    time: [{ startTime, endTime }],
    startTime,
    endTime,
  },
  {
    id: 6,
    day: "Saturday",
    isOpen: false,
    time: [{ startTime, endTime }],
    startTime,
    endTime,
  },
  {
    id: 7,
    day: "Sunday",
    isOpen: false,
    time: [{ startTime, endTime }],
    startTime,
    endTime,
  },
];
  const handleChange = (e) => {
    setLocation(e.target.value)
    let timezone = selectList.find((item) => item.value === e.target.value)
    setTimezone({
      location : timezone.value,
      locale : timezone.locale
    })
    setDisableBtn(false);
  };
  const [hours, setHours] = useState([...openingHoursData]);

  const fetchBusinessHours = async () => {
    setLoading(true);
    try {
      const result = await getBusinessHours(tenantId);
      let data = result.data[0]?.openingHours;

      if (!result.data.length) {
          setDisableBtn(false)
      }
      if (!data) {
        setLoading(false);
        return
      }
      const deepCopy = structuredClone(data);
      setLoading(false);
      setOriginalBusinessHoursData(deepCopy);
      setOriginalTimezone(result?.data[0]?.timezone)
      setHours([...data]);

      let timezoneFromDb = result?.data[0]?.timezone
      let localeFromDb = selectList.find((item) => item.value === timezoneFromDb)
      setTimezone({
        location : timezoneFromDb, 
        locale : localeFromDb.locale || 'en-US', 

      });
      setLocation(timezoneFromDb)
      setUpdate(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBusinessHours();
  }, []);

  const handleTimeChange = (time, index, timeIndex, timeType) => {
    setHours((prev) => {
      const updatedHours = [...prev];
      if (timeType === "startTime") {
        let formattedTime = new Date(time);
        formattedTime = formattedTime.toLocaleTimeString(timezone.locale, {
          hour12: true,
        });
        updatedHours[index].time[timeIndex].startTime = formattedTime;
      } else {
        let formattedTime = new Date(time);
        formattedTime = formattedTime.toLocaleTimeString(timezone.locale, {
          hour12: true,
        });
        updatedHours[index].time[timeIndex].endTime = formattedTime;

      }
      return updatedHours;
    });
    setDisableBtn(false);
  };


  const handleToggleSwitch = (e, index) => {
    setHours((prev) => {
      const updatedSwitch = [...prev];
      updatedSwitch[index].isOpen = e.target.checked;
      return updatedSwitch;
    });
    setDisableBtn(false);
  };

  const handleAddHours = (e, index) => {
    setHours((prev) => {
      const updatedTime = [...prev];
      updatedTime[index].time.push({ startTime, endTime });
      return updatedTime;
    });
    setDisableBtn(false);
  };
  const handleRemoveHours = (index, timeIndex) => {
    setHours((prev) => {
      const updatedHours = [...prev];
      updatedHours[index].time.splice(timeIndex, 1);
      return updatedHours;
    });
    setDisableBtn(false);
  };
  const handleDiscard = () => {
    if (!originalBusinessHoursData.length) {
      setHours([...openingHoursData]);
      setLoading(false);
      setDisableBtn(true);
      setBackendMessage({msg : 'Your changes have been discarded', type : 'success'})
      return;
    }

    const deepCopy = structuredClone(originalBusinessHoursData);
    setHours(deepCopy);
    setLocation(originalTimezone)
    setLoading(false);
    setDisableBtn(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      userId,
      tenantId,
      timezone: typeof timezone === "object" ?  timezone.location : timezone,
      openingHours: hours,
    };
    setDisableBtn(true);
    try {
      const result = await createBusinessHours(payload);
      setBackendMessage({ msg: result.message, type: "success" });
      let data = result.data.openingHours;
      const deepCopy = structuredClone(data);
      setOriginalBusinessHoursData(deepCopy);
      setOriginalTimezone(result?.data?.timezone)
      setUpdate(true);
      setDisableBtn(true);
    } catch (error) {
      setBackendMessage({ msg: error.response.data, type: "error" });
      setDisableBtn(false);
    }
  };
  return (
    <BusinessHoursStyles>
      {backendMessage.msg && (
        <CustomSnackbar
          payload={backendMessage}
          setPayload={setBackendMessage}
        />
      )}
      {loading ? (
        <FullPageSpinner />
      ) : (
        <div>
          <HeaderStyles>
          <div className="top">
            <div className="heading">
              <h3 className="top-heading">Business Hours</h3>
              <p className="sub-heading">
                Let me Know your customers when you&apos;re online
              </p>
            </div>
          </div>
          <div className="underline"></div>
          </HeaderStyles>
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="time-zone">
                <h3>Time Zone</h3>
                <CustomInputDropDown
                  icon={<LanguageIcon />}
                  label={"UTC"}
                  selectList={selectList}
                  value={location}
                  handleChange={handleChange}
                  required={true}
                />
              </div>
              <div className="set-standard-hours">
                <h3>Set Standard Hours</h3>
                {hours.map((item, index) => {
                  const { id, day, isOpen } = item;
                  return (
                    <div className="week-container" key={id}>
                      <p className="day">{day}</p>
                      <div>
                        <CustomSwitch
                          checked={isOpen}
                          label={isOpen ? "Open" : "Closed"}
                          handleToggleSwitch={handleToggleSwitch}
                          index={index}
                        />
                      </div>
                      <div>
                        {isOpen && (
                          <div className="time-input">
                            <TimeRangePicker
                              {...item}
                              index={index}
                              handleTimeChange={handleTimeChange}
                              handleAddHours={handleAddHours}
                              handleRemoveHours={handleRemoveHours}
                              timezone={timezone.location}
                            />
                          </div>
                        )}
                      </div>
                      {/* {isOpen && (
                    <div className="add-btn-container">
                      <button
                        className="add-btn"
                        onClick={(e) => handleAddHours(e, index)}
                      >
                      <AddIcon fontSize="small" />
                      </button>
                      <div className="name">Add</div>
                      </div>
                    )} */}
                    </div>
                  );
                })}
                <div className="button-container">
                  <Button variant="outlined" onClick={handleDiscard}>
                    Discard Changes
                  </Button>
                  <PrimaryButton
                    variant="contained"
                    disabled={disableBtn}
                    type="submit"
                    // onClick={handleSubmit}
                  >
                    {update ? "Update" : "save"}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </BusinessHoursStyles>
  );
};

export default BusinessHours;
