import { Button, Popover, PopoverProps } from "@mui/material";
import React from "react";
import { useInboxContext } from "../../context/inboxContext/inboxContext";

/**
 *
 * @param {PopoverProps} rest - rest props
 * @returns {JSX.Element}
 */
const CustomPopover = ({
  children,
  button,
  transformOrigin,
  getHandleClose,
  disabled=false,
  buttonRef,
  ...rest
}) => {
  // const {ticketNoteRef} = useInboxContext()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const popoverClose = () => {
    setAnchorEl(null)
  }

  const openPopover = (target) => {
    setAnchorEl(target)
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <button ref={buttonRef} aria-describedby={id} onClick={handleClick} disabled={disabled}>
        {button}
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        {...rest}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        openPopover={openPopover} 
        transformOrigin={transformOrigin}
      >
        {children}
      </Popover>
    </div>
  );
};

export default CustomPopover;
