import React from "react";
import ReportStyles from "../../assets/styledComponents/Report";
import CustomInputDropDown from "../CustomInputDropDown";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

const selectAverageList = [
  { name: "Average First response time" },
  { name: "Average resolution time" },
  { name: "Average response until resolution" },
];
const ChannelsList = [
  { name: "All" },
  { name: "Chat" },
  { name: "Instagram" },
  { name: "Whatsapp" },
  { name: "Facebook" },
  { name: "Email" },
];

const ReportDropdown = ({
  name,
  labelName,
  handleChange,
  resolutionFilterValue,
  channelFilterValue,
  userFilterValue,
  labelFilterValue,
}) => {
  console.log(name);
  return (
    <ReportStyles className="paper-card">
      <div className="dropdown">
        <div className="Average-dropdown">
          <CustomInputDropDown
            label={"Average first response time"}
            selectList={selectAverageList}
            handleChange={(e) => handleChange(e, "Average resolution time")}
            value={resolutionFilterValue}
            id="average-filter"
          />
        </div>
        <div className="channel-dropdown">
          <CustomInputDropDown
            label={"Channels"}
            selectList={ChannelsList}
            handleChange={(e) => handleChange(e)}
            value={channelFilterValue}
            id="channel-filter"
          />
        </div>
        <div className="user-dropdown">
          <CustomInputDropDown
            icon={<PeopleAltIcon />}
            label={"Users"}
            selectList={name}
            handleChange={(e) => handleChange(e)}
            value={userFilterValue}
            id="user-filter"
          />
        </div>
        <div className="label-dropdown">
          <CustomInputDropDown
            icon={<LabelOutlinedIcon />}
            label={"Labels"}
            selectList={labelName}
            handleChange={handleChange}
            value={labelFilterValue}
            id="label-filter"
          />
        </div>
      </div>
    </ReportStyles>
  );
};

export default ReportDropdown;
