export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT";
export const BEGIN_SET_CURRENT_CHAT = "BEGIN_SET_CURRENT_CHAT";
export const GET_CHAT_MESSAGE = "GET_CHAT_MESSAGE";
export const BEGIN_GET_CHAT_MESSAGE = "BEGIN_GET_CHAT_MESSAGE";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SET_MESSAGE_READ = "SET_MESSAGE_READ";
export const SET_SINGLE_MESSAGE_READ = "SET_SINGLE_MESSAGE_READ";
export const SET_NORMAL_MESSAGE = "SET_NORMAL_MESSAGE";
export const SET_IMAGE = "SET_IMAGE";
export const REMOVE_IMAGE = "REMOVE_IMAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE"