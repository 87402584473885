import jwt_decode from "jwt-decode";
import moment from "moment";
import { fetchWhatsappConfig, subscribe } from "../service/service";
import axios from "axios";
import { useTenantContext } from "../context/TenantContext";
import DOMPurify from "dompurify";
import he from "he";

export const getKey = (name, itemName, index) => {
  return `${name}_${itemName}_${index}`;
};

export const decodeJwt = (token) => {
  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }
  return decodedToken;
};

export const getTime = (date) => {
  // format 1:15 PM
  return moment(date).format("LT");
};
export const getFullDate = (date) => {
  // format 02 August 2023, 7:49 pm
  return moment(date).format("DD MMMM  YYYY, h:mm a");
};

export const getFullShortDate = (date) => {
  // format Oct 18, 11:39 AM
  return moment(date).format("MMM DD, hh:mm A");
};

export const getLastMessageTime = (date) => {
  return moment(date).startOf().fromNow();
};

export function stringAvatar(name) {
  return {
    children: `${name.split(" ")[0][0]}`,
  };
}

export const getFilename = (url, channelName) => {
  console.log({channelName})
  if (channelName === 'facebook') {
const { pathname } = new URL(url);
const pathSegments = pathname.split('/');
const fileNameWithExtension = pathSegments[pathSegments.length - 1];
const fileExtension = ".pdf";
const fileName = fileNameWithExtension.replace(fileExtension, '');
return [fileName, fileExtension]
  }
  const regex = /\/(\d+\.)?([^/]+)\.([a-zA-Z0-9]+)$/;
  const matches = url.match(regex);
  if (matches) {
    const filename = matches[2];
    const extension = matches[3];
    return [filename, extension];
  }
};

export const checkTimeInRange = async (currentTime, timeRange) => {
  try {
    const isTimeTrue = timeRange.map((time) => {
      const { startTime, endTime } = time;
      const startTimeObj = new Date(startTime);
      const endTimeObj = new Date(endTime);
      const checkTimeObj = new Date(currentTime);
      const startTimeInMinutes =
        startTimeObj.getUTCHours() * 60 + startTimeObj.getUTCMinutes();
      const endTimeInMinutes =
        endTimeObj.getUTCHours() * 60 + endTimeObj.getUTCMinutes();
      const checkTimeInMinutes =
        checkTimeObj.getUTCHours() * 60 + checkTimeObj.getUTCMinutes();

      return (
        startTimeInMinutes <= checkTimeInMinutes &&
        checkTimeInMinutes <= endTimeInMinutes
      );
    });

    return isTimeTrue.includes(true);
  } catch (error) {
    throw new Error("Invalid time format");
  }
};

export const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const getBrowserName = () => {
  const userAgent = window.navigator.userAgent;
  console.log(userAgent);
  let browserName;
  if (userAgent.indexOf("Chrome") !== -1) {
    browserName = "Google Chrome";
  } else if (userAgent.indexOf("Firefox") !== -1) {
    browserName = "Mozilla Firefox";
  } else if (userAgent.indexOf("Safari") !== -1) {
    browserName = "Apple Safari";
  } else if (
    userAgent.indexOf("MSIE") !== -1 ||
    userAgent.indexOf("Trident/") !== -1
  ) {
    browserName = "Internet Explorer";
  } else {
    browserName = "Unknown Browser";
  }
  return browserName;
};

export const regSw = async () => {
  try {
    if ("serviceWorker" in navigator) {
      // navigator.serviceWorker.register("/service-worker.js");
      const registration = await navigator.serviceWorker.register(
        "/service-worker.js",
        { scope: "/" }
      );
      console.log("Service Worker Registered", registration);
      return registration;
    }
  } catch (error) {
    throw Error(error);
  }
};

export const subscribePush = async (serviceWorkerReg, userId, tenantId) => {
  // eslint-disable-next-line no-undef
  const publicVapidKey = process.env.REACT_APP_NOTIFICATION_PUBLIC_KEY;
  let subscription = await serviceWorkerReg.pushManager.getSubscription();
  const browserName = getBrowserName();
  if (subscription === null) {
    subscription = await serviceWorkerReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
    });
  }
  await subscribe({ subscription, browserName }, userId, tenantId);
};

export const uploadToFb = async (
  src,
  tenantId,
  whatsappPhoneId,
  whatsappSecureKey
) => {
  const whatsappConfigFromDB = await fetchWhatsappConfig(
    tenantId,
    whatsappPhoneId
  );
  const whatsappData = whatsappConfigFromDB?.data?.filter((item) => {
    return item.phoneId === whatsappPhoneId;
  });
  const { accessToken, phoneId } = whatsappData?.[0] || {};
  const formData = new FormData();
  formData.append("messaging_product", "whatsapp");
  formData.append("file", src);
  try {
    const result = await axios.post(
      `https://graph.facebook.com/${"v17.0"}/${phoneId}/media`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          // eslint-disable-next-line no-undef
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result.data.id;
  } catch (error) {
    console.log(error);
  }
};

export const convertQuillToText = (content) => {
  let text = "";
  content.ops.forEach((op) => {
    if (op.insert) {
      if (typeof op.insert === "string") {
        text += op.insert;
      } else if (
        op.insert &&
        Object.prototype.hasOwnProperty.call(op.insert, "emoji")
      ) {
        text += op.insert.emoji;
      }
    }

    if (op.attributes) {
      if (op.attributes.bold) {
        text = `**${text}**`;
      }

      if (op.attributes.italic) {
        text = `*${text}*`;
      }

      if (op.attributes.underline) {
        text = `<u>${text}</u>`;
      }
    }

    if (op.insert === "\n") {
      text += "\n";
    }
  });
  return text;
};

export const convertHtmlToQuill = (html) => {
  const quillContent = { ops: [] };

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const body = doc.body;

  function traverse(node) {
    const ops = [];

    if (node.nodeType === 3) {
      // Text node
      ops.push({ insert: node.textContent });
    } else if (node.nodeType === 1) {
      // Element node
      const insert = {};
      if (node.nodeName.toLowerCase() === "u") {
        insert.underline = true;
      } else if (node.nodeName.toLowerCase() === "b") {
        insert.bold = true;
      } else if (node.nodeName.toLowerCase() === "i") {
        insert.italic = true;
      }

      ops.push({ insert });

      for (const childNode of node.childNodes) {
        ops.push(...traverse(childNode));
      }
    }

    return ops;
  }

  quillContent.ops = traverse(body);
  return quillContent;
};

export const convertTimeToDay = (timeZone, time) => {
  
  // const currentDateTime = timeZone ? moment().tz(timeZone) : moment();
  const currentDateTime =  moment()
  const formattedDateTime = currentDateTime.set({
    hour: moment(time, "h:mm A").hour(),
    minute: moment(time, "h:mm A").minute(),
    second: moment(time, "h:mm A").second(),
  });
  const resultDate = formattedDateTime.toDate();
  return resultDate;
};
export const getDate = (date) => {
  const momentDate = moment(date);
  return momentDate.format("DD MMM");
};

export const fullDate = (date) => {
  const formattedDate = moment(date).format('D, MMM DD h:mm A');
  return formattedDate
}

export const htmlToPlainText = (html) => {
  const sanitizedHtml = DOMPurify.sanitize(html);

  const decodedHtml = he.decode(sanitizedHtml);

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = decodedHtml;

  const paragraphs = tempDiv.querySelectorAll("p");
  paragraphs.forEach((paragraph) => {
    paragraph.insertAdjacentText("beforeend", "\n");
  });

  const plainText = tempDiv.textContent || tempDiv.innerText;

  tempDiv.remove();

  return plainText;
};
export const isEmpty = (obj) => Object.values(obj).length === 0;

export const formatFileSize = (sizeInBytes) => {
  const KB = 1024;
  const MB = KB * 1024;
  const GB = MB * 1024;

  if (sizeInBytes < KB) {
    return sizeInBytes + " B";
  } else if (sizeInBytes < MB) {
    return (sizeInBytes / KB).toFixed(2) + " KB";
  } else if (sizeInBytes < GB) {
    return (sizeInBytes / MB).toFixed(2) + " MB";
  } else {
    return (sizeInBytes / GB).toFixed(2) + " GB";
  }
};

// export const inputChangerForQuickReply = (text, quickReplyOption) => {
//   return text.replace(/{{(.*?)}}/g, (match, p1) => {
//     const quickReplyText = quickReplyOption[p1];
//     if (quickReplyText) {
//       return `<strong>${quickReplyText}</strong>`;
//     } else {
//       return match;
//     }
//   });
// };

export const inputChangerForQuickReply = (text, quickReplyOption) => {
  return text.replace(/{{(.*?)}}/g, (match, p1) => {
    const quickReplyText = quickReplyOption[p1];
    return `<strong>${quickReplyText || match}</strong>`;
  });
};
// export const inputChangerForQuickReply = (text, quickReplyOption) => {
//   return text.replace(/{{(.*?)}}/g, (match, p1) => {
//     const quickReplyText = quickReplyOption[p1];
//     return quickReplyText ? `<strong>${quickReplyText}</strong>` : "";
//   });
// };

export const capitalizeFirstLetter = (string) =>  {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export const findSubStrings = ({ text, substrings }) => {
  if (!substrings) return 
  let indices = [];
  substrings.map((substring) => {
    let startIndex = 0; // Reset startIndex for each substring
    while (startIndex !== -1) {
      startIndex = text.indexOf(substring, startIndex);
      if (startIndex !== -1) {
        const endIndex = startIndex + substring.length ;
        indices.push({ startIndex, endIndex });
        startIndex += substring.length;
      }
    }
  });
  return indices;
};

export const escapeTemplateVariables = (text) => {
  const regex = /(\{\{.*?\}\})/g;
  return text.replace(regex, (match) => ` {% verbatim %} ${match} {% endverbatim %}`);
};

export const closingEmailMessage = ({ ticketId, name }) => {
  return `
  <div style="font-family: Arial, sans-serif; line-height: 1.6; background-color: #f4f4f4; margin: 0; padding: 0;">
    <div style="margin: 20px auto; padding: 20px; background-color: #fff; border-radius: 8px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);">
        <div style="background-color: #007bff; color: #fff; padding: 10px; text-align: center; border-top-left-radius: 8px; border-top-right-radius: 8px;">
            <h2>Your Chat Inquiry has been Resolved</h2>
        </div>
        <div style="padding: 20px;">
            <p>Dear ${name},</p>
            <p>We're pleased to inform you that your chat inquiry has been successfully resolved. Your Ticket ID for this conversation is ${ticketId}.</p>
            <p>If you have any further questions or concerns, please don't hesitate to reach out to us. We're here to assist you.</p>
            <p>Thank you for choosing our service.</p>
        </div>
        <div style="margin-top: 20px; text-align: center;">
            <p>Best Regards,</p>
            <p>HelpIQ</p>
        </div>
    </div>
</div>
  `;
};

export const extractProductDetails = (html) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;
  const productImgElement = tempElement.querySelector('img[alt="product"]');
  const productTitleElement = tempElement.querySelector("em p:nth-of-type(2)");
  const productPriceElement = tempElement.querySelector("em p:nth-of-type(3)");
  const productWeightElement = tempElement.querySelector("em p:nth-of-type(4)");
  const productLinkElement = tempElement.querySelector("em p:nth-of-type(5)");
  console.log({
    productImgElement,
    productTitleElement,
    productPriceElement,
    productWeightElement,
    productLinkElement,
  });
  const pTags = tempElement.querySelectorAll("p");

  const productImgLink = productImgElement.getAttribute("src");
  let obj = {
    title: "",
    productUrl: "",
    imageUrl: productImgLink,
    grams: "",
    weightUnit: "",
    price: "",
  };

  pTags.forEach((pTag) => {
    if (pTag.querySelector("strong")) {
      const pTagContent = pTag.textContent;
      // const obj  = JSON.parse(pTagContent);
      // console.log({productObj : obj})
      let key = pTagContent.split(":")[0];
      let value = pTagContent.split(":")[1];

      if (key.trim() === "Name") {
        obj = { ...obj, title: value };
      }
      if (key === "Product") {
        let value = pTagContent.split(":")[1] + ":" + pTagContent.split(":")[2];
        obj = { ...obj, productUrl: value };
      }
      if (key === "Image Url") {
        obj = { ...obj, imageUrl: value };
      }
      if (key === "weight") {
        obj = { ...obj, grams: value };
      }
      if (key === "Price") {
        obj = { ...obj, price: value };
      }

      console.log({ productPTag: pTagContent, key, value });
    }
  });

  return {
    imageLinkDetails: {
      ...obj,
    },
  };
};


export const getTextFromHtml = htmlString => {

  console.log(">>> html String--", htmlString);

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  
  let combinedText = '';
  const paragraphs = doc.querySelectorAll('p');

  paragraphs.forEach(p => {
    if (!p.querySelector('*')) {
      combinedText += p.textContent.trim() + ' ';
    }
  });


  console.log(">>> html String- 2-", combinedText.trim());

  return combinedText.trim();
};

export function filterLines(input ) {
let prefixesToExclude = ['Name:', 'Price:', 'Weight:', 'Product:'];
    // Split the input by new lines
 let lines = input.split('\n');

    // Filter out lines that start with any of the prefixes in prefixesToExclude
    let filteredLines = lines.filter(line => {
        for (let prefix of prefixesToExclude) {
            if (line.trim().startsWith(prefix)) {
                return false;
            }
        }
        return true;
    });
    console.log({filteredLines})

    // Join the remaining lines back into a single string
    let result = filteredLines.filter(item => item !== '').join('\n');
    return result;
}


export const formatNumberWithCommas = (number) => {
    return Number(number).toLocaleString();
}

export function extractTextFromHtml(html) {
  // Create a new DOM parser
  const parser = new DOMParser();
  
  // Parse the HTML string into a document
  const doc = parser.parseFromString(html, 'text/html');
  
  // Extract the text content of the document
  const text = doc.body.textContent || "";
  
  return text.trim();
}

export const getImageWithoutAlt = (html) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;
  const imagesWithoutAlt = tempElement.querySelectorAll('img:not([alt])');
  imagesWithoutAlt.forEach(img => {
    img.style.maxWidth = '200px';
    img.style.maxHeight = '200px';
  });
  
  // Convert NodeList to an array if needed
  console.log(Array.from(imagesWithoutAlt)[0], 'finde')
  return Array.from(imagesWithoutAlt);
};