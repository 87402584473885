import React, { useState, useEffect } from "react";
import HeaderBar from "../../reusable/HeaderBar/HeaderBar";
import InstagramCard from "./InstagramCard";
import { Tab, Tabs } from "@mui/material";
import { deleteFacebookIntegration, getAllTenantStore, getInstagramAccountIsConnected, saveFacebookPage } from "../../../service/service";
import { useTenantContext } from "../../../context/TenantContext";
import MetaConfigurationStyles from "../../../assets/styledComponents/settings/MetaConfiguration";
import { useInboxContext } from "../../../context/inboxContext/inboxContext";
import CustomSnackbar from "../../snackbar/CustomSnackbar";
import FullPageSpinner from "../../FullPageSpinner";
import _ from "lodash";
import Swal from "sweetalert2";

const MetaConfiguration = () => {
  const { tenantId, userId, profile } = useTenantContext();
  const { selectedFacebookPage, metaConfValue, setMetaConfValue } =
    useInboxContext();
  const [storeTabs, setStoreTabs] = useState([]);
  const [originalStoreTabs, setOriginalStoreTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeShop, setActiveShop] = useState("");
  const [isPageRegistered, setIsPageRegistered] = useState(false);
  const [savedTabExist, setSavedTabExist] = useState(false);
  const [facebookPageExist, setFacebookPageExist] = useState(false)
  const [pageLoading, setPageLoading] = useState(false);
  const [backendMessage, setBackendMessage] = useState({
    msg: "",
    type: "error",
  });
  const [isDataEqual, setIsDataEqual] = useState(true);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const setCurrentTabValue = (storeTabs, activeTab) => {
    const activeTabValue = storeTabs?.[activeTab];
    console.log({ activeTabValue });
    const activePage = activeTabValue?.activePage;
    const facebookPages = activeTabValue?.facebookPages;
    const instagramPages = activeTabValue?.facebookPages;
    const selectedInstagramPage = activePage?.instagramPage?.name;
    const selectedFacebookPage = activePage?.facebookPage?.name;
    const selectedInstagramPageId  = activeTabValue?.instagramPageId 
    const { instagramPageName, comments, directMessages } = activePage || {};
    console.log({ activeTabValue: facebookPages });
    const metaConfVal = {
      instagramPageName: instagramPageName || "",
      instagramPages: instagramPages,
      selectedInstagramPage,
      facebookPages: facebookPages,
      selectedFacebookPage : selectedFacebookPage || '',
      comments,
      directMessages,
      selectedInstagramPageId , 
    };
    setMetaConfValue(metaConfVal);
  };

  const fetchStores = async () => {
    console.log('fetching stores');
    if (!tenantId) return;
    setPageLoading(true);
    try {
      const tabsRes = await getAllTenantStore(tenantId);
      console.log('fetching stores', tabsRes);
      if (tabsRes.success) {
        const originalData = structuredClone(tabsRes.data);
        setStoreTabs(tabsRes.data);
        setOriginalStoreTabs(originalData);
        setActiveShop(tabsRes.data[0]?.shop);
        setIsPageRegistered(tabsRes.data[0]?.facebookPages?.length > 0);
        setActiveTab(0);
        setPageLoading(false);
        setSavedTabExist(tabsRes.data[0]?.activePage);
        setFacebookPageExist(tabsRes.data[0]?.facebookPages?.length > 0)
        setCurrentTabValue(tabsRes.data, 0);
        return tabsRes.data;
      }
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchStores();
  }, []);

  useEffect(() => {
    if (storeTabs.length === 0) return;
    setIsDataEqual(storeTabs?.[activeTab]?.activePage?.name);
    setCurrentTabValue(storeTabs, activeTab);
  }, [activeTab]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setActiveShop(storeTabs[newValue]?.shop);
    setSavedTabExist(storeTabs[newValue]?.activePage);
    setFacebookPageExist(storeTabs[newValue]?.facebookPages?.length > 0)
    setIsPageRegistered(storeTabs[newValue]?.facebookPages?.length > 0);
    setIsDataEqual(_.isEqual(storeTabs[newValue], originalStoreTabs[newValue]));
    setCurrentTabValue(storeTabs, newValue);
  };
  const handleSave = async () => {
    setDisableSaveBtn(true);
    let page = storeTabs[activeTab]?.facebookPages.filter((item) => {
      return item.name === selectedFacebookPage;
    });
    const selectedPage = metaConfValue?.selectedFacebookPage
    const filterFacebookId = metaConfValue?.facebookPages?.filter((item) => {
      return item.name === selectedPage
    })  
    const pageId = filterFacebookId[0]?.id
    const instagramPage = await getInstagramAccountIsConnected(pageId, activeShop)
    let instagramPageId ;
    if (instagramPage.data){
      instagramPageId = instagramPage.data.id
      console.log(instagramPage.data.id)
    }else {
      instagramPageId  = ''
      console.log('no id found')
    } 
    try {
     const res = await saveFacebookPage({
        tenantId,
        shop: activeShop,
        activePageValue: metaConfValue,
      });
      setStoreTabs((prev) => {
        const updatedValue = [...prev];
        updatedValue[activeTab] = res.data;
        return updatedValue;
      });
      setMetaConfValue((prev) => {
        let updatedValue = {...prev};
        updatedValue.selectedInstagramPageId  = instagramPageId
        return updatedValue

      })
      setDisableSaveBtn(false);
      setIsDataEqual(true);
      setSavedTabExist(true);
      setBackendMessage({ msg: res.message, type: "success" });
    } catch (error) {
      let errorMessage = error.response.data.message
      setBackendMessage({ msg: errorMessage, type: "error" });
      Swal.fire({
        title: errorMessage,
        text: `The Selected facebook page is already connected please choose another one`,
        icon: "error",
        confirmButtonColor: "#8db178",
      });
      setDisableSaveBtn(false);
    }
  };
  const handleDelete = async() => {
    console.log({tenantId,shop : storeTabs[activeTab]?.shop})
    const shop =  storeTabs[activeTab]?.shop
    try {
      const result = await Swal.fire({
        title: "Are you sure ?",
        text: "Upon deleting the Facebook & Instagram account, all associated chats will be permanently removed ",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#8db178",
        cancelButtonColor: "#D9D9D9",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        const res= await deleteFacebookIntegration({tenantId, shop , userId});
        fetchStores();
        if (res.success) {
          Swal.fire({
            title: "Deleted!",
            text: `Successfully deleted the Facebook & Instagram account from HelpIQ. You will no longer receive its messages in HelpIQ.`,
            confirmButtonColor: "#8db178",
            icon: "success",
          });
        }
      }
    } catch (error) {
      console.log(error)
      Swal.fire({
        title: "Error",
        text: "An error occurred while deleting the account. Please contact admin at support@helpiq.app.",
        icon: "error",
        confirmButtonColor: "#8db178",
      });
    }

  }
  // const { search } = useLocation();
  // const params = new URLSearchParams(search);
  // const tabNumber = params.get('tabNumber');
  // useEffect(() => {
  //   if (tabNumber) {
  //   handleTabChange(null, parseInt(tabNumber)) 
  //   }
  // }, [])
  // console.log({activeTab})

  const userEmail = profile?.token?.email ?? ''

  return (
    <MetaConfigurationStyles>
      <>
        {backendMessage.msg && <CustomSnackbar payload={backendMessage} />}
        {pageLoading ? (
          <FullPageSpinner />
        ) : (
          <div>
            <HeaderBar
              title={"Facebook/Instagram Configuration"}
              hideSecondaryButton={true}
              primaryButtonLabel={!savedTabExist ? "save" : "update"}
              handlePrimaryButtonClick={handleSave}
              hidePrimaryButton={!facebookPageExist}
              primaryButtonDisable={disableSaveBtn}
            />

              {process.env.REACT_APP_FB_FLAG == 'true' && userEmail !== process.env.REACT_APP_FB_BYPASS ? (
                <div style={{ margin: '20px 40px' }}>
                  <h3 style={{ color: "var(--primary-800)", marginBottom: "10px" }}>Facebook Support Coming Soon to HelpIQ!</h3>
                  <span style={{ color: "darkslategray", marginTop: "4px" }}>Manage Facebook customer interactions directly from your Shopify admin. Stay tuned for seamless integration and enhanced customer support!</span>
                </div>
              )
                :
                <>
                  <div className="store-tab-container">
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      {storeTabs.map((tabData) => (
                        <Tab
                          label={`${tabData.shop}  ${tabData.isDefault ? "- Default" : ""
                            }`}
                          key={`${tabData.shop}  ${tabData.isDefault ? "- Default" : ""
                            }`}
                        />
                      ))}
                    </Tabs>
                  </div>
                  <InstagramCard
                    shop={activeShop}
                    tabValue={storeTabs[activeTab]}
                    isPageRegistered={isPageRegistered}
                    setDisableSaveBtn={setDisableSaveBtn}
                    stateValues={{
                      storeTabs,
                      setStoreTabs,
                      originalStoreTabs,
                      setOriginalStoreTabs,
                      setIsDataEqual,
                      activeTab,
                      handleDelete,
                    }}
                  />
                </>}
          </div>
        )}
      </>
    </MetaConfigurationStyles>
  );
};
export default MetaConfiguration;
