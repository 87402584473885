
import React, { useState } from "react";
import PrimaryButton from "../../components/reusable/PrimaryButton/PrimaryButton";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import { useTenantContext } from "../../context/TenantContext";
import { sendWhatsappMessage } from "../../service/service";
import CircularProgress from '@mui/material/CircularProgress';
import styled from "styled-components";
const productHtml = ({productLink, src, title, currency, price, grams, weight_unit}) => {
  return `<span>
  <img class="product-img" src=${src} alt="product" width="100px" height="100px" style="border-radius: 5px 5px 0px 0px; width: 100px; height: 100px;"><br>
  <p  class="product-title" style=margin:2px 0px; font-size: 16px; font-weight: 500; color: dimgray;><b>Name:</b> ${title}</p>
  <p class="product-price" style=margin:2px 0px; font-size: 14px;><span><b>Price: </b>  ${price}</span></p>
  <p class="product-link" style=margin:2px 0px; font-size: 14px;><span><b>Product: </b> ${productLink}</span></p><br>
  </span>
  `
}
{/* <p class="product-weight" style=margin:2px 0px; font-size: 14px;><span><b>Weight: </b>${grams} ${weight_unit}</span></p> */}

const CopilotProductList = (props) => {
  const {image, price, id , grams , quantity, title, handle} = props;
  const { socket, sendMessages, sendImageLink, sessionId, currentSessionData, normalMessage , setText, setIsProductAdded} =
    useInboxContext();
const {selectedShop}  = useTenantContext()
  const [addToChatLoading, setAddToChatLoading] = useState(false)
  const { channelName, status } = currentSessionData || {};
  let disableBtn = status !== 'progress'
  const productLink = `https://${selectedShop}/products/${handle}/?variant=${id}`;
  const { userId, tenantId } = useTenantContext();
  const handleAddToChat = async () => {
    setAddToChatLoading(true)
    try {
    setAddToChatLoading(true)
    let payload = {
       userId,
      tenantId,
      sessionId,
      channelName,
      imageLinkDetails: {
        title,
        productUrl: productLink,
        imageUrl: image,
        grams,
        // weightUnit: weight_unit,
        price: ` ${price}`,
      },     
    }
    if (channelName === "mail") {
      let productText  = productHtml({
        productLink,
        src: image,
        title,
        price,
        grams,
        // weight_unit
      })
      setText((prev) => prev + productText);
      setIsProductAdded(true)
    }
    // const result = await sendImageLink(payload);
    setAddToChatLoading(false)
    // console.log({find : result.data})
    // sendMessages(result.data);
    } catch (error) {
    setAddToChatLoading(false)
      console.log(error)
    }
  };

  return (
  <ProductLink href={productLink} target="_blank" rel="noopener noreferrer">
      <ProductImage src={image} alt="" />
      <ProductDetails>
        <ProductName>{title}</ProductName>
        <ProductPrice>Price : {price}</ProductPrice>
      </ProductDetails>
    </ProductLink>
  );
};
const ProductLink = styled.a`
  width: 135px;
  text-decoration: none;
  color: black;
  display: block;
  background: #dbdbdb;
  border-radius: 1rem;
`;

const ProductImage = styled.img`
  height: 140px;
  width: 135px;
  border-radius: 12px 12px 0px 0px;
  border: 0.5px solid silver;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1rem;
  border: 0.5px solid silver;
  border-top: none;
  border-radius: 0px 0px 15px 15px;
`;

const ProductName = styled.h3`
  text-align: center;
  font-size: 14px;
  margin: 0px;
`;

const ProductPrice = styled.div`
  font-weight: bold;
`;
export default CopilotProductList ;
