import React from "react";
import MessageViewStyles from "./MessageViewStyle";
import { Avatar } from "@mui/material";
import MessageImage from "../../chatBox/MessageImage";
import TicketNote from "../../message/TicketNote";
import DoneIcon from "@mui/icons-material/Done";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MessageView = ({
  msg,
  time,
  avatarSrc,
  sender = false,
  textColor = "black",
  bgColor = "white",
  image,
  _id,
  name,
  showSenderIcon = true
}) => {
  return (
    <MessageViewStyles textColor={textColor} bgColor={bgColor}>
      {/* {!sender && <Avatar src={avatarSrc} sx={{ float: "left" }} />} */}

      <div
        className={`txt-container-msg ${sender ? "sender-container" : ""} ${
          image ? "ticket-note" : ""
        } `}
      >
        {image && (
          <div className="image-container">
            <MessageImage width={200} height={150} imageSrc={image} id={_id} />
          </div>
        )}
        {msg && <p className="msg">{msg}
        </p>}
        {name && <p className="user-name">Note by {name}</p>}
        <div className="time">
          {time}
          {showSenderIcon ? sender ? (
            <DoneIcon
              sx={{ fontSize: "15px", marginLeft: "5px" }}
              fontSize="inherit"
            />
          ) : (
            <FontAwesomeIcon
              style={{ marginLeft: "5px", color: "#03A9F4" }}
              icon="fa-solid fa-check-double"
            />
          ) : null}
        </div>
        

      </div>
    </MessageViewStyles>
  );
};

export default MessageView;
