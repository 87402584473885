import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const CustomSnackbar = ({ payload, setPayload, duration }) => {
  let { msg, type } = payload;
  const [open, setOpen] = useState(true);
  useEffect(() => {
    if (payload) {
      setOpen(true);
    }
  }, [payload]);

  const handleClose = (e) => {
    if (e === null || e._reactName === "onClick") {
      setOpen(false);
      if (setPayload) {
        setPayload({ msg: "", type: "" });

      }
      return;
    }
    if (setPayload) {
      setPayload({ msg: "", type: "" });

    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration ? duration : "10000"}
      onClose={handleClose}
    >
      <MuiAlert severity={type} onClose={handleClose} sx={{ width: "100%" }}>
        {msg}
      </MuiAlert>
    </Snackbar>
  );
};
export default CustomSnackbar;

CustomSnackbar.propTypes = {
  payload: PropTypes.object,
  type: PropTypes.string,
};
