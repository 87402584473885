import {
  CREATE_CHAT_WIDGET_BEGIN,
  CREATE_CHAT_WIDGET_ERROR,
  CREATE_CHAT_WIDGET_SUCCESS,
  GET_CHAT_WIDGET_ERROR,
  GET_CHAT_WIDGET_SUCCESS,
  SET_APPEARANCE,
  SET_CONTACT_FORM,
  SET_IS_QUERY_ADDED,
  SET_ORDER_STATUS,
  SHOW_ORDER_STATUS,
  UPLOAD_ICON_BEGIN,
  UPLOAD_ICON_ERROR,
  UPLOAD_ICON_SUCCESS,
} from "./channelAction";
import _ from "lodash";

export const channelReducer = (state, action) => {
  console.log(action.type);

  if (action.type === CREATE_CHAT_WIDGET_BEGIN) {
    return {
      ...state,
      disableSaveBtn: true,
    };
  }
  if (action.type === CREATE_CHAT_WIDGET_SUCCESS) {
    const { message, data } = action.payload;
    const { scriptUrl, _doc } = data;
    const { contactForm, appearance, orderStatus } = data;
    const url = scriptUrl ? scriptUrl : _doc.scriptUrl;
    return {
      ...state,
      appearanceData: appearance,
      contactFormValueData: contactForm,
      orderStatusData: orderStatus,
      responseMessage: { msg: message, type: "success" },
      disableSaveBtn: true,
      scriptUrl: url,
    };
  }
  if (action.type === CREATE_CHAT_WIDGET_ERROR) {
    const { data } = action.payload;
    return {
      ...state,
      responseMessage: { msg: data, type: "error" },
      disableSaveBtn: false,
    };
  }
  if (action.type === SET_APPEARANCE) {
    const { isGradient } = action.payload;
    console.log(
      _.isEqual(state.appearanceData, {
        ...state.appearance,
        ...action.payload,
      })
    );
    const saveBtnValue = _.isEqual(state.appearanceData, {
      ...state.appearance,
      ...action.payload,
    });
    return {
      ...state,
      appearance: { ...state.appearance, ...action.payload },
      disableSaveBtn: saveBtnValue,
    };
  }
  if (action.type === SET_CONTACT_FORM) {
    let saveBtnValue = _.isEqual(state.contactFormValueData, {
      ...state.contactFormValue,
      ...action.payload,
    });
    return {
      ...state,
      contactFormValue: { ...state.contactFormValue, ...action.payload },
      disableSaveBtn: saveBtnValue,
    };
  }
  if (action.type === SET_IS_QUERY_ADDED) {
    return {
      ...state,
      isQueryAdded: true,
      disableSaveBtn: false,
    };
  }
  if (action.type === SET_ORDER_STATUS) {
    const saveBtnValue = _.isEqual(state.orderStatusData, {
      ...state.orderStatus,
      ...action.payload,
    });
    return {
      ...state,
      orderStatus: { ...state.orderStatus, ...action.payload },
      disableSaveBtn: saveBtnValue,
    };
  }
  if (action.type === SHOW_ORDER_STATUS) {
    return {
      ...state,
      showOrderStatus: action.payload,
    };
  }

  if (action.type === GET_CHAT_WIDGET_SUCCESS) {
    const { success, data } = action.payload;
    if (data === null) {
      return {
        ...state,
        isLoading: false,
      };
    }
    const { contactForm, appearance, orderStatus, scriptUrl } = data;
    if (!success) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      scriptUrl,
      appearanceData: appearance,
      contactFormValueData: contactForm,
      orderStatusData: orderStatus,
      appearance,
      contactFormValue: contactForm,
      orderStatus,
      isLoading: false,

      disableSaveBtn: true,
    };
  }

  if (action.type === GET_CHAT_WIDGET_ERROR) {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (action.type === UPLOAD_ICON_BEGIN) {
    return {
      ...state,
      disableSaveBtn: true,
    };
  }
  if (action.type === UPLOAD_ICON_SUCCESS) {
    return {
      ...state,
      appearance: {
        ...state.appearance,
        icon: action.payload,
      },
      disableSaveBtn: false,
    };
  }
  if (action.type === UPLOAD_ICON_ERROR) {
    return {
      ...state,
      responseMessage: { msg: action.payload, type: "error" },
      disableSaveBtn: false,
    };
  }
  throw new Error(`no such action : ${action.type}`);
};
