import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ChromePicker } from "react-color";
import ColorizeIcon from "@mui/icons-material/Colorize";
import { InputAdornment, Popover, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  customTextField: {
    borderRadius: "5px",
  },
});

const ColorPickerStyles = styled.div`
  .color-picker {
    display: flex;
    position: relative;
    width: 100%;
  }
  .color-container {
    z-index: 1000;
    margin-left: -1rem;
    border-radius: 0.5rem;
    width: 60px;
    height: 56px;
    background-color: ${(props) => props.themeColor};
    display: grid;
    place-items: center;
    cursor: pointer;
  }
  .color-picker-container {
    position: absolute;
    top: 0;
    right: 0;
  }
  .text-color {
    background-color: var(--primary-700);
  }
`;

const ColorPicker = ({
  onValueChange,
  handleColorChange,
  color,
  width,
  value,
  name,
  isTextColor,
  penColor = "white",
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isValidHex, setIsValidHex] = useState(false);
  const [errorValue, setErrorValue] = useState("");

  useEffect(() => {
    const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    setIsValidHex(!hexColorRegex.test(value));
    if (!hexColorRegex.test(value)) {
      setErrorValue("Please enter a valid hex value");
    } else {
      setErrorValue("");
    }
  }, [value]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <ColorPickerStyles themeColor={color}>
      <div className="color-picker">
        <TextField
          className={classes.customTextField}
          error={isValidHex}
          helperText={errorValue}
          fullWidth
          name={name}
          value={value}
          required
          onChange={onValueChange}
          sx={{
            "& .MuiInputBase-root:not(.MuiInputBase-multiline)": {
              boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
            },
            width,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <div
                  className={`${
                    isTextColor
                      ? "text-color color-container"
                      : "color-container"
                  }`}
                  onClick={handleClick}
                >
                  <ColorizeIcon sx={{ color: penColor }} />
                </div>
              </InputAdornment>
            ),
          }}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          <ChromePicker color={color} onChange={handleColorChange} />
        </Popover>
      </div>
    </ColorPickerStyles>
  );
};

export default ColorPicker;
