import styled from "styled-components";
const FaQStyles = styled.div`
  .faq-training .table {
    padding-top: 70px;
    padding-left: 40px;
    width: -webkit-fill-available
  }

  .faq-training .table-container {
    max-height: 70vh;
  }
  .store-tab-container {
    margin-left: 30px;
  }


  .delete-icon {
    cursor: pointer;
  }
  .paper-card {
    padding: 20px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background: white !important;
    padding-bottom: 25px;
    margin-top: 20px;
    width: -webkit-fill-available;
  }
  .accordion-container {
    height: 78vh;
    overflow-y: auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
`;
export default FaQStyles;
