
import styled from "styled-components";
import Modal from "../../../components/reusable/DialogModal/Modal";
import QuickReplyModal from "../../../components/inboxMessage/QuickReplyModal";
import { Chip, CircularProgress, Popover, Tooltip } from "@mui/material";
import React, { useState } from "react";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import AttachmentIcon from "@mui/icons-material/Attachment";
import QuickreplyOutlinedIcon from "@mui/icons-material/QuickreplyOutlined";
import SendIcon from "@mui/icons-material/Send";

import CustomPopover from "../../../components/reusable/CustomPopover";
import EmojiPicker from "emoji-picker-react";

import { useRef } from "react";
import useFile from "../../../hooks/useFile";
import { uploadToS3 } from "../../../utils/uploadImageToS3";

import { getSuggestReply } from "../../../service/service";
import { useTenantContext } from "../../../context/TenantContext";
import { useInboxContext } from "../../../context/inboxContext/inboxContext";
import Swal from "sweetalert2";
import { useEffect } from "react";
import useAttachmentUpload from "../../../components/chatBox/useAttachmentUpload";
import UploadPreviewChip from "./UploadPreviewChip";
import CreateIcon from '@mui/icons-material/Create';
import EmailSignaturePopover from "./EmailSignaturePopover";


const InputToolbar = ({
  text,
  setText,
  cursorPosition,
  setCursorPosition,
  disabled,
  inputRef,
  handleSubmit,
  isMessagePrivate,
  setIsMessagePrivate,
  isButtonLoading,
  setIsButtonLoading,
  attachmentDetails , 
  setAttachmentDetails,
  imageDetails, 
  setImageDetails,
  emailSignatures,
}) => {
  

  const attachmentSize = 5 * 1024 * 1024;
  const imageRef = useRef()
  const [
    attachmentRef,
    handleAttachmentClick,
    handleAttachmentChange,
    attachmentFile,
    attachmentFileDetails,
    resetFileValue,
  ] = useFile(attachmentSize);

  const [uploadFile, attachmentLoading] = useAttachmentUpload();
  const [imageLoading, setImageLoading] = useState(false)

  let attachmentFileName = attachmentFileDetails?.fileName? attachmentFileDetails?.fileName:  attachmentDetails.url

  

  const { tenantId, userId } = useTenantContext()
  const { normalMessage, currentSessionData} = useInboxContext()

  const {assignTo, taggedUser} = currentSessionData || {}
  
  const isNotAssignedUser =
    userId === assignTo ? false : taggedUser?.includes(userId);
   const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleEmojiSelect = (emoji) => {
    if (disabled) return;
    const startPos = cursorPosition;
    const endPos = cursorPosition;

    let newText = text.replaceAll('<p>', '');
    newText = newText.replaceAll('</p>', '');
    const separator = '\u200D';

    newText = newText.slice(0, startPos) + separator + emoji.emoji + separator + newText.slice(endPos);
    setText(`<p>${newText}</p>`);
    setCursorPosition(cursorPosition + 3)

    setTimeout(() => {
      inputRef?.current?.focus();
    }, 0);
  };
  console.log({emailSignatures})

  const [quickReplyOpen, setQuickReplyOpen] = useState(false);
  const handleQuickReply = () => {
    if (disabled) return;
    setQuickReplyOpen(true);
  };

  const handlePrivateBtn = (e) => {
    if (disabled) return;
    setIsMessagePrivate((prev) => !prev);
  };

  const handleImage = () => {
    imageRef.current.click();
  }
  const handleImageChange = async (e) => {
    try {
      setImageLoading(true)
      const selectedFile = e.target.files[0];
      const fileName = selectedFile.name;
      const imageURL = URL.createObjectURL(selectedFile);
      setImageDetails({name : fileName})
      let uploadedImage = await uploadToS3(selectedFile);
      // setText((prev) => prev + `<img width=100px ; height=100px; src=${uploadedImage} />`);
      setImageDetails({name : fileName, url : uploadedImage})
      setImageLoading(false)
    } catch (error) {
      console.log(error);
      setImageLoading(false)
    }
  };
  const uploadAttachment = async() => {
    if (attachmentFile) {
      setAttachmentDetails((prev) => ({...prev, loading: true, name : attachmentFileDetails.fileName}) )
      let uploadedValue = await uploadFile(attachmentFile, resetFileValue);
      console.log({uploadedValue})
      setAttachmentDetails({url : uploadedValue,name : attachmentFileDetails.fileName  })
    }    
  }

  useEffect(() => {
    if (attachmentFile !== null) {
        uploadAttachment();
    }
  },[attachmentFile])

  const handleSuggestReply = async (e) => {
    if (disabled) return;
    const userMessage = normalMessage.filter((item) => {
      if (item?.sender === "user") {
        return item;
      }
    })
    const lastUserMessage = userMessage?.[0]?.content
    console.log({ lastUserMessage })
    try {
      setIsButtonLoading(true);
      const result = await getSuggestReply({
        tenantid: tenantId,
        PreviousResponse: lastUserMessage,
      }, process.env.REACT_APP_AI_API_KEY)
      if (result.Status === 202) {
        const suggestedReplyResponse = result.Response_text.replace(/\n/g, ' ');
        setText(suggestedReplyResponse);
        setIsButtonLoading(false);

      }
      if (result.Status === 404) {
        Swal.fire({
          title: "Error!",
          text: 'The AI Token allocated for this month has been utilized',
          icon: "error",
          confirmButtonColor: "#d33",
        });
        setIsButtonLoading(false);

      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: 'Something went wrong',
        icon: "error",
        confirmButtonColor: "#d33",
      });
      console.log(error)
      setIsButtonLoading(false);
    }
  };

  const handleAttachmentDelete =() => {
    resetFileValue()
    setAttachmentDetails({url : '', name : ''})
  }

  const handleImageDelete =() => {
    setImageDetails({url : '', name : ''})
  }
  useEffect(() => {
    if (isMessagePrivate) {
     handleAttachmentDelete() 
     handleImageDelete()
    }
  }, [isMessagePrivate])

  return (
    <InputToolbarStyles>
      <div className="icons-container">
        <div className="left">
          {/* <CustomPopover
            button={
              <Tooltip title="Emoji" placement="top">
                <InsertEmoticonIcon />
              </Tooltip>
            }
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <EmojiPicker onEmojiClick={handleEmojiSelect} />
          </CustomPopover> */}
          <input
            type="file"
            //   disabled={disabled}
            accept=".png, .jpeg, .jpg .gif"
            ref={imageRef}
            style={{ display: "none" }}
            onChange={handleImageChange}
          />

          {!isMessagePrivate && (
            <Tooltip title="Image" placement="bottom">
              <button onClick={handleImage} className="image-icon">
                <ImageOutlinedIcon />
              </button>
            </Tooltip>
          )}
          <input
            type="file"
            ref={attachmentRef}
            accept=".pdf"
            //   disabled={disabled}
            style={{ display: "none" }}
            onChange={handleAttachmentChange}
          />

          {!isMessagePrivate && (
            <Tooltip title="Attachment" placement="bottom">
              <button
                onClick={handleAttachmentClick}
                className="attachment-icon"
              >
                <AttachmentIcon />
              </button>
            </Tooltip>
          )}

          <Modal
            name="Code Snippet"
            open={quickReplyOpen}
            onClose={() => setQuickReplyOpen(false)}
          >
            <QuickReplyModal
              setText={setText}
              setQuickReplyOpen={setQuickReplyOpen}
            />
          </Modal>

          {!isMessagePrivate && (
            <Tooltip title="Quick Reply" placement="bottom">
              <button className="quick-reply-btn" onClick={handleQuickReply}>
                <QuickreplyOutlinedIcon />
              </button>
            </Tooltip>
          )}

          {/* <Tooltip title="Private Note" placement="bottom">
            <button
              className={`private-message ${
                isMessagePrivate ? "private-message-active" : ""
              }`}
              onClick={handlePrivateBtn}
            >
              <TextSnippetIcon />
            </button>
          </Tooltip> */}

          {!isMessagePrivate && (
            <Tooltip title="Suggest Reply" placement="bottom">
              <button className="smart-toy-btn" onClick={handleSuggestReply}>
                <SmartToyIcon />
              </button>
            </Tooltip>
          )}
          <div className="attachment-container">
          {  attachmentFileName  && (
            <UploadPreviewChip
              name={attachmentFileName  }
              handleImageDelete={handleAttachmentDelete}
              loading={attachmentLoading}
            />
          )}

          {imageDetails.name && (
            <UploadPreviewChip
              name={imageDetails.name}
              handleImageDelete={handleImageDelete}
              loading={imageLoading}
            />
          )}
          </div>
          {/* <Tooltip title="Email Signature" placement="top">
            <button onClick={handleClick}>
              <CreateIcon />
            </button>
          </Tooltip> */}
        {/* 
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <EmailSignaturePopover
              setText={setText}
              emailSignatures={emailSignatures}
              closePopover={handleClose}
            />
          </Popover> */}
        </div>
        <div className="right">
          {isButtonLoading ? (
            <CircularProgress disableShrink size={20} />
          ) : (
            <button
              onClick={() =>
                handleSubmit([handleAttachmentDelete, handleImageDelete])
              }
              disabled={attachmentLoading || imageLoading}
            >
              <SendIcon style={{ color: "#8db178" }} />
            </button>
          )}
        </div>
      </div>
    </InputToolbarStyles>
  );
};

const InputToolbarStyles = styled.div`
  .icons-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .left {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-left: 2.2rem;
  }
  .attachment-container {
    display: flex;
    gap: 10px;
    /* width: 200px;
    flex-wrap: wrap; */
  }

  .private-message-active {
    color: #8364e8;
  }
`;

export default InputToolbar;
