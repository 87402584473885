import styled from "styled-components";
const BusinessHoursStyles = styled.div`
  .container {
    padding-top: 2rem;
    height: 87vh;
    overflow-y: auto;
    padding-bottom: 3rem;
    margin-left: 40px;
  }
  .time-zone {
    width: 493px;
  }
  .set-standard-hours {
    margin-top: 3rem;
    width: fit-content;
  }

  .set-standard-hours h3 {
    font-weight: 500;
    margin-bottom: 25px;
  }
  .week-container {
    display: flex;
    /* align-items: center; */
    gap: 5rem;
    margin-bottom: 0.6rem;
  }
  .day {
    width: 50px;
    margin-top: 0.7rem;
  }
  .button-container {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
  }
  .add-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
  .add-btn {
    border: 2px solid #d9d9d9;
    border-radius: 0.5rem;
  }
`;
export default BusinessHoursStyles;
