import React, { useRef, useState } from "react";

const useImageUpload = () => {
  const [imageSrc, setImageSrc] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageName, setImageName] = useState("");
  /**
   * @type {React.RefObject<HTMLInputElement>} imageRef
   */
  const imageRef = useRef(null);

  const handleImageClick = () => {
    imageRef.current.click();
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setImageName(selectedFile.name);
    }
    const imageURL = URL.createObjectURL(selectedFile);
    setImageSrc(imageURL);
    setImageFile(selectedFile);
  };

  const setDefaultValue = () => {
    setImageSrc(null);
    setImageFile(null);
    setImageName("");
  };

  const inputElement = (
    <input
      type="file"
      accept="image/*"
      ref={imageRef}
      style={{ display: "none" }}
      onChange={handleImageChange}
    />
  );

  return [
    inputElement,
    { imageSrc, imageFile, imageName },
    setDefaultValue,
    handleImageClick,
  ];
};
export default useImageUpload;
