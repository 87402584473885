import React, { useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
// import ImageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import quillEmoji from "react-quill-emoji";
import "react-quill-emoji/dist/quill-emoji.css";
import styled from "styled-components";
import { getKey } from "../../utils/helperFunction";
import DataObjectIcon from "@mui/icons-material/DataObject";
import { Button } from "@mui/material";
import CustomPopover from "./CustomPopover";

const TextEditorStyles = styled.div`
  .quill-container {
    position: relative;
  }
  .custom-variable {
  }
  .variable-list {
  }
  .variable-icon {
    position: absolute;
    top: 0.7rem;
    left: 50%;
  }
`;

const CustomTextEditor = ({ width, height, value, setValue, onChange }) => {
  const quillRef = useRef(null);
  const [showVariable, setShowVariable] = useState(true);
  const variableList = ["name", "age", "email"];
  const style = {
    width,
    // height,
    background: "white",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
  };
  const modules = {
    toolbar: {
      container: [
        ["image"],
        ["underline"],
        ["italic"],
        ["bold"],
        ["link"],
        ["emoji"],
      ],
    },
    "emoji-toolbar": true,
    "emoji-shortname": true,
  };
  Quill.register(
    {
      "formats/emoji": quillEmoji.EmojiBlot,
      "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
      "modules/emoji-textarea": quillEmoji.TextAreaEmoji,
      "modules/emoji-shortname": quillEmoji.ShortNameEmoji,
    },
    true
  );

  const handleVariableClick = (variable) => {
    const position = quillRef.current.getEditor().selection.savedRange.index;
    console.log({ variable, position });
    quillRef.current.getEditor().insertText(position, `{${variable}}`);
    quillRef.current.getEditor().setSelection(position + variable.length + 2);
  };
  console.log(value);

  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };

  return (
    <TextEditorStyles width={width} height={height}>
      <div className="quill-container">
        <ReactQuill
          className="fdquill"
          modules={modules}
          theme="snow"
          value={value}
          onChange={handleChange}
          style={style}
          ref={quillRef}
        />
        <CustomPopover
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          button={
            <div
              onClick={() => setShowVariable((prev) => !prev)}
              className="variable-icon"
            >
              <DataObjectIcon />
            </div>
          }
        >
          <div className="custom-variable">
            {variableList.map((item, index) => {
              return (
                <li
                  className="variable-list"
                  onClick={() => handleVariableClick(item)}
                  key={getKey("variableList", item, index)}
                >
                  {item}
                </li>
              );
            })}
          </div>
        </CustomPopover>
        {/* {showVariable && */}
        {/* } */}
      </div>
    </TextEditorStyles>
  );
};
export default CustomTextEditor;
