import { TextField } from "@mui/material";
import React, { useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import styled from "styled-components";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import ReactQuill from "react-quill";

const MarkDownInputFieldStyles = styled.div`
  width: ${(props) => props.width}px;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  gap: 1rem;
  .container:hover {
    border-radius: 8px;
    box-shadow: 0 0 0 0.1px #000, 0 0 0 0.1px red,
      inset 0 0 0 1.5px var(--primary-500);
  }
  .icons-container {
    color: #666666;
    border-top: 0.5px solid #666666;
    padding: 0.5rem 1.5rem;
  }
  .icons-container span {
    display: flex;
    gap: 1rem;
    cursor: pointer;
  }
  .signature-container {
    padding-top: 1rem;
    padding-left: 1rem;
  }
  .signature-container > div {
    margin-left: -40px;
    padding-bottom: 0.2rem;
  }
  .img-preview {
    margin-left: 40px;
  }
  .display-text {
  }
`;

const MarkDownInputField = ({
  children,
  width,
  row,
  placeholder,
  name,
  value,
  handleChange,
  image,
}) => {
  console.log(value);
  const [content, setContent] = useState("text");
  const handleContentChange = (newContent) => {
    setContent(newContent);
  };
  console.log(content);
  function Placeholder() {
    return <div className="editor-placeholder">Enter some rich text...</div>;
  }
  const editorConfig = {
    // The editor theme
    // theme: ExampleTheme,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      // HeadingNode,
      ListNode,
      ListItemNode,
      // QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
    ],
  };

  return (
    <MarkDownInputFieldStyles width={width}>
      <div className="container">
        <div
          className="signature-wrapper"
          contentEditable
          style={{ width, height: "230px" }}
          onInput={handleContentChange}
        >
          <h1>{content}</h1>

          <div className="signature-container" spellCheck="false">
            <div className="display-text">
              <ReactMarkdown>{value?.displayText}</ReactMarkdown>
            </div>
            <div className="image">
              {image && (
                <img
                  className="img-preview"
                  src={image}
                  width={60}
                  height={60}
                />
              )}
            </div>
            {/* <LexicalComposer initialConfig={editorConfig}>
              <RichTextPlugin
                contentEditable={<ContentEditable className="editor-input" />}
                placeholder={<Placeholder />}
                // ErrorBoundary={LexicalErrorBoundary}
              />
            </LexicalComposer> */}
            {/* <ReactQuill value={content} onChange={handleContentChange} /> */}
            <div className="url">
              <ReactMarkdown>{`[${value?.enterUrl}](${value?.enterUrl})`}</ReactMarkdown>
            </div>
          </div>
        </div>
        <div className="icons-container">
          <span>{children}</span>
        </div>
      </div>
    </MarkDownInputFieldStyles>
  );
};

export default MarkDownInputField;
