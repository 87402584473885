import React, { useEffect, useRef, useState } from "react";

import MessagePreview from "./MessagePreview";
import { InboxMessageStyles } from "../../assets/styledComponents/inboxMessage";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { getAllSession, getLabel, sendMessage } from "../../service/service";
import { Checkbox, FormControlLabel, FormGroup, Popover } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { filterLableOptions } from "../../utils/helperData";
import { FilterLabelStyles } from "../../assets/styledComponents/inboxMessage";
import { getBusinessHours, getRemainingTicket } from "../../service/service";
import { useTenantContext } from "../../context/TenantContext";
import { checkTimeInRange, getKey } from "../../utils/helperFunction";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import { useLocation } from "react-router-dom";

import useNotification from "../../hooks/useNotification";

import FilterLabel from "./FilterLabel";
import useDebounce from "../../hooks/useDebounce";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import MessagePreviewSkeleton from "../skeleton/MessagePreviewSkeleton";
import usePushNotification from "../../hooks/usePushNotification";
import NotificationDrawer from "../notification/NotificationDrawer";
import MessagePreviewList from "./MessagePreviewList";
import { CheckBox, FilePresent } from "@mui/icons-material";
import FilterModal from "./Filter/FilterModal";
import QuotaProgress from "../reusable/QuotaProgress/QuotaProgress";

const InboxMessage = () => {
  const { tenantId, userId, openSidebar, previewActive } = useTenantContext();
  const {
    socket,
    messageSent,
    messageReceived,
    refreshNotification,
    setRefreshNotification,
    setNormalMessage,
    sessionId,
    labelFilterQuery,
    setCurrentChat,
    highlight,
    setHighlight,
    channelName,
    inboxType,
    sessionLoading,
    setSessionLoading,
    chatSessions: session,
    // getSelectedPath,
    refreshChatSessions,
    setMessageIndex,
    updateSingleSession,
    unReadMessage,
    fetchSessions,
  } = useInboxContext();

  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  // const [session, setSession] = useState([]);
  // const [sessionLoading, setSessionLoading] = useState(true);
  // const [unReadMessage, setUnreadMessage] = useState(0);
  const [newUserJoined, setNewUserJoined] = useState(false);
  const [allLabels, setAllLabels] = useState([]);
  const [totalQuota, setTotalQuota] = useState(100);
  const [usedQuota, setUsedQuota] = useState(0);


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const [searchValue, setSearchValue] = useState("");
  // const [debouncedValue] = useDebounce(searchValue, 500);

  const [messageSearch, setMessageSearch] = useState("");
  const [messageDebounceValue] = useDebounce(messageSearch, 500);
  const [newMessage, setNewMessage] = useState({});
  const [sessionOriginalData, setSessionOrginalData] = useState({});
  const [page, setPage] = useState(1); // Initialize with the first page

  const [intObserver, lastPostRef] = useInfiniteScroll();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [playNotificationSound] = useNotification();
  const [askPermission, sendNotification] = usePushNotification();
  const [tenantUserDetailsId, setTenantUserDetailsId] = useState("");

  // let tenantUserDetailsId = "";
  const [isSnooze, setIsSnooze] = useState(false);
  // let isSnooze = false;
  const pathName = location.pathname.split("/")[1];

  useEffect(() => {
    if (inboxType === "myInbox") {
      setTenantUserDetailsId(userId);
      setIsSnooze(false);
      // setNormalMessage();
    }
    if (inboxType === "snoozedMessage") {
      setTenantUserDetailsId("");
      setIsSnooze(true);
    }
    if (inboxType === "universal") {
      setTenantUserDetailsId("");
      setIsSnooze(false);
    }
    // if (pathName === "myInbox") {
    //   setTenantUserDetailsId(userId);
    //   setNormalMessage();
    // }

    // if (pathName === "snoozedMessage") {
    //   setTenantUserDetailsId("");
    //   isSnooze = true;
    // }
  }, [
    // pathName,
    refreshNotification,
    inboxType,
  ]);

  // const [label, setLabel] = useState([]);
  const [filterLabel, setFilterLabel] = useState([]);
  const [loading, setLoading] = useState(false);
  let limit = 6;
  const [isNotification, setIsNotification] = useState(false);

  const fetchLabels = async () => {
    setLoading(true);
    try {
      const result = await getLabel(tenantId, "");
      // setLabel(result.data);
      setAllLabels(result.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };

  // useEffect(() => {
  //   // Initial session data fetching
  //   fetchData(page);
  // }, [page]);

  useEffect(() => {
    refreshChatSessions();
  }, [location.pathname]);

  useEffect(() => {
    fetchSessions(1, false, messageDebounceValue.trim()) 
  }, [messageDebounceValue])

  useEffect(() => {
    askPermission();
    // Find All Labels
    fetchLabels();
  }, []);

  // useEffect(() => {
  //   fetchLabels();
  // }, [debouncedValue]);

  // useEffect(() => {
  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const fetchBusinessHours = async () => {
    try {
      const result = await getBusinessHours(tenantId);
      let currentTime = new Date();
      let currentDay = currentTime.toLocaleDateString("en-US", {
        weekday: "long",
      });
      let businessHours = result.data[0];
      let openingHours = businessHours?.openingHours;
      for (let days of openingHours) {
        if (days.day === currentDay && days.isOpen === false) {
          setIsNotification(false);
          return;
        }
        if (days.day === currentDay && days.isOpen) {
          let isTimeInRange = await checkTimeInRange(currentTime, days.time);
          setIsNotification(isTimeInRange);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTicketsQuota = async() => {
    const res = await getRemainingTicket(tenantId);
    if (res.success) {
      setUsedQuota(res.data.usedTicketQuota);
      setTotalQuota(res.data.totalTicketQuota);
    }
  }

  useEffect(() => {
    fetchBusinessHours();
    fetchTicketsQuota();
  }, [page]);

  const [snoozeMessages, setSnoozeMessages] = useState([]);

  const initialRender = useRef(false);
  const initialSessionRender = useRef(false);


  // socket
  //   ?.off("REQUEST_NOTIFICATION")
  //   .on("REQUEST_NOTIFICATION", (newMessage, userType) => {
  //     if (userType === "newUser") {
  //       socket?.emit("SEND_NOTIFICATION", newMessage, "newUser");
  //       return;
  //     }
  //     if (newMessage.chatSession !== sessionId) {
  //       socket?.emit("SEND_NOTIFICATION", newMessage, "oldUser");
  //     }
  //   });

  // socket?.off("NOTIFICATION_SENT").on("NOTIFICATION_SENT", (message) => {
  //   console.log("NOTIFICATION_SENT");
  //   setRefreshNotification((prev) => !prev);
  //   askPermission();
  //   playNotificationSound();
  //   sendNotification(message.content);
  // });

  // socket?.off("MESSAGE_SENT").on("MESSAGE_SENT", (message) => {
  //   setNewMessage(message);
  //   if (newMessage.sender === 'user') {
  //       updateSingleSession(message?.chatSession) 
  //     }
  // });

  const handleSearch = (e) => {
    setMessageSearch(e.target.value);
    setPage(1);
  };

  return (
    <InboxMessageStyles>
      <div className="top">
        <div className="container-title-quota">
          <h3 className="title">Inbox</h3>
          <div className="quaota-container">
            <QuotaProgress usedQuota={usedQuota} totalQuota={totalQuota} />
          </div>
        </div>
        <div className="info">
          <p>{session.length} messages</p>
          <span>
            <span className="icon-container">
              <span className="color-icon"></span>
            </span>{" "}
            {unReadMessage} Unread
          </span>
        </div>
        <div className="search-container">
          <input
            className="search-box"
            onChange={handleSearch}
            type="search"
            placeholder="Search"
          />
          <div className="search-btn">
            <SearchIcon />
          </div>
        </div>

        <div className="filter">
          <FilterModal />
          {/* <button onClick={handleClick}>
            <FilterListIcon />
          </button> */}

          {/*
            To Do - Refactor
              Move the popover below to the code in the seperate component
            */}
          {/* <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <FilterLabel
              setSearchValue={setSearchValue}
              handleClose={handleClose}
              label={label}
              setLabel={setLabel}
              filterLabel={filterLabel}
              setFilterLabel={setFilterLabel}
              loading={loading}
            />
          </Popover> */}
          {/* <span>Filter by label</span> */}
        </div>
      </div>

      {sessionLoading ? (
        Array(20)
          .fill()
          .map((_, index) => {
            return <MessagePreviewSkeleton key={index} />;
          })
      ) : (
        <MessagePreviewList
          session={session}
          highlight
          setHighlight={setHighlight}
          limit
          setMessageIndex={setMessageIndex}
          allLabels={allLabels}
        />
      )}
    </InboxMessageStyles>
  );
};

export default InboxMessage;
