import React from "react";
import styled from "styled-components";

const Typing = () => {
  return (
    <TypingStyles>
      <div className="ticontainer">
        <div className="tiblock">
          <div className="tidot"></div>
          <div className="tidot"></div>
          <div className="tidot"></div>
        </div>
      </div>
    </TypingStyles>
  );
};

export default Typing;
const TypingStyles = styled.div`
  .tiblock {
    align-items: center;
    display: flex;
    height: 17px;
  }

  .ticontainer .tidot {
    background-color: #90949c;
  }

  .tidot {
    animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
    -webkit-animation: mercuryTypingAnimation 1.5s infinite ease-in-out; /* For older browsers */
    border-radius: 2px;
    display: inline-block;
    height: 4px;
    margin-right: 2px;
    width: 4px;
  }

  @keyframes mercuryTypingAnimation {
    0% {
      transform: translateY(0px);
    }
    28% {
      transform: translateY(-5px);
    }
    44% {
      transform: translateY(0px);
    }
  }

  @-webkit-keyframes mercuryTypingAnimation {
    0% {
      -webkit-transform: translateY(0px);
    }
    28% {
      -webkit-transform: translateY(-5px);
    }
    44% {
      -webkit-transform: translateY(0px);
    }
  }

  .tidot:nth-child(1) {
    animation-delay: 200ms;
    -webkit-animation-delay: 200ms; /* For older browsers */
  }

  .tidot:nth-child(2) {
    animation-delay: 300ms;
    -webkit-animation-delay: 300ms;
  }

  .tidot:nth-child(3) {
    animation-delay: 400ms;
    -webkit-animation-delay: 400ms;
  }
`;
