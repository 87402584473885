import styled from "styled-components";
const ContactFormStyles = styled.div`
  .container {
    margin-top: 2rem;
  }
  .heading {
    font-size: 18px;
    font-weight: 500;
  }
  .attachment-container {
    margin-bottom: 2rem;
  }
  .contact-form {
    margin-bottom: 1rem;
  }
  .contact-fields {
    margin-top: 2rem;
    color: #6b6b6b;
  }
  .email-address p {
    font-size: 16px;
    font-weight: 400;
    color: #81848a;
    margin-bottom: 0.7rem;
    margin-top: 0.2rem;
  }
  .add-query {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--primary-800);
    font-size: 1rem;
    font-weight: 500;
    width: fit-content;
  }
  .query-form {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  /* .query-btn {
    border: 2px solid var(--primary-800);
    border-radius: 0.5rem;
  } */
  .switch-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .switch {
    margin-right: -1.5rem;
  }
  .select {
    margin: 1rem 0;
  }
  .query-type {
    margin-top: 1.5rem;
  }
  .text-field h3 {
    margin: 1rem 0;
  }

  .text-field-wrapper { 
    margin-top: 20px;
  }

  .text-field-wrapper h3 {
    margin: 10px 0px;
  }
`;
export default ContactFormStyles;
