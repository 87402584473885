import React, { useEffect, useRef, useState } from "react";
import EmailConversationStyles from "../../assets/styledComponents/chatBox/EmailConversation";
import EmailInputBox from "../../pages/DashboardPage/email/EmailInputBox";
import CustomAvatar from "../reusable/CustomAvatar";
import EmailMessage from "./EmailMessage";
import Modal from "../reusable/DialogModal/Modal";
import EmailInputBoxStyles from "../../assets/styledComponents/inboxMessage/mail/EmailInputBox";
import { Button, CircularProgress, Dialog } from "@mui/material";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import { getAllTenantStore, sendMessage } from "../../service/service";
import { useTenantContext } from "../../context/TenantContext";
import { uploadToS3 } from "../../utils/uploadImageToS3";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import { escapeTemplateVariables, extractProductDetails } from "../../utils/helperFunction";

const EmailConversation = () => {

  const {tenantId, userId} = useTenantContext()
  const {normalMessage, currentSessionData,sendMessages, messageSent , isMessageLoading ,setPage , page ,quickReplySelected, setQuickReplySelected, text, setText, isProductAdded, setIsProductAdded} = useInboxContext()
  // const [text, setText] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [cursorPosition, setCursorPosition] = useState(0);
  const [isMessagePrivate, setIsMessagePrivate] = useState(false);
  const [fromEmail, setFromEmail] = useState({email : '', name: ''})
  const [attachmentDetails , setAttachmentDetails] = useState({url : "", name : ""})
  const [imageDetails, setImageDetails] = useState({
    url : '',
    name : '',
  })
  const [taggedUserDetails, setTaggedUserDetails] = useState([]);

  const [backendMessage, setBackendMessage] = useState({ msg: "", type: "" });

  const [reply, setReply] = useState({
    subject : '',
    emailMessageId : '',
  })
  const [openFullViewModal, setOpenFullViewModal] = useState(false);
  const [showMoreMessage, setShowMoreMessage] =useState(true)
  const [subject, setSubject] = useState('')

  const inputRef = useRef();
  const emailContainerRef = useRef()
  const [emailSignatures, setEmailSignatures] = useState([])


  const {_id : sessionId, fromEmail : toEmail , fromName : toName, storeId , latestMessage, storeName, assignTo , taggedUser} = currentSessionData || {}
  const {emailSubject} = latestMessage || {} 

  const disable = currentSessionData.status === "complete"
  const handleDelete = () => {};
  const handleTextAreaChange = (e) => {
    setText(e.target.value);
    setCursorPosition(e.target.selectionStart);
  };

    function htmlToPlainText(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const plainText = Array.from(doc.body.childNodes)
        .map((node) => {
          if (node.nodeType === Node.TEXT_NODE) {
            return node.textContent;
          } else if (node.tagName === "P") {
            return "\n" + node.textContent;
          }
          return "";
        })
        .join("");

      return plainText;
    }

  const isNotAssignedUser =
    userId === assignTo ? false : taggedUser?.includes(userId);
  
  const clearInputValue = (func) => {
    setText('')
    setIsButtonLoading(false)
    setReply({subject : '', emailMessageId: ''})
    setAttachmentDetails({url : '', name : ''})
    if (!isNotAssignedUser) {
      setIsMessagePrivate(false)
    }
    setOpenFullViewModal(false)
    setIsProductAdded(false)
    setQuickReplySelected(false)
    func.forEach((fn) => {
      fn()
    })
  }
  useEffect(() => {
    const subject = reply.emailMessageId ? reply.subject : emailSubject;
    const findLastUserMessage = normalMessage.filter((item) => {
      return item.sender === "user";
    });
    const latestSubject = findLastUserMessage[0].emailSubject;
    setSubject(latestSubject);
  }, [normalMessage]);

  
  const handleSubmit = async (func) => {
   
    try {
      const plainText = htmlToPlainText(text);

      let attachmentList = []
      if (imageDetails.url) attachmentList.push(imageDetails)
      if (attachmentDetails.url) attachmentList.push(attachmentDetails)
      if (plainText.trim() === '' && attachmentList.length === 0  ) return
      setIsButtonLoading(true)
      let  payload = {
        tenantId,
        content: plainText,
        sender: "agent",
        channelName: 'mail',
        userId,
        isPrivate: isMessagePrivate ,
        chatSession: sessionId,
        emailConfig : {
          fromEmail : fromEmail.email,
          fromName :fromEmail.name,
          toEmail,
          toName,
          // content,
          subject ,
          htmlContent : quickReplySelected ? escapeTemplateVariables(text) : text,
          textContent : plainText.trim(),
          attachment : attachmentList
        },
        taggedUser: taggedUserDetails.map((item) => item._id),
        taggedUserName: taggedUserDetails.map((item) => item.name),
        image: imageDetails?.url,
        attachment: attachmentDetails?.url,
        // attachmentSize: attachmentFileSize,
        // documentName: attachmentFile?.name,
      }
      
      if (isProductAdded) {
        const {imageLinkDetails} = extractProductDetails(text) || {}
        payload = {
          ...payload,
          imageLinkDetails,
        };
      }
      const result = await sendMessage(payload);
      sendMessages(result.data);
      clearInputValue(func)
    } catch (error) {
      console.log(error);
      setBackendMessage({ msg: error.response.data, type: "error" });
      
      setIsButtonLoading(false)
    }
  };
  const handleOpenFullView = (value) => {
    setOpenFullViewModal(value);
  };

  const fetchTenantStore = async() => {
    const result = await getAllTenantStore(tenantId) 
    const currentEmail = result.data.filter((item) => {
     return  item._id === storeId
    })
    let signatures = result.data.map((item) => {
      return {...item.emailSignature, shop  : item.shop}
    }) 
    setEmailSignatures(signatures);

    const currentSignature = signatures.filter((item) => {
      return item.shop === storeName;
    })[0]?.personalizeSignature; 

    const isSessionTagged =
    userId === assignTo ? true : taggedUser?.includes(userId);

    console.log({isSessionTagged })
    if ( isSessionTagged && !isMessagePrivate ) {
      if (!isNotAssignedUser){
        setText(currentSignature)
      }
    }else {
      setText('')
    }

    const emailConfig =currentEmail[0].emailConfiguration 
    const fromEmail = emailConfig.forwardingEmail
    const {isDomainEmailActive , email : defaultEmail, domainEmail, isActive : isDefaultEmailActive } = emailConfig || {}
    if (isDomainEmailActive) {
      const fromName = domainEmail?.split('@')[0]
      setFromEmail({email : domainEmail, name : fromName})
    }else if (isDefaultEmailActive) {
      const fromName = defaultEmail?.split('@')[0]
      setFromEmail({email : fromEmail, name : fromName})
    }
  }
  

  const scrollToBottom = () => {
  if (emailContainerRef.current) {
      emailContainerRef.current.scrollTop = 0;
    }
  }

  useEffect(() => {
    scrollToBottom()
  },[messageSent])
  useEffect(() => {
    fetchTenantStore()
  }, [])

  useEffect(() => {
    if(isMessagePrivate) {
      setText('')
    }
  }, [isMessagePrivate])
  const emailProps = {
    text,
    setText,
    reply,
    inputRef,
    cursorPosition,
    setCursorPosition,
    isMessagePrivate,
    setIsMessagePrivate,
    openFullViewModal,
    setOpenFullViewModal,
    handleDelete,
    handleSubmit,
    handleOpenFullView,
    isButtonLoading,
    setIsButtonLoading,
    toName,
    attachmentDetails , 
    setAttachmentDetails,
    taggedUserDetails,
    setTaggedUserDetails,
    imageDetails, 
    setImageDetails,
    subject, 
    setSubject,
    emailSignatures,
  };


  return (
    <EmailConversationStyles>
      {backendMessage.msg && <CustomSnackbar payload={backendMessage} />}

      {isMessageLoading && (
        <div className="center-spinner">
          <CircularProgress />
        </div>
      )}

      <Dialog
        maxWidth={"xl"}
        onClose={() => setOpenFullViewModal(false)}
        open={openFullViewModal}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            console.log("enter");
          }
        }}
      >
        <EmailInputBoxStyles>
          <div className="email-modal fullscreen-container">
            <EmailInputBox  {...emailProps} disable={disable} height={"61.5vh"} isSmall={false} />
          </div>
        </EmailInputBoxStyles>
      </Dialog>
      <div className="email-wrapper">
        <div className="email-messages-container" ref={emailContainerRef}>
          {normalMessage
            .slice()
            .reverse()
            .map((item, index) => {
              const isLast = normalMessage.length - 1 === index;
              if (showMoreMessage) {
                if (!(index === 0 || index === normalMessage.length - 1))
                  return;
              }
              const highlightReply =
                reply.emailMessageId === item?.emailMessageId;
              return (
                <EmailMessage
                  highlightReply={highlightReply}
                  showMoreMessage={showMoreMessage}
                  setShowMoreMessage={setShowMoreMessage}
                  key={index}
                  isLast={isLast}
                  page={page}
                  setPage={setPage}
                  index={index}
                  {...item}
                  setReply={setReply}
                />
              );
            })}
        </div>
        <div className="input-box disable">
          {!openFullViewModal && (
            <EmailInputBox disable={disable} {...emailProps} height={"150px"} isSmall={true} />
          )}
        </div>
      </div>
    </EmailConversationStyles>
  );
};

export default EmailConversation;
