import { Popover } from "@mui/material";

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ticketSummeryOptions } from "../../utils/helperData";
import styled from "styled-components";
import Modal from "../reusable/DialogModal/Modal";
import SnoozeNotification from "../SnoozeNotification";
import LabelModal from "../modal/LabelModal";
import { markSessionRead, setAsPriority } from "../../service/service";
import { useInboxContext } from "../../context/inboxContext/inboxContext";

import UserHistory from "./UserHistory";

import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";

const ThreeDotOptionsStyles = styled.div`
  .options {
    display: flex;
    padding: 0.7rem;
    width: 200px;
    cursor: pointer;
    align-items: flex-start;
  }
  .name {
    margin-left: 1rem;
    margin-top: 3px;
  }
  .hide {
    display: none;
  }
  .disable-user-history {
    color: gray;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const ThreeDotOptions = ({
  id,
  anchorEl,
  handleClose,
  setBackendMessage,
  // setIsOpenModal,
  //   setModalName,
  open,
}) => {
  const {
    openModal,
    setOpenModal,
    currentSessionData,
    setRefreshNotification,
    messageSent,
    refreshChatSessions,
    refreshChatSessionsListOnly,
    updateSingleSession,
  } = useInboxContext();

  const [name, setName] = useState("");
  const [element, setElement] = useState("");
  const [options, setOptions] = useState([...ticketSummeryOptions]);

  let priority = currentSessionData.priority;
  const disableUserHistory = Boolean(currentSessionData?.customerId);
  const {status} = currentSessionData || {}

  const updatePriorityIcon = () => {
    setOptions((prev) => {
      const updatedValue = [...prev];
      if (priority) {
        return [
          { icon: <StarIcon />, name: "Set as priority" },
          ...updatedValue.slice(1, 5),
        ];
      } else {
        return [
          { icon: <StarBorderIcon />, name: "Set as priority" },
          ...updatedValue.slice(1, 5),
        ];
      }
    });
  };

  useEffect(() => {
    updatePriorityIcon();
  }, [currentSessionData]);

  const handlePriorityChange = async () => {
    const result = await setAsPriority(currentSessionData?._id);
    console.log(result.data.priority);
    priority = result.data.priority;
    updatePriorityIcon();
    console.log(result);
    setRefreshNotification((prev) => !prev);
    // refreshChatSessionsListOnly();
    updateSingleSession() 
  };

  const handleMarkAsUnread = async () => {
    const isRead = options[3].name === 'Mark as Unread' ? false : true 
    try {
      const result = await markSessionRead({
        sessionId: currentSessionData?._id,
        isRead,
      });
      setBackendMessage({ msg: result.message, type: "success" });
      handleClose();
      setRefreshNotification((prev) => !prev);
      // refreshChatSessionsListOnly()
      updateSingleSession() 
      setOptions((prev) => {
        let updatedOptions = [...prev];
        if (updatedOptions[3].name === "Mark as Unread") {
          updatedOptions[3] = { ...updatedOptions[3], name: "Mark as read" };
          return updatedOptions;
        }
        if (updatedOptions[3].name === "Mark as read") {
          updatedOptions[3] = {
            ...updatedOptions[3],
            name: "Mark as Unread",
          };
          return updatedOptions;
        }
        return updatedOptions;
      });
    } catch (error) {
      setBackendMessage({ msg: error.response.data, type: "error" });
    }
  };

  const handleClick = async (name, element) => {
    if (name === "Snoozed" || name === "Label") {
      handleClose()
      setOpenModal(true);

    }

    if (name === "User History" && disableUserHistory) setOpenModal(true);

    if (name === "Set as priority") {
      handlePriorityChange();
      
    }

    setName(name);
    setElement(element);

    if (name === "Mark as Unread" || name === "Mark as read") {
      handleMarkAsUnread();
    }
  };
  useEffect(() => {
    setOptions((prev) => {
      let updatedOptions = [...prev];
      updatedOptions[3] = { ...updatedOptions[3], name: "Mark as Unread" };
      return updatedOptions;
    });
  }, [messageSent]);
  const handleModalClose = () => {
    setOpenModal(false);
    // refreshChatSessionsListOnly();
    updateSingleSession()


  };
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.map(({ icon, name, element }, idx) => {
          const isChatCompleted =
            currentSessionData.status === "complete" &&
            name !== "User History";
          return (
            <ThreeDotOptionsStyles key={idx}>
              <div
                className={`options ${isChatCompleted ? "disable-user-history" : ""} ${
                  !disableUserHistory && name === "User History"
                    ? "disable-user-history"
                    : ""
                }`}
                onClick={() => handleClick(name, element)}
              >
                <div className="icon">{icon}</div>
                <div className="name">{name}</div>
              </div>
            </ThreeDotOptionsStyles>
          );
        })}
      </Popover>
      <Modal name={name === "Snoozed" ? "Snooze Notification" :  name} onClose={() => handleModalClose()} open={openModal}>
          {name === "Snoozed" ? (
            <SnoozeNotification onClose={handleModalClose} />
          ) : name === "Label" ? (
            <LabelModal
              handleModalClose={handleModalClose}
              setBackendMessage={setBackendMessage}
            />
          ) : (
            <UserHistory {...currentSessionData} handleClose={handleClose} />
          )}
        </Modal>
    </>
  );
};

export default ThreeDotOptions;

ThreeDotOptions.propTypes = {
  id: PropTypes.string,
  anchorEl: PropTypes.string,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};
