import React, { useEffect, useRef, useState } from "react";
import TicketNoteStyles from "../../assets/styledComponents/message/TicketNote";
import SendIcon from "@mui/icons-material/Send";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { Avatar, TextField } from "@mui/material";
import MessageView from "../reusable/MessageView/MessageView";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import { useTenantContext } from "../../context/TenantContext";
import {
  createTicketNote,
  getTicketNote,
  sendMessage,
} from "../../service/service";
import {
  getFullDate,
  getFullShortDate,
  getKey,
  getTime,
} from "../../utils/helperFunction";
import useImageUpload from "../../hooks/useImageUpload";
import { uploadToS3 } from "../../utils/uploadImageToS3";
import MessageBox from "../chatBox/MessageBox";
import CustomPopover from "../reusable/CustomPopover";
import EmojiPicker from "emoji-picker-react";

const TicketNote = () => {
  const { currentSessionData, setTicketNoteValue, ticketNoteValue  } = useInboxContext();
  const { tenantId, userId } = useTenantContext();
  const { ticketId, _id, userName, status } = currentSessionData || {};
  const [text, setText] = useState("");

  const [ticketMessage, setTicketMessage] = useState();
  const [message, setMessage] = useState([]);
  const [refreshMessage, setRefreshMessage] = useState(false);
  const [disableInput, setDisableInput] =useState(false)
  const messageContainerRef = useRef()
  const bottomRef = useRef()

  const inputRef = useRef(null);

  const [inputElement, imageDetails, setDefaultValue, handleImageClick] =
    useImageUpload();
  const { imageSrc, imageFile, imageName } = imageDetails;

const scrollToBottom = () => {
 if (messageContainerRef.current && bottomRef.current) {
    const containerHeight = messageContainerRef.current.clientHeight;
    const bottomRefPosition = bottomRef.current.offsetTop;
    messageContainerRef.current.scrollTop = bottomRefPosition - containerHeight;
  }

  console.log('scroll to bottom');
};

console.log({message})

  const fetchTicketMessages = async () => {
    const result = await getTicketNote(ticketId , tenantId);
    setMessage(result.data.map((item) => item.messageId));
    setTicketMessage(result.data);
    setTimeout(() => {
      scrollToBottom() 
    }, 0);
  };

  useEffect(() => {
    setDisableInput(status=== 'complete')
    fetchTicketMessages();
  }, [refreshMessage]);

  const handleChange = (e) => {
    setTicketNoteValue(e.target.value);
  };

  const [cursorPosition, setCursorPosition] = useState(0);
  const handleEmojiSelect = (emoji) => {
    const startPos = cursorPosition;
    const endPos = cursorPosition;
    const newText = ticketNoteValue.slice(0, startPos) + emoji.emoji + ticketNoteValue.slice(endPos);
    setTicketNoteValue(newText);

    setTimeout(() => {
      inputRef.current.focus();
    }, 0);
  };

  const handleSendMessage = async () => {
    let uploadedImage;
    setTicketNoteValue("");
    if (!imageSrc && !ticketNoteValue.trim()) {
      return;
    }
    const payload = {
      ticketId,
      userId,
      tenantId,
      chatSession: _id,
    };
    if (imageSrc) {
      uploadedImage = await uploadToS3(imageFile);
      setDefaultValue();
    }
    try {
      const message = await sendMessage({
        chatSession: _id,
        userId,
        tenantId,
        content: ticketNoteValue,
        sender: "agent",
        isTicketNote: true,
        image: uploadedImage,
      });
      const result = await createTicketNote({
        ...payload,
        messageId: message.data._id,
      });
      setMessage((prev) => [...prev, {...message.data}]);
    setTimeout(() => {
      scrollToBottom() 
    }, 100);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };
  return (
    <TicketNoteStyles>
      <div className="chat-container ticket-popover">
        {/* <div className="user-details">
          <div className="profile">
            <Avatar />
          </div>
          <p className="name">{userName} </p>
        </div> */}
        <div ref={messageContainerRef} className="message-container">
          {message.length > 0 &&
            message?.map((item, index) => {
              const { createdAt, content, userId } = item || {};
              const name = userId?.name;
              const time = getFullShortDate(createdAt);
              return (
                <div key={index} className="message-wrapper">
                  <MessageView
                    bgColor={"#F2F3B0"}
                    msg={item.content}
                    time={time}
                    sender={true}
                    name={name}
                    {...item}
                    showSenderIcon={false}
                  />
                </div>
              );
            })}
          <div className="bottom-ref" ref={bottomRef}></div>
        </div>
        <div className="msg-input">
          <TextField
            onChange={handleChange}
            value={ticketNoteValue}
            ref={inputRef}
            onKeyDown={handleKeyDown}
            fullWidth
            sx={{ width: "85%", "& fieldset": { border: "none" } }}
            size={"small"}
            placeholder={imageName ? imageName : "Type your text"}
            disabled = {disableInput}
          />

          {/* <div className="icons-container">

            <CustomPopover
              button={<TagFacesIcon />}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <EmojiPicker onEmojiClick={handleEmojiSelect} />
            </CustomPopover>
            {inputElement}
            <div className="image-upload" onClick={handleImageClick}>
              <ImageOutlinedIcon />
            </div>
          </div> */}
          <div onClick={handleSendMessage} className="send-btn">
            <SendIcon />
          </div>
        </div>
      </div>
    </TicketNoteStyles>
  );
};

export default TicketNote;
