import styled from "styled-components";
const EmailConversationStyles = styled.div`
   flex: 1;
   min-height: 500px;
  .email-wrapper {
    height: 100%;
    /* padding-bottom: 2.5rem; */
    display: flex;
    flex-direction: column;
  }
  .email-messages-container {
    /* height: 300px; */
    /* flex-grow: 1; */
    /* min-height: 38vh; */
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .email-messages-container  > :first-child {
    .sender-email-container {
      border-top: none !important;
    }
  }

  .input-box {
    padding: 1rem;
  }
  .center-spinner {
    text-align: center;
  }
`;
export default EmailConversationStyles;
