import React, { useState } from "react";
import styled from "styled-components";
import ReplyIcon from "@mui/icons-material/Reply";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { deleteComment, deleteInstagramComment } from "../../service/service";
import Swal from "sweetalert2";
import Like from "./Like";
import { useInboxContext } from "../../context/inboxContext/inboxContext";
import { getKey, getTime } from "../../utils/helperFunction";

const SocialMessage = ({
  msg,
  userId,
  time,
  handleReplyClick,
  isCurrentUser,
  facebookCommentMetaData,
  instagramCommentMetaData,
  tenantId,
  shop,
  messageId,
  channelName,
}) => {
  const { commentId, isLiked, replies, userName } =
    channelName === "facebook"
      ? facebookCommentMetaData || {}
      : channelName === "instagram"
        ? instagramCommentMetaData || {}
        : {};
  const [disableDelete, setDisableDelete] = useState(false);

  const [repliesVisible, setRepliedVisible] = useState(false);
  const { currentSessionData, setFbCommentCount, deleteMessage } = useInboxContext();

  const handleDelete = async () => {
    setDisableDelete(true);
    const result = await Swal.fire({
      title: "Delete Comment?",
      text: `Deleting the comment will remove it from your facebook comments.`,
      confirmButtonColor: "#8db178",
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Cancel`,
      reverseButtons: true,
    });
    try {


      if (channelName === "facebook" && result.isConfirmed) {
        const res = await deleteComment({ commentId, userId, shop, tenantId, messageId, sessionId: currentSessionData._id });
        setFbCommentCount((prev) => prev - 1)
        deleteMessage(messageId)
        Swal.fire({
          title: "Deleted!",
          text: `Comment Deleted Successfully.`,
          confirmButtonColor: "#8db178",
          icon: "success",
        });
      }
      setDisableDelete(false);
    } catch (error) {
      setDisableDelete(false);
      console.log(error);
      Swal.fire({
        title: "Error!",
        text: "Comment already deleted",
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  };

  const handleVibilityIconClick = () => {
    setRepliedVisible(!repliesVisible)
  }
  return (
    <SocialMessageStyles>
      <div className={`social-container ${repliesVisible ? 'replies-visible' : ""}`}>
        <div className="icons-container">
          <div className="msg-wrapper">
            <div className="social-msg-container">{msg}</div>
            <button onClick={() => handleVibilityIconClick()}>
              {repliesVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </button>
            {isCurrentUser ? (
              <div className="action-icons">
                <button onClick={handleReplyClick}>
                  <ReplyIcon />
                </button>
                {channelName === 'facebook' && <button>
                  {/* <FavoriteBorderIcon /> */}
                  <Like
                    commentId={commentId}
                    isLiked={isLiked}
                    messageId={messageId}
                  />
                </button>}
                {channelName === 'facebook' && <button disabled={disableDelete} onClick={handleDelete}>
                  <DeleteOutlineIcon />
                </button>}
              </div>
            ) : null}
          </div>
          {userName &&
            <span className="time userName">{userName}</span>
          }
          <span className="time">{time}</span>

          {console.log("Replies----", replies)}
          <div className="replies-wrapper">
            {
              repliesVisible && replies?.map((replyData, index) => {
                const { id: commentId, message: content, postId } = replyData
                return (
                  <div key={getKey('reply', 'post', index)} className={`social-container ${repliesVisible ? 'replies-visible' : ""}`}>
                    <div className="icons-container">
                      <div className="msg-wrapper">
                        <div className="social-msg-container">{replyData.message || replyData.text}</div>
                        {isCurrentUser ? (
                          <div className="action-icons">
                            <button onClick={() => handleReplyClick({ type: "nestedReply", postId, commentId, content })}>
                              <ReplyIcon />
                            </button>
                            {channelName === 'facebook' && <button>
                              {/* <FavoriteBorderIcon /> */}
                              <Like
                                commentId={commentId}
                                isLiked={isLiked}
                                messageId={messageId}
                              />
                            </button>}
                            {/* {channelName === 'facebook' && <button disabled={disableDelete} onClick={() => handleDelete(commentId)}>
                  <DeleteOutlineIcon />
                </button>} */}
                          </div>
                        ) : null}
                      </div>
                      {userName &&
                        <span className="time userName">{userName}</span>
                      }
                      <span className="time nested-time">{replyData?.from?.time && getTime(replyData?.from.time)}</span>

                    </div>
                  </div>
                )
              }
              )}
          </div>
        </div>
      </div>
    </SocialMessageStyles>
  );
};

export default SocialMessage;

const SocialMessageStyles = styled.div`
  .social-msg-container {
    margin-left: 1rem;
    background: rgb(255, 255, 255);
    border: 1px solid silver;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
    border: 1px solid rgb(153, 153, 153);
    overflow-wrap: break-word;
    white-space: pre-line;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    width: max-content;
  }
  
  .replies-visible .social-msg-container {
    background: var(--primary-500)
  }

  .replies-visible .replybox .social-msg-container {
    background: var(--primary-200) !important;
  }

  .msg-wrapper {
    display: flex;
    width: max-content;
    gap: 4px;
  }

  .social-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .icons-container {
    max-width: 70%;
    display: flex;
    flex-direction: column;
  }
  .icons {
    display: flex;
    align-self: flex-end;
    margin-top: 0.5rem;
    gap: 0.5rem;
    margin-right: 0.5rem;
  }

  .replybox .time {
    display: block;
    width: max-content;
    margin-top: 4px;
    margin-left: 1rem;
    margin-bottom: 10px;
  }

  .time {
    align-self: flex-start;
    margin-left: 1.5rem;
  }
  .userName {
    min-width: 200;
  }

  .userName {
    min-width: 200px;
  }

  .replies-wrapper {
    margin-left: 60px;
    background: lemon;
  }

  .action-icons {
    /* margin-top: 1rem; */
    display: flex;
    gap: 4px;
    // margin-bottom: 2rem;
  }
  .nested-time {
    margin-bottom: .25rem;
  }
`;
