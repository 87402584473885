import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import InviteTeamMemberStyles from "../../assets/styledComponents/settings/InviteTeamMembers";
import {
  getAllPermission,
  inviteUser,
  getTeams,
  getUserPermission,
} from "../../service/service";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import { getKey } from "../../utils/helperFunction";
import * as service from "../../service/service.js";
import { useTenantContext } from "../../context/TenantContext";
import ActionButtonGroup from "../reusable/ActionButtonGroup/ActionButtonGroup";
import PrimaryButton from "../reusable/PrimaryButton/PrimaryButton";

import { EMAIL_REGEX } from "../../utils/constants";

const StyledTextField = styled(TextField)`
  & input::placeholder {
    color: black;
    font-weight: 500;
    opacity: 0.6;
  }
  & .MuiInputBase-root {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  }
`;

const StyledSelect = styled(Select)`
  p {
    color: "red";
  }
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25);
`;

const InviteTeamMember = ({
  setShowInviteUser,
  editUserDetail,
  setEditUserDetail,
  handleCancelUser,
}) => {
  const initialFormState = {
    name: "",
    email: "",
    role: "",
    // team: "",
  };
  const formValue = [
    { name: "name", type: "text", options: [], placeholder: "Name" },
    { name: "email", type: "email", options: [], placeholder: "Email" },
  ];

  const { tenantId } = useTenantContext();

  const [inviteForm, setInviteForm] = useState({ ...initialFormState });
  const [errors, setErrors] = useState({ ...initialFormState });
  const [checkboxValue, setCheckboxValue] = useState([]);
  const [showAccess, setShowAccess] = useState(false);
  const [backendMsg, setBackendMsg] = useState({ msg: "", type: "error" });

  const isEdit = Object.keys(editUserDetail).length !== 0;
  const { _id, name, email, role, accessPermission } = editUserDetail;

  const resetValues = () => {
    setInviteForm({ ...initialFormState });
    setErrors({ ...initialFormState });
    setCheckboxValue([]);
    setShowAccess(false);
    setBackendMsg({ msg: "", type: "error" });
  };

  const handleCancel = () => {
    resetValues();
    handleCancelUser();
  };

  // TODO disable this if statement in future to create team
  // const fetchTeams = async () => {
  //   const result = await getTeams(tenantId);
  //   let teamArray = result.data?.filter((item) => {
  //     return item._id === teamId;
  //   });

  //   if (isEdit) {
  //     setInviteForm({ name, email, role, team: teamArray[0].name });
  //   }
  // };

  const fetchAllPermission = async () => {
    let editPermissionValue = {};
    const result = await getAllPermission();
    if (isEdit) {
      setShowAccess(accessPermission);
      let userPermission = await getUserPermission(_id);
      result.data.map((item) => {
        editPermissionValue[item._id] = item;
      });
      userPermission.data.map((item) => {
        editPermissionValue[item._id] = item;
      });
      setCheckboxValue(Object.values(editPermissionValue));
    } else {
      setCheckboxValue(result.data);
    }

    // TODO delete this if statement in future to create team
    if (isEdit) {
      setInviteForm({ name, email, role });
    }
  };

  useEffect(() => {
    fetchAllPermission();
    // TODO disable this if statement in future to create team
    //fetchTeams();
  }, []);

  const handleChange = (e) => {
    setInviteForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setErrors((prev) => ({ ...prev, [e.target.name]: "" }));
  };

  const showNotification = (msg) => {
    setBackendMsg({
      msg,
      type: "success",
    });
    setInviteForm({ ...initialFormState });
    setEditUserDetail({});
    setTimeout(() => {
      setShowInviteUser(false);
    }, 1000);
  };

  const validateFormData = async () => {
    const newErrors = { ...errors };
    let isValid = true;

    if (!inviteForm.name) {
      newErrors.name = "Name is Required";
      isValid = false;
    } else if (inviteForm.name.length < 3) {
      newErrors.name = "Name is too short";
      isValid = false;
    }

    if (!inviteForm.email) {
      newErrors.email = "Email is Required";
      isValid = false;
    } else if (!EMAIL_REGEX.test(inviteForm.email)) {
      newErrors.email = "Email is invalid";
      isValid = false;
    }

    if (!inviteForm.role) {
      newErrors.role = "Role is Required";
      isValid = false;
    }

    if (!isValid) {
      setErrors(newErrors);
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
  
    const formData = {
      ...inviteForm,
      accessPermission: showAccess,
      permissionList: checkboxValue,
      name : inviteForm.name.trim()
    };
  
    const isValid = await validateFormData();
    if (!isValid) return;
  
    try {
      if (isEdit) {
        await service.editUserDetail({
          payload: formData,
          userId: editUserDetail._id,
        });
        showNotification("User Detail Updated Successfully");
      } else {
        const result = await inviteUser({ payload: formData, tenantId });
        Swal.fire({
          title: "User Invited Successfully!",
          text: "The user has been invited successfully.",
          icon: "success",
          confirmButtonColor: "#8db178",
        });
        resetValues();
        setShowInviteUser(false);
      }
    }
      catch (error) {
        Swal.fire({
          title: "Error!",
          text: error.response?.data?.message || "An error occurred during user invitation.",
          icon: "error",
          confirmButtonColor: "#d33",
        });
      setBackendMsg({ msg: error.response.data.message, type: "error" });
    }
  };
  
  console.log(checkboxValue);

  return (
    <InviteTeamMemberStyles>
      {backendMsg.msg && <CustomSnackbar payload={backendMsg} />}
      <>
        <div className="top">
          <h2 className="top-heading">
            {isEdit ? "Edit Team Member" : "Invite Team Member"}
          </h2>

          <ActionButtonGroup
            handlePrimaryButtonClick={handleSubmit}
            handleSecondaryButtonClick={handleCancel}
            secondaryButtonLabel={"Cancel"}
            primaryButtonLabel={"Update"}
            hidePrimaryButton={!isEdit}
          />
        </div>
        <div className="underline"></div>
        <div className="container">
          <h3>{isEdit ? "Edit" : "Invite"} a user</h3>
          <p className="invite-msg">
            {isEdit ? "Edit" : "Invite"} your team members and configure permissions and essential details.
          </p>
          <div className="form-container">
            <div className="form-row">
              {formValue.map(({ type, name, placeholder }, idx) => {
                return (
                  <div
                    key={getKey(name, "formValue", idx)}
                    className="form-row"
                  >
                    <h4 className="label">{name}</h4>
                    <StyledTextField
                      type={type}
                      required
                      value={inviteForm[name]}
                      disabled={isEdit && type === 'email'}
                      placeholder={placeholder}
                      name={name}
                      error={Boolean(errors[name])}
                      helperText={errors[name]}
                      onChange={handleChange}
                    />
                  </div>
                );
              })}
              <h4 className="label">Role</h4>
              <StyledSelect
                name="role"
                required
                displayEmpty
                value={inviteForm.role}
                error={Boolean(errors["role"])}
                onChange={handleChange}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p className="placeHolder">Select Role</p>;
                  }

                  return selected;
                }}
              >
                <MenuItem disabled>
                  <em>Select Role</em>
                </MenuItem>
                <MenuItem value={"Agent"}>Agent</MenuItem>
                <MenuItem value={"Admin"}>Admin</MenuItem>
              </StyledSelect>
              {errors.role && (
                <FormHelperText
                  sx={{ color: "red !important", marginLeft: "15px " }}
                >
                  {errors.role}
                </FormHelperText>
              )}
              {/* enable it in future to create teams */}
              {/* <h4 className="label">Team</h4>
              <StyledSelect
                name="team"
                required
                displayEmpty
                value={inviteForm.team}
                onChange={handleChange}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p className="placeHolder">Select Team</p>;
                  }
                  return selected;
                }}
              >
                <MenuItem value="">
                  <em>Select Team</em>
                </MenuItem>
                {teams.map((item, idx) => {
                  const { name } = item;
                  return (
                    <MenuItem key={getKey(name, "teams", idx)} value={name}>
                      {name}
                    </MenuItem>
                  );
                })}
              </StyledSelect> */}
            </div>
            {/* <div className="permission">
              <h4>Access Permission</h4>
              <FormControlLabel
                style={{ marginRight: "-.2rem" }}
                required
                control={
                  <Switch
                    checked={showAccess}
                    onClick={(e) => setShowAccess(e.target.checked)}
                  />
                }
              />
            </div> */}
            {showAccess &&
              checkboxValue.map((item, idx) => {
                const { name, value, label } = item;
                return (
                  <FormControlLabel
                    key={idx}
                    style={{ width: "200px" }}
                    label={name}
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(e) => {
                          const updatedValue = [...checkboxValue];
                          updatedValue[idx].value = e.target.checked;
                          setCheckboxValue(updatedValue);
                        }}
                      />
                    }
                  />
                );
              })}
            {!isEdit && (
              <div className="btn">
                <PrimaryButton variant="contained" onClick={handleSubmit}>
                  Send Invite to user
                </PrimaryButton>
              </div>
            )}
          </div>
        </div>
      </>
    </InviteTeamMemberStyles>
  );
};

export default InviteTeamMember;

InviteTeamMember.propTypes = {
  setShowInviteUser: PropTypes.func,
  editUserDetail: PropTypes.object,
  setEditUserDetail: PropTypes.func,
};
