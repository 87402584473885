import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useInboxContext } from "../../context/inboxContext/inboxContext";

const MessageImage = ({ loading, imageSrc, id, width , height }) => {
  console.log({width , height})
  const [imageLoaded, setImageLoaded] = useState(true);
  const [imageRequested, setImageRequested] = useState(false);
  const [containerStyle, setContainerStyle] = useState(
    {
        width,
        height,
        position: 'relative',
        borderRadius: '2rem'
    }
  );

  const isImageUploading = id === "loadingImage";
  // const isImageUploading =true
  useEffect(() => {
    setImageRequested(true);
    
  }, []);

  let containerHeight =  width < height ? 400 : 200
  useEffect(() => {
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      const containerWidth = 300; 

      if (img.width === img.height) {
        containerHeight = 300;
      }

      setContainerStyle({
        width: `${containerWidth}px`,
        height: `${containerHeight}px`,
        position: 'relative',
        borderRadius: '2rem'
      });
      setImageLoaded(false);
    };
  }, [imageSrc]);

  return (
    <div style={{ width : 300, height : containerHeight , overflow : 'hidden'}} className={`chat-image`}>
      {imageRequested && (
        <div  key={imageSrc} style={containerStyle}>
          <img
            src={imageSrc}
            alt=""
            style={{
              width: 300,
              height: containerHeight,
              objectFit: "cover",
              border: '1px solid silver'
            }}
          />
        </div>
      )}
      {imageLoaded && (
        <div className={imageLoaded ? "image-uploading" : "hide-upload-icon"}>
          <CircularProgress disableShrink />
        </div>
      )}
      {/* {true && (
        <div
          className={"image-uploading"}
        >
          <CircularProgress disableShrink  />
        </div>
      )} */}
    </div>
  );
};

export default MessageImage;
