import styled from "styled-components";
const AppearanceScreenStyles = styled.div`
  .container {
    width: 461px;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25);
    border-radius: 21px 21px 0px 0px;
  }
  .welcome-container {
    background-color: var(--primary-700);
    border-radius: 21px 21px 0px 0px;
    padding: 2rem;
    padding-bottom: 0.5rem;
  }
  .welcome-container h3 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  .welcome-container p {
    width: 80%;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  .icon-container {
    z-index: 1000;
    margin-left: -1rem;
    border-radius: 0.5rem;
    width: 60px;
    height: 56px;
    background-color: var(--primary-700);
    display: grid;
    place-items: center;
  }

  .contact-form {
    margin-top: 0.8rem;
    margin-left: 2rem;
  }
  .text-field {
    margin: 0.5rem 0 1rem 0;
  }
  .list-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    border-bottom: 0.5px solid #c2c2c2;
  }
  .list-btn li {
    cursor: pointer;
    font-size: 22px;
    font-weight: 700;
  }
  .active {
    color: var(--primary-500);
    border-bottom: 4px solid var(--primary-500);
    margin-bottom: -0.7rem;
  }
`;
export default AppearanceScreenStyles;
