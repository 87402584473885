import React from "react";
import ReportFilterStyles from "../../assets/styledComponents/ReportFilter";
import CustomInputDropDown from "../CustomInputDropDown";

const CustomReportFilter = ({
  heading,
  label,
  icon,
  selectList,
  marginRight,
  handleChange,
  value
}) => {
  return (
    <ReportFilterStyles>
      <div className="channel paper-card" >
        <h4 style={{marginRight}}>Filter By {heading}</h4>
        <div className="channel-dropdown">
          <CustomInputDropDown
            icon={icon}
            label={label}
            selectList={selectList}
            handleChange={handleChange}
            value={value}
          />
        </div>
      </div>
    </ReportFilterStyles>
  );
};

export default CustomReportFilter;
