import styled from "styled-components";
const VerifyOtpStyles = styled.div`
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10vh;
    padding: 0 3rem;
  }
  header {
    font-size: 1.2rem;
  }
  .close-icon {
    cursor: pointer;
  }
  .container {
    margin: 0 auto;
    margin-top: 20vh;
    width: 50%;
    height: 50vh;
    padding: 3rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .right{
    margin-left:650px;
    width:600px
  }
  .onbording-user{
    margin-bottom: 14px;
    font-size: 28px;
    font-weight: 500;
  }
  .heading {
    font-size: 20px;
    font-weight: 300;
    margin-bottom:30px
  }
  .verified {
    margin: auto 0;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
  }
  .verified p {
    margin-bottom: 2rem;
  }

  .password-wrapper {
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }
  .password-input {
    width: 500px;
  }

  .password-error {
    margin-top: -2rem;
    width: 500px;
    margin-bottom: -1rem;
  }
  .password-error ul {
    font-size: 0.8rem;
    display: flex;
    flex-wrap: wrap;
  }
  .list {
    display: flex;
    align-items: center;
    width: 180px;
  }
`;
export default VerifyOtpStyles;
