import styled from "styled-components";

const LabelModalStyles = styled.div`
   .container{
    // padding:18px;
    margin:20px
   }
  .label-container {
    height: 350px;
    width: 350px;
    /* background-color: red; */
    overflow-y: auto;
    width: 339px;
  }
  .search-container {
    margin-left: 1rem;
    margin-top: 1rem;
  }
  .title {
    margin: 1rem 0;
    margin-left: 3px;
    font-size: 17px;
    margin: 12px 0px;
    paddingLeft: 8px;
    }
  ul {
    gap: 1rem;
    // margin-bottom: 1rem;
  }
  li {
    // padding: 0.5rem 0;
    display: flex;
    margin-left: -13px;
    align-items: center;
    gap: 1rem;

    color: #666666;
    font-size: 19px;
    margin: 6px;
    cursor: pointer;

  }

  .active {
    background-color: var(--primary-100);
    border-radius: 0.2rem;
  }
  .btn-container {
    text-align: right;
    margin-bottom: 2rem;
    margin-right: 1rem;
    margin-top:10px
  }

  .search-box {
    width: 82%;
  }
`;
export default LabelModalStyles;
