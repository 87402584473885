import { useState } from "react";
import notificationSound from "../assets/audio/notificationSound.mp3";
const useNotification = () => {
  const [playSound, setPlaySound] = useState();

  const playNotification = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  const playNotificationSound = () => {
    setPlaySound(true);
    playNotification();
  };
  return [playNotificationSound];
};

export default useNotification;
