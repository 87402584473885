import React from "react";
import FacebookCardStyles from "../../../assets/styledComponents/settings/FacebookCard";
import MetaIntegrationForm from "../../reusable/ChannelConfiguration/MetaIntegrationForm";
import HeaderBar from "../../reusable/HeaderBar/HeaderBar";

const InstagramConfiguration = ({ tabValue, setDisableSaveBtn, stateValues  }) => {
  const data = {
    head: "Instagram Configuration",
    title: "Instagram Page",
    switch1: "Comments",
    switch2: "Direct Messages",
  };
  return (
    <>
      <FacebookCardStyles>
        <MetaIntegrationForm {...data} tabValue={tabValue} setDisableSaveBtn={setDisableSaveBtn}
          stateValues ={stateValues}
        />
      </FacebookCardStyles>
    </>
  );
};
export default InstagramConfiguration;
