import styled from "styled-components";

const FilterLabelStyles = styled.div`
  padding: 1rem;
  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0.3rem;
    margin-bottom: 0.3rem;
  }
  .top h3{
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
  }
  input[type="search"] {
    all: unset;
    border-radius: 0.5rem;
    width: 150px;
    padding: 0.5rem;
    background-color: #d9d9d9;
    border: none;
    padding-left: 3rem;
    padding-top: 0.6rem;
    margin-bottom:15px
  }
  .search-container {
    position: relative;
  }
  .search-btn {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 12px;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  .info {
    width: 70%;
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-between;
  }
  .btn-primary {
    float: right;
    margin-bottom: 1rem;
  }
  .btn-container {
    text-align: right;
    margin-top: 0.5rem;
    bottom: 0;
  }
  .label-container {
    display: flex;
    height: 380px;
    overflow-y: auto;
    flex-direction: column;
  }
  .priority-container {
    height: 408px;
  }
`;
export default FilterLabelStyles;
