import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import { Avatar } from "@mui/material";
import DialogModal from "../reusable/DialogModal/Modal";
import { AssignToStyles } from "../../assets/styledComponents/message";
import { assignTo, getAllUserSetup } from "../../service/service";
import { useTenantContext } from "../../context/TenantContext";
import { getKey, stringAvatar } from "../../utils/helperFunction";
import { useInboxContext } from "../../context/inboxContext/inboxContext";

const AssignToModal = ({
  modalName,
  isOpenModal,
  handleCloseModal,
  setBackendMessage,
}) => {
  const { tenantId, profile } = useTenantContext();
  const { socket, currentSessionData, setCurrentSessionData, refreshChatSessionsListOnly } =
    useInboxContext();

  const { _id: sessionId } = currentSessionData;

  const [selectedPerson, setSelectedPerson] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserSetup = async () => {
    try {
      const result = await getAllUserSetup({ tenantId });
      setUserDetails(result.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserSetup();
  }, []);

  const handleUserSelection = async (selectedUserId, name) => {
    try {
      const result = await assignTo({ sessionId, selectedUserId });
      setBackendMessage({
        msg: `Chat message assigned to ${name} Successfully`,
        type: "success",
      });
      socket?.emit(
        "AGENT_ASSIGNED",
        sessionId,
        tenantId,
        name,
        selectedUserId,
        result.data?.assignUser?.profileImageUrl
      );
      setCurrentSessionData(result.data);
      refreshChatSessionsListOnly();
      handleCloseModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DialogModal
      name={modalName}
      onClose={() => handleCloseModal()}
      open={isOpenModal}
      fullWidth={true}
    >
      <AssignToStyles>
        <div className="modal-container">
          <div className="search-container">
            <input className="search-box" type="search" placeholder="Search" />
            <div className="search-btn">
              <SearchIcon />
            </div>
          </div>
          <ul>
            {userDetails.map(({ _id, name, profileImageUrl }, index) => {
              return (
                <li
                  className={`person ${selectedPerson === name ? "active" : null
                    }`}
                  key={getKey(name, "assign_user", index)}
                  onClick={() => handleUserSelection(_id, name)}
                >
                  {profileImageUrl ? (
                    <Avatar src={profileImageUrl} />
                  ) : (
                    <Avatar {...stringAvatar(name)}></Avatar>
                  )}
                  <span>
                    {name} {_id === profile?.token?.userId ? "(Myself)" : ""}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </AssignToStyles>
    </DialogModal>
  );
};

export default AssignToModal;
AssignToModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
