import React, { createContext, useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { decodeJwt } from "../utils/helperFunction";
import { getAllTenantStore } from "../service/service";

const TenantContext = createContext({});

const TenantProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [tenantId, setTenantId] = useState();
  const [userId, setUserId] = useState();
  const [isTokenLoading, setTokenLoading] = useState(true);
  const [openSidebar, setOpenSideBar] = useState(false);
  const [iconTitle, setIconTitle] = useState("inbox");
  const [tenantStores, setTenantStores] = useState([]);
  const [previewActive, setPreviewActive] = useState({
    isActive: false,
    sessionId: "",
  });
  const [selectedShop, setSelectedShop] = useState("");
  const [refreshProfile,setRefreshProfile] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      let decodedToken = decodeJwt(token);
      setProfile({
        token: decodedToken,
        isMenuDisabled: !decodedToken.shop || !decodedToken.pricingPlan,
        role: decodedToken.role,
        permissions: decodedToken.permissions,
      });
      setTenantId(decodedToken.tenantId);
      setUserId(decodedToken.userId);

      setTokenLoading(false);
    } else {
      setTokenLoading(false);
    }
  }, [refreshProfile]);

  const setToken = (token) => {
    let decodedToken = decodeJwt(token);
    setProfile({
      token: decodedToken,
      isMenuDisabled: !decodedToken.shop || !decodedToken.pricingPlan,
    });
    setRefreshProfile((prev) => !prev)
    setTenantId(decodedToken.tenantId);
    setUserId(decodedToken.userId);
    localStorage.setItem("token", token);
    localStorage.setItem("shop", decodedToken.shop);
    setTokenLoading(false)
  };

  const fetchStores = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      let decodedToken = decodeJwt(token);
      const tabsRes = await getAllTenantStore(decodedToken.tenantId);
      if (tabsRes.success) {
        setTenantStores(
          tabsRes.data.map((item) => ({ name: item?.shop, value: item?.shop }))
        );
      }
    }
  };

  useEffect(() => {
    fetchStores();
  }, []);





  return (
    <TenantContext.Provider
      value={{
        profile,
        setProfile,
        setToken,
        tenantId,
        isTokenLoading,
        setTokenLoading,
        userId,
        openSidebar,
        setOpenSideBar,
        iconTitle,
        setIconTitle,
        tenantStores,
        previewActive,
        setPreviewActive,
        selectedShop, 
        setSelectedShop
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};

TenantProvider.propTypes = {
  children: PropTypes.element,
};

/**
 * Custom hook to use the TenantContext.
 * @returns {{ userId: string, tenantId: string, profile: string, setProfile : Function, tenantStore: string , previewActive : Boolean , setPreviewActive : Function  }}
 */
const useTenantContext = () => {
  return useContext(TenantContext);
};

export { TenantContext, TenantProvider, useTenantContext };
