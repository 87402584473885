import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import PrimaryButton from "../../reusable/PrimaryButton/PrimaryButton";

const DnsTable = ({ domainDnsRecord, handleDnsVerify }) => {
  function createData(name, host_name, type, value) {
    return { name, host_name, type, value };
  }

  const dkim_record = domainDnsRecord?.[0]?.dkim_record;
  const { type, value, host_name, status } = dkim_record || {};

  const brevo_code = domainDnsRecord?.[1]?.brevo_code;
  const {
    type: brevoType,
    value: brevoValue,
    host_name: brevoHostName,
    status: brevoStatus,
  } = brevo_code || {};

  const rows = [
    createData("dkim_record", host_name, type, value, status),
    createData("brevo_code", brevoHostName, brevoType, brevoValue, brevoStatus),
  ];

  return (
    <TableContainer sx={{ borderRadius: "0" }} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left">Hostname</TableCell>
            <TableCell align="left">Type </TableCell>
            <TableCell align="left">Add this value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const { name, type, value, host_name, status } = row || {};
            return (
              <TableRow
                key={name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {name}
                </TableCell>
                <TableCell align="left">{host_name}</TableCell>
                <TableCell align="left">{type}</TableCell>
                <TableCell
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    wordBreak: 'break-all',
                  }}
                  align="left"
                >
                  <p style={{ width: "fit-content" }}>{value}</p>
                  {/* {!status && <PrimaryButton onClick={(e) => handleDnsVerify(name, e)}>Verify</PrimaryButton>} */}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DnsTable;
