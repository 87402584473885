import styled from "styled-components";
const CustomReportTableStyles = styled.div`

  .table-container {
    overflow-x: auto;
    max-width: 100%;
    border: 1px solid #666666;
    border-radius: 2px;
    /* max-height: 38vh; */
    height: max(calc(100vh - 550px), 200px) ;
    overflow-y: auto;
    border-radius: 6px;
    border: 1px solid;
    border-color: var(--underline-clr);
    background: #FFF;
    padding: 0px 20px;
  }
  .table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    
  }
  .thead {
    /* background-color: green; */
  }
  .th {
    // padding: 1rem;
    // padding-inline: 2rem;

    white-space: normal;
    word-wrap: break-word;
    text-align: left; /* Adjust as needed */
    padding: 12px 8px; /* Adjust as needed */
    position: sticky;
    top: 0;
    // background-color: #f8f8f8; /* Adjust as needed */
    z-index: 1;
    font-size: 16px;
    background: white;
  }

  .tr:hover {
    background-color: var(--primary-100);
  }
  .td {
    // padding: 1.2rem;
    white-space: normal;
    word-wrap: break-word;
    text-align: left; /* Adjust as needed */
    padding: 12px 8px;
    font-size: 16px;
    // padding-inline: 2rem;
  }

  .table th:after {
    content: "";
    display: block;
    border-bottom: 0.5px solid;
    border-color: var(--underline-clr);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 15px;
    font-weight: 500;
  }
  .empty-table {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pagination {
    float: right;
    margin-top: 1rem;
  }

  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: flex-end;

`;
export default CustomReportTableStyles;
