import styled from "styled-components";
const InboxMenuStyles = styled.div`
  display: flex;
  // width: calc(100% - 45px);

  .inbox-menu-container {
    width: 230px;
    border-right: 1px solid var(--border-clr);
    position: relative;
  }

  .collapsable-inbox-menu {
    width: 50px;
  }

  .inbox-menu-container .collapsable-icon {
    color: var(--primary-500);
    top: 18px;
    position: absolute;
    right: -12px;
    cursor: pointer;
    font-size: 24px;
    background: white;
  }

  .inbox-menu-container .collapsable-icon svg {
    height: 14px !important;
    width: 14px !important;
  }

  .inbox-container {
    padding: -50rem;
    font-size: 16px;
    font-weight: 400;
    height: 85vh;
    overflow-y: auto;
  }
  .container {
    padding: -50rem;
    font-size: 16px;
    font-weight: 400;
  }
  .title {
    padding: 0 1rem;
    margin-top: 2rem;
    font-size: 16px;
    font-weight: 500;
  }
  .dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .remove-btn {
    font-size: 0rem;
    border: 3px solid #d9d9d9;
    border-radius: 0.5rem;
  }
  @media (max-width: 1300px) {
    font-size: 14px;
    .inbox-menu-container {
      width: 180px;
      font-size: 14px;
    }
    .collapsable-inbox-menu {
      width: 50px;
    }

  }
`;
export default InboxMenuStyles;
