import { useRef, useState } from "react";
const useClipboard = (text) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (clipboardText) => {
    const copyText = clipboardText || text;
    try {
      await navigator.clipboard.writeText(copyText);
      setIsCopied(true);
    } catch (error) {
      console.log(error);
    }
  };
  return [isCopied, copyToClipboard, setIsCopied];
};

export default useClipboard;
