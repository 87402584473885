import { InputAdornment } from "@material-ui/core";
import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  customTextField: {
    boxShadow: "unset !important",
    "& .MuiInputBase-root": {
      // boxShadow:
      //   "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);",
      boxShadow:
        "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
    },
  },
});

/**
 *
 * @param {TextFieldProps} rest - rest props
 * @returns {JSX.Element}
 */

const CustomTextField = ({
  sx,
  placeholder,
  width,
  value,
  boxShadow = 6,
  row = 1,
  startAdornment,
  size,
  error,
  helperText,
  handleChange,
  name,
  img,
  type = "text",
  required,
  endAdornment,
  ...rest
}) => {
  const classes = useStyles();

  let inputProps;
  if (startAdornment) {
    inputProps = {
      startAdornment: (
        <InputAdornment position="start">{startAdornment}</InputAdornment>
      ),
    };
  }

  if (endAdornment) {
    inputProps = {
      endAdornment: (
        <InputAdornment position="end">{endAdornment}</InputAdornment>
      ),
    };
  }

  return (
    <TextField
      sx={{
        width: width,
        ...sx,
        borderRadius: ".3rem",
      }}
      className={classes.customTextField}
      type={type}
      size={size}
      placeholder={placeholder}
      name={name}
      value={value}
      error={error}
      helperText={helperText}
      onChange={handleChange}
      rows={row}
      fullWidth
      required={required}
      multiline
      {...rest}
      // InputProps={{
      //   startAdornment: (
      //     <InputAdornment position="start">{startAdornment}</InputAdornment>
      //   ),
      // }}
      InputProps={inputProps}
    />
  );
};

export default CustomTextField;
