import styled from "styled-components";
const NotificationPreviewStyles = styled.div`
.container {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    padding: 0.5rem 0.25rem;
    cursor: pointer;
    border-radius: 4px;
    // box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    //   0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  .wrapper {
    height: 80vh;
    overflow-y: auto;
    padding: 0.5rem 1rem;
  }
  .message-container {
    margin-left: 0.75rem;
  }
  .isPrivate {
    background-color: #ffc453;
  }
  .social-icons {
    margin-left: auto;
    margin-right: .25rem;
  }
  .time {
    font-size: 0.75rem;
    color: gray;
    margin-top: .5rem;
    font-weight: bold;
  }
  .name {
    width: 160px;
  }
  
`;

export default NotificationPreviewStyles;