import styled from "styled-components";
const UserProfileStyles = styled.div`
  /* width: 81vw; */
  overflow-y: hidden;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .underline {
    background-color: var(--border-clr);
    width: 100%;
    height: 1px;
  }
  .table-container {
    padding: 1rem 2rem;
    height: 90vh;
    overflow-y: auto;
  }
  .table-cell {
    font-weight: 500;
    font-size: 24px;
  }
  .profile {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .name h4 {
    font-size: 19px;
    font-weight: 500;
  }
  .items {
    font-size: 18px;
    font-weight: 400;
  }
  .preview-img {
    border-radius: 7rem;
    width: 60;
    height: 60;
  }

  @media (max-width: 1368px) {
    width: 100%;
  }
`;
export default UserProfileStyles;
